import { withRouter } from "react-router-dom";
import { Component } from "react";

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      var parent_origin = "https://mylabnutrition.net";
      window.parent.postMessage(
        JSON.stringify({
          task: "scroll_top",
          height: 0
        }),
        parent_origin
      );

      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
