import React from "react";
import { Column, Columns, Container } from "bloomer";
import "./footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import CookieSettingsToggle from "../../../components/CookieSettingsToggle";
import { faFacebook, faInstagram, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";

export default class MFooter extends React.Component {
  render() {
    return (
      <div className={this.props.className}>
        <section className="pt-40 pb-80 footer vw-mobile-only">
          <Container hasTextAlign="centered">
            <Columns isMultiline>
              <FooterLogo />
              <FooterSocial />
              <Column isSize="full">
                <Columns isMobile>
                  <FooterLinksL />
                  <FooterLinksR />
                </Columns>
              </Column>
              <FooterCopyright />
            </Columns>
          </Container>
        </section>
        <section className="pt-80 footer vw-tablet">
          <Container hasTextAlign="centered">
            <Columns className="footer-columns">
              <Column>
                <FooterLogo />
              </Column>
              <FooterLinksL />
              <FooterLinksR />
              {/* <Column className="footer-menu">
                <h5 className="title">
                  Qualità controllata
                  <br /> e totalmente italiana
                </h5>
                <img src="/images/loghi/acef-sm.png" width="60px" alt="" />
                &nbsp;&nbsp;
                <img src="/images/loghi/nutrival-sm.png" width="60px" alt="" />
              </Column>
              <Column className="footer-menu">
                <h5 className="title">
                  Spedizione gratuita
                  <br /> e pagamenti sicuri
                </h5>
                <img src="/images/loghi/paypal.png" width="120px" alt="" />
              </Column> */}
            </Columns>
            <div className="mt-30 mb-20">
              <FooterSocial />

              <FooterCopyright />
            </div>
          </Container>
        </section>
      </div>
    );
  }
}

class FooterLogo extends React.Component {
  render() {
    return (
      <Column isSize="full">
        <img src="/images/MyLab-Nutrition-Group.png" className="footer-logo" alt="" />
        <img src="/images/loghi_group_rosso.png" className="footer-logo-brand" alt="" />
      </Column>
    );
  }
}

class FooterSocial extends React.Component {
  render() {
    return (
      <Column isSize="full" className="footer-social">
        <a target="_blank" rel="noopener noreferrer" href="https://fb.me/mylabnutrition" className="social-icon fb">
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a target="_blank" rel="noopener noreferrer" href="https://instagram.com/mylab_nutrition" className="social-icon insta">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/mylabnutrition/" className="social-icon linked">
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/mylab_nutrition" className="social-icon tw">
          <FontAwesomeIcon icon={faTwitter} />
        </a>
      </Column>
    );
  }
}

class FooterLinksL extends React.Component {
  render() {
    return (
      <Column className="footer-menu">
        <Link to="/come-funziona">Come Funziona</Link>
        {/* <Link to="/quality">Qualità</Link> */}
        <Link to="/chi-siamo">Chi siamo</Link>
        <Link to="/affiliate">Affiliati</Link>
      </Column>
    );
  }
}

class FooterLinksR extends React.Component {
  render() {
    return (
      <Column className="footer-menu">
        <Link to="/contatti">Contattaci</Link>
        <Link to="/privacy">Privacy</Link>
        <Link to="/terms">Termini d'uso</Link>
        <CookieSettingsToggle>
          <span className="footer-link">Cookies</span>
        </CookieSettingsToggle>
      </Column>
    );
  }
}

class FooterCopyright extends React.Component {
  render() {
    return (
      <Column isSize="full" className="footer-copyright">
        MyLab Nutrition © 2020 <br className="vw-mobile-only" />
        | MyLab Nutrition S.R.L. | IT 14602891005 |
        <br className="vw-mobile-only" />
        Via Marsala 29/h - 00185 Rome (Italy)
      </Column>
    );
  }
}
