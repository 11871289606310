export default {
  data: [
    {
      description: "Scegli la categoria principale nella quale vuoi maggiormente ottenere risultati (massimo 3 scelte)",
      percentage: 10,
      questions: [
        {
          formName: "categorieAI",
          fullwidth: true,
          kind: "checkbox2",
          maxSelection: 3,
          order: 1,
          values: [
            { label: "Salute dei Capelli", value: "salutedeicapelli" },
            { label: "Benessere Pelle", value: "benesserepelle" },
            { label: "Problemi alle vie urinarie", value: "problemiallevieurinarie" },
            { label: "Ciclo e menopausa", value: "cicloemenopausa" },
            { label: "Articolazioni e ossa", value: "articolazionieossa" },
            { label: "Problemi alla circolazione", value: "problemiallacircolazione" },
            { label: "Cellulite e ritenzione idrica", value: "celluliteeritenzioneidrica" },
            { label: "Depurazione e benessere intestinale", value: "depurazioneebenessereintestinale" },
            { label: "Problemi alla digestione", value: "problemididigestione" },
            { label: "Perdita di peso e colesterolo", value: "perditadipesoecolesterolo" },
            { label: "Difese immunitarie", value: "difeseimmunitarie" },
            { label: "Energia e spossatezza", value: "energiaespossatezzaquotidiana" },
            { label: "Ansia sonno e stress", value: "ansiasonnoestress" },
            { label: "Attività sportiva e aumento massa", value: "attivitàsportivaeaumentomassa" },
          ],
        },
      ],
      uid: 0,
    },

    {
      percentage: 15,
      questions: [
        { formName: "name", kind: "text", order: 1, placeholder: "Come ti chiami?", title: "Nome" },
        {
          formName: "age",
          kind: "number",
          order: 3,
          max: 120,
          min: 5,
          title: "Età",
          placeholder: "La tua età",
        },
        {
          formName: "sex",
          kind: "radiobox",
          order: 4,
          title: "Sesso",
          values: [
            { label: "Uomo", value: "male" },
            { label: "Donna", value: "female" },
          ],
        },
        { formName: "email", kind: "text", onlyGuest: true, order: 2, placeholder: "La tua email", title: "Email" },
        { formName: "privacy", kind: "privacy", onlyGuest: true, values: [{ label: "privacy", value: "none" }] },
        { formName: "newsletter", kind: "newsletter", values: [{ label: "newsletter", value: "newsletter_yes" }] },
      ],
      uid: 1,
    },

    {
      description: "Parliamo della tua fisionomia",
      percentage: 20,
      questions: [
        { formName: "weight", kind: "number", max: 300, min: 40, order: 1, title: "Peso" },
        { errorMessage: "Inserire un' altezza compresa tra 80 e 250 cm", formName: "height", kind: "number", max: 250, min: 80, order: 2, title: "Altezza" },
        {
          formName: "bodysize",
          kind: "radiobox",
          order: 3,
          title: "STRUTTURA FISICA",
          values: [
            { label: "Esile", order: 1, value: "light" },
            { label: "Normale", order: 2, value: "normal" },
            { label: "Robusta", order: 3, value: "muscle" },
          ],
        },
      ],
      uid: 2,
    },
  ],
};
