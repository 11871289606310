export default {
  data: [
    {
      description: "In quali tra queste problematiche riferite a problemi all'apparato urogenitale?",
      percentage: 30,
      questions: [
        {
          formName: "target",
          fullwidth: true,
          kind: "radiobox2",
          order: 1,
          values: [
            { label: "Prostatite", value: "prostatite" },
            // { label: "Infiammazione vescicale e incontinenza", value: "incontinenza" }
            // { label: "Uretrite", value: "uretrite" },
            { label: "Infezioni vie urinarie", value: "infezione_urinarie" }
          ]
        }
      ],
      uid: 0
    },
    //////////////////  PROSTATITE //////////////////////
    {
      condition: [{ formName: "target", value: ["prostatite"] }],

      description: "Hai bruciore o dolore durante la minzione?",
      percentage: 40,
      questions: [
        {
          formName: "bruciore_minzione",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "bruciore_minzione_si" },
            { label: "No", value: "bruciore_minzione_no" }
          ]
        }
      ],
      uid: 1
    },
    {
      condition: [{ formName: "target", value: ["prostatite"] }],

      description: "Hai un'alimentazione sregolata?",
      percentage: 50,
      questions: [
        {
          formName: "prostatite_alimentazione_sregolata",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "prostatite_alimentazione_sregolata_si" },
            { label: "No", value: "prostatite_alimentazione_sregolata_no" }
          ]
        }
      ],
      uid: 2
    },
    {
      condition: [{ formName: "target", value: ["prostatite"] }],

      description: "Soffri di problemi intestinali?",
      percentage: 60,
      questions: [
        {
          formName: "prostatite_problemi_intestinali",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "prostatite_problemi_intestinali_si" },
            { label: "No", value: "prostatite_problemi_intestinali_no" }
          ]
        }
      ],
      uid: 3
    },
    {
      condition: [{ formName: "target", value: ["prostatite"] }],

      description: "Hai un'attività sessuale regolare?",
      percentage: 80,
      questions: [
        {
          formName: "prostatite_attivta_sessuale",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "prostatite_attivta_sessuale_si" },
            { label: "No", value: "prostatite_attivta_sessuale_no" }
          ]
        }
      ],
      uid: 4
    },
    //////////////////  INCONTINTENZA //////////////////////
    {
      condition: [{ formName: "target", value: ["incontinenza"] }],

      description: "Hai difficoltà a trattenere l'urina dopo uno sforzo fisico?",
      percentage: 40,
      questions: [
        {
          formName: "difficolta_trattenere_urina",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "difficolta_trattenere_urina_si" },
            { label: "No", value: "difficolta_trattenere_urina_no" }
          ]
        }
      ],
      uid: 5
    },
    {
      condition: [{ formName: "target", value: ["incontinenza"] }],

      description: "Ti rechi spesso in bagno durante la giornata?",
      percentage: 60,
      questions: [
        {
          formName: "incontinenza_rechi_bagno",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "2 o 3 volte", value: "incontinenza_rechi_bagno_2" },
            { label: "3 o 4 volte", value: "incontinenza_rechi_bagno_3" },
            { label: "Più di 5", value: "incontinenza_rechi_bagno_5" }
          ]
        }
      ],
      uid: 6
    },
    {
      condition: [{ formName: "target", value: ["incontinenza"] }],

      description: "Bevi molti caffè?",
      percentage: 80,
      questions: [
        {
          formName: "incontinenza_caffe",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "1 al giorno", value: "incontinenza_caffe_1" },
            { label: "Più di 2 al giorno", value: "incontinenza_caffe_2" },
            { label: "Non bevo caffè", value: "incontinenza_caffe_no" }
          ]
        }
      ],
      uid: 7
    },
    //////////////////  URETRITE //////////////////////
    {
      condition: [{ formName: "target", value: ["uretrite"] }],

      description: "Avverti dolore durante la minzione?",
      percentage: 40,
      questions: [
        {
          formName: "dolore_minzione",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "dolore_minzione_si" },
            { label: "No", value: "dolore_minzione_no" },
            { label: "A volte", value: "dolore_minzione_a_volte" }
          ]
        }
      ],
      uid: 8
    },
    {
      condition: [{ formName: "target", value: ["uretrite"] }],

      description: "Hai difficoltà ad urinare?",
      percentage: 60,
      questions: [
        {
          formName: "difficolta_urinare",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "difficolta_urinare_si" },
            { label: "No", value: "difficolta_urinare_no" },
            { label: "A volte", value: "difficolta_urinare_a_volte" }
          ]
        }
      ],
      uid: 9
    },
    {
      condition: [{ formName: "target", value: ["uretrite"] }],

      description: "Senti il bisogno di urinare con frequenza?",
      percentage: 80,
      questions: [
        {
          formName: "urinare_con_frequenza",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "urinare_con_frequenza_si" },
            { label: "No", value: "urinare_con_frequenza_no" },
            { label: "A volte", value: "urinare_con_frequenza_a_volte" }
          ]
        }
      ],
      uid: 10
    },
    //////////////////  INFEZIONE //////////////////////
    {
      condition: [{ formName: "target", value: ["infezione_urinarie"] }],

      description: "Senti spesso un bisogno urgente di urinare?",
      percentage: 40,
      questions: [
        {
          formName: "infezione_bisogno_urinare",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "infezione_bisogno_urinare_si" },
            { label: "No", value: "infezione_bisogno_urinare_no" }
          ]
        }
      ],
      uid: 11
    },
    {
      condition: [{ formName: "target", value: ["infezione_urinarie"] }],

      description: "Avverti senso di peso e dolore alla parte lombare della schiena o nella parte superiore dell'addome?",
      percentage: 60,
      questions: [
        {
          formName: "infezione_dolore_addome",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "infezione_dolore_addome_si" },
            { label: "No", value: "infezione_dolore_addome_no" }
          ]
        }
      ],
      uid: 12
    },
    {
      condition: [{ formName: "target", value: ["infezione_urinarie"] }],

      description: "Bevi abbastanza acqua durante il giorno?",
      percentage: 70,
      questions: [
        {
          formName: "infezione_bevi_acqua",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "infezione_bevi_acqua_si" },
            { label: "No", value: "infezione_bevi_acqua_no" }
          ]
        }
      ],
      uid: 13
    },
    {
      condition: [{ formName: "target", value: ["infezione_urinarie"] }],

      description: "Hai una corretta regolarità intestinale?",
      percentage: 80,
      questions: [
        {
          formName: "infezione_regolarita_intestinale",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "infezione_regolarita_intestinale_si" },
            { label: "No", value: "infezione_regolarita_intestinale_no" }
          ]
        }
      ],
      uid: 14
    }
  ]
};
