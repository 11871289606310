export default {
  data: [
    {
      description: "In quali tra queste problematiche riferite al tuo sistema digestivo ti ritrovi?",
      percentage: 30,
      questions: [
        {
          formName: "target",
          kind: "radiobox2",
          order: 1,
          values: [
            { label: "Disturbi digestivi", value: "disturbi_digestivi" },
            { label: "Colon irritabile", value: "colon" },
            { label: "Stitichezza", value: "stitichezza" },
            { label: "Flatulenza", value: "flatulenza" }
          ]
        }
      ],
      uid: 0
    },
    ////////////////// DISTURBI DIGESTIVI //////////////////////
    {
      condition: [{ formName: "target", value: ["disturbi_digestivi"] }],

      description: "Dopo i pasti hai sonnolenza?",
      percentage: 40,
      questions: [
        {
          formName: "sonnolenza",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "sonnolenza_si" },
            { label: "No", value: "sonnolenza_no" },
            { label: "A volte", value: "sonnolenza_a_volte" }
          ]
        }
      ],
      uid: 1
    },
    {
      condition: [{ formName: "target", value: ["disturbi_digestivi"] }],

      description: "Avverti dopo i pasti pesantezza di stomaco?",
      percentage: 50,
      questions: [
        {
          formName: "pesantezza",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "pesantezza_si" },
            { label: "No", value: "pesantezza_no" },
            { label: "Dipende", value: "pesantezza_dipende" }
          ]
        }
      ],
      uid: 2
    },
    {
      condition: [{ formName: "target", value: ["disturbi_digestivi"] }],

      description: "Senti spesso il bisogno di espellere l'aria?",
      percentage: 60,
      questions: [
        {
          formName: "espellere_aria",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "espellere_aria_si" },
            { label: "No", value: "espellere_aria_no" }
          ]
        }
      ],
      uid: 3
    },
    {
      condition: [{ formName: "target", value: ["disturbi_digestivi"] }],

      description: "Ti capita di frequente di avere dei rigurgiti di acidità?",
      percentage: 65,
      questions: [
        {
          formName: "rigurgiti",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "rigurgiti_si" },
            { label: "No", value: "rigurgiti_no" }
          ]
        }
      ],
      uid: 4
    },
    {
      condition: [{ formName: "target", value: ["disturbi_digestivi"] }],

      description: "Le tue feci sono a pallini?",
      percentage: 70,
      questions: [
        {
          formName: "feci",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "feci_si" },
            { label: "No", value: "feci_no" },
            { label: "A volte", value: "feci_a_volte" }
          ]
        }
      ],
      uid: 5
    },
    {
      condition: [{ formName: "target", value: ["disturbi_digestivi"] }],

      description: "Fai uso di antiacidi?",
      percentage: 80,
      questions: [
        {
          formName: "antiacidi",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "antiacidi_si" },
            { label: "No", value: "antiacidi_no" },
            { label: "A volte", value: "antiacidi_a_volte" }
          ]
        }
      ],
      uid: 6
    },
    ////////////////// COLON IRRITABILE //////////////////////
    {
      condition: [{ formName: "target", value: ["colon"] }],

      description: "Mastichi i cibi velocemente?",
      percentage: 40,
      questions: [
        {
          formName: "mastichi_velocemente",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "mastichi_velocemente_si" },
            { label: "No", value: "mastichi_velocemente_no" },
            { label: "Solo quando sono nervoso/a", value: "mastichi_velocemente_nervoso" }
          ]
        }
      ],
      uid: 7
    },
    {
      condition: [{ formName: "target", value: ["colon"] }],

      description: "Soffri spesso di fastidi addominali?",
      percentage: 50,
      questions: [
        {
          formName: "fastidi_addominali",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "fastidi_addominali_si" },
            { label: "No", value: "fastidi_addominali_no" },
            { label: "A volte", value: "fastidi_addominali_a_volte" }
          ]
        }
      ],
      uid: 8
    },
    {
      condition: [{ formName: "target", value: ["colon"] }],

      description: "Noti che le tue feci sono spesso alterate?",
      percentage: 60,
      questions: [
        {
          formName: "feci_alterate",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "feci_alterate_si" },
            { label: "No", value: "feci_alterate_no" },
            { label: "A volte", value: "feci_alterate_a_volte" }
          ]
        }
      ],
      uid: 9
    },
    {
      condition: [{ formName: "target", value: ["colon"] }],

      description: "Le tue scariche sono frequenti e/o alternate a diarrea e stitichezza?",
      percentage: 70,
      questions: [
        {
          formName: "diarrea",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "diarrea_si" },
            { label: "No", value: "diarrea_no" },
            { label: "A volte", value: "diarrea_a_volte" }
          ]
        }
      ],
      uid: 10
    },
    {
      condition: [{ formName: "target", value: ["colon"] }],

      description: "Fai un uso eccessivo di carboidrati raffinati?",
      percentage: 80,
      questions: [
        {
          formName: "carboidrati_raffinati",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "carboidrati_raffinati_si" },
            { label: "No", value: "carboidrati_raffinati_no" },
            { label: "A volte", value: "carboidrati_raffinati_a_volte" }
          ]
        }
      ],
      uid: 11
    },
    ////////////////// STITICHEZZA //////////////////////
    {
      condition: [{ formName: "target", value: ["stitichezza"] }],

      description: "Soffri di disturbi intestinali?",
      percentage: 40,
      questions: [
        {
          formName: "disturbi_intestinali",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "disturbi_intestinali_si" },
            { label: "No", value: "disturbi_intestinali_no" },
            { label: "A volte", value: "disturbi_intestinali_a_volte" }
          ]
        }
      ],
      uid: 12
    },
    {
      condition: [{ formName: "target", value: ["stitichezza"] }],

      description: "Conduci una vita stressata?",
      percentage: 50,
      questions: [
        {
          formName: "vita_stressata",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "vita_stressata_si" },
            { label: "No", value: "vita_stressata_no" }
          ]
        }
      ],
      uid: 13
    },
    {
      condition: [{ formName: "target", value: ["stitichezza"] }],

      description: "Consumi abitualmente fibre (frutta, verdure, cereali)?",
      percentage: 60,
      questions: [
        {
          formName: "fibre",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Tutti i giorni", value: "fibre_tutti_giorni" },
            { label: "Ogni tanto", value: "fibre_ogni_tanto" },
            { label: "Quasi mai", value: "fibre_mai" }
          ]
        }
      ],
      uid: 14
    },
    {
      condition: [{ formName: "target", value: ["stitichezza"] }],

      description: "Bevi acqua durante la giornata?",
      percentage: 70,
      questions: [
        {
          formName: "bevi_acqua",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "bevi_acqua_si" },
            { label: "Non molta", value: "bevi_acqua_non_molta" },
            { label: "Solo d'estate", value: "bevi_acqua_estate" }
          ]
        }
      ],
      uid: 15
    },
    {
      condition: [{ formName: "target", value: ["stitichezza"] }],

      description: "Conduci una vita sedentaria?",
      percentage: 80,
      questions: [
        {
          formName: "vita_sedentaria",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "vita_sedentaria_si" },
            { label: "No", value: "vita_sedentaria_no" },
            { label: "Faccio sport regolarmente", value: "vita_sedentaria_sport" }
          ]
        }
      ],
      uid: 16
    },
    {
      condition: [{ formName: "target", value: ["stitichezza"] }],

      description: "Fai uso costante di lassativi?",
      percentage: 85,
      questions: [
        {
          formName: "lassativi",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "lassativi_si" },
            { label: "No", value: "lassativi_no" },
            { label: "A volte", value: "lassativi_a_volte" }
          ]
        }
      ],
      uid: 17
    },
    ////////////////// FLATULENZA //////////////////////
    {
      condition: [{ formName: "target", value: ["flatulenza"] }],

      description: "Mangi molto velocemente masticando poco?",
      percentage: 40,
      questions: [
        {
          formName: "mangi_velocemente",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "mangi_velocemente_si" },
            { label: "No", value: "mangi_velocemente_no" },
            { label: "A volte", value: "mangi_velocemente_a_volte" }
          ]
        }
      ],
      uid: 18
    },
    {
      condition: [{ formName: "target", value: ["flatulenza"] }],

      description: "Bevi bevande gassate?",
      percentage: 50,
      questions: [
        {
          formName: "bevande_gassate",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "bevande_gassate_si" },
            { label: "No", value: "bevande_gassate_no" },
            { label: "Ogni tanto", value: "bevande_gassate_ogni_tanto" }
          ]
        }
      ],
      uid: 19
    },
    {
      condition: [{ formName: "target", value: ["flatulenza"] }],

      description: "Mangi cibi ricchi di grassi e zuccheri?",
      percentage: 60,
      questions: [
        {
          formName: "cibi_grassi",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "cibi_grassi_si" },
            { label: "No", value: "cibi_grassi_no" },
            { label: "Ogni tanto", value: "cibi_grassie_ogni_tanto" }
          ]
        }
      ],
      uid: 20
    },
    {
      condition: [{ formName: "target", value: ["flatulenza"] }],

      description: "Ti senti gonfio dopo i pasti?",
      percentage: 70,
      questions: [
        {
          formName: "gonfio",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Sempre", value: "gonfio_sempre" },
            { label: "No", value: "gonfio_no" },
            { label: "Dipende da cosa mangio", value: "gonfio_ogni_tanto" }
          ]
        }
      ],
      uid: 21
    },
    {
      condition: [{ formName: "target", value: ["flatulenza"] }],

      description: "Assumi prodotti per combattere l'aerofagia?",
      percentage: 80,
      questions: [
        {
          formName: "prodotti_aerofagia",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "prodotti_aerofagia_si" },
            { label: "No", value: "prodotti_aerofagia_no" }
          ]
        }
      ],
      uid: 22
    }
  ]
};
