import React from "react";
import { chiudiButton, withModalsCtx } from "../../../contexts/ModalsContext";
import { withRouter } from "react-router-dom";
import { Button, Column, Columns, Container, Subtitle } from "bloomer";
import { withLabCtx } from "../../../contexts/LabContext";
import times from "../../../svgs/ic-24-close.svg";
import Loader from "../../../components/Loader";
import Progressbar from "../../../components/Progressbar";
import QuestionRenderer from "../../../components/consulenza/QuestionRenderer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MNavbar from "../../../components/mobile/MNavbar";
import MFooter from "../../../components/mobile/footer/MFooter";
import firebase from "firebase/app";
import Utils from "../../../Utils";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import * as qs from "query-string";

class MConsulenzaCategoriePage extends React.Component {
  state = {
    loading: true,
    valid: false,
    goForward: false,
    questionsToRender: [],
  };

  constructor(props) {
    super(props);
    this.validate = this.validate.bind(this);
    this.continue = this.continue.bind(this);
    this.loadData = this.loadData.bind(this);
    this.renderPage = this.renderPage.bind(this);
  }

  componentDidMount() {
    // window.parent.scrollTo(0, 0);
    this.loadData();
    console.log("AA mount categorie ai");

    const pageId = parseInt(this.props.match.params.pageId, 10);
    if (pageId == 0) this.saveUserLanding();
  }

  getNextPage(pageId) {
    const newPage = this.props.lab.categorieQuestions[pageId + 1];
    // console.log("AA pageid", pageId);
    if (!!newPage) {
      let nPageId = newPage.uid;
      if (!!newPage.condition) {
        console.log("AA page condition", pageId, newPage);

        let conditionSatisfied = true;

        newPage.condition.forEach((c) => {
          // const comparePage = this.state.sorted[c.page];
          const formName = c.formName;
          const answer = this.props.lab.survey[formName];
          console.log("AA page formName", formName);

          if (Array.isArray(answer)) {
            let found = false;
            answer.forEach((a) => {
              c.value.forEach((v) => {
                if (v === a) found = true;
              });
            });

            conditionSatisfied = conditionSatisfied && found;
          } else {
            let found = false;
            c.value.forEach((v) => {
              if (v === answer) found = true;
            });

            conditionSatisfied = conditionSatisfied && found;
          }
        });

        if (!conditionSatisfied) {
          return this.getNextPage(newPage.uid);
        }
      }
      if (!!this.props.ai) {
        return `/consulenzaCategorieAI/${nPageId}`;
      } else {
        return `/consulenzaCategorie/${nPageId}`;
      }
    } else {
      this.setState({ surveyResult: null });

      if (!!this.props.ai) {
        return `/consulenzaAI/0`;
      } else {
        const categoria = this.props.lab.survey["categoriaSelectedTarget"];
        return `/consulenza?cat=` + categoria;
      }
    }
  }

  async saveUserLanding() {
    const userid = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).userid;

    localStorage.setItem("user_cookie", userid);

    const data = {
      user: localStorage.getItem("user_cookie") || "-",
      data: new Date(),
    };

    await firebase
      .firestore()
      .collection("consulenza_session_landing")
      .add(data);
  }

  async saveUser() {
    const userid = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).userid;

    localStorage.setItem("user_cookie", userid);

    const data = {
      user: localStorage.getItem("user_cookie") || "-",
      data: new Date(),
    };

    await firebase
      .firestore()
      .collection("consulenza_session_started")
      .add(data);
  }

  continue() {
    if (this.state.pageId == 0) {
      window.fbq("track", "Consulenza_Inizio_Categorie");
      this.saveUser();
    }

    this.setState({ loading: true, goForward: true });

    this.props.history.push(this.getNextPage(this.state.pageId));
  }

  checkMultipleValues(q) {
    let multipleValue = 0;
    let questionToMultiply = null;
    q.forEach((qu) => {
      if (!!qu.checkPrevValue) {
        const prev = qu.checkPrevValue;
        multipleValue = this.props.lab.survey[prev.form];
        questionToMultiply = qu;
        console.log("AA val", multipleValue);
      }
    });
    let questionsToRender = q;
    if (multipleValue > 0) {
      questionsToRender = q.filter((qu) => qu.formName != questionToMultiply.formName);
      const title = questionToMultiply.title;
      const formName = questionToMultiply.formName;
      for (let i = 0; i < multipleValue; i++) {
        const index = i + 1;
        const questionToPush = { ...questionToMultiply, title: title + " " + index, formName: formName + index };
        questionsToRender.push(questionToPush);
      }
      q = questionsToRender;
      console.log("AA questions after", q);
    }

    return q;
  }

  removeTargetFromSecondaryTarget(q) {
    q.forEach((form) => {
      if ((form.formName = "targetSecondary")) {
        if (!form.originalValues) form.originalValues = form.values;

        form.values = form.originalValues.filter((v) => v.value != this.props.lab.survey["target"]);
      }
    });

    return q;
  }

  validate() {
    if (this.state.page) {
      Utils.log("validating page", this.state.pageId);

      let isValid = true;
      let isValidForAllForm = false;
      var questionsToRender = this.checkMultipleValues(this.state.page.questions);

      questionsToRender.forEach((q) => {
        Utils.log(this.props.lab.isValidQuestion);
        isValid = isValid && this.props.lab.isValidQuestion(q);
        if (!isValidForAllForm) {
          isValidForAllForm = this.props.lab.isAnswerValidForAllForm(q);
          if (isValidForAllForm) {
            questionsToRender = [];
            questionsToRender.push(q);
          }
        }
      });

      if (isValidForAllForm) isValid = isValidForAllForm;
      else questionsToRender = this.checkMultipleValues(this.state.page.questions);

      if (this.state.page.questions.length > 0 && this.state.page.questions[0].formName === "targetSecondary") {
        questionsToRender = this.removeTargetFromSecondaryTarget(questionsToRender);
      }

      Utils.log("validated page, answer valid for all form", this.state.pageId, isValidForAllForm, questionsToRender);
      Utils.log("validated page", this.state.pageId, isValid);
      this.setState({ valid: isValid, questionsToRender }, () => Utils.log(this.state.valid));
    } else this.setState({ valid: false });
  }

  loadData() {
    this.setState({
      timer: setTimeout(() => {
        if (!this.props.lab.categorieQuestions) {
          if (!!this.props.ai) this.props.lab.loadConsulenzaCategorieAIQuestions();
          else this.props.lab.loadConsulenzaCategorieQuestions();

          return this.loadData();
        }
        const pageId = parseInt(this.props.match.params.pageId, 10) || 0;

        console.log("AA this.props.lab.categorieQuestions", this.props.lab.categorieQuestions);
        const page = this.props.lab.categorieQuestions[pageId];

        // if (!page) this.props.history.push("/consulenzaCategorie");

        this.setState(
          {
            pageId: pageId,
            page: page,
            questionsToRender: page.questions,
            loading: false,
          },
          this.validate
        );
      }, 500),
    });
  }

  componentWillUnmount() {
    if (this.state.timer) {
      clearTimeout(this.state.timer);
    }
  }

  render() {
    const c = document.getElementById("container");
    if (!!c) {
      var h = c.offsetHeight;

      Utils.log("AA widonw", h);
      var parent_origin = "https://mylabnutrition.net";

      window.parent.postMessage(
        JSON.stringify({
          task: "resize",
          height: h,
        }),
        parent_origin
      );
    }

    return (
      <div>
        {/* <MNavbar /> */}
        <div className="m-consulenza-container green-background">
          <Container id="container">
            <Columns style={{ marginBottom: "0" }} isMobile>
              <Column>
                {/* <img src={times} className="is-pulled-left vw-mobile-only  consulenza-close " onClick={() => this.props.history.push("/consulenza")} alt="" />
                <h6 onClick={this.props.history.goBack()} className="title user-area-title vw-tablet">
                  <FontAwesomeIcon icon={faChevronLeft} />
                  &nbsp; Torna indietro
                </h6> */}
                <Columns isMobile>
                  <Column>
                    <h1 className="title category" style={{ fontSize: "26px", marginBottom: "20px !important" }}></h1>
                    <h1 className="vw-mobile-only" style={{ textAlign: "center", fontSize: "30px" }}>
                      {!!this.state.page && this.state.page.blockName}
                    </h1>
                  </Column>
                </Columns>
              </Column>
            </Columns>

            {!this.state.loading ? this.renderPage() : <Loader white />}
          </Container>
          {/* {this.state.page ? (
            <div className="btns-footer fixed vw-mobile-only">
              {this.state.pageId !== 0 ? (
                <Button onClick={() => this.props.history.goBack()} className="is-transparent is-bordered-white is-pulled-left">
                  Indietro
                </Button>
              ) : null}
              <Button disabled={!this.state.valid} onClick={this.continue} className="is-pulled-right">
                Continua
              </Button>
            </div>
          ) : null} */}
        </div>
        {/* <MFooter className="vw-tablet" /> */}
      </div>
    );
  }

  goBack() {
    console.log("AA goBack", this.state.pageId);
    if (this.state.pageId === 0) {
      window.top.location.href = "https://mylabnutrition.net/consulenza-ai/";
    } else {
      this.props.history.goBack();
    }
  }

  renderPage() {
    return (
      <div>
        <div className="mylab-percentage mb-20">
          <Progressbar value={this.state.page.percentage} text={this.state.page.blockName} />
        </div>
        <div className="consulenza-form">
          {this.state.page.description ? <Subtitle>{this.state.page.description}</Subtitle> : null}
          {this.state.questionsToRender.map((q) => (
            <QuestionRenderer className="is-question " key={q.formName} callback={this.validate} question={q} />
          ))}
        </div>
        <div className="ta-c mt-60">
          <Button isLoading={this.state.goForward} disabled={!this.state.valid} onClick={this.continue} className="is-primary is-blue is-half-width">
            avanti
          </Button>
        </div>
        <div className="ta-l vw-tablet">
          <Button onClick={() => this.goBack()}>indietro</Button>
        </div>
      </div>
    );
  }
}

export default withLabCtx(withModalsCtx(withRouter(MConsulenzaCategoriePage)));
