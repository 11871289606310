import React, { Component, Suspense, lazy } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import PrivateRoute from "./AppPrivateRoute";
import MultiRender from "./components/MultiRender";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Actions from "./views/Actions";
import ScrollToTop from "./components/mobile/ScrollToTop";
import { Elements } from "react-stripe-elements";

import { default as MLandingHallOffame } from "./views/mobile/halloffame/MLanding";
import { default as MLandingCrea } from "./views/mobile/crea/MLanding";
import { default as MFormulaHallOffame } from "./views/mobile/halloffame/MFormula";
import { default as MPersonalTrainerHallOffame } from "./views/mobile/halloffame/MPersonalTrainer";
import { default as MLandingConsulenza } from "./views/mobile/consulenza/MLanding";
import Loader from "./components/Loader";
import MCombinazioni from "./views/desktop/MCombinazioni";
import MConsulenzaTransizione from "./views/mobile/consulenza/MConsulenzaTransizione";
import MSapeviChe from "./views/desktop/MSapeviChe";
import MConsulenzaCategoriePage from "./views/mobile/consulenza/MConsulenzaCategoriePage";

const MCompletaDati = lazy(() => import("./views/mobile/MCompletaDati"));
const MConsulenzaPage = lazy(() => import("./views/mobile/consulenza/MConsulenzaPage"));
const MConsulenzaFinish = lazy(() => import("./views/mobile/consulenza/MConsulenzaFinish"));
const MUtente = lazy(() => import("./views/mobile/utente/MUtente"));
const MUtenteFormule = lazy(() => import("./views/mobile/utente/MUtenteFormule"));
const MUtenteOrdini = lazy(() => import("./views/mobile/utente/MUtenteOrdini"));
const MUtenteDatiAccount = lazy(() => import("./views/mobile/utente/MUtenteDatiAccount"));
const MUtenteDatiPersonali = lazy(() => import("./views/mobile/utente/MUtenteDatiPersonali"));
const MUtenteDatiFatturazione = lazy(() => import("./views/mobile/utente/MUtenteDatiFatturazione"));
const MUtenteDatiPagamento = lazy(() => import("./views/mobile/utente/MUtenteDatiPagamento"));
const MUtenteDatiSpedizione = lazy(() => import("./views/mobile/utente/MUtenteDatiSpedizione"));
const MUtentePagamenti = lazy(() => import("./views/mobile/utente/MUtentePagamenti"));
const MUtentePrivacy = lazy(() => import("./views/mobile/utente/MUtentePrivacy"));
const MHome = lazy(() => import("./views/mobile/MHome"));
const MChiSiamo = lazy(() => import("./views/mobile/MChiSiamo"));
const MComeFunziona = lazy(() => import("./views/mobile/MComeFunziona"));
const MQualita = lazy(() => import("./views/mobile/MQualita"));
const MAffiliate = lazy(() => import("./views/mobile/MAffiliate"));
const MPrivacy = lazy(() => import("./views/mobile/MPrivacy"));
const MContatti = lazy(() => import("./views/mobile/MContatti"));
const MLaboratorio = lazy(() => import("./views/mobile/MLaboratorio"));
const MRecupero = lazy(() => import("./views/mobile/MRecupero"));
const MConsulenzaRivedi = lazy(() => import("./views/mobile/consulenza/MConsulenzaRivedi"));
const MFormula = lazy(() => import("./views/mobile/MFormula"));
const MOrdine = lazy(() => import("./views/mobile/MOrdine"));
const MIngrediente = lazy(() => import("./views/mobile/MIngrediente"));
const MAddFlavour = lazy(() => import("./views/mobile/cart/MAddFlavour"));
const MCart = lazy(() => import("./views/mobile/cart/MCart"));
const MCartError = lazy(() => import("./views/mobile/cart/MCartError"));
const MCartSuccess = lazy(() => import("./views/mobile/cart/MCartSuccess"));
const MDoOrder = lazy(() => import("./views/mobile/doorder/MDoOrder"));
const MUtenteDelete = lazy(() => import("./components/mobile/MUtenteDelete"));
const MBusinessDashboard = lazy(() => import("./views/mobile/business/MBusinessDashboard"));
const MTuoBusiness = lazy(() => import("./views/mobile/business/MTuoBusiness"));
const MBusinessContratto = lazy(() => import("./views/mobile/business/MBusinessContratto"));
const MVendite = lazy(() => import("./views/mobile/business/MVendite"));
const MCondividiFormula = lazy(() => import("./views/mobile/business/MCondividiFormula"));
const MDocumentoAssunzione = lazy(() => import("./views/desktop/MDocumentoAssunzione"));
const DAdmin = lazy(() => import("./views/desktop/DAdmin"));
const MIngredienteGenerali = lazy(() => import("./views/mobile/MIngredienteGenerali"));
const MAcquisto = lazy(() => import("./views/mobile/MAcquisto"));
const MRiepilogo = lazy(() => import("./views/mobile/MRiepilogo"));
const MInfoLaboratorio = lazy(() => import("./views/mobile/crea/MInfoLaboratorio"));
const MCondividiPT = lazy(() => import("./views/mobile/MCondividiPT"));

class AppRouter extends Component {
  // state = {
  //   isMobile: true
  // };

  componentDidMount() {
    // this.setState({ isMobile: window.innerWidth < 400 });
    // this.resizeListenerObserver = window.addEventListener("resize", e => {
    //   this.setState({ isMobile: window.innerWidth < 400 });
    // });
  }

  componentWillUnmount() {
    if (this.resizeListenerObserver) this.resizeListenerObserver();
  }

  render() {
    return (
      <TransitionGroup>
        <CSSTransition key={this.props.location.key} classNames="fade" timeout={80}>
          <Suspense fallback={<Loader />}>
            <Switch location={this.props.location}>
              <ScrollToTop location={this.props.location}>
                <PrivateRoute
                  exact
                  path="/business"
                  render={(props) => (
                    <MultiRender>
                      <MBusinessDashboard {...props} />
                    </MultiRender>
                  )}
                />
                <PrivateRoute
                  exact
                  path="/contratto"
                  render={(props) => (
                    <MultiRender>
                      <MBusinessContratto {...props} />
                    </MultiRender>
                  )}
                />
                <PrivateRoute
                  exact
                  path="/tuo-business"
                  render={(props) => (
                    <MultiRender>
                      <MTuoBusiness {...props} />
                    </MultiRender>
                  )}
                />
                <PrivateRoute
                  exact
                  path="/vendite/:uid"
                  render={(props) => (
                    <MultiRender>
                      <MVendite {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/condividi-formula/:uid"
                  render={(props) => (
                    <MultiRender>
                      <MCondividiFormula {...props} />
                    </MultiRender>
                  )}
                />
                <PrivateRoute
                  exact
                  path="/me"
                  render={(props) => (
                    <MultiRender>
                      <MUtente {...props} />
                    </MultiRender>
                  )}
                />
                <PrivateRoute
                  exact
                  path="/me/formule"
                  render={(props) => (
                    <MultiRender>
                      <MUtenteFormule {...props} />
                    </MultiRender>
                  )}
                />
                <PrivateRoute
                  exact
                  path="/me/ordini"
                  render={(props) => (
                    <MultiRender>
                      <MUtenteOrdini {...props} />
                    </MultiRender>
                  )}
                />
                <PrivateRoute
                  exact
                  path="/me/account"
                  render={(props) => (
                    <MultiRender>
                      <MUtenteDatiAccount {...props} />
                    </MultiRender>
                  )}
                />
                <PrivateRoute
                  exact
                  path="/me/dati-personali"
                  render={(props) => (
                    <MultiRender>
                      <MUtenteDatiPersonali {...props} />
                    </MultiRender>
                  )}
                />
                <PrivateRoute
                  exact
                  path="/me/dati-pagamento"
                  render={(props) => (
                    <MultiRender>
                      <MUtenteDatiPagamento {...props} />
                    </MultiRender>
                  )}
                />
                <PrivateRoute
                  exact
                  path="/me/dati-spedizione"
                  render={(props) => (
                    <MultiRender>
                      <MUtenteDatiSpedizione {...props} />
                    </MultiRender>
                  )}
                />
                <PrivateRoute
                  exact
                  path="/me/dati-fatturazione"
                  render={(props) => (
                    <MultiRender>
                      <MUtenteDatiFatturazione {...props} />
                    </MultiRender>
                  )}
                />
                <PrivateRoute
                  exact
                  path="/me/pagamenti"
                  render={(props) => (
                    <MultiRender>
                      <MUtentePagamenti {...props} />
                    </MultiRender>
                  )}
                />
                <PrivateRoute
                  exact
                  path="/me/privacy"
                  render={(props) => (
                    <MultiRender>
                      <MUtentePrivacy {...props} />
                    </MultiRender>
                  )}
                />
                <PrivateRoute
                  exact
                  path="/me/delete"
                  render={(props) => (
                    <MultiRender>
                      <MUtenteDelete {...props} />
                    </MultiRender>
                  )}
                />
                <PrivateRoute
                  exact
                  path="/ordine/:orderId"
                  render={(props) => (
                    <MultiRender>
                      <MOrdine {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/chi-siamo"
                  render={(props) => (
                    <MultiRender>
                      <MChiSiamo {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/crea"
                  render={(props) => (
                    <MultiRender>
                      <MLandingCrea {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/info-laboratorio"
                  render={(props) => (
                    <MultiRender>
                      <MInfoLaboratorio {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/crea/:formulaUid"
                  render={(props) => (
                    <MultiRender>
                      <MLandingCrea {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/hall-of-fame"
                  render={(props) => (
                    <MultiRender>
                      <MLandingHallOffame {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/hall-of-fame/personaltrainer/:ptUid"
                  render={(props) => (
                    <MultiRender>
                      <MPersonalTrainerHallOffame {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/hall-of-fame/formula/:formulaUid"
                  render={(props) => (
                    <MultiRender>
                      <MFormulaHallOffame {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/consulenza"
                  render={(props) => (
                    <MultiRender>
                      <MLandingConsulenza {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/consulenza/finish"
                  render={(props) => (
                    <MultiRender>
                      <MConsulenzaFinish {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/consulenzaAI/finish"
                  render={(props) => (
                    <MultiRender>
                      <MConsulenzaFinish {...props} ai />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/consulenza/transizione"
                  render={(props) => (
                    <MultiRender>
                      <MConsulenzaTransizione {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/combinazioni"
                  render={(props) => (
                    <MultiRender>
                      <MCombinazioni {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/sapeviche"
                  render={(props) => (
                    <MultiRender>
                      <MSapeviChe {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/consulenza/review"
                  render={(props) => (
                    <MultiRender>
                      <MConsulenzaRivedi {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  path="/consulenza/:pageId(\d+)"
                  render={(props) => (
                    <MultiRender>
                      <MConsulenzaPage {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  path="/consulenzaAI/:pageId(\d+)"
                  render={(props) => (
                    <MultiRender>
                      <MConsulenzaPage ai {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  path="/consulenzaCategorie/:pageId(\d+)"
                  render={(props) => (
                    <MultiRender>
                      <MConsulenzaCategoriePage {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  path="/consulenzaCategorieAI/:pageId(\d+)"
                  render={(props) => (
                    <MultiRender>
                      <MConsulenzaCategoriePage ai {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/formulabusiness/:formulaUid"
                  render={(props) => (
                    <MultiRender>
                      <MFormula {...props} showBusinessStats="true" />
                    </MultiRender>
                  )}
                />
                {/* <Route
                  exact
                  path="/acquistoconsulenza/:formulaUid"
                  render={props => (
                    <MultiRender>
                      <MAcquisto {...props} />
                    </MultiRender>
                  )}
                /> */}
                <Route
                  exact
                  path="/riepilogoconsulenza/:formulaUid"
                  render={(props) => (
                    <MultiRender>
                      <MRiepilogo {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/prototipo/:formulaUid"
                  render={(props) => (
                    <MultiRender>
                      <MFormula {...props} shared="true" />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/formula/:formulaUid"
                  render={(props) => (
                    <MultiRender>
                      <MFormula {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  path="/formula/:formulaUid/flavours"
                  render={(props) => (
                    <MultiRender>
                      <MAddFlavour {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/condividi/:formulaUid"
                  render={(props) => (
                    <MultiRender>
                      <MCondividiPT {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/ingrediente/:ingredienteUid"
                  render={(props) => (
                    <MultiRender>
                      <MIngrediente {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/cart/:formulaUid"
                  render={(props) => (
                    <MultiRender>
                      <Elements>
                        <MCart {...props} />
                      </Elements>
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/cartError"
                  render={(props) => (
                    <MultiRender>
                      <MCartError {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/cartSuccess"
                  render={(props) => (
                    <MultiRender>
                      <MCartSuccess {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  path="/cart/do-order/:status(shipment|payment|summary)"
                  render={(props) => (
                    <MultiRender>
                      <MDoOrder {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/quality"
                  render={(props) => (
                    <MultiRender>
                      <MQualita {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/come-funziona"
                  render={(props) => (
                    <MultiRender>
                      <MComeFunziona {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/affiliate"
                  render={(props) => (
                    <MultiRender>
                      <MAffiliate {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/privacy"
                  render={(props) => (
                    <MultiRender>
                      <MPrivacy {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/terms"
                  render={(props) => (
                    <MultiRender>
                      <MPrivacy {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/contatti"
                  render={(props) => (
                    <MultiRender>
                      <MContatti {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/laboratorio"
                  render={(props) => (
                    <MultiRender>
                      <MLaboratorio {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/password-recovery"
                  render={(props) => (
                    <MultiRender>
                      <MRecupero {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/completa-dati"
                  render={(props) => (
                    <MultiRender>
                      <MCompletaDati {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/"
                  render={(props) => (
                    <MultiRender>
                      <MHome {...props} />
                      {/* <MConsulenzaCategoriePage {...props} /> */}
                    </MultiRender>
                  )}
                />
                <Route
                  path="/auth/action"
                  render={(props) => (
                    <MultiRender>
                      <Actions {...props} />
                    </MultiRender>
                  )}
                />
                <Route
                  exact
                  path="/documento-assunzione/:formulaUid"
                  render={(props) => (
                    <MultiRender>
                      <MDocumentoAssunzione {...props} />
                    </MultiRender>
                  )}
                />
                <Route render={(props) => <MultiRender>{/*<Error404 {...props}/>*/}</MultiRender>} />
                <PrivateRoute
                  path="/admin"
                  render={(props) => (
                    <MultiRender>
                      <DAdmin {...props} />
                    </MultiRender>
                  )}
                />
              </ScrollToTop>
            </Switch>
          </Suspense>
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

export default withRouter(AppRouter);
