import React, { Component } from "react";
import firebase from "firebase/app";
import { withRouter } from "react-router-dom";
import Utils from "../Utils";
import { Decimal } from "decimal.js";
import OrderItemKind from "../enums/OrderItemKind";
import PaymentMethod from "../enums/PaymentMethod";
import CouponType from "../enums/CouponType";
import Provvigioni from "../enums/Provvigioni";

export const OrderContext = React.createContext(null);

export const OrderConsumer = OrderContext.Consumer;

export class OrderProvider extends Component {
  state = {
    formula: null,
    price: 0,
    totalToPay: 0,
    couponDiscount: 0,
    shippingCost: 0,
    speseContrassegno: 0,
    amountForFreeShipping: 40,
    totalToPay: 0,
    firstUserOrder: false,
    status: null
  };

  constructor(props) {
    super(props);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.setPaymentMethod = this.setPaymentMethod.bind(this);
    this.paypalOrPBCError = this.paypalOrPBCError.bind(this);
    this.applyCoupon = this.applyCoupon.bind(this);
    this.removeCoupon = this.removeCoupon.bind(this);
    this.watchOrder = this.watchOrder.bind(this);
    this.createOrder = this.createOrder.bind(this);
    this.calculateTotalToPay = this.calculateTotalToPay.bind(this);
    this.setTotalPrice = this.setTotalPrice.bind(this);
    this.setStripePayment = this.setStripePayment.bind(this);
    this.resetStatus = this.resetStatus.bind(this);
  }

  resetStatus() {
    this.setState({
      status: null,
      coupon: null,
      couponDiscount: 0
    });
    Utils.log("AA ordercontext reset");
  }

  componentWillUnmount() {
    if (this.state.cartObserver) {
      this.state.cartObserver();
      delete this.state.cartObserver;
    }
    if (this.state.configObserver) {
      this.state.configObserver();
      delete this.state.configObserver;
    }
  }

  setPaymentMethod(pm) {
    Utils.log("AA payment", pm);
    this.setState({ paymentMethod: pm }, () => this.calculateTotalToPay());
  }

  setTotalPrice(price) {
    this.setState({ price }, () => this.calculateTotalToPay());
  }

  calculateTotalToPay() {
    var totalToPay = Math.max(this.state.price - this.state.couponDiscount, 0);
    const freeShipping = totalToPay > this.state.amountForFreeShipping || this.state.freeShippingCoupon;
    this.setState({ freeShipping, recipePrice: totalToPay });
    Utils.log("isFreeShipping", freeShipping);

    if (this.state.paymentMethod === PaymentMethod.PBC) totalToPay = totalToPay + this.state.speseContrassegno;

    if (!freeShipping && this.state.shippingCost > 0) totalToPay = totalToPay + this.state.shippingCost;

    Utils.log("Totale to pauy", totalToPay);
    this.setState({ freeShipping, totalToPay: totalToPay.toFixed(2) });
  }

  watchOrder(uid) {
    Utils.log("AA order uid", uid);
    const orderObserver = firebase
      .firestore()
      .collection("orders")
      .doc(uid)
      .onSnapshot(
        snap => {
          try {
            const data = snap.data();
            if (snap.exists) {
              Utils.log("AA orderContext order status", data.status);
              this.setState({ status: data.status, paymentError: data.paymentError || "" });
            }
          } catch (e) {
            console.error("error saving cart snap", e);
          }
        },
        err => {
          console.error("error on cart snapshot: ", err);
          Utils.handleError(err);
        }
      );

    this.setState({
      orderObserver
    });
  }

  async componentDidMount() {
    const configObserver = await firebase
      .firestore()
      .collection("config")
      .where("uid", "==", "production")
      .onSnapshot(doc => {
        doc.forEach(c => {
          Utils.log("config", c.data());
          this.setState({
            shippingCost: c.data().shippingCost,
            speseContrassegno: c.data().speseContrassegno,
            amountForFreeShipping: c.data().amountForFreeShipping
          });
        });
      });

    this.setState({
      configObserver: configObserver,
      paypalOrPBCError: this.paypalOrPBCError,
      applyCoupon: this.applyCoupon,
      removeCoupon: this.removeCoupon,
      watchOrder: this.watchOrder,
      setFormula: this.setFormula,
      createOrder: this.createOrder,
      calculateTotalToPay: this.calculateTotalToPay,
      setTotalPrice: this.setTotalPrice,
      setStripePayment: this.setStripePayment,
      setPaymentMethod: this.setPaymentMethod,
      resetStatus: this.resetStatus
    });

    this.resetStatus();
  }

  async createOrder(order, f) {
    Utils.log("AA createOrder", f);
    var newStatus = order.status;

    order.paymentMethod = this.state.paymentMethod;
    order.status = "DURING_PAYMENT";
    if (this.state.paymentMethod === PaymentMethod.PBC || this.state.paymentMethod === PaymentMethod.PAYPAL) {
      newStatus = "PAYED";
    }

    order.shippingCost = this.state.freeShipping ? 0 : this.state.shippingCost;
    order.speseContrassegno = this.state.speseContrassegno;
    order.gift = this.state.couponGift || "";
    order.coupon = this.state.couponCode || "";

    if (f.owner != firebase.auth().currentUser.uid) {
      if (order.quantity === 2) order.provvigioni = f.elements.length === 3 ? Provvigioni.BASIC.two : f.elements.length === 4 ? Provvigioni.SMART.two : Provvigioni.GOLD.two;
      else order.provvigioni = f.elements.length === 3 ? Provvigioni.BASIC.one : f.elements.length === 4 ? Provvigioni.SMART.one : Provvigioni.GOLD.one;
    }

    this.setState({ status: order.status });

    Utils.log("AA order", order, f);
    const ref = await firebase.firestore().collection("orders").add(order);

    order.uid = ref.id;
    const orderUid = ref.id;

    Utils.log("AA created order ", orderUid);

    //Set items
    const itemsRef = firebase.firestore().collection("orders").doc(orderUid).collection("items");

    const type = OrderItemKind.FORMULA;

    var dailyPrice = 0;
    const itemUid = `${type}_${f.uid}`;
    if (type === OrderItemKind.FORMULA) dailyPrice = await new Utils().getRecipeDailyPrice(f);
    else dailyPrice = f.price;

    await itemsRef.doc(itemUid).set({
      kind: type,
      refUid: f.uid,
      uid: itemUid,
      quantity: order.quantity,
      item: f,
      price: this.state.recipePrice,
      totSachets: f.totSachets * 12,
      add: new Date()
    });

    if (order.paymentMethod == PaymentMethod.STRIPE) {
      this.setStripePayment(order);
    } else {
      const userId = order.owner || order.customer;

      if (!!userId) {
        //Set USER Charge for Paypal
        const chargeRef = firebase.firestore().collection("users").doc(userId).collection("charges").doc(orderUid);
        const charge = {
          creation: new Date(),
          type: order.paymentMethod,
          customerId: order.customer || null,
          amount: order.discountedPrice,
          reason: "ORDER",
          reasonRef: firebase.firestore().collection("orders").doc(orderUid),
          charged: "YES"
        };

        Utils.log("AA charge", charge);
        await chargeRef.set(charge);
      }

      Utils.log("AA new status for order", orderUid, newStatus);

      await firebase.firestore().collection("orders").doc(orderUid).update({
        status: newStatus,
        charged: true
      });
    }

    this.watchOrder(orderUid);
  }

  async paypalOrPBCError() {
    //same as trigger onUpdateOrderCharge();
    const order = this.state.data;
    await firebase.firestore().collection("orders").doc(order.uid).update({ charged: true, status: "PAYMENT_REFUSED" });
  }

  async applyCoupon(coupon) {
    var couponDiscount = new Decimal(0);
    var freeShippingCoupon = !!coupon && !!coupon.freeShipping;
    const couponGift = !!coupon && !!coupon.gift ? coupon.gift : "";

    if (!!coupon && !!coupon.minExpense && this.state.price < coupon.minExpense) return false;

    if (!!coupon && coupon.type === CouponType.MARKETING_FIXED_PRICE) {
      this.setState(
        {
          couponCode: coupon.code,
          couponDiscount: coupon.amount,
          freeShippingCoupon,
          couponGift
        },
        this.calculateTotalToPay
      );
    } else {
      //   const recipesRef = firebase
      //     .firestore()
      //     .collection("orders")
      //     .doc(this.state.data.uid)
      //     .collection("items");
      //   const recipes = await recipesRef.get();
      //   const promises = [];
      //   recipes.forEach(r => {
      //     const data = r.data();
      //     promises.push(
      //       new Promise(async r => {
      //         if (data.kind === OrderItemKind.FORMULA) data.dailyPrice = await new Utils().getRecipeDailyPrice(data.item);
      //         else data.dailyPrice = data.item.price;
      //         data.fullPrice = new Decimal(data.dailyPrice).times(data.quantity).toFixed(2);
      //         const recipePrice = new Decimal(data.fullPrice);
      //   var recipeDiscount = 0;
      //   if (!!coupon) recipeDiscount = recipePrice.times(coupon.amount).dividedBy(100);
      //         if (!!coupon && (await this.formulaCanApplyDiscount(coupon, data.item.owner))) {
      //           Utils.log("AA apply coupon", coupon.code);
      //           this.updateRecipePrice(data.uid, recipePrice.minus(recipeDiscount), coupon);
      const discount = !!coupon ? coupon.amount : 0;
      couponDiscount = new Decimal(this.state.price).times(discount).dividedBy(100);
      //         } else {
      //           Utils.log("AA DONT apply coupon");
      //           this.updateRecipePrice(data.uid, recipePrice, null);
      //         }
      //         r(data);
      //       })
      //     );
      //   });
      //   await Promise.all(promises);
      this.setState(
        {
          couponCode: !!coupon ? coupon.code : "",
          couponDiscount: couponDiscount.toDecimalPlaces(2).toFixed(2),
          freeShippingCoupon,
          couponGift
        },
        this.calculateTotalToPay
      );
    }
    //Utils.log("Discount total", couponDiscount.toDecimalPlaces(2).toFixed(2));

    // await firebase
    //   .firestore()
    //   .collection("orders")
    //   .doc(this.state.data.uid)
    //   .update({
    //     coupon: !!coupon ? coupon.code : firebase.firestore.FieldValue.delete()
    //   });

    return true;
  }

  async removeCoupon() {
    this.applyCoupon(null);
  }

  async setStripePayment(order) {
    const { stripeInfo } = order;
    Utils.log("Received Stripe token:", stripeInfo.stripeToken);
    Utils.log("Received Stripe :", stripeInfo);

    await firebase
      .firestore()
      .collection("orders")
      .doc(order.uid)
      .update({
        stripeInfo: stripeInfo,
        chargeId: stripeInfo.stripeToken || order.uid,
        chargeType: PaymentMethod.STRIPE,
        chargeCustomerId: order.customer || order.shippingInfo.email,
        price: this.state.price,
        discountedPrice: this.state.totalToPay,
        status: "DURING_PAYMENT",
        orderDate: new Date(),
        creation: new Date(),
        shippingCost: this.state.freeShipping ? 0 : this.state.shippingCost
      });
  }

  render() {
    return (
      <OrderContext.Provider value={this.state}>
        <div>{this.props.children}</div>
      </OrderContext.Provider>
    );
  }
}

export default withRouter(OrderProvider);

export function withOrderCtx(Component) {
  return props => <OrderConsumer>{ctx => <Component order={ctx} {...props} />}</OrderConsumer>;
}
