import React from "react";

export default class CookieSettingsToggle extends React.Component {
  toggleCookiesSettings() {
    const panel = document.getElementsByClassName("cc-settings")[0];
    const overlay = document.getElementsByClassName("cc-overlay ")[0];
    panel.classList.toggle("visible");
    overlay.classList.toggle("visible");
    overlay.classList.toggle("block");
  }

  render() {
    return (
      <div onClick={() => this.toggleCookiesSettings()}>
        {this.props.children}
      </div>
    );
  }
}
