import React from 'react';
import {Column, Columns, Image} from "bloomer";
import StarRating from "../StarRating";

export default class MFormulaListElement extends React.Component {

    render() {
        const dFace = "https://www.gravatar.com/avatar/00000000000000000000000000000000";
        const formula = this.props.formula;

        return (
            <div onClick={this.props.onClick} className='formula-list-element has-no-bg has-padding-3 arrow--right'>
                <Columns isMobile>
                    <Column>
                        <StarRating size='medium' rating={formula.vote} label={formula.voteCount}/>
                        <p className='has-text-14' style={{lineHeight: '12px'}}>{formula.name}</p>
                        {formula.subtitle ? <p className='has-text-13'>
                            <small>{formula.subtitle}</small>
                        </p> : null}
                    </Column>
                </Columns>
                <Columns isMultiline>
                    {formula.categories ?
                        formula.categories.map(c => (
                            <Column key={c.uid} isSize='full' className="is-flex is-vertical-centered ptb-0">
                                <Image className='is-pulled-left is-14x14' src={c.image}/>&nbsp;
                                <span className="has-text-13 has-normal-weight">{c.name}</span>
                            </Column>
                        ))
                        : null}
                    {formula.orders ? <Column isSize='full' className="is-flex is-vertical-centered ptb-0">
                        <Image className='is-pulled-left is-14x14' src="https://via.placeholder.com/14x14"/>&nbsp;
                        <span className="has-text-13 has-normal-weight">{formula.orders}</span>
                    </Column> : null}
                </Columns>
                {formula.ownerData ? <Columns isMobile>
                    <Column isSize='narrow'>
                        <Image isSize='32x32' isRatio="square" className='is-rounded is-profile-photo'
                               src={formula.ownerData.photoURL || dFace}/>
                    </Column>
                    <Column className="pl-0">
                        <h4 className='subtitle has-super-light-gray no-m'>&nbsp;di {formula.ownerData.displayName}</h4>
                        <StarRating rating={formula.ownerData.vote} size='small' stars='5'
                                    label={formula.ownerData.voteCount}/>
                    </Column>
                </Columns> : null}
            </div>
        );
    }
}