export default {
  data: [
    {
      percentage: 2,
      questions: [
        { formName: "name", kind: "text", order: 1, placeholder: "Come ti chiami?", title: "Nome" },
        {
          formName: "age",
          kind: "radiobox",
          order: 3,
          title: "Età",
          values: [
            { label: "18 - 25", value: "18-25" },
            { label: "26 - 35", value: "26-35" },
            { label: "36 - 45", value: "36-45" },
            { label: "46 in sù", value: "46+" },
          ],
        },
        {
          formName: "sex",
          kind: "radiobox",
          order: 4,
          title: "Sesso",
          values: [
            { label: "Uomo", value: "male" },
            { label: "Donna", value: "female" },
          ],
        },
        { formName: "email", kind: "text", onlyGuest: true, order: 2, placeholder: "La tua email", title: "Email" },
        { formName: "privacy", kind: "privacy", onlyGuest: true, values: [{ label: "privacy", value: "none" }] },
        { formName: "newsletter", kind: "newsletter", values: [{ label: "newsletter", value: "newsletter_yes" }] },
      ],
      uid: 0,
    },
    {
      description: "Parliamo della tua fisionomia",
      percentage: 4,
      questions: [
        { formName: "weight", kind: "number", max: 300, min: 40, order: 1, title: "Peso" },
        { errorMessage: "Inserire un' altezza compresa tra 80 e 250 cm", formName: "height", kind: "number", max: 250, min: 80, order: 2, title: "Altezza" },
        {
          formName: "bodysize",
          kind: "radiobox",
          order: 3,
          title: "STRUTTURA FISICA",
          values: [
            { label: "Esile", order: 1, value: "light" },
            { label: "Normale", order: 2, value: "normal" },
            { label: "Robusta", order: 3, value: "muscle" },
          ],
        },
      ],
      uid: 1,
    },
    {
      description: "Fumatore?",
      percentage: 6,
      questions: [
        {
          formName: "smoker",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Non fumo", value: "no_smoker" },
            { label: "5-10 sigarette al giorno", value: "little_smoker" },
            { label: "più di 10 sigarette al giorno", value: "big_smoger" },
          ],
        },
      ],
      uid: 2,
    },
    {
      description: "Consumi bevande alcoliche o bibite gasate?",
      percentage: 8,
      questions: [
        {
          formName: "alchol",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Mai", value: "no_alchol" },
            { label: "Saltuariamente", value: "little_alchol" },
            { label: "Tutti i giorni", value: "big_alchol" },
          ],
        },
      ],
      uid: 3,
    },
    {
      description: "Alimentazione e peso",
      percentage: 10,
      questions: [
        {
          formName: "food",
          kind: "radiobox",
          title: "Alimentazione",
          order: 1,
          values: [
            { label: "Sregolata", value: "food_bad" },
            { label: "Ogni tanto sgarro", value: "food_normal" },
            { label: "Sono molto attento a quello che mangio", value: "food_good" },
          ],
        },
        {
          formName: "weight_increase",
          kind: "radiobox",
          title: "Prendi peso facilmente?",
          order: 2,
          values: [
            { label: "Si", value: "yes_weight_increase" },
            { label: "No", value: "no_weight_increase" },
          ],
        },
      ],
      uid: 4,
    },
    {
      description: "Attività sportiva",
      percentage: 12,
      questions: [
        {
          formName: "sport",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Scarsa", value: "no_sport_activity" },
            { label: "Moderata", value: "normal_sport_activity" },
            { label: "Intensa", value: "intese_sport_activity" },
          ],
        },
      ],
      uid: 5,
    },
    // {
    //   description: "Hai difficoltà a ricordare eventi, date, numeri?",
    //   percentage: 14,
    //   questions: [
    //     {
    //       formName: "memory",
    //       kind: "radiobox",
    //       order: 1,
    //       values: [
    //         { label: "Spesso", value: "bad_memory" },
    //         { label: "Saltuariamente", value: "normal_memory" },
    //         { label: "Mai", value: "good_memory" }
    //       ]
    //     }
    //   ],
    //   uid: 6
    // },
    // {
    //   description: "La notte ti svegli di frequente?",
    //   percentage: 15,
    //   questions: [
    //     {
    //       formName: "sleep",
    //       kind: "radiobox",
    //       order: 1,
    //       values: [
    //         { label: "Spesso", value: "bad_sleep" },
    //         { label: "Ogni tanto", value: "normal_sleep" },
    //         { label: "Di rado ", value: "good_sleep" }
    //       ]
    //     }
    //   ],
    //   uid: 7
    // },
    // {
    //   description: "Al mattino ti svegli stanco?",
    //   percentage: 16,
    //   questions: [
    //     {
    //       formName: "tired",
    //       kind: "radiobox",
    //       order: 1,
    //       values: [
    //         { label: "Mai", value: "good_tired" },
    //         { label: "Dipende", value: "normal_tired" },
    //         { label: "Sempre", value: "bad_tired" }
    //       ]
    //     }
    //   ],
    //   uid: 8
    // },
    // {
    //   description: "Gli arti superiori e inferiori sono freddi?",
    //   percentage: 17,
    //   questions: [
    //     {
    //       formName: "cold_arms",
    //       kind: "radiobox",
    //       order: 1,
    //       values: [
    //         { label: "Sempre", value: "always_cold_arms" },
    //         { label: "Solo d'inverno", value: "winter_cold_arms" },
    //         { label: "Quasi mai", value: "never_cold_arms" }
    //       ]
    //     }
    //   ],
    //   uid: 9
    // },
    // {
    //   description: "Perdi i capelli con frequenza?",
    //   percentage: 18,
    //   questions: [
    //     {
    //       formName: "hairloss",
    //       kind: "radiobox",
    //       order: 1,
    //       values: [
    //         { label: "Si", value: "yes_hairloss" },
    //         { label: "No", value: "no_hairloss" },
    //         { label: "Solo in alcuni periodi", value: "normal_hairloss" }
    //       ]
    //     }
    //   ],
    //   uid: 10
    // },
    {
      description: "Accusi problemi di digestione?",
      percentage: 20,
      questions: [
        {
          formName: "digestion_prob",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "yes_digestion_prob" },
            { label: "No", value: "no_digestion_prob" },
            { label: "Ogni tanto", value: "normal_digestion_prob" },
          ],
        },
      ],
      uid: 11,
    },
    // {
    //   description: "Soffri di irregolarità intestinale?",
    //   percentage: 22,
    //   questions: [
    //     {
    //       formName: "intestine_prob",
    //       kind: "radiobox",
    //       order: 1,
    //       values: [
    //         { label: "Si", value: "bad_intestine" },
    //         { label: "Ogni tanto", value: "normal_intestine" },
    //         { label: "No", value: "good_intestine" }
    //       ]
    //     }
    //   ],
    //   uid: 12
    // },
    // {
    //   description: "Quando ti ferisci hai difficoltà di cicatrizzazione?",
    //   percentage: 24,
    //   questions: [
    //     {
    //       formName: "scarring",
    //       kind: "radiobox",
    //       order: 1,
    //       values: [
    //         { label: "Si", value: "bad_scarring" },
    //         { label: "Non sempre", value: "normal_scarring" },
    //         { label: "Mai", value: "good_scarring" }
    //       ]
    //     }
    //   ],
    //   uid: 13
    // },
    // {
    //   description: "Avverti sintomi riconducibili a disturbi nervosi? Se più di uno seleziona multiplo",
    //   percentage: 26,
    //   questions: [
    //     {
    //       formName: "nervous_disease",
    //       fullwidth: true,
    //       kind: "checkbox",
    //       order: 1,
    //       // title: "Hai problemi di salute o sei una donna in gravidanza?",
    //       values: [
    //         { excludeAll: true, label: "Nessuno", value: "no_nervous_disease" },
    //         { label: "Stress", value: "stress" },
    //         { label: "Ansia", value: "anxiety" },
    //         { label: "Altri", value: "other_nervous_disease" }
    //       ]
    //     }
    //   ],
    //   uid: 14
    // },
    {
      description: "Soffri spesso di malattie stagionali?",
      percentage: 28,
      questions: [
        {
          formName: "seasonal_disease",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Sempre", value: "bad_seasonal_disease" },
            { label: "Ogni tanto", value: "normal_seasonal_disease" },
            { label: "Quasi mai", value: "good_seasonal_disease" },
          ],
        },
      ],
      uid: 15,
    },
  ],
};
