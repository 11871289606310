import React from "react";
import times from "../../../svgs/ic-24-close.svg";
import firebase from "firebase/app";
import Utils from "../../../Utils";
import Loader from "../../../components/Loader";
import MNavbar from "../../../components/mobile/MNavbar";
import MFooter from "../../../components/mobile/footer/MFooter";
import { withUserCtx } from "../../../contexts/UserContext";
import { withModalsCtx } from "../../../contexts/ModalsContext";
import { withLabCtx } from "../../../contexts/LabContext";
import { withConfigCtx } from "../../../contexts/ConfigContext";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Column, Columns, Container, Control, Field, Input, Modal, ModalBackground, ModalCard, ModalCardBody } from "bloomer";
import MRiepilogoHeader from "../../../components/mobile/MRiepilogoHeader";
import RiepilogoFormulaModal from "../../../modals/RiepilogoFormulaModal";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";

class MLanding extends React.Component {
  state = {
    tab: "home",
    search: "",
    recap: "",
    typeId: "",
    isAddToForumlaActive: false,
    selectedElement: {},
    newFormula: {
      elements: []
    },
    totWeight: 0,
    totSachets: 0,
    totMoney: 0,
    elementKindMap: {
      LIQUID: "ml",
      POWDER: "g"
    },
    editMode: false,
    thereIsATempFormula: false
  };

  constructor(props) {
    super(props);
    this.toggleFormulaRecap = this.toggleFormulaRecap.bind(this);
    this.goBackToTypes = this.goBackToTypes.bind(this);
    this.goToListSection = this.goToListSection.bind(this);
    this.closeAddToForumla = this.closeAddToForumla.bind(this);
    this.addQuantity = this.addQuantity.bind(this);
    this.removeElement = this.removeElement.bind(this);
    this.goToRecap = this.goToRecap.bind(this);
    this.calculateFormulaTotals = this.calculateFormulaTotals.bind(this);
  }

  componentDidMount() {
    // window.customerly("hide");

    const newFormula = JSON.parse(localStorage.getItem("formulaLaboratorio"));

    if (!!newFormula) this.setState({ newFormula }, () => this.calculateFormulaTotals(newFormula));

    if (this.props.match.params.formulaUid) {
      this.setState({ editMode: true });
      this.loadFormula();
    }
  }

  componentWillReceiveProps(nextProps) {
    // if (nextProps.user && nextProps.user.uid) {
    //   if (!this.state.tempFormulaLoaded && !this.props.match.params.formulaUid) this.loadTempFormulaIfExists(nextProps.user.uid);
    // }

    if (!!nextProps.Config.kitBasic) {
      this.setState({
        priceBasic: nextProps.Config.kitBasic[0],
        priceSmart: nextProps.Config.kitSmart[0],
        priceGold: nextProps.Config.kitGold[0]
      });
    }
  }

  calculateFormulaTotals(formula) {
    var totMoney = 0;
    var totWeight = 0;
    var totSachets = 0;
    for (var i in formula.elements) {
      totWeight = totWeight + formula.elements[i].sachets * formula.elements[i].sachetGrams;
      totMoney = totMoney + formula.elements[i].sachetPrice * formula.elements[i].sachets;
      totSachets = totSachets + formula.elements[i].sachets;
    }

    this.setState({
      totMoney: totMoney,
      totWeight: totWeight,
      totSachets: totSachets
    });
  }

  async loadFormula() {
    this.setState({ loading: true });
    const formulaUid = this.props.match.params.formulaUid;
    try {
      const recipeObserver = await firebase
        .firestore()
        .collection("recipes")
        .doc(formulaUid)
        .onSnapshot(async snap => {
          if (snap.exists) {
            const data = { ...snap.data() };
            data.dailyPrice = await new Utils().getRecipeDailyPrice(data);
            data.dailyGrams = Utils.getRecipeDailyGrams(data);
            this.calculateFormulaTotals(data);
            Utils.log("new formula load", data);
            this.setState({
              newFormula: data,
              loading: false
            });
          } else {
            this.props.modals.addError(`Formula ${formulaUid} non trovata`, null, () => {
              if (this.state.observer) this.state.observer();
              this.props.history.goBack()();
            });
          }
        });
      this.setState({
        observer: recipeObserver
      });
    } catch (e) {
      Utils.handleError(e);
      this.props.modals.addError(`Formula ${formulaUid} non trovata`, null, () => this.props.history.goBack()());
    }
  }

  async loadTempFormulaIfExists(userUid) {
    this.setState({ loading: true, tempFormulaLoaded: true });
    try {
      let tempRecipe = false;
      const recipeObserver = await firebase
        .firestore()
        .collection("recipes")
        .where("status", "==", "temp")
        .where("owner", "==", userUid)
        .onSnapshot(async snap => {
          snap.forEach(s => {
            tempRecipe = s.data();
          });

          if (tempRecipe) {
            tempRecipe.dailyPrice = await new Utils().getRecipeDailyPrice(tempRecipe);
            tempRecipe.dailyGrams = Utils.getRecipeDailyGrams(tempRecipe);
            this.calculateFormulaTotals(tempRecipe);
            Utils.log("new formula temp", tempRecipe);

            this.setState({
              newFormula: tempRecipe,
              thereIsATempFormula: true,
              loading: false
            });
          }
          this.setState({
            observer: recipeObserver
          });
        });
    } catch (e) {
      Utils.handleError(e);
      this.props.modals.addError(`Formula temp non trovata`, null, () => this.props.history.goBack()());
    }
  }

  toggleFormulaRecap() {
    if (this.state.recap !== "") {
      this.setState({ recap: "" });
    } else {
      this.setState({ recap: "opened" });
    }
  }

  async removeElement(ingredient) {
    Utils.log("AA element", ingredient);
    var newList = [];
    let totWeight = this.state.totWeight;
    let totMoney = this.state.totMoney;
    let totSachets = this.state.totSachets;
    for (var i in this.state.newFormula.elements) {
      if (this.state.newFormula.elements[i].uid !== ingredient.uid) {
        newList.push(this.state.newFormula.elements[i]);
      } else {
        totWeight = totWeight - ingredient.sachets * ingredient.sachetGrams;
        totMoney = totMoney - ingredient.sachetPrice * ingredient.sachets;
        totSachets = totSachets - ingredient.sachets;
      }
    }

    var recap = "";
    if (newList.length > 0) {
      recap = "opened";
    } else {
      recap = "";
      totMoney = 0;
      totWeight = 0;
      totSachets = 0;
    }

    var { newFormula } = this.state;
    newFormula.elements = newList;
    Utils.log("new formula remove", newFormula);

    //await this.props.lab.updateFormula(newFormula);

    this.setState(
      {
        newFormula: newFormula,
        recap: recap,
        totMoney: totMoney,
        totWeight: totWeight,
        totSachets: totSachets
      },
      () => this.calculateFormulaTotals(newFormula)
    );

    newFormula.elements.forEach(e => {
      delete e.categories;
    });
    localStorage.setItem("formulaLaboratorio", JSON.stringify(newFormula));
  }
  goBackToTypes() {
    window.scrollTo(0, 0);

    this.setState({ tab: "home" });
  }

  goToListSection(typeId) {
    window.scrollTo(0, 0);

    this.setState({
      tab: "list",
      typeId: typeId
    });
  }

  closeAddToForumla() {
    this.setState({ isAddToForumlaActive: false });
  }

  addQuantity(amount, element) {
    if (Utils.elementIsProtein(element) && Utils.checkFormulaContainsProteins(this.state.newFormula)) {
      this.props.modals.addError("La formula contiene già un elemento Proteine");

      return;
    }
    let newFormula = this.state.newFormula;
    let totWeight = this.state.totWeight;
    let totMoney = this.state.totMoney;
    let totSachets = this.state.totSachets;

    let updatingQuantity = false;

    for (var i in newFormula.elements) {
      if (newFormula.elements[i].uid === element.uid) {
        newFormula.elements[i].quantity = amount;
        newFormula.elements[i].sachets = amount / element.sachetGrams;
        updatingQuantity = true;
      }
    }

    if (updatingQuantity) {
      Utils.log("new formula updating quantity");

      this.calculateFormulaTotals(newFormula);
      this.setState({
        isAddToForumlaActive: false,
        newFormula: newFormula
      });
    } else {
      let newElement = {
        uid: element.uid,
        categories: element.categories,
        coverImage: element.coverImage,
        maxGrams: element.maxGrams,
        name: element.name,
        elementKind: element.elementKind,
        sachetGrams: element.sachetGrams,
        sachetPrice: element.sachetPrice,
        quantity: amount,
        type: element.type.id,
        sachets: amount / element.sachetGrams
      };

      newFormula.elements.push(newElement);
      Utils.log("new formula add", newFormula);

      this.setState({
        isAddToForumlaActive: false,
        newFormula: newFormula,
        totWeight: totWeight + amount,
        totMoney: totMoney + this.state.selectedElement.sachetPrice * newElement.sachets,
        totSachets: totSachets + newElement.sachets
      });
    }

    newFormula.elements.forEach(e => {
      delete e.categories;
    });

    localStorage.setItem("formulaLaboratorio", JSON.stringify(newFormula));
  }

  goToRecap() {
    this.setState({ openRiepilogoModal: !this.state.openRiepilogoModal });
  }

  checkFormulaContainsProteins() {
    var { newFormula } = this.state;
    let containsProteins = false;
    let monthlyPrice = newFormula.monthlyPrice;

    newFormula.elements.slice(0, 5).forEach(e => {
      if (e.type === "e36bb8cd-06f7-4de4-bd7f-4d02a0fb24f8") {
        monthlyPrice += this.props.Config.proteinCost;
        containsProteins = true;
      }
    });

    newFormula.monthlyPrice = monthlyPrice;
    newFormula.containsProteins = containsProteins;
    newFormula.proteinsRequested = containsProteins;
    this.setState({ newFormula });
  }

  async vaiRiepilogo() {
    var f = this.state.newFormula;
    if (this.state.editMode || this.state.thereIsATempFormula) {
      Utils.log("AA formula state editmode or thereIsATempFormula");
      f.creataLaboratorio = true;
      await this.props.lab.updateFormula(f);
      this.props.history.push("/formula/" + f.uid);
    } else {
      const nomeCliente = localStorage.getItem("NomeClientePT") || "te";
      f.elementsRequested = f.elements.length;
      f.monthlyPrice = f.elements.length === 3 ? this.state.priceBasic : f.elements.length === 4 ? this.state.priceSmart : this.state.priceGold;
      f.creataLaboratorio = true;
      f.creationDate = new Date();
      f.name = "Pack personalizzato per " + nomeCliente;
      f.nomeCliente = nomeCliente;
      Utils.log("UserBefore", firebase.auth().currentUser);
      Utils.log("AA formula save");

      this.checkFormulaContainsProteins();

      await this.props.lab.saveFormula(f);
      const newId = this.props.lab.tempFormulaId;
      this.props.history.push("/formula/" + newId);
    }
  }

  render() {
    let formulaRecap = "my-formula-bottom " + this.state.recap;
    const riepilogoDisabled = this.state.newFormula.elements.length < 3 || this.state.newFormula.elements.length > 5;
    const condividi = !!this.props.user && !!this.props.user.personalTrainer;

    return (
      <div className="lab-area gray-gradient">
        <MNavbar className="is-transparent is-fixed-top" />
        <MRiepilogoHeader scegli scegliOn laboratorio condividi={!!condividi} />
        {this.state.tab === "home" && (
          <div>
            <Container>
              <div className=" has-text-centered">
                <h1 className="title has-text-green">IL LABORATORIO INTERATTIVO</h1>
                <h1 className="subtitle has-text-green">Componi la formula per il tuo cliente</h1>
              </div>
            </Container>
            <HomeSection goToListSection={this.goToListSection} newFormula={this.state.newFormula} editMode={this.state.editMode} history={this.props.history} {...this.props} />
          </div>
        )}
        {/* {this.state.tab === "list" && ( */}
        <div className="my-formula-bottom opened">
          <Container className="my-formula-bottom-body">
            <Columns>
              <Column isSize="2/3">
                <h5 className="title" style={{ marginBottom: "0px" }}>
                  {this.state.editMode ? this.state.newFormula.name : "Il KIT che stai creando..."}
                </h5>
                <div>
                  {this.state.newFormula.elements.length === 0 ? (
                    <h6>Nessun ingrediente aggiunto</h6>
                  ) : this.state.newFormula.elements.length === 5 ? (
                    <h6>Raggiunto il limite massimo di bustine</h6>
                  ) : (
                    <h6>Min 3 - Max 5 bustine</h6>
                  )}
                </div>
              </Column>
              <Column>
                <Columns isMobile isSize="1/3">
                  <Column>
                    <Button onClick={() => this.goBackToTypes()} className="is-transparent is-bordered-white is-half-height">
                      ALTRI ELEMENTI
                    </Button>
                  </Column>
                  <Column>
                    <Button disabled={!!riepilogoDisabled} onClick={() => this.goToRecap()} className="is-transparent is-bordered-white is-half-height">
                      VAI AL RIEPILOGO
                    </Button>
                  </Column>
                </Columns>
              </Column>
            </Columns>

            {this.state.newFormula.elements.map((element, id) => (
              <div key={id}>
                <Columns isMobile isMultiline className="crea-top-summary">
                  <Column isSize={{ mobile: 7 }} className="name-col">
                    <h1 className="new-formula-element">{element.name}</h1>
                  </Column>
                  <Column isSize={{ mobile: 3 }} className="name-col">
                    {/* <h1 className="new-formula-element ta-r">{Utils.stringToCurrency(element.sachetPrice * element.sachets)}</h1> */}
                  </Column>
                  <Column isSize={{ mobile: 2 }} className="name-col">
                    <Button onClick={() => this.removeElement(element)} className="is-transparent ml-10 no-p" style={{ marginTop: "-3px" }}>
                      <img src={times} width="20px" className="top-summary-delete" />
                    </Button>
                  </Column>
                </Columns>
              </div>
            ))}
          </Container>
        </div>
        // )}
        {this.state.tab === "list" && (
          <div>
            <ListSection
              goBackFunction={this.goBackToTypes}
              addQuantity={this.addQuantity}
              typeId={this.state.typeId}
              history={this.props.history}
              handleChange={this.handleChange}
              removeElement={this.removeElement}
              newFormula={this.state.newFormula}
              goToRecap={this.goToRecap}
            />
          </div>
        )}
        <RiepilogoFormulaModal
          isActive={this.state.openRiepilogoModal}
          vaiRiepilogo={() => this.vaiRiepilogo()}
          aggiungiAltriElementi={() => {
            this.setState({ openRiepilogoModal: false }, () => this.goBackToTypes());
          }}
          formula={this.state.newFormula}
          onClose={() => this.setState({ openRiepilogoModal: false })}
        />
        <MFooter />
      </div>
    );
  }
}

class HomeSection extends React.Component {
  state = {
    trainers: [],
    tab: "types",
    elements: []
  };

  render() {
    return (
      <Container className="lab-section">
        <div className="pb-12 mb-80">
          {this.state.tab === "types" && <TypesSection goToListSection={this.props.goToListSection} lab={this.props.lab} />}

          {this.state.tab !== "types" && <ListSection history={this.props.history} elements={this.state.elements} searchValue={this.state.searchValue} handleChange={this.handleChange} />}
        </div>
      </Container>
    );
  }
}

class TypesSection extends React.Component {
  state = {
    types: [],
    loading: true
  };

  render() {
    if (!this.props.lab || !this.props.lab.types) return <Loader />;
    return (
      <div>
        <Columns className="ptb-12 has-text-centered" isMultiline>
          {this.props.lab.types.map((category, id) => (
            <Column key={id}>
              <div className=" zoom-hoover" onClick={() => this.props.goToListSection(category.uid)}>
                <h1>
                  <img className="type-img" width="110px" height="auto" src={"/images/" + category.uid + ".png"} alt="" />
                </h1>
                <h3 className="subtitle no-m" style={{ fontSize: "33px" }}>
                  {category.name}
                </h3>

                <Button className="is-transparent is-bordered-green is-half-width mb-20">Esplora</Button>
              </div>
            </Column>
          ))}
        </Columns>
      </div>
    );
  }
}

class ListSection extends React.Component {
  state = {
    elements: [],
    categoryTitle: "",
    typeId: null,
    loading: false
  };

  componentDidMount() {
    if (!this.props.elements) {
      this.loadElements();
    }
  }
  async loadElements() {
    const reference = firebase
      .firestore()
      .collection("eltypes")
      .doc(this.props.typeId);

    reference.onSnapshot(async snap => {
      const data = { ...snap.data() };
      this.setState({ categoryTitle: data.name });
    });

    const observable = await firebase
      .firestore()
      .collection("elements")
      .where("type", "==", reference)
      .where("visible", "==", true)
      .where("availability", "==", true)
      .onSnapshot(async snap => {
        const data = [];
        snap.forEach(d => {
          data.push(d.data());
        });

        this.setState({ elements: data, loading: false });
      });

    this.setState({ observable: observable });
  }

  componentWillUnmount() {
    if (!!this.state.observable) this.state.observable();
  }

  renderResults(count, string) {
    return (
      <div>
        <Field className="input-field search">
          <Control className="has-icons-right">
            <Columns isMobile>
              <Column>
                <Input type="text" placeholder="Cerca per ingrediente" value={string} onChange={this.props.handleChange} />
              </Column>
              <Column isSize="narrow" style={{ paddingLeft: 0 }}>
                <Button className="is-transparent">
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
              </Column>
            </Columns>
          </Control>
        </Field>
        <p className="muted mb-20">
          {count} Risultati per "{string}"
        </p>
      </div>
    );
  }

  renderNavigation() {
    return (
      <div>
        <Container>
          <Columns isMobile className="has-text-red">
            <Column isSize="1/2" className="has-text-centered">
              <Button onClick={this.props.goBackFunction} className="header-arrow-text no-p">
                <FontAwesomeIcon className="icn" icon={["fa", "angle-left"]} />
                <span className="arrow-text">torna al laboratorio</span>
              </Button>
            </Column>
            <Column isSize="1/2" className="has-text-centered">
              <Button onClick={this.props.goToRecap} disabled={this.props.newFormula.elements.length === 0} className="header-arrow-text no-p">
                <span className="arrow-text">continua con il kit</span>
                <FontAwesomeIcon className="icn" icon={["fa", "angle-right"]} />
              </Button>
            </Column>
          </Columns>
        </Container>
      </div>
    );
  }
  renderTitle() {
    return (
      <div className="mtb-20 has-text-centered">
        <h2 className="text-big" style={{ display: "inline-block" }}>
          {this.state.categoryTitle}
        </h2>
        <img style={{ verticalAlign: "top", marginLeft: "20px", height: "70px", widows: "auto" }} src={"/images/" + this.props.typeId + ".png"} alt="" />
      </div>
    );
  }

  render() {
    let elements = this.props.elements ? this.props.elements : this.state.elements;

    if (this.state.loading) return <Loader />;

    return (
      <div>
        {this.props.elements ? (
          this.renderResults(this.props.elements.length, this.props.searchValue)
        ) : (
          <div>
            {this.renderTitle()}
            {/* {this.renderNavigation()} */}
          </div>
        )}

        {elements.map((element, id) => (
          <MElementAdd key={id} element={element} {...this.props} />
        ))}

        {/* {this.renderNavigation()} */}
      </div>
    );
  }
}

class MElementAdd extends React.Component {
  state = {
    element: null,
    elementAdded: false
  };

  componentWillReceiveProps(p) {
    this.setElement(p);
  }

  componentDidMount() {
    this.setElement(this.props);
  }

  setElement(p) {
    let { element, newFormula } = p;

    let found = false;
    newFormula.elements.forEach(e => {
      if (e.uid === element.uid) {
        found = true;
        element.quantity = e.quantity;
      }
    });

    if (!found) element.quantity = 0;

    this.setState({ element, elementAdded: !!found ? true : false });
  }

  render() {
    const { element } = this.state;
    if (!element) return null;

    const className = "min-height-140 mb-1 has-no-bg has-padding-3";
    const addDisabled = this.props.newFormula.elements.length >= 5;
    return (
      <div key={element.uid} className={!!this.state.elementAdded ? className + " element-selected" : className} style={{ minHeight: "130px", maxHeight: "none" }}>
        <Columns isMobile isMultiline className="desktop-lab-element-columns">
          <Column isSize={{ mobile: 4, tablet: 4 }}>
            <img src={`https://assets.mylabnutrition.net/product/${element.uid}.png`} className="element-img" alt="" />
          </Column>
          <Column className="pt-15 ">
            <h1 className="title-laboratorio-element">{element.name}</h1>
            <div className="vw-mobile-only">
              <Button
                className={
                  this.state.elementAdded ? "is-transparent is-bordered-white is-width-140 mb-10 mt-10 is-half-height" : "is-transparent is-bordered-green is-width-140 mb-10 mt-10 is-half-height"
                }
                onClick={() => this.props.history.push(`/ingrediente/${element.uid}`)}
              >
                PROPRIETà
              </Button>
              <Button
                disabled={this.state.elementAdded || addDisabled}
                className={this.state.elementAdded ? "is-transparent is-bordered-white is-width-140 mb-10 mt-10 is-half-height" : "is-primary green is-width-140 mb-10 mt-10 is-half-height"}
                onClick={() => this.props.addQuantity(element.sachetGrams, element)}
              >
                AGGIUNGI AL KIT
              </Button>
              {/* <RawMaterial closeModal={this.closeAddToForumla} element={element} removeElement={this.props.removeElement} callback={amount => this.props.addQuantity(amount, element)} /> */}
              <Button
                disabled={!this.state.elementAdded}
                onClick={() => this.props.removeElement(element)}
                className={
                  this.state.elementAdded ? "is-transparent is-bordered-white is-width-140 mb-10 mt-10 is-half-height" : "is-transparent is-bordered-green is-width-140 mb-10 mt-10 is-half-height"
                }
              >
                RIMUOVI
              </Button>
            </div>

            <div className="vw-tablet">
              <Columns style={{ width: "450px" }}>
                <Column>
                  <Button
                    className={
                      this.state.elementAdded ? "is-transparent is-bordered-white is-width-140 mb-10 mt-15 is-half-height" : "is-transparent is-bordered-green is-width-140 mb-10 mt-15 is-half-height"
                    }
                    onClick={() => this.props.history.push(`/ingrediente/${element.uid}`)}
                  >
                    PROPRIETà
                  </Button>
                </Column>
                <Column>
                  <Button
                    disabled={this.state.elementAdded || addDisabled}
                    className={this.state.elementAdded ? "is-transparent is-bordered-white is-width-140 mb-10 mt-15 is-half-height" : "is-primary green is-width-140 mb-10 mt-15 is-half-height"}
                    onClick={() => this.props.addQuantity(element.sachetGrams, element)}
                  >
                    AGGIUNGI AL KIT
                  </Button>
                  {/* <RawMaterial closeModal={this.closeAddToForumla} element={element} removeElement={this.props.removeElement} callback={amount => this.props.addQuantity(amount, element)} /> */}
                </Column>
                <Column>
                  <Button
                    disabled={!this.state.elementAdded}
                    onClick={() => this.props.removeElement(element)}
                    className={this.state.elementAdded ? "is-transparent is-bordered-white is-close-button mb-10 mt-15" : "is-transparent is-bordered-green is-close-button mb-10 mt-15"}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                </Column>
              </Columns>
            </div>
          </Column>
        </Columns>
      </div>
    );
  }
}

export default withConfigCtx(withModalsCtx(withUserCtx(withRouter(withLabCtx(MLanding)))));
