export default {
  data: [
    {
      description: "In quali tra queste problematiche riferite ai disturbi del sistema nervoso ti ritrovi?",
      percentage: 30,
      questions: [
        {
          formName: "target",
          fullwidth: true,
          kind: "radiobox2",
          order: 1,
          values: [
            { label: "Difficoltà di concentrazione", value: "difficolta_concentrazione" },
            { label: "Perdita di memoria", value: "memoria" },
            { label: "Stanchezza", value: "stanchezza" },
            { label: "Irrequietezza", value: "irrequietezza" },
            { label: "Stress prolungato", value: "stress" },
            { label: "Ansia", value: "ansia" },
            { label: "Squilibri del sonno", value: "sonno" },
            { label: "Malessere psicologico", value: "depressione" }
          ]
        }
      ],
      uid: 0
    },
    ////////////////// CONCENTRAZIONE //////////////////////
    {
      condition: [{ formName: "target", value: ["difficolta_concentrazione"] }],

      description: "Hai difficoltà nel ricordare gli eventi?",
      percentage: 40,
      questions: [
        {
          formName: "difficolta_ricordare",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "difficolta_ricordare_si" },
            { label: "No", value: "difficolta_ricordare_no" },
            { label: "A volte", value: "difficolta_ricordare_a_volte" }
          ]
        }
      ],
      uid: 1
    },
    {
      condition: [{ formName: "target", value: ["difficolta_concentrazione"] }],

      description: "Soffri di anemia da carenza di ferro?",
      percentage: 50,
      questions: [
        {
          formName: "anemia",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "anemia_si" },
            { label: "No", value: "anemia_no" }
          ]
        }
      ],
      uid: 2
    },
    {
      condition: [{ formName: "target", value: ["difficolta_concentrazione"] }],

      description: "Svolgi un'attività in cui necessiti di una eccessiva concentrazione?",
      percentage: 60,
      questions: [
        {
          formName: "eccessiva_concentrazione",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "eccessiva_concentrazione_si" },
            { label: "No", value: "eccessiva_concentrazione_no" },
            { label: "A volte", value: "eccessiva_concentrazione_a_volte" }
          ]
        }
      ],
      uid: 3
    },
    {
      condition: [{ formName: "target", value: ["difficolta_concentrazione"] }],

      description: "Ti senti spesso stanco/a o spossato/a?",
      percentage: 80,
      questions: [
        {
          formName: "spossato",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "spossato_si" },
            { label: "No", value: "spossato_no" },
            { label: "A volte", value: "spossato_a_volte" }
          ]
        }
      ],
      uid: 3
    },
    {
      condition: [{ formName: "target", value: ["difficolta_concentrazione"] }],

      description: "Fai uso di vitamine e minerali?",
      percentage: 90,
      questions: [
        {
          formName: "vitamine",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "vitamine_si" },
            { label: "No", value: "vitamine_no" },
            { label: "A volte", value: "vitamine_a_volte" }
          ]
        }
      ],
      uid: 4
    },
    ////////////////// MEMORIA //////////////////////
    {
      condition: [{ formName: "target", value: ["memoria"] }],

      description: "Soffri di mal di testa?",
      percentage: 40,
      questions: [
        {
          formName: "malditesta",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "malditesta_si" },
            { label: "No", value: "malditesta_no" },
            { label: "A volte", value: "malditesta_a_volte" }
          ]
        }
      ],
      uid: 5
    },
    {
      condition: [{ formName: "target", value: ["memoria"] }],

      description: "Soffri di disturbi neurologici?",
      percentage: 50,
      questions: [
        {
          formName: "neurologici",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "neurologici_si" },
            { label: "No", value: "neurologici_no" }
          ]
        }
      ],
      uid: 6
    },
    {
      condition: [{ formName: "target", value: ["memoria"] }],

      description: "Ti senti depresso/a?",
      percentage: 60,
      questions: [
        {
          formName: "depresso",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "depresso_si" },
            { label: "No", value: "depresso_no" },
            { label: "A volte", value: "depresso_a_volte" }
          ]
        }
      ],
      uid: 7
    },
    {
      condition: [{ formName: "target", value: ["memoria"] }],

      description: "Hai difficoltà nel ricordare eventi passati?",
      percentage: 80,
      questions: [
        {
          formName: "difficolta_eventi_passati",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "difficolta_eventi_passati_si" },
            { label: "No", value: "difficolta_eventi_passati_no" },
            { label: "Non ricordo quelli recenti", value: "difficolta_eventi_passati_recenti" }
          ]
        }
      ],
      uid: 8
    },
    {
      condition: [{ formName: "target", value: ["memoria"] }],

      description: "Fai uso di sostanze alcoliche?",
      percentage: 90,
      questions: [
        {
          formName: "sostanze_alcoliche",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "sostanze_alcoliche_si" },
            { label: "No", value: "sostanze_alcoliche_no" },
            { label: "Ogni tanto", value: "sostanze_alcoliche_ogni_tanto" }
          ]
        }
      ],
      uid: 9
    },
    ////////////////// STANCHEZZA //////////////////////
    {
      condition: [{ formName: "target", value: ["stanchezza"] }],

      description: "Soffri di allergie o intolleranze?",
      percentage: 40,
      questions: [
        {
          formName: "allergie",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "allergie_si" },
            { label: "No", value: "allergie_no" }
          ]
        }
      ],
      uid: 10
    },
    {
      condition: [{ formName: "target", value: ["stanchezza"] }],

      description: "Al risveglio ti senti stanco?",
      percentage: 50,
      questions: [
        {
          formName: "stanco",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "stanco_si" },
            { label: "No", value: "stanco_no" },
            { label: "Ogni tanto", value: "stanco_ogni_tanto" }
          ]
        }
      ],
      uid: 11
    },
    {
      condition: [{ formName: "target", value: ["stanchezza"] }],

      description: "Soffri di insonnia?",
      percentage: 60,
      questions: [
        {
          formName: "insonnia",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "insonnia_si" },
            { label: "No", value: "insonnia_no" },
            { label: "A volte", value: "insonnia_a_volte" }
          ]
        }
      ],
      uid: 12
    },
    {
      condition: [{ formName: "target", value: ["stanchezza"] }],

      description: "Segui un'alimentazione bilanciata?",
      percentage: 80,
      questions: [
        {
          formName: "alimentazione_bilanciata",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "alimentazione_bilanciata_si" },
            { label: "No", value: "alimentazione_bilanciata_no" },
            { label: "A volte", value: "alimentazione_bilanciata_a_volte" }
          ]
        }
      ],
      uid: 13
    },
    {
      condition: [{ formName: "target", value: ["stanchezza"] }],

      description: "Svolgi regolarmente attività fisica?",
      percentage: 82,
      questions: [
        {
          formName: "attività_fisica",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "attività_fisica_si" },
            { label: "No", value: "attività_fisica_no" },
            { label: "A volte", value: "attività_fisica_a_volte" }
          ]
        }
      ],
      uid: 14
    },
    {
      condition: [{ formName: "target", value: ["stanchezza"] }],

      description: "Conduci una vita stressante?",
      percentage: 84,
      questions: [
        {
          formName: "vita_stressante",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "vita_stressante_si" },
            { label: "No", value: "vita_stressante_no" }
          ]
        }
      ],
      uid: 15
    },
    {
      condition: [{ formName: "target", value: ["stanchezza"] }],

      description: "Fai uso di integratori vitaminici con frequenza?",
      percentage: 88,
      questions: [
        {
          formName: "integratori_vitaminici",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "integratori_vitaminici_si" },
            { label: "No", value: "integratori_vitaminici_no" },
            { label: "Ogni tanto", value: "integratori_vitaminici_ogni_tanto" }
          ]
        }
      ],
      uid: 16
    },
    ////////////////// IRREQUIETEZZA //////////////////////
    {
      condition: [{ formName: "target", value: ["irrequietezza"] }],

      description: "Ti senti sovraccaricato da impegni che non riesci a portare a termine?",
      percentage: 40,
      questions: [
        {
          formName: "impegni",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "impegni_si" },
            { label: "No", value: "impegni_no" },
            { label: "A volte", value: "impegni_a_volte" }
          ]
        }
      ],
      uid: 17
    },
    {
      condition: [{ formName: "target", value: ["irrequietezza"] }],

      description: "Avverti la sensazione di non trovare pace?",
      percentage: 50,
      questions: [
        {
          formName: "non_trovare_pace",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "non_trovare_pace_si" },
            { label: "No", value: "non_trovare_pace_no" },
            { label: "A volte mi capita", value: "non_trovare_pace_a_volte" }
          ]
        }
      ],
      uid: 18
    },
    {
      condition: [{ formName: "target", value: ["irrequietezza"] }],

      description: "Trovi difficoltà a rilassarti?",
      percentage: 60,
      questions: [
        {
          formName: "difficolota_rilassarti",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "difficolota_rilassarti_si" },
            { label: "No", value: "difficolota_rilassarti_no" },
            { label: "Vorrei ma non riesco", value: "difficolota_rilassarti_non_riesco" }
          ]
        }
      ],
      uid: 19
    },
    {
      condition: [{ formName: "target", value: ["irrequietezza"] }],

      description: "Ti ritieni un perfezionista?",
      percentage: 80,
      questions: [
        {
          formName: "perfezionista",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "perfezionista_si" },
            { label: "No", value: "perfezionista_no" }
          ]
        }
      ],
      uid: 20
    },
    ////////////////// STRESS PROLUNGATO //////////////////////
    {
      condition: [{ formName: "target", value: ["stress"] }],

      description: "Hai subito di recente qualche trauma?",
      percentage: 40,
      questions: [
        {
          formName: "trauma",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "trauma_si" },
            { label: "No", value: "trauma_no" }
          ]
        }
      ],
      uid: 21
    },
    {
      condition: [{ formName: "target", value: ["stress"] }],

      description: "Senti di avere problemi che non riesci a risolvere?",
      percentage: 45,
      questions: [
        {
          formName: "problemi",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "problemi_si" },
            { label: "No", value: "problemi_no" },
            { label: "A volte", value: "problemi_a_volte" }
          ]
        }
      ],
      uid: 22
    },
    {
      condition: [{ formName: "target", value: ["stress"] }],

      description: "Hai difficoltà nel prendere sonno?",
      percentage: 50,
      questions: [
        {
          formName: "difficolta_sonno",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "difficolta_sonno_si" },
            { label: "No", value: "difficolta_sonno_no" },
            { label: "Ogni tanto", value: "difficolta_sonno_ogni_tanto" }
          ]
        }
      ],
      uid: 23
    },
    {
      condition: [{ formName: "target", value: ["stress"] }],

      description: "Hai disturbi fisici che ti preoccupano?",
      percentage: 55,
      questions: [
        {
          formName: "disturbi_fisici",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "disturbi_fisici_si" },
            { label: "No", value: "disturbi_fisici_no" },
            { label: "A volte", value: "disturbi_fisici_a_volte" }
          ]
        }
      ],
      uid: 24
    },
    {
      condition: [{ formName: "target", value: ["stress"] }],

      description: "Ti senti spesso a disagio in alcune situazioni?",
      percentage: 60,
      questions: [
        {
          formName: "disagio",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "disagio_si" },
            { label: "No", value: "disagio_no" },
            { label: "Ogni tanto", value: "disagio_ogni_tanto" }
          ]
        }
      ],
      uid: 25
    },
    {
      condition: [{ formName: "target", value: ["stress"] }],

      description: "Avverti rabbia?",
      percentage: 65,
      questions: [
        {
          formName: "rabbia",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "rabbia_si" },
            { label: "No", value: "rabbia_no" },
            { label: "Ogni tanto", value: "rabbia_ogni_tanto" }
          ]
        }
      ],
      uid: 26
    },
    {
      condition: [{ formName: "target", value: ["stress"] }],

      description: "Ti capita di piangere senza un motivo apparente?",
      percentage: 70,
      questions: [
        {
          formName: "piangere",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "piangere_si" },
            { label: "No", value: "piangere_no" }
          ]
        }
      ],
      uid: 27
    },
    {
      condition: [{ formName: "target", value: ["stress"] }],

      description: "Ti senti infastidito/a dai cambiamenti?",
      percentage: 75,
      questions: [
        {
          formName: "infastidito",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "infastidito_si" },
            { label: "No", value: "infastidito_no" }
          ]
        }
      ],
      uid: 28
    },
    {
      condition: [{ formName: "target", value: ["stress"] }],

      description: "Fai uso di psicofarmaci?",
      percentage: 80,
      questions: [
        {
          formName: "psicofarmaci",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "psicofarmaci_si" },
            { label: "No", value: "psicofarmaci_no" },
            { label: "Ogni tanto", value: "psicofarmaci_ogni_tanto" }
          ]
        }
      ],
      uid: 29
    },
    ////////////////// ANSIA //////////////////////
    {
      condition: [{ formName: "target", value: ["ansia"] }],

      description: "Avverti una sensazione di vuoto alla testa?",
      percentage: 40,
      questions: [
        {
          formName: "vuoto_testa",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "vuoto_testa_si" },
            { label: "No", value: "vuoto_testa_no" },
            { label: "E' capitato", value: "vuoto_testa_capitato" }
          ]
        }
      ],
      uid: 30
    },
    {
      condition: [{ formName: "target", value: ["ansia"] }],

      description: "Hai pensieri negativi ricorrenti?",
      percentage: 45,
      questions: [
        {
          formName: "pensieri_negativi",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "pensieri_negativi_si" },
            { label: "No", value: "pensieri_negativi_no" }
          ]
        }
      ],
      uid: 31
    },
    {
      condition: [{ formName: "target", value: ["ansia"] }],

      description: "Avverti tremori o palpitazioni?",
      percentage: 50,
      questions: [
        {
          formName: "tremori",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "tremori_si" },
            { label: "No", value: "tremori_no" }
          ]
        }
      ],
      uid: 32
    },
    {
      condition: [{ formName: "target", value: ["ansia"] }],

      description: "Ti è capitato di avere dolore toracico?",
      percentage: 55,
      questions: [
        {
          formName: "dolore_toracico",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Spesso", value: "dolore_toracico_si" },
            { label: "No", value: "dolore_toracico_no" },
            { label: "A volte", value: "dolore_toracico_a_volte" }
          ]
        }
      ],
      uid: 33
    },
    {
      condition: [{ formName: "target", value: ["ansia"] }],

      description: "Soffri di attacchi di panico?",
      percentage: 60,
      questions: [
        {
          formName: "panico",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "panico_si" },
            { label: "No", value: "panico_no" },
            { label: "E' capitato", value: "panico_capitato" }
          ]
        }
      ],
      uid: 34
    },
    {
      condition: [{ formName: "target", value: ["ansia"] }],

      description: "Hai la sensazione che ti manchi l'aria spesso?",
      percentage: 65,
      questions: [
        {
          formName: "manca_aria",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "No", value: "manca_aria_no" },
            { label: "Ogni tanto", value: "manca_aria_ogni_tanto" }
          ]
        }
      ],
      uid: 35
    },
    {
      condition: [{ formName: "target", value: ["ansia"] }],

      description: "Sei soggetto/a a sudare quando provi un'emozione inaspettata?",
      percentage: 70,
      questions: [
        {
          formName: "sudore",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "sudore_si" },
            { label: "No", value: "sudore_no" }
          ]
        }
      ],
      uid: 36
    },
    {
      condition: [{ formName: "target", value: ["ansia"] }],

      description: "Fai uso di ansiolitici?",
      percentage: 80,
      questions: [
        {
          formName: "ansiolitici",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "ansiolitici_si" },
            { label: "No", value: "ansiolitici_no" },
            { label: "A volte", value: "ansiolitici_a_volte" }
          ]
        }
      ],
      uid: 37
    },
    ////////////////// SQUILIBRI SONNO //////////////////////
    {
      condition: [{ formName: "target", value: ["sonno"] }],

      description: "Hai difficoltà ad addormentarti?",
      percentage: 40,
      questions: [
        {
          formName: "difficolta_addormentarti",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "difficolta_addormentarti_si" },
            { label: "No", value: "difficolta_addormentarti_no" },
            { label: "A volte", value: "difficolta_addormentarti_a_volte" }
          ]
        }
      ],
      uid: 38
    },
    {
      condition: [{ formName: "target", value: ["sonno"] }],

      description: "Ti capita di svegliarti più volte la notte?",
      percentage: 47,
      questions: [
        {
          formName: "sveglio_di_notte",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "sveglio_di_notte_si" },
            { label: "No", value: "sveglio_di_notte_no" },
            { label: "A volte", value: "sveglio_di_notte_a_volte" }
          ]
        }
      ],
      uid: 39
    },
    {
      condition: [{ formName: "target", value: ["sonno"] }],

      description: "Durante il giorno accusi stanchezza?",
      percentage: 54,
      questions: [
        {
          formName: "accusi_stanchezza",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "accusi_stanchezza_si" },
            { label: "No", value: "accusi_stanchezza_no" },
            { label: "A volte", value: "accusi_stanchezza_a_volte" }
          ]
        }
      ],
      uid: 40
    },
    {
      condition: [{ formName: "target", value: ["sonno"] }],

      description: "Ceni tardi la sera?",
      percentage: 60,
      questions: [
        {
          formName: "ceni_tardi",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "ceni_tardi_si" },
            { label: "No", value: "ceni_tardi_no" },
            { label: "Ogni tanto", value: "ceni_tardi_ogni_tanto" }
          ]
        }
      ],
      uid: 41
    },
    {
      condition: [{ formName: "target", value: ["sonno"] }],

      description: "I pasti serali sono abbondanti?",
      percentage: 66,
      questions: [
        {
          formName: "pasti_abbondanti",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "pasti_abbondanti_si" },
            { label: "No", value: "pasti_abbondanti_no" },
            { label: "Ogni tanto", value: "pasti_abbondanti_ogni_tanto" }
          ]
        }
      ],
      uid: 42
    },
    {
      condition: [{ formName: "target", value: ["sonno"] }],

      description: "Vai a dormire ad orari regolari?",
      percentage: 72,
      questions: [
        {
          formName: "orari_regolari",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "orari_regolari_si" },
            { label: "No", value: "orari_regolari_no" },
            { label: "A volte", value: "orari_regolari_a_volte" }
          ]
        }
      ],
      uid: 43
    },
    {
      condition: [{ formName: "target", value: ["sonno"] }],

      description: "Fumi e bevi bevande alcoliche?",
      percentage: 78,
      questions: [
        {
          formName: "fumi",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "fumi_si" },
            { label: "No", value: "fumi_no" },
            { label: "A volte", value: "fumi_a_volte" }
          ]
        }
      ],
      uid: 44
    },
    {
      condition: [{ formName: "target", value: ["sonno"] }],

      description: "Bevi caffè?",
      percentage: 85,
      questions: [
        {
          formName: "caffe",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si, uno al mattino", value: "caffe_1" },
            { label: "2-3 al giorno", value: "caffe_2" },
            { label: "più di 3 al giorno", value: "caffe_3" }
          ]
        }
      ],
      uid: 45
    },
    ////////////////// DEPRESSIONE //////////////////////
    {
      condition: [{ formName: "target", value: ["depressione"] }],

      description: "Ti capita di pensare alle stesse cose?",
      percentage: 40,
      questions: [
        {
          formName: "pensare_stesse_cose",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "pensare_stesse_cose_si" },
            { label: "No", value: "pensare_stesse_cose_no" },
            { label: "A volte", value: "pensare_stesse_cose_a_volte" }
          ]
        }
      ],
      uid: 46
    },
    {
      condition: [{ formName: "target", value: ["depressione"] }],

      description: "Ti manca la voglia di fare anche le azioni più banali?",
      percentage: 47,
      questions: [
        {
          formName: "manca_voglia",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "manca_voglia_si" },
            { label: "No", value: "manca_voglia_no" },
            { label: "Ogni tanto", value: "manca_voglia_ogni_tanto" }
          ]
        }
      ],
      uid: 47
    },
    {
      condition: [{ formName: "target", value: ["depressione"] }],

      description: "Tendi ad avere sensi di colpa?",
      percentage: 54,
      questions: [
        {
          formName: "sensi_colpa",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "sensi_colpa_si" },
            { label: "No", value: "sensi_colpa_no" },
            { label: "A volte", value: "sensi_colpa_a_volte" }
          ]
        }
      ],
      uid: 48
    },
    {
      condition: [{ formName: "target", value: ["depressione"] }],

      description: "Ti senti triste?",
      percentage: 60,
      questions: [
        {
          formName: "triste",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "triste_si" },
            { label: "No", value: "triste_no" },
            { label: "Ogni tanto", value: "triste_ogni_tanto" }
          ]
        }
      ],
      uid: 49
    },
    {
      condition: [{ formName: "target", value: ["depressione"] }],

      description: "Tendi ad isolarti?",
      percentage: 66,
      questions: [
        {
          formName: "isolarti",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "isolarti_si" },
            { label: "No", value: "isolarti_no" },
            { label: "Ogni tanto", value: "isolarti_ogni_tanto" }
          ]
        }
      ],
      uid: 50
    },
    {
      condition: [{ formName: "target", value: ["depressione"] }],

      description: "Mangi in maniera smisurata dolci?",
      percentage: 72,
      questions: [
        {
          formName: "dolci_smisurati",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "dolci_smisurati_si" },
            { label: "No", value: "dolci_smisurati_no" },
            { label: "Ogni tanto", value: "dolci_smisurati_ogni_tanto" }
          ]
        }
      ],
      uid: 51
    },
    {
      condition: [{ formName: "target", value: ["depressione"] }],

      description: "Hai scarso appetito?",
      percentage: 80,
      questions: [
        {
          formName: "scarso_appentito",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "scarso_appentito_si" },
            { label: "No", value: "scarso_appentito_no" },
            { label: "Ogni tanto", value: "scarso_appentito_ogni_tanto" }
          ]
        }
      ],
      uid: 52
    },
    {
      condition: [{ formName: "target", value: ["depressione"] }],

      description: "Fai uso di antidepressivi?",
      percentage: 85,
      questions: [
        {
          formName: "antidepressivi",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "antidepressivi_si" },
            { label: "No", value: "antidepressivi_no" },
            { label: "Ogni tanto", value: "antidepressivi_ogni_tanto" }
          ]
        }
      ],
      uid: 53
    }
  ]
};
