import React from 'react';
import {Container, Field} from "bloomer";
import {withLabCtx} from "../../contexts/LabContext";
import Slider from 'rc-slider';

class SliderRenderer extends React.Component {

    constructor(props) {
        super(props);
        this.updateValue = this.updateValue.bind(this);
        this.generateMarks = this.generateMarks.bind(this);
        const min = this.props.question.min || 0;
        const max = this.props.question.max || 5;
        const step = this.props.question.step || 1;

        const marks = {};
        for (let i = min; i <= max; i = i + step) {
            marks[i] = "" + i;
        }
        this.state = {marks: this.props.question.marks || marks};
    }

    updateValue(value) {
        this.props.updateValue(this.props.question.formName, value);
    }

    generateMarks() {
        const min = this.props.question.min || 0;
        const max = this.props.question.max || 5;
        const step = this.props.question.step || 1;

        const marks = {};
        for (let i = min; i <= max; i + step) {
            marks[i] = "" + i;
        }
        return marks;
    }

    render() {
        return <Field className={this.props.className || ''}>
            {this.props.question.title ? <h6 className='title'>{this.props.question.title}</h6> : null}

            <Container>
                <Slider onChange={this.updateValue}
                        min={this.props.question.min || 0}
                        max={this.props.question.max || 5}
                        step={this.props.question.step || 1}
                        marks={this.state.marks}
                        defaultValue={this.props.lab.survey[this.props.question.formName] || 0}/>
            </Container>
        </Field>
    }
}

export default withLabCtx(SliderRenderer);