import Utils from "./Utils";
import firebase from "firebase/app";
import Decimal from "decimal.js";

export default class ConsulenzaAI {
  weightSort(l) {
    l.sort((a, b) => {
      if (a.weight > b.weight) return -1;
      if (a.weight < b.weight) return 1;
      return 0;
    });

    console.log("AA element sort", l);
    return l;
  }

  controllaEccezione(survey, sku) {
    // console.log("AA controlla eccezione sku", sku, survey);

    if (survey["age"] < 45 && sku == "N056") return false; //Nutriserep

    if (survey["sex"] == "female" && sku == "N056") return false; //Nutriserep
    if (survey["sex"] == "male" && sku == "N048") return false;

    if (survey["56" == "154"] && sku == "N016") return false;
    if (survey["diseaseas" == "anticoagulanti"] && sku == "T063") return false;

    return true;
  }

  forzaElementi(c, prodottiSorted, survery) {
    //rimuovere dalla lista

    if (c == "Perdita di peso e colesterolo" && survery["55"] == "151") {
      prodottiSorted = prodottiSorted.filter((p) => p.sku != "N016");
      prodottiSorted.unshift({ sku: "N016", peso: "100", nome: "Fermacol" });
    }

    if (c == "Depurazione e benessere intestinale" && survery["44"] == "121") {
      prodottiSorted = prodottiSorted.filter((p) => p.sku != "N017");
      prodottiSorted.unshift({ sku: "N017", peso: "100", nome: "Grani di Herbe" });
    }

    if (prodottiSorted[0].sku == "T011" || prodottiSorted[0].sku == "T012" || prodottiSorted[1].sku == "T011" || prodottiSorted[1].sku == "T012") {
      //pre e post workout nei primi 2 risultati
      prodottiSorted = prodottiSorted.filter((p) => p.sku != "T001" && p.sku != "T002" && p.sku != "T018" && p.sku != "T022" && p.sku != "T023");
    }

    if (prodottiSorted[0].sku == "T008" || prodottiSorted[1].sku == "T008") {
      //Vikings nelle prime 2 posizioni
      prodottiSorted = prodottiSorted.filter((p) => p.sku != "N057");
    }

    return prodottiSorted;
  }

  sostituisciElementi(c, prodottiSorted, survery) {
    //rimuovere dalla lista

    if (c == "Attività sportiva e aumento massa" && survery["intolerances"] == "lf") {
      prodottiSorted.forEach((p) => {
        if (p.sku === "T004") {
          p.sku = "T061";
          p.nome = "Proteine della soia";
        }
      });
    }

    return prodottiSorted;
  }

  getCategoryFormatted(c) {
    return c
      .replaceAll(" ", "")
      .replaceAll(",", "")
      .toLowerCase();
  }

  async executeConsulenza(consulenza, labContext, categorie, domande, domandeRisposte) {
    Utils.log("CONSULENZA V3");

    console.log("AA ccc AI categorieRisposte", categorie);
    console.log("AA ccc AI sorted", domande);
    console.log("AA ccc AI domandeRisposte", domandeRisposte);

    const categorieAI = consulenza.categorieAI;
    delete consulenza.categoria;
    delete consulenza.categorias;
    // delete consulenza.categorieAI;

    console.log("AA ccc AI", consulenza);

    const risposte = [];

    Object.keys(consulenza).forEach(function(c) {
      risposte.push(consulenza[c]);
    });

    console.log("AA ccc AI risposte", risposte);

    const db = firebase.firestore();

    const pesi = [];
    const pesiSnap = await db.collection("consulenza_pesi").get();

    pesiSnap.forEach((p) => {
      let peso = p.data();

      peso.peso = parseFloat(peso.peso.toString().replace(",", "."));

      if (!risposte.includes(peso.id_risposta) || peso.peso === 0) return;

      Object.keys(categorie).forEach(function(c) {
        const cat = categorie[c];

        if (cat.risposte.includes(peso.id_risposta)) {
          peso.categoria = cat.categoria;
          pesi.push(peso);
        } else {
        }
      });
    });
    console.log("AA ccc AI pesi", pesi);

    const catPesi = [];
    pesi.forEach((p) => {
      if (!catPesi[p.categoria]) {
        catPesi[p.categoria] = {
          prodotti: [],
        };
      }

      const prodotti = catPesi[p.categoria].prodotti;
      if (!prodotti[p.sku]) {
        prodotti[p.sku] = {
          nome: p.prodotto,
          peso: 0,
        };
      }

      prodotti[p.sku].peso += p.peso;
      catPesi[p.categoria].prodotti = prodotti;
    });

    console.log("AA ccc AI catPesi", catPesi);

    const elementsFinale = [];

    Object.keys(catPesi).forEach((c) => {
      let prodottiSorted = [];
      Object.keys(catPesi[c].prodotti).forEach((d) => {
        if (!elementsFinale[d]) {
          elementsFinale[d] = { sku: d, nome: catPesi[c].prodotti[d].nome, peso: 0 };
        }
        let peso = elementsFinale[d].peso + catPesi[c].prodotti[d].peso;

        elementsFinale[d] = { sku: d, nome: catPesi[c].prodotti[d].nome, peso: peso };

        if (this.controllaEccezione(consulenza, d)) {
          prodottiSorted.push({ ...catPesi[c].prodotti[d], sku: d });
        }
      });

      prodottiSorted = prodottiSorted.sort((a, b) => (a.peso > b.peso && -1) || 1);
      prodottiSorted = this.sostituisciElementi(c, prodottiSorted, consulenza);
      prodottiSorted = this.forzaElementi(c, prodottiSorted, consulenza);

      catPesi[c].prodottiSorted = prodottiSorted;
    });

    let elementsFinaleSorted = [];
    Object.keys(elementsFinale).forEach((c) => {
      if (this.controllaEccezione(consulenza, elementsFinale[c].sku)) elementsFinaleSorted.push(elementsFinale[c]);
    });

    elementsFinaleSorted = elementsFinaleSorted.sort((a, b) => (a.peso > b.peso && -1) || 1);

    if (categorieAI.includes("attivitàsportivaeaumentomassa")) elementsFinaleSorted = this.sostituisciElementi("Attività sportiva e aumento massa", elementsFinaleSorted, consulenza);

    if (categorieAI.includes("perditadipesoecolesterolo")) elementsFinaleSorted = this.forzaElementi("Perdita di peso e colesterolo", elementsFinaleSorted, consulenza);
    if (categorieAI.includes("depurazioneebenessereintestinale")) elementsFinaleSorted = this.forzaElementi("Depurazione e benessere intestinale", elementsFinaleSorted, consulenza);
    if (categorieAI.includes("attivitàsportivaeaumentomassa")) elementsFinaleSorted = this.forzaElementi("Attività sportiva e aumento massa", elementsFinaleSorted, consulenza);
    elementsFinaleSorted = this.forzaElementi("-", elementsFinaleSorted, consulenza);

    console.log("AA elementsFinaleSorted after", elementsFinaleSorted);

    //Normalizzazione

    const normalizzazioneDati = [];
    let totaleDenom = 0;

    domande.forEach((d) => {
      let cat = "";
      if (!!d.categoria)
        cat = d.categoria
          .replaceAll(" ", "")
          .replaceAll(",", "")
          .toLowerCase();

      if (categorieAI.includes(cat)) {
        if (!normalizzazioneDati[d.categoria]) {
          normalizzazioneDati[d.categoria] = {
            numDomande: 0,
          };
        }
        normalizzazioneDati[d.categoria].numDomande = normalizzazioneDati[d.categoria].numDomande + 1;
        totaleDenom = totaleDenom + 5;
      }
    });

    Object.keys(normalizzazioneDati).forEach(function(c) {
      const n = normalizzazioneDati[c];
      n.peso = (n.numDomande * 5) / totaleDenom;
      n.valoreMassimo = 5 * n.numDomande;
    });

    let elementsNormalized = [];
    let elements3plus3 = [];
    Object.keys(catPesi).forEach((c) => {
      let added = false;
      Object.keys(catPesi[c].prodottiSorted).forEach((d) => {
        const prod = catPesi[c].prodottiSorted[d];
        const pesoCat = normalizzazioneDati[c].peso;

        if (!added && elements3plus3.filter((e) => e.sku === prod.sku).length === 0) {
          elements3plus3.push({ ...catPesi[c].prodottiSorted[d], sku: prod.sku, pesoNormalized: 1000, valoreFinale: 1000 * pesoCat, categoria: c });
          added = true;
        }

        const valoreMassimo = normalizzazioneDati[c].valoreMassimo;
        elementsNormalized.push({ ...catPesi[c].prodottiSorted[d], sku: prod.sku, pesoNormalized: prod.peso / valoreMassimo, valoreFinale: (prod.peso / valoreMassimo) * pesoCat, categoria: c });
        // elements3plus3.push({ ...catPesi[c].prodottiSorted[d], sku: prod.sku, pesoNormalized: prod.peso / valoreMassimo, valoreFinale: (prod.peso / valoreMassimo) * pesoCat });
      });
    });

    console.log("AA elementsNormalized", elementsNormalized, elements3plus3);

    const elementsNormalizedSomma = [];
    Object.keys(elementsNormalized).forEach((c) => {
      const prod = elementsNormalized[c];
      console.log("AA prod", prod);

      if (!elementsNormalizedSomma[prod.nome])
        elementsNormalizedSomma[prod.nome] = {
          nome: elementsNormalized[c].nome,
          sku: elementsNormalized[c].sku,
          sommaPesi: 0,
        };

      elementsNormalizedSomma[prod.nome].sommaPesi += elementsNormalized[c].valoreFinale;
    });

    let elementsNormalizedSommaArr = [];
    Object.keys(elementsNormalizedSomma).forEach((c) => {
      const el = elementsNormalizedSomma[c];
      console.log("AA el", el);
      elementsNormalizedSommaArr.push({
        nome: el.nome,
        sku: el.sku,
        peso: el.sommaPesi,
      });
    });

    elementsNormalizedSommaArr = elementsNormalizedSommaArr.sort((a, b) => (a.peso > b.peso && -1) || 1);

    console.log("AA normalizzazioneDati", elementsNormalizedSommaArr, elementsNormalizedSomma, elementsFinaleSorted, elementsNormalized, normalizzazioneDati, totaleDenom);

    const result = { catPesi, elementsFinaleSorted, elementsNormalizedSommaArr, elements3plus3 };
    return result;

    Utils.log(consulenza);

    const uniqueElements = [];
    const categories = [];
    const elements = [];
    Utils.log("cc ELEMENTS uniq", uniqueElements);

    if (uniqueElements.length > 0) {
      const consigli = "Ecco i nostri consigli per la tua integrazione.";
      const warnings =
        "Tenere fuori dalla portata dei bambini al di sotto dei tre anni. " +
        "Non superare la dose giornaliera consigliata. " +
        "Gli integratori non vanno intesi come sostituti di una dieta variata, " +
        "equilibrata e di un sano stile di vita. Non utilizzare in gravidanza, nei bambini, " +
        "in caso di ipertensione, tachicardia, problemi cardiaci, renali o comunque per periodi " +
        "prolungati senza sentire il parere del medico. Questa Formula " +
        "non sostituisce e non è da intendersi come il parere di un medico.";

      const recipe = {
        creationDate: new Date(),
        name: `Pack di integratori personalizzato di ${consulenza.name}`,
        description: `${consigli} \nOttimizzata per: ${categories.map((x) => x.name).join(", ")} \n${warnings}`,
        owner: consulenza.owner || "GUEST",
        roles: {},
        originalCreator: "mylabnut-7f5d-462f-86df-13179b064c29",
        public: false,
        kind: "SACHET",
      };

      const elPromises = [];
      let recipeElements = [];

      uniqueElements.forEach((e) => {
        elPromises.push(
          new Promise(async (resolve) => {
            const elData = (
              await firebase
                .firestore()
                .collection("elements")
                .doc(e)
                .get()
            ).data();
            elData.quantity = elData.sachetGrams;

            let bustine = Math.floor(elData.quantity / elData.sachetGrams);
            if (Utils.hasResto(elData.quantity, elData.sachetGrams)) bustine++;
            elData.sachets = bustine;

            elData.tags = [];
            const prms = [];
            (
              await firebase
                .firestore()
                .collection("elements")
                .doc(e)
                .collection("tags")
                .get()
            ).forEach(async (et) => {
              prms.push(
                new Promise(async (r) => {
                  const a = et.data();
                  elData.tags.push((await a.ref.get()).data());
                  r(true);
                })
              );
            });
            await Promise.all(prms);

            recipeElements.push(elData);
            resolve(elData);
          })
        );
      });

      await Promise.all(elPromises);

      Utils.log("cc categories", categories[0].uid);
      Utils.log("cc recipeElements before", recipeElements);

      recipeElements.forEach((re) => {
        elements.forEach((el) => {
          if (el.uid === re.uid) {
            re.weight = el.weight;
            return;
          }
        });
      });

      recipeElements = this.weightSort(recipeElements);
      // if (!!categories && categories.length > 0) recipeElements = Utils.sortWeightOnCategory(recipeElements, categories[0].uid);

      Utils.log("cc recipeElements", recipeElements);

      recipe.elements = recipeElements;
      recipe.categories = categories;
      recipe.ownerName = consulenza.name;
      recipe.user_cookie = localStorage.getItem("user_cookie");
      Utils.log(recipe);

      recipe.roles[recipe.owner] = "owner";
      const ref = await db.collection("recipes").add(recipe);
      Utils.log("Created recipe ", ref.id);
      return ref.id;
    }
    return null;
  }
}
