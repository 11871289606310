import React from "react";
import times from "../../../svgs/ic-24-close.svg";
import { withUserCtx } from "../../../contexts/UserContext";
import { withLabCtx } from "../../../contexts/LabContext";
import StarRating from "../../../components/StarRating";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Column, Columns, Tabs, TabList, Tab, TabLink } from "bloomer";
import firebase from "firebase/app";
import Utils from "../../../Utils";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";

class MPersonalTrainer extends React.Component {
  state = {
    trainers: [],
    recipes: [],
    tab: "info",
    trainer: {
      displayName: "",
      photoURL: ""
    }
  };

  constructor(props) {
    super(props);

    this.readRecipes = this.readRecipes.bind(this);
  }

  changeTab(tab) {
    this.setState({ tab: tab });
  }

  async componentDidMount() {
    const ptUid = this.props.match.params.ptUid;

    try {
      const trainerObserver = await firebase
        .firestore()
        .collection("profiles")
        .doc(ptUid)
        .onSnapshot(async snap => {
          if (snap.exists) {
            const data = { ...snap.data() };
            Utils.log(data);

            this.setState({
              trainer: data,
              loaded: true
            });
          } else {
            console.error("trainer not found", ptUid);
            this.props.modals.addError(`Trainer ${ptUid} non trovata`, null, () => {
              if (this.state.observer) this.state.observer();
              this.props.history.goBack()();
            });
          }
        });
      this.setState({
        observer: trainerObserver
      });
    } catch (e) {
      console.error("error fetching trainer data", e);
      Utils.handleError(e);
    }

    this.readRecipes();
  }

  async readRecipes() {
    const ptUid = this.props.match.params.ptUid;

    try {
      const trainerObserver = await firebase
        .firestore()
        .collection("recipes")
        .where("owner", "==", ptUid)
        .onSnapshot(async docs => {
          const data = [];
          docs.forEach(d => {
            data.push(d.data());
          });
          this.setState({ recipes: data });
        });
      this.setState({
        observer: trainerObserver
      });
    } catch (e) {
      console.error("error fetching trainer data", e);
      Utils.handleError(e);
    }
  }

  componentWillUnmount() {
    if (this.state.observer) this.state.observer();
  }

  render() {
    let starvalue = 5;
    const { trainer } = this.state;
    return (
      <div style={{ backgroundColor: "#161617", height: "100vh" }}>
        <Container className="lab-box group-17 is-opposite no-max-height no-box-shadow">
          <Columns isMobile>
            <Column>
              <img
                src={times}
                className="is-pulled-left"
                onClick={this.props.history.goBack()}
                style={{
                  minWidth: "24px",
                  minHeight: "24px",
                  maxWidth: "24px",
                  maxHeight: "24px",
                  marginTop: "1.5px"
                }}
                alt=""
              />
              <h3 className="title">&nbsp;Hall Of Fame</h3>
              <div className="is-clear-fix" />
              <Columns className="no-mb" isMobile>
                <Column isSize={{ mobile: 9 }}>
                  <h6 className="title has-text-mylab-color-light mb-0">&nbsp;&#060; torna a tutti i professionisti</h6>
                  <h2 className="title">
                    &nbsp;
                    {trainer.displayName}
                  </h2>
                  <h3 className="subtitle has-super-light-gray">&nbsp;Personal Trainer</h3>
                  <StarRating rating={starvalue} label="212" />
                </Column>
                <Column isSize={{ mobile: 3 }}>
                  <figure className="image is-90x90">
                    <img className="is-rounded" src={trainer.photoURL} alt="" />
                  </figure>
                </Column>
              </Columns>
              <h2 className="subtitle">&nbsp;Esperto in formule:</h2>
              <div>
                <span className="tag my-lab has-text-14">Brucia grassi</span>
                <span className="tag my-lab has-text-14">Aumento energia</span>
              </div>
            </Column>
          </Columns>
        </Container>
        <Tabs className="no-mb">
          <TabList>
            <Tab className={this.state.tab === "recepies" ? "is-active" : ""}>
              <TabLink onClick={() => this.changeTab("recepies")}>
                <span>Formule</span>
              </TabLink>
            </Tab>
            <Tab className={this.state.tab === "info" ? "is-active" : ""}>
              <TabLink onClick={() => this.changeTab("info")}>
                <span>Informazioni</span>
              </TabLink>
            </Tab>
          </TabList>
        </Tabs>
        {this.state.tab === "info" ? <InfoTab trainers={this.state.trainers} /> : <RecepiesTab recipes={this.state.recipes} {...this.props} />}
      </div>
    );
  }
}

class InfoTab extends React.Component {
  state = {
    trainers: []
  };

  render() {
    return (
      <div className="pb-12 mb-50">
        <Columns>
          <Column>
            <div className="lab-box has-no-bg border-bottom has-padding-3" style={{ minHeight: "150px", maxHeight: "150px" }}>
              <Columns isMobile>
                <Column isSize={{ mobile: 6 }} className="has-text-centered">
                  <h6 className="title">nome</h6>
                  <h2 className="subtitle">Michael Pastorelli</h2>
                </Column>
                <Column isSize={{ mobile: 6 }} className="has-text-centered">
                  <h6 className="title">età</h6>
                  <h2 className="subtitle">28 anni</h2>
                </Column>
              </Columns>
              <Columns isMobile>
                <Column isSize={{ mobile: 6 }} className="has-text-centered">
                  <h6 className="title">professione</h6>
                  <h2 className="subtitle">Personal Trainer</h2>
                </Column>
                <Column isSize={{ mobile: 6 }} className="has-text-centered">
                  <h6 className="title">città</h6>
                  <h2 className="subtitle">Roma</h2>
                </Column>
              </Columns>
            </div>
            <div className="lab-box has-no-bg border-bottom has-padding-3" style={{ minHeight: "150px", maxHeight: "none" }}>
              <h4 className="title">Qualifiche di Michael</h4>
              <p className="has-text-18 has-normal-weight">
                Tenere fuori dalla portata dei bambini al di sotto dei tre anni. Non superare la dose giornaliera consigliata. Gli integratori non vanno intesi come sostituti di una dieta variata,
                equilibrata e di un sano stile di vita. Non utilizzare in gravidanza, in caso di patologie renali e nei bambini, o comunque per periodi prolungati senza sentire il parere del medico.
              </p>
            </div>
            <div className="lab-box has-no-bg border-bottom has-padding-3" style={{ minHeight: "150px", maxHeight: "none" }}>
              <h4 className="title">La storia di Michael</h4>
              <p className="has-text-18 has-normal-weight">
                Tenere fuori dalla portata dei bambini al di sotto dei tre anni. Non superare la dose giornaliera consigliata. Gli integratori non vanno intesi come sostituti di una dieta variata,
                equilibrata e di un sano stile di vita. Non utilizzare in gravidanza, in caso di patologie renali e nei bambini, o comunque per periodi prolungati senza sentire il parere del medico.
              </p>
            </div>
            <div className="lab-box has-no-bg no-box-shadow has-padding-3" style={{ minHeight: "150px", maxHeight: "none" }}>
              <h4 className="title">Link utili</h4>
              <ul>
                <li className="mb-10" onClick={() => this.goTo("#")}>
                  Pagina Facebook <FontAwesomeIcon icon={faSignInAlt} className="is-pulled-right" />
                </li>
                <li className="mb-10" onClick={() => this.goTo("#")}>
                  Sito Web <FontAwesomeIcon icon={faSignInAlt} className="is-pulled-right" />
                </li>
                <li className="mb-10" onClick={() => this.goTo("#")}>
                  Centro fitness in cui lavora <FontAwesomeIcon icon={faSignInAlt} className="is-pulled-right" />
                </li>
              </ul>
            </div>
          </Column>
        </Columns>
      </div>
    );
  }
}

class RecepiesTab extends React.Component {
  componentDidMount() {
    Utils.log("Recipe tab didmount");
  }
  render() {
    let starvalue = 5;

    const { recipes } = this.props;
    return (
      <div className="pb-12 mb-50">
        {recipes.map(recipe => (
          <div
            key={recipe.uid}
            onClick={() => this.props.history.push(`/formula/${recipe.uid}`)}
            className="lab-box has-no-bg has-padding-3 border-bottom arrow--right"
            style={{ minHeight: "130px", maxHeight: "none" }}
          >
            <Columns isMobile>
              <Column>
                <StarRating rating={starvalue} label="14" />
                <h1 className="subtitle">
                  &nbsp;
                  {recipe.name}
                </h1>
              </Column>
            </Columns>
            <Columns>
              <Column isSize={{ mobile: 12 }} className="is-flex is-vertical-centered ptb-0">
                <img className="is-pulled-left" src="https://via.placeholder.com/14x14" style={{ height: "14px", width: "14px" }} alt="" /> &nbsp;{" "}
                <span className="has-text-13 has-normal-weight">Brucia Grassi</span>
              </Column>
              <Column isSize={{ mobile: 12 }} className="is-flex is-vertical-centered ptb-0">
                <img className="is-pulled-left" src="https://via.placeholder.com/14x14" style={{ height: "14px", width: "14px" }} alt="" /> &nbsp;{" "}
                <span className="has-text-13 has-normal-weight">144 Ordini</span>
              </Column>
            </Columns>
          </div>
        ))}
      </div>
    );
  }
}

export default withLabCtx(withUserCtx(withRouter(MPersonalTrainer)));
