export default {
  data: [
    {
      description: "Quante ore dormi la notte?",
      percentage: 35,
      questions: [
        {
          formName: "ore_sonno",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "3-4", value: "ore_sonno_3" },
            { label: "5-6", value: "ore_sonno_5" },
            { label: "Più di 6", value: "ore_sonno_6" }
          ]
        }
      ],
      uid: 0
    },
    {
      description: "Gestisci le tue giornate senza affanno?",
      percentage: 40,
      questions: [
        {
          formName: "senza_affanno",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "senza_affanno_si" },
            { label: "No", value: "senza_affanno_no" }
          ]
        }
      ],
      uid: 1
    },
    {
      description: "Hai fatto uso di qualche tecnica di concentrazione?",
      percentage: 42,
      questions: [
        {
          formName: "concentrazione",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "concentrazione_si" },
            { label: "No", value: "concentrazione_no" }
          ]
        }
      ],
      uid: 2
    },
    {
      description: "Segui una dieta corretta e bilanciata?",
      percentage: 45,
      questions: [
        {
          formName: "dieta_bilanciata",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "dieta_bilanciata_si" },
            { label: "No", value: "dieta_bilanciata_no" },
            { label: "A volte", value: "dieta_bilanciata_a_volte" }
          ]
        }
      ],
      uid: 3
    },
    {
      description: "Ti alleni con frequenza?",
      percentage: 50,
      questions: [
        {
          formName: "alleni_con_frequenza",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "alleni_con_frequenza_si" },
            { label: "No", value: "alleni_con_frequenza_no" }
          ]
        }
      ],
      uid: 4
    },
    {
      description: "Ti senti spesso stanco/a?",
      percentage: 55,
      questions: [
        {
          formName: "spesso_stanco",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "spesso_stanco_si" },
            { label: "No", value: "spesso_stanco_no" },
            { label: "A volte", value: "spesso_stanco_a_volte" }
          ]
        }
      ],
      uid: 5
    },
    {
      description: "Vedi i traguardi che vuoi raggiungere lontani?",
      percentage: 60,
      questions: [
        {
          formName: "traguardi_lontani",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "traguardi_lontani_si" },
            { label: "No", value: "traguardi_lontani_no" }
          ]
        }
      ],
      uid: 6
    },
    {
      description: "Hai difficoltà nel gestire le tue emozioni?",
      percentage: 65,
      questions: [
        {
          formName: "difficolta_emozioni",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "difficolta_emozioni_si" },
            { label: "No", value: "difficolta_emozioni_no" },
            { label: "A volte", value: "difficolta_emozioni_a_volte" }
          ]
        }
      ],
      uid: 7
    },
    {
      description: "Ti senti in difficoltà quando ti devi concentrare su un obiettivo da raggiungere?",
      percentage: 70,
      questions: [
        {
          formName: "difficolta_concentrazione",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "difficolta_concentrazione_si" },
            { label: "No", value: "difficolta_concentrazione_no" },
            { label: "Ci provo ma non ci riesco", value: "difficolta_concentrazione_ci_provo" }
          ]
        }
      ],
      uid: 8
    },
    {
      description: "Riesci a mantenere la tenuta dell'attenzione su tutta la durata della prestazione?",
      percentage: 72,
      questions: [
        {
          formName: "attenzione",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "attenzione_si" },
            { label: "No", value: "attenzione_no" },
            { label: "A volte", value: "attenzione_a_volte" }
          ]
        }
      ],
      uid: 9
    },
    {
      description: "Ritieni sia importante la concentrazione per chi fa sport?",
      percentage: 75,
      questions: [
        {
          formName: "importanza_concentrazione",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "importanza_concentrazione_si" },
            { label: "No", value: "importanza_concentrazione_no" },
            { label: "Dipende dallo sport", value: "importanza_concentrazione_dipende" }
          ]
        }
      ],
      uid: 10
    },
    {
      description: "Fai uso di integratori per migliorare la tua concentrazione?",
      percentage: 80,
      questions: [
        {
          formName: "integratori_concentrazione",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "integratori_concentrazione_si" },
            { label: "No", value: "integratori_concentrazione_no" }
          ]
        }
      ],
      uid: 11
    }
  ]
};
