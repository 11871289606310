import React, { Component } from "react";
import firebase from "firebase/app";
import Utils from "../Utils";
import { Decimal } from "decimal.js";
import OrderItemKind from "../enums/OrderItemKind";
import PaymentMethod from "../enums/PaymentMethod";
import CouponType from "../enums/CouponType";

export const CartContext = React.createContext(null);

export const CartConsumer = CartContext.Consumer;

export class CartProvider extends Component {
  state = {
    data: {},
    count: 0,
    price: 0,
    months: 1,
    couponDiscount: 0,
    doses: 0,
    loading: true,
    paymentMethod: null,
    shippingCost: 0,
    speseContrassegno: 0,
    amountForFreeShipping: 40,
    totalToPay: 0,
    firstUserOrder: false
  };

  observers = {};

  constructor(props) {
    super(props);

    this.detach = this.detach.bind(this);
    this.watchCart = this.watchCart.bind(this);
    this.assignCart = this.assignCart.bind(this);
    this.createCart = this.createCart.bind(this);
    this.getProductId = this.getProductId.bind(this);
    this.addElementToCart = this.addElementToCart.bind(this);
    this.checkUserValidity = this.checkUserValidity.bind(this);
    this.checkCartValidity = this.checkCartValidity.bind(this);
    this.checkProductsAvailability = this.checkProductsAvailability.bind(this);
    this.deleteElementFromCart = this.deleteElementFromCart.bind(this);
    this.updateProductQuantity = this.updateProductQuantity.bind(this);
    this.confirmStripePay = this.confirmStripePay.bind(this);
    this.updateBillingInfo = this.updateBillingInfo.bind(this);
    this.paypalOrPBCError = this.paypalOrPBCError.bind(this);
    this.paypalOrPBCSuccess = this.paypalOrPBCSuccess.bind(this);
    this.applyCoupon = this.applyCoupon.bind(this);
    this.removeCoupon = this.removeCoupon.bind(this);
    this.removeCart = this.removeCart.bind(this);
    this.resetCartStatus = this.resetCartStatus.bind(this);
    this.getTotalToPay = this.getTotalToPay.bind(this);
    this.updatePayment = this.updatePayment.bind(this);
  }

  async updateBillingInfo(billingInfo) {
    await firebase
      .firestore()
      .collection("orders")
      .doc(this.state.data.uid)
      .update({
        richiediFattura: true,
        billingInfo
      });
  }

  async confirmStripePay() {
    if (!this.state.data.stripeInfo || !this.state.data.stripeInfo.stripeToken) {
      Utils.log("Error with STRIPE token");
      return;
    }

    //Utils.log("charge id", this.state.data.stripeInfo.stripeToken);

    await firebase
      .firestore()
      .collection("orders")
      .doc(this.state.data.uid)
      .update({
        chargeId: this.state.data.stripeInfo.stripeToken,
        chargeType: PaymentMethod.STRIPE,
        chargeCustomerId: this.state.data.customer,
        price: this.state.price,
        discountedPrice: this.state.totalToPay,
        status: "DURING_PAYMENT",
        newOrderCoupons: true,
        orderDate: new Date(),
        creation: new Date(),
        shippingCost: this.state.freeShipping ? 0 : this.state.shippingCost,
        gift: this.state.couponGift || ""
      });
  }

  async paypalOrPBCSuccess() {
    const discountedPrice = this.state.totalToPay;

    //same as trigger onUpdateOrderCharge();
    const order = this.state.data;
    const uid = order.uid;
    const chargeRef = firebase
      .firestore()
      .collection("users")
      .doc(order.owner || order.customer)
      .collection("charges")
      .doc(order.uid);
    const charge = {
      creation: new Date(),
      type: this.state.paymentMethod,
      customerId: order.chargeCustomerId || null,
      amount: discountedPrice,
      reason: "ORDER",
      reasonRef: firebase
        .firestore()
        .collection("orders")
        .doc(uid),
      charged: "YES"
    };

    const orderStatus = order.paymentMethod === PaymentMethod.PBC ? "PAY_BY_CASH" : "PAYED";

    await firebase
      .firestore()
      .collection("orders")
      .doc(uid)
      .update({
        price: this.state.price,
        discountedPrice: discountedPrice,
        charged: true,
        status: orderStatus,
        shippingCost: this.state.freeShipping ? 0 : this.state.shippingCost,
        speseContrassegno: this.state.speseContrassegno,
        gift: this.state.couponGift || "",
        orderDate: new Date(),
        creation: new Date(),
        newOrderCoupons: true
      });
    chargeRef.set(charge);
  }

  async paypalOrPBCError() {
    //same as trigger onUpdateOrderCharge();
    const order = this.state.data;
    await firebase
      .firestore()
      .collection("orders")
      .doc(order.uid)
      .update({ charged: true, status: "PAYMENT_REFUSED" });
  }

  getProductId(uid) {
    for (let i = 0; i < this.state.items.length; i++) {
      if (this.state.items[i].uid === uid) {
        return this.state.items[i].item.uid;
      }
    }
    return null;
  }

  async userIsPT(uid) {
    const userRef = firebase
      .firestore()
      .collection("profiles")
      .doc(uid);

    const user = await userRef.get();

    return !!user.data().affiliation || !!user.data().personalTrainer;
  }

  async formulaCanApplyDiscount(coupon, formulaOwner) {
    Utils.log("AA formualOwner", formulaOwner, coupon.referral);
    return (
      coupon.type === CouponType.MARKETING_PERC ||
      !coupon.referral ||
      formulaOwner === coupon.referral ||
      /*coupon.type === CouponType.INFLUENCER &&*/ (formulaOwner != coupon.referral && !(await this.userIsPT(formulaOwner)))
    );
  }

  async applyCoupon(coupon) {
    Utils.log("AA coupon", coupon);

    var couponDiscount = new Decimal(0);
    var freeShippingCoupon = !!coupon && !!coupon.freeShipping;
    const couponGift = !!coupon && !!coupon.gift ? coupon.gift : "";

    if (!!coupon && !!coupon.minExpense && this.state.price < coupon.minExpense) return false;

    if (!!coupon && coupon.type === CouponType.MARKETING_FIXED_PRICE) {
      this.setState(
        {
          couponDiscount: coupon.amount,
          freeShippingCoupon,
          couponGift
        },
        this.watchCart
      );
    } else {
      const recipesRef = firebase
        .firestore()
        .collection("orders")
        .doc(this.state.data.uid)
        .collection("items");

      const recipes = await recipesRef.get();
      const promises = [];
      recipes.forEach(r => {
        const data = r.data();
        promises.push(
          new Promise(async r => {
            if (data.kind === OrderItemKind.FORMULA) data.dailyPrice = await new Utils().getRecipeDailyPrice(data.item);
            else data.dailyPrice = data.item.price;
            data.fullPrice = new Decimal(data.dailyPrice).times(data.quantity).toFixed(2);

            const recipePrice = new Decimal(data.fullPrice);
            var recipeDiscount = 0;
            if (!!coupon) recipeDiscount = recipePrice.times(coupon.amount).dividedBy(100);

            if (!!coupon && (await this.formulaCanApplyDiscount(coupon, data.item.owner))) {
              Utils.log("AA apply coupon", coupon.code);

              this.updateRecipePrice(data.uid, recipePrice.minus(recipeDiscount), coupon);
              couponDiscount = couponDiscount.plus(recipeDiscount);
            } else {
              Utils.log("AA DONT apply coupon");
              this.updateRecipePrice(data.uid, recipePrice, null);
            }

            r(data);
          })
        );
      });

      await Promise.all(promises);

      this.setState(
        {
          couponDiscount: couponDiscount.toDecimalPlaces(2).toFixed(2),
          freeShippingCoupon,
          couponGift
        },
        this.getTotalToPay
      );
    }
    //Utils.log("Discount total", couponDiscount.toDecimalPlaces(2).toFixed(2));

    await firebase
      .firestore()
      .collection("orders")
      .doc(this.state.data.uid)
      .update({
        coupon: !!coupon ? coupon.code : firebase.firestore.FieldValue.delete()
      });

    return true;
  }

  async removeCoupon() {
    this.applyCoupon(null);
  }

  removeCart() {
    localStorage.removeItem("cart");
    this.setState({
      count: 0,
      data: {},
      items: []
    });
  }

  async resetCartStatus() {
    localStorage.removeItem("cart");
    await firebase
      .firestore()
      .collection("orders")
      .doc(this.state.data.uid)
      .update({
        status: "CART"
      });

    this.setState({
      data: { status: "CART" }
    });
  }

  async updateRecipePrice(uid, price, coupon) {
    const recipesRef = firebase
      .firestore()
      .collection("orders")
      .doc(this.state.data.uid)
      .collection("items");

    const recipes = await recipesRef.get();
    recipes.forEach(async r => {
      Utils.log("AA Coupon", coupon);
      await firebase
        .firestore()
        .collection("orders")
        .doc(this.state.data.uid)
        .collection("items")
        .doc(uid)
        .update({ price: price.toFixed(2), coupon: !!coupon ? coupon : firebase.firestore.FieldValue.delete() });
    });
  }

  async updateProductQuantity(uid, quantity) {
    //Utils.log("updating quantity for item", uid);

    const itemRef = firebase
      .firestore()
      .collection("orders")
      .doc(this.state.data.uid)
      .collection("items")
      .doc(uid);

    const snapData = await itemRef.get();

    Utils.log("product data", snapData, snapData.exists);
    if (!snapData.exists) return;

    var dailyPrice = 0;
    if (snapData.data().kind === OrderItemKind.FORMULA) dailyPrice = await new Utils().getRecipeDailyPrice(snapData.data().item);
    else dailyPrice = snapData.data().item.price;

    const price = new Decimal(dailyPrice).times(quantity).toFixed(2);
    Utils.log("product data price", price, dailyPrice);
    Utils.log("product data data", snapData.data());

    itemRef.update({ quantity, price });
    this.getTotalToPay();
  }

  async deleteElementFromCart(uid) {
    //Utils.log("removing item from cart", uid);
    return await firebase
      .firestore()
      .collection("orders")
      .doc(this.state.data.uid)
      .collection("items")
      .doc(uid)
      .delete();
  }

  async assignCart() {
    try {
      const curUser = firebase.auth().currentUser;
      if (!curUser) {
        Utils.log("Remove CART");
        this.removeCart();
      }

      if (!!curUser && this.state.data.customer === "GUEST") {
        const data = { ...this.state.data };
        data.customer = curUser.uid;
        data.customerName = curUser.displayName;
        data.roles = {};
        data.roles[data.customer] = "OWNER";
        await firebase
          .firestore()
          .collection("orders")
          .doc(data.uid)
          .update({
            customer: data.customer,
            customerName: data.customerName,
            roles: data.roles
          });
      }
    } catch (error) {
      Utils.handleError(error);
      console.warn("Cannot assign cart to user", error);
    }
  }

  async checkUserValidity() {
    if (this.props.data.customer !== firebase.auth().currentUser.uid) {
      // Utils.log("Update cart with current user");
      await this.createCart();
    }
  }

  async checkProductsAvailability() {
    const { items } = this.state;
    let available = true;
    let formulaName = null;
    const itemsPromises = [];

    items.forEach(async i => {
      itemsPromises.push(
        new Promise(async re => {
          const collection = i.kind === OrderItemKind.FORMULA ? "recipes" : "products";

          const snap = await firebase
            .firestore()
            .collection(collection)
            .doc(i.refUid)
            .get();

          const promises = [];

          if (snap.exists) {
            const data = snap.data();
            Utils.log("AA cart formula", data);

            if (i.kind === OrderItemKind.PRODUCT) {
              available = data.availability;
            } else {
              const elements = data.elements;
              elements.forEach(async e => {
                promises.push(
                  new Promise(async r => {
                    const elSnap = await firebase
                      .firestore()
                      .collection("elements")
                      .doc(e.uid)
                      .get();

                    r(e.uid);
                    Utils.log("AA cart element", elSnap.data());

                    if (!elSnap.data().availability) {
                      available = false;
                    }
                  })
                );
              });
              await Promise.all(promises);
            }

            if (!available && !formulaName) formulaName = data.name;
          }
          Utils.log("AA available", available);

          re(i);
        })
      );
    });

    await Promise.all(itemsPromises);

    Utils.log("AA cart element not found", formulaName);

    return formulaName;
  }

  async addElementToCart(uid, type, quantity, callback) {
    this.removeCart();

    try {
      // if (!(await this.checkCartValidity())) {
      //Utils.log("Cart not valid, creating a new one");
      await this.createCart();
      // }

      Utils.log("AA cart", uid, type, quantity);

      //Utils.log("cart ready");
      let data = null;

      const collection = type === OrderItemKind.FORMULA ? "recipes" : "products";
      const snap = await firebase
        .firestore()
        .collection(collection)
        .doc(uid)
        .get();
      if (snap.exists) {
        data = snap.data();
      } else {
        this.props.modals.addError(`Il prodotto o formula con ID ${uid} non è stato trovato`);
        return null;
      }

      Utils.log("AA cart data", this.state.data.uid);

      const itemsRef = firebase
        .firestore()
        .collection("orders")
        .doc(this.state.data.uid)
        .collection("items");

      const itemUid = `${type}_${uid}`;
      const itemSnap = await itemsRef.doc(itemUid).get();
      // Utils.log(itemSnap);

      var dailyPrice = 0;
      if (type === OrderItemKind.FORMULA) dailyPrice = await new Utils().getRecipeDailyPrice(data);
      else dailyPrice = data.price;

      if (!itemSnap.exists)
        await itemsRef.doc(`${type}_${uid}`).set({
          kind: type,
          refUid: uid,
          uid: itemUid,
          quantity: quantity,
          item: data,
          price: new Decimal(dailyPrice).times(quantity).toFixed(2),
          add: new Date()
        });
      else {
        const updatedQuantity = itemSnap.data().quantity + quantity;
        await itemsRef.doc(itemUid).update({
          quantity: updatedQuantity,
          price: new Decimal(dailyPrice).times(quantity).toFixed(2)
        });
      }

      //Utils.log("added element to cart");
      if (callback) callback();
    } catch (e) {
      console.error("error adding element to cart", e);
      Utils.handleError(e);
      if (callback) callback(e);
    }
  }

  async checkCartValidity() {
    if (!!this.state.data && !!this.state.data.uid) {
      try {
        return this.state.data.status === "CART";
      } catch (e) {
        Utils.handleError(e);
        return false;
      }
    } else return false;
  }

  async createCart() {
    const resource = {
      roles: {},
      items: [],
      creation: new Date(),
      status: "CART",
      statuses: [
        {
          date: new Date(),
          description: "Creazione carrello",
          status: "CART"
        }
      ]
    };
    if (firebase.auth().currentUser) {
      resource.newOrder = true;
      resource.customer = firebase.auth().currentUser.uid;
      resource.customerName = firebase.auth().currentUser.displayName;
      resource.roles[firebase.auth().currentUser.uid] = "owner";
    } else {
      resource.roles["GUEST"] = "owner";
    }

    this.detach();

    const ref = await firebase
      .firestore()
      .collection("orders")
      .add(resource);
    await firebase
      .firestore()
      .collection("orders")
      .doc(ref.id)
      .update({ uid: ref.id });

    //Utils.log("created cart ", ref.id);

    this.setState(
      {
        data: { uid: ref.id },
        price: new Decimal(0),
        doses: new Decimal(0),
        count: 0
      },
      this.watchCart
    );
    return ref.id;
  }

  updatePayment(pm) {
    this.setState({
      ...this.state,
      paymentMethod: pm
    });
  }

  getTotalToPay() {
    var totalToPay = Math.max(this.state.price - this.state.couponDiscount, 0);
    const freeShipping = totalToPay > this.state.amountForFreeShipping || this.state.freeShippingCoupon;
    this.setState({ freeShipping });
    Utils.log("isFreeShipping", freeShipping);

    if (this.state.paymentMethod === PaymentMethod.PBC) totalToPay = totalToPay + this.state.speseContrassegno;

    if (!freeShipping && this.state.shippingCost > 0) totalToPay = totalToPay + this.state.shippingCost;

    Utils.log("Totale to pauy", totalToPay);
    // return new Decimal(total).toDecimalPlaces(2).toFixed(2);
    this.setState({ freeShipping, totalToPay: totalToPay.toFixed(2) });
  }

  async componentDidMount() {
    const defaultUid = localStorage.getItem("cart");
    if (!!defaultUid) {
      this.setState({ data: { uid: defaultUid } }, this.watchCart);
    } else if (!!firebase.auth().currentUser) {
      const carts = [];
      const snaps = await firebase
        .firestore()
        .collection("orders")
        .where("customer", "==", firebase.auth().currentUser.uid)
        .where("status", "==", "CART")
        .get();

      snaps.forEach(s => carts.push(s.data()));

      if (carts.length > 0) {
        this.setState({ data: { uid: carts[0].uid } }, this.watchCart);
      } else this.watchCart();
    } else this.watchCart();

    const configObserver = await firebase
      .firestore()
      .collection("config")
      .where("uid", "==", "production")
      .onSnapshot(doc => {
        doc.forEach(c => {
          Utils.log("config", c.data());
          this.setState({
            shippingCost: c.data().shippingCost,
            speseContrassegno: c.data().speseContrassegno,
            amountForFreeShipping: c.data().amountForFreeShipping
          });
        });
      });

    this.setState({
      addElementToCart: this.addElementToCart,
      checkProductsAvailability: this.checkProductsAvailability,
      checkUserValidity: this.checkUserValidity,
      assignCart: this.assignCart,
      deleteElementFromCart: this.deleteElementFromCart,
      updateProductQuantity: this.updateProductQuantity,
      getProductId: this.getProductId,
      confirmStripePay: this.confirmStripePay,
      updateBillingInfo: this.updateBillingInfo,
      paypalOrPBCError: this.paypalOrPBCError,
      paypalOrPBCSuccess: this.paypalOrPBCSuccess,
      applyCoupon: this.applyCoupon,
      removeCoupon: this.removeCoupon,
      removeCart: this.removeCart,
      resetCartStatus: this.resetCartStatus,
      getTotalToPay: this.getTotalToPay,
      updatePayment: this.updatePayment,
      configObserver: configObserver

      //   loading: false
    });

    firebase.auth().onAuthStateChanged(this.assignCart);
  }

  componentWillUnmount() {
    this.detach();
  }

  detach() {
    if (this.observers.cart) {
      this.observers.cart();
      delete this.observers.cart;
    }
    if (this.observers.cartItems) {
      this.observers.cartItems();
      delete this.observers.cartItems;
    }

    if (this.state.configObserver) {
      this.state.configObserver();
      delete this.state.configObserver;
    }
  }

  watchCart() {
    this.detach();

    if (!!this.state.data && !!this.state.data.uid) {
      const uid = this.state.data.uid;
      //Utils.log("start observing cart snapshot");
      this.observers.cart = firebase
        .firestore()
        .collection("orders")
        .doc(uid)
        .onSnapshot(
          snap => {
            try {
              const data = snap.data();
              // Utils.log("cart snapshot received", uid);
              if (snap.exists && (data.status === "PAYED" || data.status === "PAY_BY_CASH")) {
                localStorage.removeItem("cart");
                this.setState({
                  ...this.state,
                  data
                });
              } else if (snap.exists && (data.status === "PAYMENT_REFUSED" || data.status === "DURING_PAYMENT")) {
                this.setState({
                  ...this.state,
                  data: { ...data, status: data.status }
                });
              } else if (snap.exists && data.status === "CART") {
                this.setState({
                  data: data,
                  paymentMethod: data.paymentMethod
                });
                localStorage.setItem("cart", data.uid);
                // } else {
                //   if (this.observers.cart) {
                //     this.observers.cart();
                //     this.observers.cart = null;
                //   }
                //   this.setState({ data: null });
                //   localStorage.removeItem("cart");
              }
            } catch (e) {
              console.error("error saving cart snap", e);
            }
          },
          err => {
            console.error("error on cart snapshot: ", err);
            Utils.handleError(err);
            this.watchCart();
          }
        );

      this.observers.cartItems = firebase
        .firestore()
        .collection("orders")
        .doc(uid)
        .collection("items")
        .onSnapshot(
          async () => {
            try {
              const snap = await firebase
                .firestore()
                .collection("orders")
                .doc(uid)
                .collection("items")
                .get();
              const intItems = [];
              let doses = new Decimal(0);
              let price = new Decimal(0);
              const promises = [];
              //Utils.log("cart items snapshot received");
              snap.forEach(s => {
                if (s.exists) {
                  const data = s.data();
                  promises.push(
                    new Promise(async r => {
                      intItems.push(data);
                      if (data.kind === OrderItemKind.FORMULA) {
                        data.dailyPrice = await new Utils().getRecipeDailyPrice(data.item);
                        data.dailyGrams = Utils.getRecipeDailyGrams(data.item);
                        data.fullPrice = new Decimal(data.dailyPrice).times(data.quantity).toFixed(2);

                        doses = doses.plus(data.quantity);
                        price = price.plus(new Decimal(data.quantity).times(data.dailyPrice));
                      } else {
                        data.fullPrice = new Decimal(data.item.price).times(data.quantity).toFixed(2);
                        data.dailyPrice = new Decimal(data.fullPrice).dividedBy(data.quantity).toFixed(2);

                        price = price.plus(new Decimal(data.quantity).times(data.dailyPrice));
                      }
                      r(data);
                    })
                  );
                }
              });

              await Promise.all(promises);

              this.setState(
                {
                  items: intItems,
                  count: intItems.length,
                  price: price.toDecimalPlaces(2).toFixed(2),
                  doses: doses.toDecimalPlaces(0).toFixed(0),
                  loading: false
                },
                this.getTotalToPay
              );
            } catch (e) {
              console.error("error saving cart items snap", e);
            }
          },
          err => {
            console.error("error on cart items snapshot: ", err);
            Utils.handleError(err);
            this.watchCart();
          }
        );
    } else {
      this.setState({
        ...this.state,
        loading: false
      });
    }
  }

  render() {
    return (
      <CartContext.Provider value={this.state}>
        <div>{this.props.children}</div>
      </CartContext.Provider>
    );
  }
}

export function withCartCtx(Component) {
  return props => <CartConsumer>{ctx => <Component cart={ctx} {...props} />}</CartConsumer>;
}
