import React from "react";
import {
  Button,
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHeader,
  ModalCardTitle,
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  DropdownDivider,
  Navbar,
  NavbarBrand,
  NavbarEnd,
  NavbarItem
} from "bloomer";
import { withUserCtx } from "../../contexts/UserContext";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import "../../scss/custom.css";
import times from "../../svgs/ic-24-close.svg";
import MultiRender from "../MultiRender";
import { Title } from "bloomer/lib/elements/Title";

class MNavbar extends React.Component {
  state = {
    userDropdown: false,
    transparent: true,
    user: null
  };

  constructor(props) {
    super(props);
    this.updateTransparencyOnScroll = this.updateTransparencyOnScroll.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { user } = nextProps;

    this.setState({ user });
  }

  goToUserArea() {
    this.props.userCtx.showBusinessArea(false);
    this.props.history.push("/me/formule");
  }

  goToPTLanding() {
    this.props.history.push("/business");
  }

  goToPTArea() {
    this.props.userCtx.showBusinessArea(true);
    this.props.history.push("/business");
  }

  goToMyBusiness() {
    this.props.userCtx.showBusinessArea(true);
    this.props.history.push("/tuo-business");
  }

  goToBusinessFormulas() {
    this.props.userCtx.showBusinessArea(true);
    this.props.history.push("/me/formule");
  }

  goToAdminArea() {
    this.props.history.push("/admin");
  }

  goToUserFormulas() {
    this.props.history.push("/me/formule");
  }

  goToLab() {
    this.props.history.push("/laboratorio");
  }

  goToUserDetails() {
    this.props.history.push("/me");
  }

  goToUserAccountDetails() {
    this.props.history.push("/me/account");
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ userDropdown: false });
    }
  }

  updateTransparencyOnScroll() {
    if (window.scrollY < 60) {
      this.setState({ transparent: true });
    } else {
      this.setState({ transparent: false });
    }
  }

  onEnd() {
    this.setState({ userDropdown: false });
  }
  componentDidMount() {
    this.updateTransparencyOnScroll();
    const observer = document.addEventListener("scroll", this.updateTransparencyOnScroll);

    // const mouseObserver = document.addEventListener("mousedown", this.handleClickOutside);

    this.setState({ observer /*, mouseObserver */ });
  }

  componentWillUnmount() {
    if (this.state.observer) {
      this.state.observer();
      delete this.state.observer;
    }
    if (this.state.mouseObserver) {
      this.state.mouseObserver();
      delete this.state.mouseObserver;
    }
  }

  render() {
    const { generali } = this.props;
    const { history } = this.props;
    const showBusinessDropdown = this.props.user && this.props.user.personalTrainer;
    return (
      <div>
        <Navbar className={this.props.generali ? "navbar-generali" : "mobile-navbar " + (!this.props.isDark && this.state.transparent ? "full-transparent " : "") + this.props.className || ""}>
          <div ref={this.setWrapperRef} className="nav-container">
            <NavbarBrand>
              <NavbarItem>
                <Link to="/">
                  {this.props.generali ? (
                    <img style={{ maxHeight: "55px" }} src="/images/logoGenerali.png" alt="MyLab Nutrition - Il tuo integratore personalizzato" />
                  ) : (
                    <img src="/images/icona_mylab.png" alt="MyLab Nutrition - Il tuo integratore personalizzato" />
                  )}
                </Link>
              </NavbarItem>
            </NavbarBrand>
            <NavbarEnd>
              <NavbarItem>
                <div className="vw-mobile-only">
                  <Button
                    onClick={() =>
                      this.setState({
                        userDropdown: !this.state.userDropdown
                      })
                    }
                    aria-haspopup="true"
                    aria-controls="dropdown-menu"
                    className="is-transparent "
                  >
                    <img width="36px" src="/images/menu-hamburger.png" />
                  </Button>
                </div>
                <Dropdown className="vw-tablet dropdown-user" isAlign="right" isActive={this.state.userDropdown}>
                  <DropdownTrigger>
                    <Button
                      onClick={() =>
                        this.setState({
                          userDropdown: !this.state.userDropdown
                        })
                      }
                      aria-haspopup="true"
                      aria-controls="dropdown-menu"
                      className="is-transparent"
                    >
                      <img width="36px" src="/images/menu-hamburger.png" />
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu>
                    <DropdownContent>
                      <DropdownItem onClick={() => history.push("/")}>HOME</DropdownItem>
                      <DropdownDivider />
                      <DropdownItem onClick={() => history.push("/come-funziona")}>COME FUNZIONA</DropdownItem>
                      <DropdownItem onClick={() => history.push("/consulenza")}>INIZIA IL TEST</DropdownItem>
                      <DropdownItem onClick={() => history.push("/chi-siamo")}>LA STARTUP</DropdownItem>

                      {this.state.user ? (
                        <div>
                          <DropdownItem onClick={() => history.push("/me")}>Dati Profilo</DropdownItem>
                          <DropdownItem onClick={() => history.push("/me/formule")}>FORMULE/ORDINI</DropdownItem>

                          {this.state.user.personalTrainer && (
                            <div>
                              <DropdownDivider />
                              <Title className="dropdown-title has-text-grey-light">AREA BUSINESS</Title>

                              <DropdownItem onClick={() => this.goToPTArea()}>DASHBOARD BUSINESS</DropdownItem>
                              <DropdownItem onClick={() => this.goToMyBusiness()}>PROFILO BUSINESS</DropdownItem>
                              <DropdownItem onClick={() => history.push("/laboratorio")}>CREA FORMULA</DropdownItem>
                              <DropdownItem onClick={() => this.goToBusinessFormulas()}>LE TUE FORMULE</DropdownItem>
                            </div>
                          )}
                          {this.state.user.admin && (
                            <div>
                              <DropdownDivider />
                              <DropdownItem onClick={() => history.push("/admin")}>ADMIN</DropdownItem>
                            </div>
                          )}
                          <DropdownDivider />
                          <DropdownItem className="has-text-grey-light" onClick={this.props.userCtx.doLogout}>
                            Esci dall'account
                          </DropdownItem>
                        </div>
                      ) : (
                        <DropdownItem className="has-text-grey-light" onClick={this.props.userCtx.doLogin}>
                          ACCEDI
                        </DropdownItem>
                      )}
                    </DropdownContent>
                  </DropdownMenu>
                </Dropdown>
              </NavbarItem>
            </NavbarEnd>
          </div>
        </Navbar>

        <div className="vw-mobile-only">
          <NavbarMenuModal isActive={this.state.userDropdown} user={this.state.user} onEnd={() => this.onEnd()} {...this.props} />
        </div>
      </div>
    );
  }
}

export default withRouter(withUserCtx(MNavbar));

class NavbarMenuModal extends React.Component {
  state = {
    modalOpen: false
  };

  doLogin() {
    this.props.userCtx.doLogin();
    this.props.onEnd();
  }

  doLogout() {
    this.props.userCtx.doLogout();
    this.props.onEnd();
  }

  render() {
    return (
      <Modal isActive={this.props.isActive} className="has-text-centered is-small">
        <ModalCard className={window.innerWidth <= 768 ? "is-fullscreen" : ""} style={{ backgroundColor: "#dfe7e9" }}>
          <div style={{ height: "100%", backgroundColor: "#dfe7e9" }}>
            <ModalCardHeader style={{ opacity: "0.58", backgroundColor: "#981b3b" }}>
              <img
                src={times}
                className="is-pulled-left"
                onClick={this.props.onEnd}
                style={{
                  minWidth: "24px",
                  minHeight: "24px",
                  maxWidth: "24px",
                  maxHeight: "24px"
                }}
                alt=""
              />
            </ModalCardHeader>
            <ModalCardBody style={{ backgroundColor: "#dfe7e9" }}>
              <div>
                <Link className="navbar-text" to="/">
                  HOME
                </Link>
                <DropdownDivider />

                <Link className="navbar-text" to="/come-funziona">
                  COME FUNZIONA
                </Link>
                <Link className="navbar-text" to="/consulenza">
                  INIZIA IL TEST
                </Link>
                <Link className="navbar-text" to="/chi-siamo">
                  LA STARTUP
                </Link>
                <Link className="navbar-text" to="/me">
                  DATI PROFILO
                </Link>
                <Link className="navbar-text" to="/me/formule">
                  FORMULE/ORDINI
                </Link>
                {!this.props.user ? (
                  <Button className="has-text-green navbar-text is-transparent" onClick={() => this.doLogin()}>
                    ACCEDI
                  </Button>
                ) : (
                  <MultiRender>
                    {this.props.user.personalTrainer && (
                      <div>
                        <DropdownDivider />
                        <Title className="navbar-text-title">AREA BUSINESS</Title>

                        <Link className="navbar-text" to="/business">
                          DASHBOARD BUSINESS
                        </Link>
                        <Link className="navbar-text" to="/tuo-business">
                          PROFILO BUSINESS
                        </Link>
                        <Link className="navbar-text" to="/laboratorio">
                          CREA FORMULA
                        </Link>
                        <Link className="navbar-text" to="/me/formule">
                          LE TUE FORMULE
                        </Link>
                      </div>
                    )}
                    <DropdownDivider />

                    <Button className="has-text-green navbar-text is-transparent" onClick={() => this.doLogout()}>
                      ESCI
                    </Button>
                  </MultiRender>
                )}
              </div>
            </ModalCardBody>
            <ModalCardFooter />
          </div>
        </ModalCard>
      </Modal>
    );
  }
}
