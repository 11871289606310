import React from "react";
import MFormulaListElement from "./MFormulaListElement";
import { Link } from "react-router-dom";
import Utils from "../../Utils";

export default class MFormulaList extends React.PureComponent {
  defaultClick() {
    Utils.log("default click");
  }
  render() {
    Utils.log(this.props);
    const { formulas } = this.props;
    const hasClick = typeof this.props.onClick === "function";

    if (hasClick) {
      return formulas.map(f => <MFormulaListElement key={f.uid} formula={f} onClick={() => this.props.onClick(f)} />);
    } else {
      return formulas.map(f => (
        <Link to={"/formula/" + f.uid} key={f.uid} style={{ textDecoration: "none" }}>
          <MFormulaListElement key={f.uid} formula={f} />
        </Link>
      ));
    }
  }
}
