export default {
  data: [
    {
      description: "In quali tra queste problematiche riferite alle difficoltà circolatorie ti rispecchi?",
      percentage: 30,
      questions: [
        {
          formName: "target",
          fullwidth: true,
          kind: "radiobox2",
          order: 1,
          values: [
            { label: "Gambe pesanti", value: "gambe_pesanti" },
            { label: "Fragilità Capillare cellulare", value: "fragilita_capillare" },
            { label: "Insufficienza Venosa", value: "insufficienza_venosa" },
            { label: "Cellulite", value: "cellulite" },
            { label: "Ritenzione idrica", value: "ritenzione_idrica" },
            { label: "Estremità Fredde", value: "estremita_fredde" }
          ]
        }
      ],
      uid: 0
    },
    ////////////////// GAMBE PESANTI //////////////////////
    {
      condition: [{ formName: "target", value: ["gambe_pesanti"] }],

      description: "Soffri di problemi venosi?",
      percentage: 40,
      questions: [
        {
          formName: "problemi_venosi",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "problemi_venosi_si" },
            { label: "No", value: "problemi_venosi_no" }
          ]
        }
      ],
      uid: 1
    },
    {
      condition: [{ formName: "target", value: ["gambe_pesanti"] }],

      description: "Soffri di diabete?",
      percentage: 50,
      questions: [
        {
          formName: "diabete",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "diabete_si" },
            { label: "No", value: "diabete_no" }
          ]
        }
      ],
      uid: 2
    },
    {
      condition: [{ formName: "target", value: ["gambe_pesanti"] }],

      description: "Accusi dolori intensi o formicolii?",
      percentage: 60,
      questions: [
        {
          formName: "formicolii",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "formicolii_si" },
            { label: "No", value: "formicolii_no" },
            { label: "Ogni tanto", value: "formicolii_ogni_tanto" }
          ]
        }
      ],
      uid: 3
    },
    {
      condition: [{ formName: "target", value: ["gambe_pesanti"] }],

      description: "Soffri di disturbi della colonna vertebrale?",
      percentage: 80,
      questions: [
        {
          formName: "disturbi_colonna",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "disturbi_colonna_si" },
            { label: "No", value: "disturbi_colonna_no" }
          ]
        }
      ],
      uid: 4
    },
    ////////////////// FRAGILITA CAPILLARE //////////////////////
    {
      condition: [{ formName: "target", value: ["fragilita_capillare"] }],

      description: "Sono visibili macchie ed ematomi sugli arti?",
      percentage: 40,
      questions: [
        {
          formName: "ematomi",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "ematomi_si" },
            { label: "No", value: "ematomi_no" }
          ]
        }
      ],
      uid: 5
    },
    {
      condition: [{ formName: "target", value: ["fragilita_capillare"] }],

      description: "Qualche tuo familiare soffre degli stessi problemi?",
      percentage: 50,
      questions: [
        {
          formName: "familiare_capillari",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "familiare_capillari_si" },
            { label: "No", value: "familiare_capillari_no" }
          ]
        }
      ],
      uid: 6
    },
    {
      condition: [{ formName: "target", value: ["fragilita_capillare"] }],

      description: "Soffri di squilibri ormonali?",
      percentage: 60,
      questions: [
        {
          formName: "squilibri_ormonali",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "squilibri_ormonali_si" },
            { label: "No", value: "squilibri_ormonali_no" }
          ]
        }
      ],
      uid: 7
    },
    {
      condition: [{ formName: "target", value: ["fragilita_capillare"] }],

      description: "Fai uso di anticoncezionali?",
      percentage: 70,
      questions: [
        {
          formName: "anticoncezionali",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "anticoncezionali_si" },
            { label: "No", value: "anticoncezionali_no" },
            { label: "A periodi", value: "anticoncezionali_a_periodi" }
          ]
        }
      ],
      uid: 8
    },
    {
      condition: [{ formName: "target", value: ["fragilita_capillare"] }],

      description: "Fai uso di rimedi che aiutano a migliorare il tuo problema?",
      percentage: 80,
      questions: [
        {
          formName: "rimedi_problema_capillare",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "rimedi_problema_capillare_si" },
            { label: "No", value: "rimedi_problema_capillare_no" },
            { label: "Ogni tanto", value: "rimedi_problema_capillare_ogni_tanto" }
          ]
        }
      ],
      uid: 9
    },
    ////////////////// INSUFFICIENZA VENOSA //////////////////////
    {
      condition: [{ formName: "target", value: ["insufficienza_venosa"] }],

      description: "Soffri di crampi ai polpacci?",
      percentage: 40,
      questions: [
        {
          formName: "crampi_polpacci",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "crampi_polpacci_si" },
            { label: "No", value: "crampi_polpacci_no" },
            { label: "Ogni tanto", value: "crampi_polpacci_ogni_tanto" }
          ]
        }
      ],
      uid: 10
    },
    {
      condition: [{ formName: "target", value: ["insufficienza_venosa"] }],

      description: "I disturbi ti compromettono il sonno?",
      percentage: 50,
      questions: [
        {
          formName: "sonno_compromesso",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "sonno_compromesso_si" },
            { label: "No", value: "sonno_compromesso_no" },
            { label: "Ogni tanto", value: "sonno_compromesso_ogni_tanto" }
          ]
        }
      ],
      uid: 11
    },
    {
      condition: [{ formName: "target", value: ["insufficienza_venosa"] }],

      description: "Accusi dolori agli arti?",
      percentage: 60,
      questions: [
        {
          formName: "dolori_arti",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "dolori_arti_si" },
            { label: "No", value: "dolori_arti_no" },
            { label: "Ogni tanto", value: "dolori_arti_ogni_tanto" }
          ]
        }
      ],
      uid: 12
    },
    {
      condition: [{ formName: "target", value: ["insufficienza_venosa"] }],

      description: "Avverti formicolii e pruriti?",
      percentage: 80,
      questions: [
        {
          formName: "pruriti",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "pruriti_si" },
            { label: "No", value: "pruriti_no" },
            { label: "Ogni tanto", value: "pruriti_ogni_tanto" }
          ]
        }
      ],
      uid: 13
    },
    ////////////////// CELLULITE //////////////////////
    {
      condition: [{ formName: "target", value: ["cellulite"] }],

      description: "Hai la pelle arrossata?",
      percentage: 40,
      questions: [
        {
          formName: "pelle_arrossata",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "pelle_arrossata_si" },
            { label: "No", value: "pelle_arrossata_no" },
            { label: "Ogni tanto", value: "pelle_arrossata_ogni_tanto" }
          ]
        }
      ],
      uid: 14
    },
    {
      condition: [{ formName: "target", value: ["cellulite"] }],

      description: "La zona colpita dalla cellulite è dolorante?",
      percentage: 50,
      questions: [
        {
          formName: "cellulite_dolore",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "cellulite_dolore_si" },
            { label: "No", value: "cellulite_dolore_no" },
            { label: "Ogni tanto", value: "cellulite_dolore_ogni_tanto" }
          ]
        }
      ],
      uid: 15
    },
    {
      condition: [{ formName: "target", value: ["cellulite"] }],

      description: "Fai attività fisica con frequenza?",
      percentage: 60,
      questions: [
        {
          formName: "cellulite_attivita_fisica",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "cellulite_attivita_fisica_si" },
            { label: "No", value: "cellulite_attivita_fisica_no" },
            { label: "Ogni tanto", value: "cellulite_attivita_fisica_ogni_tanto" }
          ]
        }
      ],
      uid: 16
    },
    {
      condition: [{ formName: "target", value: ["cellulite"] }],

      description: "Mangi in maniera equilibrata?",
      percentage: 80,
      questions: [
        {
          formName: "cellulite_mangi_equilibrato",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "cellulite_mangi_equilibrato_si" },
            { label: "No", value: "cellulite_mangi_equilibrato_no" },
            { label: "Ogni tanto", value: "cellulite_mangi_equilibrato_ogni_tanto" }
          ]
        }
      ],
      uid: 17
    },
    ////////////////// RITENZIONE IDRICA //////////////////////
    {
      condition: [{ formName: "target", value: ["ritenzione_idrica"] }],

      description: "Se premi con forza sulla tua coscia rimane ben visibile la tua impronta?",
      percentage: 40,
      questions: [
        {
          formName: "impronta_visibile",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "impronta_visibile_si" },
            { label: "No", value: "impronta_visibile_no" }
          ]
        }
      ],
      uid: 18
    },
    {
      condition: [{ formName: "target", value: ["ritenzione_idrica"] }],

      description: "Fai uso eccessivo di sale?",
      percentage: 50,
      questions: [
        {
          formName: "uso_eccessivo_sale",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "uso_eccessivo_sale_si" },
            { label: "No", value: "uso_eccessivo_sale_no" },
            { label: "Ogni tanto", value: "uso_eccessivo_sale_ogni_tanto" }
          ]
        }
      ],
      uid: 19
    },
    {
      condition: [{ formName: "target", value: ["ritenzione_idrica"] }],

      description: "Fai abuso di sigarette ed alcolici?",
      percentage: 60,
      questions: [
        {
          formName: "abuso_alcolici",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "abuso_alcolici_si" },
            { label: "No", value: "abuso_alcolici_no" },
            { label: "Ogni tanto", value: "abuso_alcolici_ogni_tanto" }
          ]
        }
      ],
      uid: 20
    },
    {
      condition: [{ formName: "target", value: ["ritenzione_idrica"] }],

      description: "Svolgi attività fisica?",
      percentage: 70,
      questions: [
        {
          formName: "ritenzione_attivita_fisica",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "ritenzione_attivita_fisica_si" },
            { label: "No", value: "ritenzione_attivita_fisica_no" },
            { label: "Ogni tanto", value: "ritenzione_attivita_fisica_ogni_tanto" }
          ]
        }
      ],
      uid: 21
    },
    {
      condition: [{ formName: "target", value: ["ritenzione_idrica"] }],

      description: "Bevi acqua a sufficienza?",
      percentage: 80,
      questions: [
        {
          formName: "ritenzione_bevi_acqua",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "ritenzione_bevi_acqua_si" },
            { label: "No", value: "ritenzione_bevi_acqua_no" },
            { label: "Dovrei bere di più", value: "ritenzione_bevi_acqua_di_piu" }
          ]
        }
      ],
      uid: 23
    },
    ////////////////// ESTREMITA FREDDE //////////////////////
    {
      condition: [{ formName: "target", value: ["estremita_fredde"] }],

      description: "Soffri il freddo agli arti solo d'inverno?",
      percentage: 40,
      questions: [
        {
          formName: "freddo_arti",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "freddo_arti_si" },
            { label: "No", value: "freddo_arti_no" }
          ]
        }
      ],
      uid: 24
    },
    {
      condition: [{ formName: "target", value: ["estremita_fredde"] }],

      description: "Soffri il dolore cervicale?",
      percentage: 50,
      questions: [
        {
          formName: "dolore_cervicale",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "dolore_cervicale_si" },
            { label: "No", value: "dolore_cervicale_no" }
          ]
        }
      ],
      uid: 25
    },
    {
      condition: [{ formName: "target", value: ["estremita_fredde"] }],

      description: "Sei una persona emotiva?",
      percentage: 60,
      questions: [
        {
          formName: "persona_emotiva",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "persona_emotiva_si" },
            { label: "No", value: "persona_emotiva_no" }
          ]
        }
      ],
      uid: 26
    },
    {
      condition: [{ formName: "target", value: ["estremita_fredde"] }],

      description: "Soffri di tensioni posturali?",
      percentage: 80,
      questions: [
        {
          formName: "tensioni_posturali",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "tensioni_posturali_si" },
            { label: "No", value: "tensioni_posturali_no" },
            { label: "Ogni tanto", value: "tensioni_posturali_ogni_tanto" }
          ]
        }
      ],
      uid: 27
    }
  ]
};
