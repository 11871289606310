import React from "react";
import { Navbar, NavbarItem, NavbarMenu } from "bloomer";
import { withUserCtx } from "../../contexts/UserContext";
import Home from "../../svgs/ic-24-home.svg";
import DaSapere from "../../svgs/ic-24-help-outline.svg";
import Qualita from "../../svgs/ic-24-verified-white.svg";
import Carrello from "../../svgs/ic-24-cart.svg";
import Basket from "../../svgs/ic-24-basket.svg";
import Formule from "../../svgs/ic-24-description.svg";
import Plus from "../../svgs/ic-24-add-circle-outline.svg";
import Business from "../../svgs/ic-24-account-outline.svg";
import Dashboard from "../../svgs/ic-24-equalizer.svg";
import ChevronLeft from "../../svgs/ic-24-chevron-left.svg";
import { Link, withRouter } from "react-router-dom";
import { withCartCtx } from "../../contexts/CartContext";

class MBottomNavbar extends React.Component {
  state = {
    userDropdown: false,
    transparent: true
  };

  constructor(props) {
    super(props);
    this.updateTransparencyOnScroll = this.updateTransparencyOnScroll.bind(this);
  }

  updateTransparencyOnScroll() {
    if (window.scrollY < 60) {
      this.setState({ transparent: true });
    } else {
      this.setState({ transparent: false });
    }
  }

  componentDidMount() {
    this.updateTransparencyOnScroll();
    const observer = document.addEventListener("scroll", this.updateTransparencyOnScroll);
    this.setState({ observer: observer });
  }

  componentWillUnmount() {
    if (this.state.observer) {
      this.state.observer();
      delete this.state.observer;
    }
  }

  render() {
    return null;

    const isLogged = !!this.props.user;
    const showBusinessArea = isLogged && this.props.userCtx.showingBusinessArea;

    var login = 0;
    if (showBusinessArea) login = 2;
    else if (isLogged) login = 1;

    const badge = this.props.cart.count > 0 ? this.props.cart.count : null;
    return (
      <Navbar className="bottom-navbar is-fixed-bottom vw-mobile-only">
        <NavbarMenu>
          <NavbarItem>{this.renderTab(login, ["/", "/", "/"], null, [Home, ChevronLeft, ChevronLeft], ["Home", "Sito web", "Sito web"])}</NavbarItem>

          <NavbarItem>{this.renderTab(login, ["/come-funziona", "/me/formule", "/business"], null, [DaSapere, Formule, Dashboard], ["Da Sapere", "Formule", "Dashboard"])}</NavbarItem>

          <NavbarItem>
            <Link to="/laboratorio" className={"button is-primary is-fab " + (this.props.stopAnim ? "" : "is-animated")}>
              <img src={Plus} alt="" />
            </Link>
          </NavbarItem>
          <NavbarItem>{this.renderTab(login, ["/quality", "/me/ordini", "/me/formule"], null, [Qualita, Basket, Formule], ["Qualità", "Ordini", "Formule"])}</NavbarItem>

          <NavbarItem>{this.renderTab(login, ["/cart", "/cart", "/tuo-business"], badge, [Carrello, Carrello, Business], ["Carrello", "Carrello", "Business"])}</NavbarItem>
        </NavbarMenu>
      </Navbar>
    );
  }

  renderTab(login, urls, badge, icons, names) {
    return <IconRenderer url={urls[login]} badge={login === 2 ? null : badge} icon={icons[login]} name={names[login]} />;
  }
}

class IconRenderer extends React.Component {
  render() {
    return (
      <Link to={this.props.url}>
        <div data-badge={this.props.badge} className="icon badge is-badge-white">
          <img src={this.props.icon} alt="" />
        </div>
        <p>{this.props.name}</p>
      </Link>
    );
  }
}
export default withRouter(withCartCtx(withUserCtx(MBottomNavbar)));
