export default {
  data: [
    {
      description: "Hai difficoltà nel recuperare energie in tempi brevi?",
      percentage: 35,
      questions: [
        {
          formName: "difficolta_recupero",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "difficolta_recupero_si" },
            { label: "No", value: "difficolta_recupero_no" },
            { label: "A volte", value: "difficolta_recupero_a_volte" }
          ]
        }
      ],
      uid: 0
    },
    {
      description: "Hai una forte produzione di acido lattico durante lo sforzo?",
      percentage: 40,
      questions: [
        {
          formName: "acido_lattico",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "acido_lattico_si" },
            { label: "No", value: "acido_lattico_no" },
            { label: "Non saprei", value: "acido_lattico_non_so" }
          ]
        }
      ],
      uid: 1
    },
    {
      description: "Soffri spesso di crampi?",
      percentage: 43,
      questions: [
        {
          formName: "crampi",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "crampi_si" },
            { label: "No", value: "crampi_no" }
          ]
        }
      ],
      uid: 2
    },
    {
      description: "Conosci strategie di recupero?",
      percentage: 45,
      questions: [
        {
          formName: "strategie_recupero",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "strategie_recupero_si" },
            { label: "No", value: "strategie_recupero_no" }
          ]
        }
      ],
      uid: 3
    },
    {
      description: "Ti alimenti in maniera bilanciata?",
      percentage: 48,
      questions: [
        {
          formName: "idratazione_corretta",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "idratazione_corretta_si" },
            { label: "No", value: "idratazione_corretta_no" },
            { label: "A volte", value: "idratazione_corretta_a_volte" }
          ]
        }
      ],
      uid: 4
    },
    {
      description: "Segui un programma di allenamento specifico?",
      percentage: 50,
      questions: [
        {
          formName: "programma_specifico",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "programma_specifico_si" },
            { label: "No", value: "programma_specifico_no" }
          ]
        }
      ],
      uid: 5
    },
    {
      description: "Ti idrati in maniera corretta?",
      percentage: 53,
      questions: [
        {
          formName: "idratazione_corretta",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "idratazione_corretta_si" },
            { label: "No", value: "idratazione_corretta_no" },
            { label: "Non saprei", value: "idratazione_corretta_non_saprei" }
          ]
        }
      ],
      uid: 6
    },
    {
      description: "Recuperi in maniera corretta durante il tuo allenamento?",
      percentage: 55,
      questions: [
        {
          formName: "recupero_corretto",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "recupero_corretto_si" },
            { label: "No", value: "recupero_corretto_no" },
            { label: "Non saprei", value: "recupero_corretto_non_saprei" }
          ]
        }
      ],
      uid: 7
    },
    {
      description: "Esegui periodi di scarico?",
      percentage: 58,
      questions: [
        {
          formName: "periodi_scarico",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "periodi_scarico_si" },
            { label: "No", value: "periodi_scarico_no" },
            { label: "A volte", value: "periodi_scarico_a_volte" }
          ]
        }
      ],
      uid: 8
    },
    {
      description: "Svolgi esercizi di respirazione e rilassamento?",
      percentage: 60,
      questions: [
        {
          formName: "esercizi_respirazione",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "esercizi_respirazione_si" },
            { label: "No", value: "esercizi_respirazione_no" }
          ]
        }
      ],
      uid: 9
    },
    {
      description: "Dormi a sufficienza?",
      percentage: 63,
      questions: [
        {
          formName: "dormi_sufficienza",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "dormi_sufficienza_si" },
            { label: "No", value: "dormi_sufficienza_no" },
            { label: "A volte", value: "dormi_sufficienza_a_volte" }
          ]
        }
      ],
      uid: 10
    },
    {
      description: "Sei soggetto ad infortuni con frequenza?",
      percentage: 65,
      questions: [
        {
          formName: "soggetto_infortuni",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "soggetto_infortuni_si" },
            { label: "No", value: "soggetto_infortuni_no" }
          ]
        }
      ],
      uid: 11
    },
    {
      description: "Fai stretching prima e dopo l'allenamento?",
      percentage: 68,
      questions: [
        {
          formName: "stretching",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Sempre", value: "stretching_sempre" },
            { label: "No", value: "stretching_no" },
            { label: "Ogni tanto", value: "stretching_ogni_tanto" }
          ]
        }
      ],
      uid: 12
    },
    {
      description: "Sai ascoltare il tuo corpo?",
      percentage: 70,
      questions: [
        {
          formName: "ascoltare_corpo",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "ascoltare_corpo_si" },
            { label: "No", value: "ascoltare_corpo_no" },
            { label: "A volte", value: "ascoltare_corpo_a_volte" }
          ]
        }
      ],
      uid: 13
    },
    {
      description: "Avverti indolenzimenti dopo il tuo allenamento?",
      percentage: 80,
      questions: [
        {
          formName: "indolenzimenti",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "indolenzimenti_si" },
            { label: "No", value: "indolenzimenti_no" },
            { label: "A volte", value: "indolenzimenti_a_volte" }
          ]
        }
      ],
      uid: 14
    },
    {
      description: "Fai uso di integratori prima durante e dopo l'allenamento?",
      percentage: 80,
      questions: [
        {
          formName: "integratori_prima_dopo",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "integratori_prima_dopo_si" },
            { label: "No", value: "integratori_prima_dopo_no" },
            { label: "A volte", value: "integratori_prima_dopo_a_volte" }
          ]
        }
      ],
      uid: 15
    }
  ]
};
