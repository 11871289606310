export default {
  data: [
    {
      description: "In quali tra queste problematiche riferite al tuo corpo ti ritrovi?",
      percentage: 30,
      questions: [
        {
          formName: "target",
          fullwidth: true,
          kind: "radiobox2",
          order: 1,
          values: [
            { label: "Disturbi epato biliari", value: "epato_biliari" },
            { label: "Cefalea digestiva", value: "cefalea_digestiva" },
            { label: "Ipercolesterolemia", value: "ipercolesterolemia" },
            { label: "Ipertrigliceridemia", value: "ipertrigliceridemia" },
            { label: "Abuso Alcool-Fumo", value: "abuso_alcool" }
          ]
        }
      ],
      uid: 0
    },
    //////////////////  Disturbi epato biliari //////////////////////
    {
      condition: [{ formName: "target", value: ["epato_biliari"] }],

      description: "Consumi pasti troppo abbondanti?",
      percentage: 40,
      questions: [
        {
          formName: "pasti_abbondanti",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "pasti_abbondanti_si" },
            { label: "No", value: "pasti_abbondanti_no" },
            { label: "A volte", value: "pasti_abbondanti_a_volte" }
          ]
        }
      ],
      uid: 1
    },
    {
      condition: [{ formName: "target", value: ["epato_biliari"] }],

      description: "Hai gonfiore addominale associato a difficoltà digestive?",
      percentage: 60,
      questions: [
        {
          formName: "difficolta_digestive",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "difficolta_digestive_si" },
            { label: "No", value: "difficolta_digestive_no" },
            { label: "A volte", value: "difficolta_digestive_a_volte" }
          ]
        }
      ],
      uid: 2
    },
    {
      condition: [{ formName: "target", value: ["epato_biliari"] }],

      description: "Fai uso continuo di farmaci?",
      percentage: 80,
      questions: [
        {
          formName: "epato_biliari_farmaci",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "epato_biliari_farmaci_si" },
            { label: "No", value: "epato_biliari_farmaci_no" },
            { label: "A volte", value: "epato_biliari_farmaci_a_volte" }
          ]
        }
      ],
      uid: 3
    },
    //////////////////  CEFALEA DIGESTIVA //////////////////////
    {
      condition: [{ formName: "target", value: ["cefalea_digestiva"] }],

      description: "L'assunzione anche minima di alcuni alimenti o bevande (es. Vino bianco) ti provoca mal di testa?",
      percentage: 40,
      questions: [
        {
          formName: "cefalea_digestiva_malditesta",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "cefalea_digestiva_malditesta_si" },
            { label: "No", value: "cefalea_digestiva_malditesta_no" },
            { label: "A volte", value: "cefalea_digestiva_malditesta_a_volte" }
          ]
        }
      ],
      uid: 4
    },
    {
      condition: [{ formName: "target", value: ["cefalea_digestiva"] }],

      description: "Se mangi più del previsto hai mal di testa?",
      percentage: 60,
      questions: [
        {
          formName: "cefalea_digestiva_mangi",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "cefalea_digestiva_mangi_si" },
            { label: "No", value: "cefalea_digestiva_mangi_no" },
            { label: "A volte", value: "cefalea_digestiva_mangi_a_volte" }
          ]
        }
      ],
      uid: 5
    },
    {
      condition: [{ formName: "target", value: ["cefalea_digestiva"] }],

      description: "Soffri di cervicalgie?",
      percentage: 80,
      questions: [
        {
          formName: "cervicalgie",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "cervicalgie_si" },
            { label: "No", value: "cervicalgie_no" },
            { label: "A volte", value: "cervicalgie_a_volte" }
          ]
        }
      ],
      uid: 6
    },
    //////////////////  IPERCOLESTEROLEMIA //////////////////////
    {
      condition: [{ formName: "target", value: ["ipercolesterolemia"] }],

      description: "Hai familiari che soffrono di ipercolesterolemia?",
      percentage: 40,
      questions: [
        {
          formName: "ipercolesterolemia_familiari",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "ipercolesterolemia_familiari_si" },
            { label: "No", value: "ipercolesterolemia_familiari_no" }
          ]
        }
      ],
      uid: 7
    },
    {
      condition: [{ formName: "target", value: ["ipercolesterolemia"] }],

      description: "Quali sono i tuoi valori?",
      percentage: 60,
      questions: [
        {
          formName: "ipercolesterolemia_valori",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Sotto i 200", value: "ipercolesterolemia_valori_200" },
            { label: "Tra 200 e 400", value: "ipercolesterolemia_valori_400" },
            { label: "Oltre 240", value: "ipercolesterolemia_valori_240" },
            { label: "Non li conosco", value: "ipercolesterolemia_valori_non_conosco" }
          ]
        }
      ],
      uid: 8
    },
    {
      condition: [{ formName: "target", value: ["ipercolesterolemia"] }],

      description: "Fai uso di farmaci per controllarlo?",
      percentage: 70,
      questions: [
        {
          formName: "ipercolesterolemia_farmaci",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "ipercolesterolemia_farmaci_si" },
            { label: "No", value: "ipercolesterolemia_farmaci_no" }
          ]
        }
      ],
      uid: 9
    },
    {
      condition: [{ formName: "target", value: ["ipercolesterolemia"] }],

      description: "Mangi in maniera sregolata?",
      percentage: 80,
      questions: [
        {
          formName: "ipercolesterolemia_mangi",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "ipercolesterolemia_mangi_si" },
            { label: "No", value: "ipercolesterolemia_mangi_no" },
            { label: "A volte", value: "ipercolesterolemia_mangi_a_volte" }
          ]
        }
      ],
      uid: 10
    },
    //////////////////  IPERTRIGLICERIDEMIA //////////////////////
    {
      condition: [{ formName: "target", value: ["ipertrigliceridemia"] }],

      description: "Hai familiari che soffrono di questo disturbo?",
      percentage: 40,
      questions: [
        {
          formName: "ipertrigliceridemia_familiari",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "ipertrigliceridemia_familiari_si" },
            { label: "No", value: "ipertrigliceridemia_familiari_no" }
          ]
        }
      ],
      uid: 11
    },
    {
      condition: [{ formName: "target", value: ["ipertrigliceridemia"] }],

      description: "Quali sono i tuoi valori attuali?",
      percentage: 60,
      questions: [
        {
          formName: "ipertrigliceridemia_valori",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Tra 150 e 199", value: "ipertrigliceridemia_valori_150" },
            { label: "Tra 200 e 399 ", value: "ipertrigliceridemia_valori_200" },
            { label: "Oltre 400", value: "ipertrigliceridemia_valori_400" }
          ]
        }
      ],
      uid: 12
    },
    {
      condition: [{ formName: "target", value: ["ipertrigliceridemia"] }],

      description: "Hai anche il colesterolo alto?",
      percentage: 70,
      questions: [
        {
          formName: "ipertrigliceridemia_colesterolo",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "ipertrigliceridemia_colesterolo_si" },
            { label: "No", value: "ipertrigliceridemia_colesterolo_no" }
          ]
        }
      ],
      uid: 13
    },
    {
      condition: [{ formName: "target", value: ["ipertrigliceridemia"] }],

      description: "Segui una corretta alimentazione?",
      percentage: 80,
      questions: [
        {
          formName: "ipertrigliceridemia_alimentazione",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "ipertrigliceridemia_alimentazione_si" },
            { label: "No", value: "ipertrigliceridemia_alimentazione_no" }
          ]
        }
      ],
      uid: 14
    },
    //////////////////  ABUSO ALCOL //////////////////////
    {
      condition: [{ formName: "target", value: ["abuso_alcool"] }],

      description: "Quando abusi di fumo o alcool hai sensazioni di tremori muscolari?",
      percentage: 40,
      questions: [
        {
          formName: "tremori_muscolari",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "tremori_muscolari_si" },
            { label: "No", value: "tremori_muscolari_no" },
            { label: "A volte", value: "tremori_muscolari_a_volte" }
          ]
        }
      ],
      uid: 15
    },
    {
      condition: [{ formName: "target", value: ["abuso_alcool"] }],

      description: "Fai uso costante di vitamina c?",
      percentage: 50,
      questions: [
        {
          formName: "vitamica_c",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "vitamica_c_si" },
            { label: "No", value: "vitamica_c_no" },
            { label: "Quasi mai", value: "vitamica_c_quasi_mai" }
          ]
        }
      ],
      uid: 16
    },
    {
      condition: [{ formName: "target", value: ["abuso_alcool"] }],

      description: "Soffri di tachicardia?",
      percentage: 60,
      questions: [
        {
          formName: "abuso_alcool_tachicardia",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "abuso_alcool_tachicardia_si" },
            { label: "No", value: "abuso_alcool_tachicardia_no" },
            { label: "A volte", value: "abuso_alcool_tachicardia_a_volte" }
          ]
        }
      ],
      uid: 17
    },
    {
      condition: [{ formName: "target", value: ["abuso_alcool"] }],

      description: "Hai difficoltà a memorizzare?",
      percentage: 80,
      questions: [
        {
          formName: "abuso_alcool_difficolta_memoria",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "abuso_alcool_difficolta_memoria_si" },
            { label: "No", value: "abuso_alcool_difficolta_memoria_no" },
            { label: "A volte", value: "abuso_alcool_difficolta_memoria_a_volte" }
          ]
        }
      ],
      uid: 18
    }
  ]
};
