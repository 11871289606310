import React from 'react';

export default class StarRating extends React.Component {

    state = {
        rating: 0,
        size: 'medium', // small
        stars: 5,
        label: '' // N/A ???
    };

    componentDidMount() {
        if (this.props.rating) this.setState({rating: this.props.rating});
        if (this.props.size) this.setState({size: this.props.size});
        if (this.props.stars) this.setState({stars: this.props.stars});
        if (this.props.label) this.setState({label: this.props.label});
    }

    createItems() {
        let starItems = [];
        for (let i = 1; i <= this.state.stars; i++) {
            starItems.push(<div className="star" key={i}>
                <i className="star-empty"/>
                <i className="star-half"/>
                <i className="star-filled"/>
                        </div>);
        }

        return starItems;
    }

    render() {

        let stars;

        if (Number.isInteger(this.state.rating)) {
            stars = 'value-' + this.state.rating;
        } else {
            stars = 'value-' + this.state.rating + ' half';
        }

        let className = 'rating ' + this.state.size + ' star-icon direction-ltr ' + stars + ' color-ok justify-content-left';
       
        return (
            <div className={className}>
                <div className="star-container">
                    {this.createItems()}
                </div>
                <div className="label-value">{this.state.label}</div>
            </div>
        )
    }
}