import { Container } from "bloomer/lib/layout/Container";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withLabCtx } from "../../../contexts/LabContext";
import Utils from "../../../Utils";

class MConsulenzaTransizione extends Component {
  state = {
    value: 0,
    text: "lifestyle"
  };

  componentDidMount() {
    this.setState({
      timer: setTimeout(() => {
        this.props.history.push("/consulenza/0");
      }, 7000)
    });
  }

  render() {
    return (
      <div className="m-consulenza-container green-background">
        <Container>
          <h1 className="title category" style={{ fontSize: "26px" }}>
            {Utils.getCategory(localStorage.getItem("categoria"))}
          </h1>
          <div className="progressbar is-consulenza" style={{ width: "50%", margin: "auto", marginTop: "180px" }}>
            <div className="percentage-line green" />
            <div className="percentage-line transition-animation" style={{ left: "0" }} />

            <div className="goal">
              <div className="is-thumb-big" style={{ left: "calc(0% - 18px)" }} />
              <div className="block-name" style={{ left: "calc(0% - 50px)", width: "100px", textAlign: "center", top: "-65px" }}>
                lifestyle
              </div>
            </div>
            <div className="goal">
              <div className="is-thumb-big progress-backgroundcolor-animation" style={{ left: "calc(50% - 18px)", backgroundColor: "#5b7682" }} />
              <div className="block-name progress-color-animation" style={{ left: "calc(50% - 50px)", width: "100px", textAlign: "center", top: "-65px", color: "#5b7682" }}>
                goals
              </div>
            </div>
            <div className="goal">
              <div className="is-thumb-big progress-backgroundcolor-animation-2" style={{ left: "calc(100% - 18px)", backgroundColor: "#5b7682" }} />
              <div className="block-name progress-color-animation-2" style={{ left: "calc(100% - 50px)", width: "100px", textAlign: "center", top: "-65px", color: "#5b7682" }}>
                salute
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default withLabCtx(withRouter(MConsulenzaTransizione));
