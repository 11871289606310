import React from "react";
import times from "../../../svgs/ic-24-close.svg";
import firebase from "firebase/app";
import Loader from "../../../components/Loader";
import { withUserCtx } from "../../../contexts/UserContext";
import { withLabCtx } from "../../../contexts/LabContext";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Column, Columns, Container, Control, Field, Input, Tab, TabLink, TabList, Tabs } from "bloomer";
import MFormulaList from "../../../components/mobile/MFormulaList";
import MTrainerListItem from "../../../components/mobile/MTrainerListItem";
import MBottomNavbar from "../../../components/mobile/MBottomNavbar";
import Utils from "../../../Utils";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

class MLanding extends React.Component {
  state = {
    FormulasTab: [],
    tab: "formulas"
  };

  changeTab(tab) {
    this.setState({ tab: tab });
  }

  render() {
    const { categories } = this.props.lab;

    Utils.log("Categories landing", categories);
    return (
      <div style={{ backgroundColor: "#161617", height: "100vh" }}>
        <Container>
          <Columns isMobile>
            <Column>
              <img
                src={times}
                className="is-pulled-left"
                onClick={this.props.history.goBack()}
                style={{
                  minWidth: "24px",
                  minHeight: "24px",
                  maxWidth: "24px",
                  maxHeight: "24px",
                  marginTop: "1.5px"
                }}
                alt=""
              />
              <h3 className="title">&nbsp;Hall Of Fame</h3>
            </Column>
          </Columns>
        </Container>
        <Tabs>
          <TabList>
            <Tab className={this.state.tab === "pro" ? "is-active" : ""}>
              <TabLink onClick={() => this.changeTab("pro")}>
                <span>Professionisti</span>
              </TabLink>
            </Tab>
            <Tab className={this.state.tab === "formulas" ? "is-active" : ""}>
              <TabLink onClick={() => this.changeTab("formulas")}>
                <span>Formule</span>
              </TabLink>
            </Tab>
          </TabList>
        </Tabs>
        {this.state.tab === "pro" ? <TrainersTab {...this.props} /> : <FormulasTab {...this.props} />}
        <MBottomNavbar />
      </div>
    );
  }
}

class TrainersTab extends React.Component {
  state = {
    trainers: [],
    page: 0,
    loading: true
  };

  constructor() {
    super();

    this.openTrainer = this.openTrainer.bind(this);
  }

  openTrainer(uid) {
    this.props.history.push(`/hall-of-fame/personaltrainer/${uid}`);
  }

  loadMore() {
    Utils.log("Loading more");
  }
  async readTrainers() {
    const observable = await firebase
      .firestore()
      .collection("profiles")
      .limit(10)
      .onSnapshot(async docs => this.SetTrainers(docs));
    this.setState({ observable: observable });
  }

  SetTrainers(docs) {
    const data = [];
    docs.forEach(d => {
      data.push(d.data());
    });
    this.setState({ trainers: data, loading: false });
  }

  searchTrainers(q) {
    Utils.log("will search", q);
    firebase
      .firestore()
      .collection("profiles")
      .where("displayName", ">=", q)
      .where("displayName", "<", q + "z")
      .limit(10)
      .get()
      .then(docs => this.SetTrainers(docs));
  }

  componentDidMount() {
    this.readTrainers();
  }

  componentWillUnmount() {
    if (this.state.observable) this.state.observable();
    if (this.throttle) {
      clearTimeout(this.throttle);
    }
  }

  onChangeInput(e) {
    const q = e.target.value;
    clearTimeout(this.throttle);
    this.throttle = setTimeout(() => {
      if (q && q.length && q.length >= 3) {
        this.searchTrainers(q);
      } else if (q && q.length === 0) {
        this.readTrainers();
      }
    }, 1000);
  }

  render() {
    if (this.state.loading) return <Loader />;

    const { trainers } = this.state;
    return (
      <Container>
        <div className="pb-12 mb-50">
          <Field className="input-field search">
            <Control className="has-icons-right">
              <Columns isMobile>
                <Column>
                  <Input type="text" placeholder="Cerca per nome" onChange={this.onChangeInput.bind(this)} />
                </Column>
                <Column isSize="narrow" style={{ paddingLeft: 0 }}>
                  <Button className="is-transparent">
                    <FontAwesomeIcon icon={faSearch} />
                  </Button>
                </Column>
              </Columns>
            </Control>
          </Field>
          <Columns isMobile>
            <Column isSize={{ mobile: 6 }} className="has-text-18">
              <span id="">{trainers.length}</span> Professionisti
            </Column>
            <Column isSize={{ mobile: 6 }} className="has-text-18 has-text-right has-text-mylab-color-light">
              Rating
            </Column>
          </Columns>
          <Columns>
            <Column>
              <div style={{ height: "700px", overflow: "auto" }}>
                {trainers.map((trainer, id) => (
                  <MTrainerListItem key={id} trainer={trainer} onClick={this.openTrainer} />
                ))}
              </div>
            </Column>
          </Columns>
        </div>
      </Container>
    );
  }
}

class FormulasTab extends React.Component {
  state = {
    formulas: null,
    loading: true
  };

  constructor(props) {
    super(props);

    this.readFormulas = this.readFormulas.bind(this);
    this.openFormula = this.openFormula.bind(this);
  }

  async readFormulas() {
    if (!!this.state.formulas) {
      Utils.log("already read");
      return;
    } else {
      Utils.log("already NOT read");
    }

    const formulas = [];
    const promises = [];

    await firebase
      .firestore()
      .collection("recipes")
      .where("public", "==", true)
      .get()
      .then(docs => {
        docs.forEach(async d => {
          // formulas.push(d.data());
          var formula = d.data();

          promises.push(
            new Promise(async r => {
              await firebase
                .firestore()
                .collection("profiles")
                .doc(formula.owner)
                .get()
                .then(owner => {
                  formula.ownerData = owner.data();
                  formulas.push(formula);
                  Utils.log("pushung data");
                });
              r(formula);
            })
          );
        });
      });

    await Promise.all(promises);

    Utils.log("setting data2");
    this.setState({
      formulas,
      loading: false
    });

    // this.setState({ observable: observable });
  }

  componentDidMount() {
    this.readFormulas();
  }

  openFormula(uid) {
    this.props.history.push(`/hall-of-fame/formula/${uid}`);
  }

  render() {
    if (this.state.loading) return <Loader />;

    const { categories } = this.props.lab;
    const { formulas } = this.state;

    Utils.log("Formula render", formulas);

    return (
      <div className="pb-12 mb-50">
        <Container>
          <Columns>
            <Column className="ptb-0 no-overflow-with-h-scroll" ismobile="true">
              <div className="tag-scroll" style={{ width: "700px" }}>
                <span className="tag my-lab">Tutte</span>
                {categories &&
                  categories.map(c => (
                    <span key={c.name} className="tag my-lab">
                      {c.name}
                    </span>
                  ))}
              </div>
            </Column>
          </Columns>
          <Columns isMobile>
            <Column isSize={{ mobile: 6 }} className="has-text-18">
              <span id="">{formulas && formulas.length}</span> Formule
            </Column>
            <Column isSize={{ mobile: 6 }} className="has-text-18 has-text-right has-text-mylab-color-light">
              Rating
            </Column>
          </Columns>
        </Container>

        <MFormulaList formulas={this.state.formulas} onClick={this.openFormula} />
      </div>
    );
  }
}

export default withLabCtx(withUserCtx(withRouter(MLanding)));
