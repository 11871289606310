import React from "react";
import { Column, Columns, Field } from "bloomer";
import { withLabCtx } from "../../contexts/LabContext";
import Box from "./Box";

class CheckBoxRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.addRemoveValue = this.addRemoveValue.bind(this);
  }

  addRemoveValue(value) {
    const selected = !!this.props.lab.survey[this.props.question.formName] ? this.props.lab.survey[this.props.question.formName].length : 0;
    let reachedMaxSelection = false;
    if (!!this.props.question.maxSelection && selected === this.props.question.maxSelection) reachedMaxSelection = true;
    console.log("ZZ selected", selected, value, this.getChecked(value));
    if (reachedMaxSelection && !this.getChecked(value)) return;

    this.props.addRemoveValue(this.props.question, value.value);
  }

  getChecked(value) {
    return !!this.props.lab.survey[this.props.question.formName] && this.props.lab.survey[this.props.question.formName].indexOf(value.value) !== -1;
  }

  render() {
    return (
      <Field className={this.props.className || ""}>
        {this.props.question.title ? <h6 className="title">{this.props.question.title}</h6> : null}
        <Columns className="radioboxRenderer" isMobile isMultiline style={{ display: this.props.displayBlock ? "block" : "" }}>
          {this.props.question.values.map((value) => (
            <Column key={value.value} isSize={this.props.question.fullwidth ? "12" : this.props.question.size || "4"}>
              <Box generali={this.props.generali} onClick={() => this.addRemoveValue(value)} checked={this.getChecked(value)} value={value} />
            </Column>
          ))}
        </Columns>
      </Field>
    );
  }
}

export default withLabCtx(CheckBoxRenderer);
