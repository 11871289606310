import React, { Component } from "react";
import BaseModal from "../modals/BaseModal";
import Contacts from "../modals/Contacts";

export const ModalsContext = React.createContext(null);

export const ModalsConsumer = ModalsContext.Consumer;

export class ModalsProvider extends Component {
  state = {
    modals: [],
    sequence: 0,
    defaultModals: {
      contacts: false
    }
  };

  constructor(props) {
    super(props);

    this.contacts = React.createRef();

    this.nextId = this.nextId.bind(this);
    this.addModal = this.addModal.bind(this);
    this.addModalWithClass = this.addModalWithClass.bind(this);
    this.addError = this.addError.bind(this);
    this.callback = this.callback.bind(this);
    this.addMessage = this.addMessage.bind(this);
    this.openContact = this.openContact.bind(this);
    this.closeDefaultModal = this.closeDefaultModal.bind(this);
  }

  componentDidMount() {
    this.setState({
      addModal: this.addModal,
      addModalWithClass: this.addModalWithClass,
      addError: this.addError,
      addMessage: this.addMessage,
      openContact: this.openContact
    });
  }

  /**
   *
   * @param title - Title of the modal
   * @param jsxContent - JSX Content of the modal Body
   * @param buttons - array of buttons, default only one primary button with OK result, in this form:
   * <pre>
   *     [{
   *       'label': 'my button',
   *       'className': 'button is-primary is-fullwidth',
   *       'result': 'btn-mybtn-pressed'
   *     },
   *      ...
   *     ]
   * </pre>
   * @param backgroundClickCloses - Boolean - (defaults: false) if true, a click on the background closes the modal
   * @param callback - callback for the result
   */
  addModal(title, jsxContent, buttons, backgroundClickCloses, callback) {
    if (!buttons || buttons.length === 0) buttons = [okButton];
    backgroundClickCloses = !!backgroundClickCloses;
    const id = this.nextId();
    const modal = {
      element: (
        <BaseModal id={id} key={id} buttons={buttons} title={title} callback={this.callback}>
          {jsxContent}
        </BaseModal>
      ),
      callback: callback,
      id: id,
      backgroundClickCloses: backgroundClickCloses
    };

    this.setState(prevState => ({
      modals: [...prevState.modals, modal]
    }));
  }

  addModalWithClass(className, title, jsxContent, buttons, backgroundClickCloses, callback) {
    if (!buttons || buttons.length === 0) buttons = [okButton];
    backgroundClickCloses = !!backgroundClickCloses;
    const id = this.nextId();
    const modal = {
      element: (
        <BaseModal className={className} id={id} key={id} buttons={buttons} title={title} callback={this.callback}>
          {jsxContent}
        </BaseModal>
      ),
      callback: callback,
      id: id,
      backgroundClickCloses: backgroundClickCloses
    };

    this.setState(prevState => ({
      modals: [...prevState.modals, modal]
    }));
  }

  /**
   * This shows up a popup saying that there was an error,
   * ou should use TEXT (first argument, nullable) for a single message or JSXCONTENT (second argument, nullable)
   * for a complex message. If you pass both, only JSXCONTENT will be shown. If none is passed the modal will say
   * "Un errore sconosciuto ci ha impedito di completare la richiesta, ti chiediamo di riprovare"
   * @param text
   * @param jsxContent
   * @param callback
   */
  addError(text, jsxContent, callback) {
    text = text || "Un errore sconosciuto ci ha impedito di completare la richiesta, ti chiediamo di riprovare";
    if (!jsxContent) {
      jsxContent = <p>{text}</p>;
    }
    this.addModal("Errore", jsxContent, null, null, callback);
  }

  /**
   * This shows up a popup saying that there was a message,
   * ou should use TEXT (first argument, nullable) for a single message or JSXCONTENT (second argument, nullable)
   * for a complex message. If you pass both, only JSXCONTENT will be shown. If none is passed the modal will say
   * "La richiesta è stata completata con successo"
   * @param text
   * @param jsxContent
   * @param callback
   */
  addMessage(text, jsxContent, callback) {
    text = text || "La richiesta è stata completata con successo";
    if (!jsxContent) {
      jsxContent = <p>{text}</p>;
    }
    this.addModal("Successo!", jsxContent, null, null, callback);
  }

  callback(id, result) {
    const modals = [...this.state.modals];
    let index = -1;
    modals.forEach((e, idx) => {
      if (e.id === id) index = idx;
    });
    if (index === -1) {
      console.warn("modal not in array, id: ", id);
      return;
    }

    const m = modals[index];
    if ((result === "background-click" && m.backgroundClickCloses) || result !== "background-click") {
      if (m.callback) {
        m.callback(result);
      }
      modals.splice(index, 1);
      this.setState({ modals: modals });
    }
  }

  nextId() {
    /*
     * Per sicurezza, ma dubito che qualcuno riesca a far aprire
     * oltre 4294967295 modali...
     */
    if (this.state.sequence >= (Number.MAX_SAFE_INTEGER || 4294967295)) {
      const id = 1;
      this.setState({ sequence: id });
      return id;
    } else {
      let counter = this.state.sequence;
      const id = counter++;
      this.setState({ sequence: id });
      return id;
    }
  }

  openContact() {
    const dModals = { ...this.state.defaultModals };
    dModals.contacts = true;
    this.setState({ defaultModals: dModals });
  }

  closeDefaultModal(modalId, result) {
    const dModals = { ...this.state.defaultModals };
    dModals[modalId] = false;
    this.setState({ defaultModals: dModals });
  }

  render() {
    return (
      <ModalsContext.Provider value={this.state}>
        <div>
          <Contacts key="contacts" callback={r => this.closeDefaultModal("contacts", r)} isActive={this.state.defaultModals.contacts} />
          {this.props.children}
          {this.state.modals.map(m => m.element)}
        </div>
      </ModalsContext.Provider>
    );
  }
}

export const chiudiButton = {
  label: "Chiudi",
  className: "button is-transparent is-outlined is-fullwidth",
  result: "dismiss"
};

export const okButton = {
  label: "Ok",
  className: "button is-primary is-fullwidth",
  result: "OK"
};

export function withModalsCtx(Component) {
  return props => <ModalsConsumer>{ctx => <Component modals={ctx} {...props} />}</ModalsConsumer>;
}
