import React, { Component } from "react";
import { Columns } from "bloomer/lib/grid/Columns";
import { Column } from "bloomer/lib/grid/Column";
import MultiRender from "../MultiRender";

export default class MRiepilogoHeader extends Component {
  render() {
    const { scegli, scegliOn, seleziona, riepilogo, acquista, condividi, hideSeleziona } = this.props;
    const text = scegli ? "Scegli gli elementi" : "Vedi il tuo risultato";
    const image = scegli ? "/images/scegli" : "/images/seleziona";
    return (
      <div>
        <Columns isMobile className="riepilogo-header">
          {!hideSeleziona && (
            <Column className={!!this.props.onSeleziona ? "mouse-pointer has-text-centered" : "has-text-centered"} onClick={this.props.onSeleziona}>
              {scegliOn ? <img className="riepilogo-img" src={image + "-rosso.png"} /> : <img className="riepilogo-img" src={image + "-azzurro.png"} />}
              <h5 className={scegliOn ? "subtitle has-text-red" : "subtitle has-text-green"}>{text}</h5>
            </Column>
          )}
          <Column className={!!this.props.onRiepilogo ? "mouse-pointer has-text-centered" : "has-text-centered"} onClick={this.props.onRiepilogo}>
            {riepilogo ? <img className="riepilogo-img" src="/images/riepilogo-rosso.png" /> : <img className="riepilogo-img" src="/images/riepilogo-azzurro.png" />}{" "}
            <h5 className={riepilogo ? "subtitle has-text-red" : "subtitle has-text-green"}>
              Controlla
              <br />
              il riepilogo
            </h5>
          </Column>
          <Column className="has-text-centered">
            {!!condividi ? (
              <div onClick={this.props.onCondividi} className={!!this.props.onCondividi && "mouse-pointer"}>
                {acquista ? <img className="riepilogo-img" src="/images/condividi-rosso.png" /> : <img className="riepilogo-img" src="/images/condividi-azzurro.png" />}
                <h5 className={acquista ? "subtitle has-text-red" : "subtitle has-text-green"}>
                  Condividi
                  <br />
                  la formula
                </h5>
              </div>
            ) : (
              <div onClick={this.props.onAcquista} className={!!this.props.onAcquista && "mouse-pointer"}>
                {acquista ? <img className="riepilogo-img" src="/images/acquista-rosso.png" /> : <img className="riepilogo-img" src="/images/acquista-azzurro.png" />}
                <h5 className={acquista ? "subtitle has-text-red" : "subtitle has-text-green"}>
                  Acquista
                  <br />
                  il tuo kit
                </h5>
              </div>
            )}
          </Column>
        </Columns>
      </div>
    );
  }
}
