import React, { Component } from "react";
import { Button, Column, Columns, Container, Field, Input, Label, Modal, ModalBackground, ModalCard, ModalCardBody, ModalCardFooter, ModalCardHeader, ModalCardTitle, TextArea } from "bloomer";
import firebase from "firebase/app";
import { withRouter } from "react-router-dom";
import { withUserCtx } from "../contexts/UserContext";
import Utils from "../Utils";
import { withModalsCtx } from "../contexts/ModalsContext";

class Contacts extends Component {
  state = {
    name: "",
    surname: "",
    email: "",
    additionalInfo: "",
    isActive: false,
    loading: false
  };

  constructor(props) {
    super(props);
    this.open = this.open.bind(this);
    this.onEnd = this.onEnd.bind(this);
    this.changeEvent = this.changeEvent.bind(this);
  }

  open() {
    this.setState({ isActive: true });
  }

  async componentDidMount() {
    if (!!this.props.user) {
      this.setState({
        name: this.props.user.name,
        surname: this.props.user.surname,
        email: this.props.user.email
      });
    }
  }

  changeEvent(e) {
    const s = {};
    s[e.target.name] = e.target.value;
    this.setState(s);
  }

  async send() {
    try {
      this.setState({ loading: true });
      await firebase
        .firestore()
        .collection("contacts")
        .add({
          creation: new Date(),
          name: this.state.name,
          surname: this.state.surname,
          email: this.state.email,
          additionalInfo: this.state.additionalInfo,
          isNew: true
        });
      this.props.modals.addMessage("Messaggio inviato!", null, () => {
        this.onEnd("sent");
      });
    } catch (e) {
      console.error("Cannot send feedback", e);
      this.props.modals.addError("Impossibile inviare il messaggio, ti preghiamo di riprovare");
      Utils.handleError(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  onEnd(result) {
    this.props.callback(result);
  }

  render() {
    return (
      <Modal className="has-text-centered is-small gray-gradient" isActive={this.props.isActive}>
        <ModalCard>
          <ModalCardHeader>
            <ModalCardTitle>Contattaci!</ModalCardTitle>
          </ModalCardHeader>
          <ModalCardBody>
            <Container>
              <form ref={form => (this.contattiModalForm = form)} name="contattiModalForm">
                <Columns isMultiline>
                  <Column isSize="1/2">
                    <Field className="input-field no-m">
                      <Label>Nome</Label>
                      <Input type="text" placeholder="Nome" name="name" required defaultValue={this.state.name} onChange={this.changeEvent} />
                    </Field>
                  </Column>
                  <Column isSize="1/2">
                    <Field className="input-field no-m">
                      <Label>Cognome</Label>
                      <Input type="text" placeholder="Cognome" name="surname" defaultValue={this.state.surname} onChange={this.changeEvent} />
                    </Field>
                  </Column>
                  <Column isSize="full">
                    <Field className="input-field no-m">
                      <Label>email</Label>
                      <Input type="email" placeholder="Email" name="email" required defaultValue={this.state.email} onChange={this.changeEvent} />
                    </Field>
                  </Column>
                  <Column isSize="full">
                    <Field className="input-field no-m">
                      <Label>Feedback</Label>
                      <TextArea name="additionalInfo" placeholder="Feedback" required defaultValue={this.state.additionalInfo} onChange={this.changeEvent} />
                    </Field>
                  </Column>
                </Columns>
              </form>
            </Container>
          </ModalCardBody>
          <ModalCardFooter>
            <Button isLoading={this.state.loading} onClick={() => this.send()} disabled={!this.contattiModalForm || !this.contattiModalForm.checkValidity()} isColor="primary" className="is-fullwidth">
              Invia
            </Button>
            <Button onClick={() => this.onEnd("cancel")} className="is-fullwidth is-transparent is-outlined">
              Annulla
            </Button>
          </ModalCardFooter>
        </ModalCard>
      </Modal>
    );
  }
}

export default withModalsCtx(withUserCtx(withRouter(Contacts)));
