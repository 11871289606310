import React, { Component } from "react";
import firebase from "firebase/app";
import { Button, Column, Columns, Field, Help, Input, Label, Modal, ModalBackground, ModalCard, ModalCardBody, ModalCardFooter, ModalCardHeader, ModalCardTitle } from "bloomer";
import CriptoService from "../services/CriptoService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import times from "../svgs/ic-24-close-red.svg";
import { withRouter } from "react-router-dom";
import Utils from "../Utils";
import MailchimpService from "../services/MailchimpService";
import { withUserCtx } from "../contexts/UserContext";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import CookieSettingsToggle from "../components/CookieSettingsToggle";

class Login extends Component {
  state = {
    touched: false,
    emailValid: false,
    loadingRecover: false,
    loadingLogin: false,
    email: null,
    password: null,
    error: false,
    showMail: false,
    isLogin: true,
    isCompany: false,
    acceptMailing: false,
    acceptTerms: false,
    showPassword: false,
    formValid: true,
  };

  constructor(props) {
    super(props);

    this.onEnd = this.onEnd.bind(this);
    this.doLogin = this.doLogin.bind(this);
    this.doRecover = this.doRecover.bind(this);
    this.doRegister = this.doRegister.bind(this);
    this.subscribeMailchimp = this.subscribeMailchimp.bind(this);
    this.invertLogin = this.invertLogin.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.isLoading = this.isLoading.bind(this);
    this.deleteError = this.deleteError.bind(this);
    this.doFbLogin = this.doFbLogin.bind(this);
    this.renderLogin = this.renderLogin.bind(this);
    this.renderRegister = this.renderRegister.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.checkEmailIsUsed = this.checkEmailIsUsed.bind(this);
    this.createUserIfNotExists = this.createUserIfNotExists.bind(this);
    this.emailIsValid = this.emailIsValid.bind(this);
  }

  emailIsValid(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email) return re.test(String(email).toLowerCase());
  }

  componentWillReceiveProps(nextProps) {
    const { registration } = nextProps;

    this.setState({ isLogin: !registration });

    const email = localStorage.getItem("emailLogin");

    if (!!email) {
      localStorage.removeItem("emailLogin");

      this.setState({ emailValid: this.emailIsValid(email), touched: true, email, emailUsed: false });
    }
  }

  onEnd() {
    this.setState({ isLogin: true }, this.props.onEnd);
  }

  invertLogin(e) {
    if (e) e.preventDefault();
    this.setState({ isLogin: !this.state.isLogin, error: null });
  }

  isLoading() {
    return this.state.loadingLogin || this.state.loadingRecover;
  }

  async updateState(data) {
    const ths = this;
    return new Promise((res, rej) => {
      ths.setState(data, (result) => {
        res(result);
      });
    });
  }

  setMessage(e) {
    try {
      this.setState({ error: e });
    } catch (pErr) {
      this.setState({ error: "Unknown Error" });
      Utils.handleError(pErr);
    }
  }

  async doLogin(e) {
    try {
      if (e) e.preventDefault();
      console.debug("set loading");
      this.setState({ loadingLogin: true });
      console.debug("do login");
      await firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password);

      if (!!this.state.errorCredentials && this.state.errorCredentials.email === this.state.email) {
        const credentials = this.state.errorCredentials;
        this.setState({ errorCredentials: null });
        firebase
          .auth()
          .currentUser.linkWithCredential(this.state.credentials.credential)
          .then(() => alert(`Abbiamo collegato in automatico il tuo account ${credentials.credential.provider} a questo login`))
          .catch(() => alert(`Abbiamo provato a collegare il tuo account ${credentials.credential.provider} a questo login, ma non ci siamo riusciti`));
      }

      this.setState({ loadingLogin: false, error: false });
      console.debug("updating user ctx");
      if (this.onEnd) this.onEnd();
    } catch (e) {
      Utils.log(`Error on login:`, e);
      var errorMessage = e.message;
      if (e.code === "auth/wrong-password") errorMessage = "La password inserita non è valida";
      this.setMessage(errorMessage);
      this.setState({ loadingLogin: false });
      Utils.handleError(e);
    }
  }

  async doRecover(e) {
    try {
      this.setState({ isLogin: true });
      if (this.props.history) this.props.history.push("/password-recovery");
      else window.location.href = "/password-recovery";
    } finally {
      this.onEnd();
    }
  }

  onChangeInput(e) {
    const s = {};
    s[e.target.name] = e.target.value;
    this.setState(s);
  }

  subscribeMailchimp(email) {
    const listIdAffiliazioni = "81a0b091d7";
    MailchimpService.subscribe(
      {
        EMAIL: email,
      },
      listIdAffiliazioni
    );
  }

  async doRegister() {
    if (this.isRegisterInvalid()) {
      this.setState({
        ...this.state,
        formValid: false,
      });
      return;
    }

    this.subscribeMailchimp(this.state.email);

    try {
      const payload = {
        // password: this.state.password,
        email: this.state.email,
        displayName: this.state.email,
        marketing: this.state.acceptMailing,
        terms: this.state.acceptTerms,
        photoURL: `https://www.gravatar.com/avatar/${CriptoService.md5(this.state.email)}`,
      };

      payload.roles = {};

      this.setState({ loadingRegister: true });

      const auth = firebase.auth();
      const firestore = firebase.firestore();

      try {
        await auth.createUserWithEmailAndPassword(payload.email, this.state.password);

        if (!auth.currentUser) {
          await auth.signInWithEmailAndPassword(payload.email, this.state.password);
        }

        payload.roles[auth.currentUser.uid] = "owner";

        try {
          await auth.currentUser.sendEmailVerification();
        } catch (e) {
          Utils.handleError(e);
          console.error(`cannot send email verification`, e);
        }

        try {
          await firestore.collection("users").doc(auth.currentUser.uid).set(payload);
        } catch (e) {
          Utils.handleError(e);
          console.error("Cannot save user profile and data", e);
        }

        this.onEnd();
      } catch (e) {
        this.setMessage(e.message);
        Utils.handleError(e);
      } finally {
        this.setState({ loadingRegister: false });
      }
    } catch (e) {
      Utils.handleError(e);
      console.error("error while register", e);
      alert(e.message);
    }
  }

  isRegisterInvalid() {
    const passwordValid = !!this.state.password && this.state.password.length >= 8;
    return !(this.state.emailValid && passwordValid && this.state.acceptTerms && !this.state.emailUsed);
  }

  onChangeEmail(e) {
    const el = e.target;
    console.log("AA email", el.value);

    if (el.value) {
      const isValid = this.emailIsValid(el.value);
      this.setState({
        emailValid: isValid,
        touched: true,
        email: String(el.value).toLowerCase(),
        emailUsed: false,
      });
    } else {
      this.setState({ email: "", emailUsed: false, emailIsValid: false, touched: true });
    }
  }

  async checkEmailIsUsed() {
    if (this.state.emailValid) {
      const providers = await firebase.auth().fetchProvidersForEmail(this.state.email);
      const emailUsed = !!providers;
      Utils.log(providers);
      this.setState({ emailUsed: emailUsed });
    } else this.setState({ emailUsed: false });
  }

  onChangePassword(e) {
    const el = document.getElementById("password");
    this.setState({ password: String(el.value) });
  }

  async createUserIfNotExists() {
    const auth = firebase.auth();

    var roles = {};
    roles[auth.currentUser.uid] = "owner";
    const userRef = firebase.firestore().collection("users").doc(auth.currentUser.uid);

    try {
      const userSnap = await userRef.get();
      const terms = userSnap.exists && userSnap.data() ? userSnap.data().terms : false;

      Utils.log("AA existe", userSnap.exists);
    } catch (error) {
      Utils.handleError(error, "Login.js");

      const displayName = auth.currentUser.displayName;

      const names = displayName.split(" ").slice(0, -1).join(" ");
      const surname = displayName.split(" ").slice(-1).join(" ");

      await userRef.set({
        uid: auth.currentUser.uid,
        displayName: displayName,
        name: names,
        surname: surname,
        photoURL: auth.currentUser.photoURL,
        roles: roles,
      });
    }
  }

  async doFbLogin(e) {
    if (e) e.preventDefault();

    try {
      const fbProv = new firebase.auth.FacebookAuthProvider();
      fbProv.addScope("user_age_range");
      fbProv.setCustomParameters({
        display: "popup",
      });

      const auth = firebase.auth();
      await auth.signInWithPopup(fbProv);

      this.createUserIfNotExists();

      if (this.onEnd) this.onEnd();
    } catch (error) {
      Utils.handleError(error);
      if (error.code === "auth/account-exists-with-different-credential") {
        const providers = await firebase.auth().fetchProvidersForEmail(error.email);
        this.props.modals.addError(`Esiste già un utente registrato sul nostro sito con questa email. Prova ad autenticarti con email e password`);

        // alert(`C'è stato un problema con il login di Facebook, prova ad usare un'altro sistema di autenticazione con la tua email (${error.email}).
        //             \nSistemi disponibili: ${providers.join(", ")}`);
        this.setState({ errorCredentials: error });
      } else {
        alert(`C'è stato un problema con il login di Facebook, errore con codice ${error.code}. ${error.message}`);
      }
    }
  }

  deleteError() {
    this.setState({ error: false });
  }

  renderLogin() {
    return (
      <form name="login">
        <Field className="input-field mb-30">
          <Input onChange={this.onChangeEmail} value={this.state.email} name="email" type="email" placeholder="La tua email" required />
          <Help isColor="mylab-light-gray">L'email del tuo account</Help>
        </Field>
        <Field className="input-field mb-30">
          <Input onChange={this.onChangeInput} name="password" type={this.state.showPassword ? "text" : "password"} autoComplete="false" placeholder="La tua password" />
          <Help isColor="mylab-light-gray">Minimo 8 caratteri</Help>
        </Field>
        <Field>
          <Button onClick={this.doRecover} className="is-transparent is-fullwidth">
            Password dimenticata?
          </Button>
        </Field>
        <Field>
          <Button
            disabled={!this.state.emailValid || !this.state.password || this.state.password.length < 8}
            onClick={this.doLogin}
            isLoading={this.state.loadingLogin}
            isColor="primary"
            className="is-fullwidth"
          >
            Accedi alla tua area personale
          </Button>
          {this.state.error ? <Help isColor="danger">{this.state.error}</Help> : null}
        </Field>
        <h2 className="subtitle mt-20 has-text-centered">
          Non hai un account?{" "}
          <a href="#" onClick={this.invertLogin}>
            Registrati
          </a>
        </h2>
      </form>
    );
  }

  renderRegister() {
    const treat = <input className="is-checkradio is-primary" name="trattamento" type="checkbox" readOnly required checked={this.state.acceptTerms} />;
    const marketing = <input className="is-checkradio is-primary" name="marketing" type="checkbox" readOnly checked={this.state.acceptMailing} />;
    return (
      <form name="register">
        <Field className="input-field mb-30">
          <Input onChange={this.onChangeEmail} name="email" type="email" placeholder="La tua email" />
          <Help isColor="mylab-light-gray">L'email del tuo account</Help>
        </Field>
        <Field className="input-field mb-30">
          <Input onChange={this.onChangeInput} name="password" type={this.state.showPassword ? "text" : "password"} placeholder="La tua password" />
          <Help isColor="mylab-light-gray">Minimo 8 caratteri</Help>
        </Field>
        <Field>
          <Columns isMobile>
            <Column isSize="narrow">
              {treat}
              <Label onClick={() => this.setState({ acceptTerms: !this.state.acceptTerms })} />
            </Column>
            <Column>
              <h2>
                Acconsento al trattamento dei miei dati per l'erogazione minima del servizio senza il quale non potrebbe essere erogato (
                <a href="/privacy" target="_blank">
                  vai all'informativa
                </a>
                );
              </h2>
            </Column>
          </Columns>
          {!this.state.acceptTerms && !this.state.formValid ? <Help isColor="danger">Devi accettare i termini per continuare</Help> : ""}
        </Field>
        <Field className="checkradio-container">
          <Columns isMobile>
            <Column isSize="narrow">
              {marketing}
              <Label onClick={() => this.setState({ acceptMailing: !this.state.acceptMailing })} />
            </Column>
            <Column>
              <h2>
                Acconsento al trattamento dei miei dati per finalità di marketing da parte di MyLab Nutrition s.r.l. (
                <a href="/privacy" target="_blank">
                  par 2.3 dell'informativa
                </a>
                );
              </h2>
            </Column>
          </Columns>
        </Field>
        <Field>
          <Button
            disabled={!this.state.emailValid || !this.state.password || this.state.password.length < 8}
            onClick={this.doRegister}
            isLoading={this.state.loadingRegister}
            isColor="primary"
            className="is-fullwidth"
          >
            Registrati a MYLAB nutrition
          </Button>

          {this.state.error ? <Help>{this.state.error}</Help> : null}
        </Field>
        <h2 className="subtitle mt-80 has-text-centered">
          Hai già un account?{" "}
          <a href="#" onClick={this.invertLogin}>
            Accedi
          </a>
        </h2>
      </form>
    );
  }

  render() {
    return (
      <Modal className="has-text-centered is-small gray-gradient pb-60" isActive={this.props.isActive}>
        <ModalCard className={window.innerWidth <= 768 ? "is-fullscreen" : ""}>
          <div className="gray-gradient">
            <ModalCardHeader>
              <img
                src={times}
                className="is-pulled-left"
                onClick={this.onEnd}
                style={{
                  minWidth: "24px",
                  minHeight: "24px",
                  maxWidth: "24px",
                  maxHeight: "24px",
                }}
                alt=""
              />
              <ModalCardTitle>{this.state.isLogin ? "Accedi" : "Registrati"}</ModalCardTitle>
            </ModalCardHeader>
            <ModalCardBody>
              <div className="has-text-centered">
                <Button onClick={this.doFbLogin} className="is-fullwidth has-text-left is-facebook">
                  <FontAwesomeIcon icon={faFacebook} />
                  <span style={{ width: "100%" }} className="has-text-centered">
                    Entra con Facebook
                  </span>
                </Button>
                <p className="mtb-20 has-text-centered has-text-red is-bolder">oppure</p>
                {this.state.isLogin ? this.renderLogin() : this.renderRegister()}
              </div>
            </ModalCardBody>
            <ModalCardFooter />
          </div>
        </ModalCard>
      </Modal>
    );
  }
}

export default withRouter(withUserCtx(Login));
