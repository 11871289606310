import React from "react";
import check from "../../svgs/ic-24-check-circle-solid.svg";

export default class Box extends React.Component {
  render() {
    return (
      <div onClick={this.props.onClick} className={"mln-box " + (this.props.generali ? "generali " : " ") + (this.props.checked ? "is-checked" : "")}>
        {/* <img src={check} className="check" alt="" /> */}
        {this.props.value.image ? (
          <div>
            <img src={this.props.value.image} alt="" />
          </div>
        ) : null}
        <p>{this.props.value.label}</p>
      </div>
    );
  }
}
