import React, { Component } from "react";
import { Button, Column, Columns, Field, Help, Input, Modal, ModalBackground, ModalCard, ModalCardBody, ModalCardHeader } from "bloomer";
import times from "../svgs/ic-24-close.svg";

class RiepilogoFormulaModal extends Component {
  render() {
    const nome = localStorage.getItem("NomeClientePT");
    const numEl = this.props.formula.elements.length;
    const subtitle = numEl === 5 ? "Il tuo kit è completo!" : "Puoi sceglierne ancora " + (5 - numEl);
    return (
      <Modal isActive={this.props.isActive} className="laboratorio-modal">
        <ModalCard className={window.innerWidth <= 768 ? "is-fullscreen" : ""}>
          <ModalCardHeader>
            <Columns style={{ width: "100%" }} isMobile>
              <Column isSize="2/3">
                <h3 className="title has-text-white">
                  HAI SCELTO {numEl} ELEMENTI PER IL KIT DI {nome}
                </h3>
              </Column>
              <Column>
                <img src={times} className="left-header-button is-pulled-right" onClick={this.props.onClose} alt="" />
              </Column>
            </Columns>
          </ModalCardHeader>

          <ModalCardBody>
            <h3 className="subtitle has-text-white bolder">{subtitle}</h3>
            <div>
              <Button className="is-primary is-fullwidth mb-20" onClick={this.props.vaiRiepilogo}>
                VAI AL RIEPILOGO
              </Button>

              <Button className="is-transparent is-bordered-white is-fullwidth mb-20" onClick={this.props.aggiungiAltriElementi} disabled={numEl >= 5}>
                AGGIUNGI ALTRI ELEMENTI
              </Button>
            </div>
          </ModalCardBody>
        </ModalCard>
      </Modal>
    );
  }
}

export default RiepilogoFormulaModal;
