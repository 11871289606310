export default {
  data: [
    {
      description: "Per quale area di interesse vuoi effettuare la Consulenza A.I.?",
      percentage: 0,
      questions: [
        {
          formName: "tipoCategorie",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Benessere e Salute", value: "benessere" },
            { label: "Attività Sportiva", value: "sport" }
          ]
        }
      ],
      uid: 0
    },
    //////////////////  TARGET //////////////////////
    {
      condition: [{ formName: "tipoCategorie", value: ["benessere"] }],
      description: "Scegli una categoria di Salute e Benessere nella quale vuoi ottenere risultati",
      percentage: 10,
      questions: [
        {
          formName: "categoriaSelectedTarget",
          subtitle: "Una volta terminata la consulenza potrai ricominciare e selezionarne un'altra",
          fullwidth: true,
          kind: "radiobox2",
          order: 1,
          values: [
            { label: "Peso Corporeo", value: "peso_corporeo" },
            { label: "Depurazione o Disintossicazione del corpo", value: "depurazione" },
            { label: "Dolori Ossa, Tendini, Muscoli", value: "dolori_ossa" },
            { label: "Apparato urogenitale Femminile", value: "urogenitale_femminile" },
            { label: "Apparato urogenitale Maschile", value: "urogenitale_maschile" },
            { label: "Sistema Nervoso", value: "sistema_nervoso" },
            { label: "Sistema Digestivo", value: "sistema_digestivo" },
            { label: "Derma e Capelli", value: "derma_capelli" },
            { label: "Difficoltà Circolatoria", value: "difficolta_circolatoria" },
            { label: "Difese immunitarie", value: "difese_immunitarie" }
          ]
        }
      ],
      uid: 1
    },
    {
      condition: [{ formName: "tipoCategorie", value: ["sport"] }],
      description: "Scegli una categoria della tua Attività Sportiva nella quale vuoi ottenere risultati",
      percentage: 10,
      questions: [
        {
          formName: "categoriaSelectedTarget",
          subtitle: "Una volta terminata la consulenza potrai ricominciare e selezionarne un'altra",
          fullwidth: true,
          kind: "radiobox2",
          order: 1,
          values: [
            { label: "Diminuzione Massa grassa/Definizione", value: "diminuzione_grasso" },
            { label: "Massa muscolare", value: "massa_muscolare" },
            { label: "Resistenza ed Energia", value: "resistenza_energia" },
            { label: "Recupero Veloce", value: "recupero_energia" },
            { label: "Concentrazione per gli Obiettivi", value: "concentrazione" }
          ]
        }
      ],
      uid: 2
    }
  ]
};
