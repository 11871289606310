export default {
  data: [
    {
      description: "Resisti alla fatica per lungo tempo?",
      percentage: 35,
      questions: [
        {
          formName: "resistenza_fatica",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "resistenza_fatica_si" },
            { label: "No", value: "resistenza_fatica_no" },
            { label: "A volte", value: "resistenza_fatica_a_volte" }
          ]
        }
      ],
      uid: 0
    },
    {
      description: "Quante ore ti alleni a settimana?",
      percentage: 40,
      questions: [
        {
          formName: "ore_allenamento",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "3", value: "ore_allenamento_3" },
            { label: "5", value: "ore_allenamento_5" },
            { label: "Più di 5", value: "ore_allenamento_piu" }
          ]
        }
      ],
      uid: 1
    },
    {
      description: "Conosci il tuo VO 2 Max?",
      percentage: 43,
      questions: [
        {
          formName: "vo2max",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "vo2max_si" },
            { label: "No", value: "vo2max_no" }
          ]
        }
      ],
      uid: 2
    },
    {
      description: "Fai attività aerobica con continuità?",
      percentage: 45,
      questions: [
        {
          formName: "attivita_aerobica",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "attivita_aerobica_si" },
            { label: "No", value: "attivita_aerobica_no" },
            { label: "A volte", value: "attivita_aerobica_a_volte" }
          ]
        }
      ],
      uid: 3
    },
    {
      description: "Soffri spesso di crampi?",
      percentage: 48,
      questions: [
        {
          formName: "crampi",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "crampi_si" },
            { label: "No", value: "crampi_no" },
            { label: "A volte", value: "crampi_a_volte" }
          ]
        }
      ],
      uid: 4
    },
    {
      description: "Ti senti stressato?",
      percentage: 50,
      questions: [
        {
          formName: "senti_stressato",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "senti_stressato_si" },
            { label: "No", value: "senti_stressato_no" },
            { label: "A volte", value: "senti_stressato_a_volte" }
          ]
        }
      ],
      uid: 5
    },
    {
      description: "Segui un'alimentazione corretta e bilanciata?",
      percentage: 53,
      questions: [
        {
          formName: "alimentazione_bilanciata",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "alimentazione_bilanciata_si" },
            { label: "No", value: "alimentazione_bilanciata_no" },
            { label: "A volte", value: "alimentazione_bilanciata_a_volte" }
          ]
        }
      ],
      uid: 6
    },
    {
      description: "Ti idrati con costanza in maniera corretta?",
      percentage: 55,
      questions: [
        {
          formName: "idratazione_corretta",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "idratazione_corretta_si" },
            { label: "No", value: "idratazione_corretta_no" },
            { label: "A volte", value: "idratazione_corretta_a_volte" }
          ]
        }
      ],
      uid: 7
    },
    {
      description: "Segui un piano di allenamento preciso?",
      percentage: 58,
      questions: [
        {
          formName: "piano_allenamento",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "piano_allenamento_si" },
            { label: "No", value: "piano_allenamento_no" }
          ]
        }
      ],
      uid: 8
    },
    {
      description: "Riesci a gestire i tuoi sforzi?",
      percentage: 60,
      questions: [
        {
          formName: "gestisci_sforzi",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "gestisci_sforzi_si" },
            { label: "No", value: "gestisci_sforzi_no" },
            { label: "Non so come fare", value: "gestisci_sforzi_non_so" }
          ]
        }
      ],
      uid: 9
    },
    {
      description: "Pensi di conoscere bene il tuo corpo?",
      percentage: 63,
      questions: [
        {
          formName: "conosci_corpo",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "conosci_corpo_si" },
            { label: "No", value: "conosci_corpo_no" },
            { label: "Abbastanza", value: "conosci_corpo_abbastanza" }
          ]
        }
      ],
      uid: 10
    },
    {
      description: "Ti senti stanco/a dopo l'allenamento?",
      percentage: 65,
      questions: [
        {
          formName: "stanco_dopo_allenamento",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "stanco_dopo_allenamento_si" },
            { label: "No", value: "stanco_dopo_allenamento_no" },
            { label: "A volte", value: "stanco_dopo_allenamento_a_volte" }
          ]
        }
      ],
      uid: 11
    },
    {
      description: "Alterni spesso i tuoi allenamenti?",
      percentage: 68,
      questions: [
        {
          formName: "alterni_allenamenti",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "alterni_allenamenti_si" },
            { label: "No", value: "alterni_allenamenti_no" },
            { label: "A volte", value: "alterni_allenamenti_a_volte" }
          ]
        }
      ],
      uid: 12
    },
    {
      description: "Ti senti spesso sfiduciato nonostante i tuoi sforzi?",
      percentage: 70,
      questions: [
        {
          formName: "sfiduciato",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "sfiduciato_si" },
            { label: "No", value: "sfiduciato_no" },
            { label: "A volte", value: "sfiduciato_a_volte" }
          ]
        }
      ],
      uid: 13
    },
    {
      description: "Fai uso di integratori specifici con costanza?",
      percentage: 80,
      questions: [
        {
          formName: "integratori_costanza",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "integratori_costanza_si" },
            { label: "No", value: "integratori_costanza_no" },
            { label: "A volte", value: "integratori_costanza_a_volte" }
          ]
        }
      ],
      uid: 14
    }
  ]
};
