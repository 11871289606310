import React, { Component } from "react";
import { Button, Column, Columns, Modal, ModalBackground, ModalCard, ModalCardBody, ModalCardFooter, ModalCardHeader, ModalCardTitle } from "bloomer";
import { withModalsCtx } from "../contexts/ModalsContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

class RiprendiModal extends Component {
  render() {
    const { formula } = this.props;
    return (
      <Modal className="riprendi-modal has-text-centered is-small" isActive={this.props.isActive} style={{ justifyContent: "start", padding: "80px" }}>
        <ModalBackground />
        <ModalCard>
          <ModalCardBody>
            <FontAwesomeIcon style={{ marginRight: "5px", color: "#4d6a76", marginBottom: "30px", float: "right" }} icon={faTimes} onClick={this.props.closeModal} />

            <h4 className="title" style={{ color: "#4d6a76" }}>
              Vuoi riprendere la consulenza da dove l'hai lasciata?
            </h4>
          </ModalCardBody>
          <ModalCardFooter style={{ textAlign: "center", margin: "auto" }}>
            <Button className=" " onClick={this.props.riprendiConsulenza}>
              Riprendi
            </Button>
          </ModalCardFooter>
        </ModalCard>
      </Modal>
    );
  }
}

export default withModalsCtx(RiprendiModal);
