import React from 'react';
import {Control, Field, Help, Label, Select} from "bloomer";
import {withLabCtx} from "../../contexts/LabContext";

class SelectQuestion extends React.Component {

    render() {
        return <Field className={'input-field ' + (this.props.className || '')}>
            <Control>
                {this.props.question.title ? <Label>{this.props.question.title}</Label> : null}
                <Select onChange={e => this.props.updateValue(e.target.name, e.target.value)}
                        placeholder={this.props.question.placeholder ? this.props.question.placeholder : "Scegli..."}
                        name={this.props.question.formName}
                        defaultValue={this.props.lab.survey[this.props.question.formName]}>
                    <option
                        value={null}>{this.props.question.placeholder ? this.props.question.placeholder : "Scegli..."}</option>
                    {this.props.question.values.map(v => <option key={v.value} value={v.value}>{v.label}</option>)}
                </Select>
                {this.props.question.subtitle ? <Help>{this.props.question.subtitle}</Help> : null}
            </Control>
        </Field>
    }
}

export default withLabCtx(SelectQuestion)
