export default {
  data: [
    {
      description: "Assumi giornalmente frutta e verdura?",
      percentage: 30,
      questions: [
        {
          formName: "frutta_verdura",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "frutta_verdura_si" },
            { label: "No", value: "frutta_verdura_no" },
            { label: "Ogni tanto", value: "frutta_verdura_ogni_tanto" }
          ]
        }
      ],
      uid: 0
    },
    {
      description: "Assumi giornalmente alimenti contenenti olii?",
      percentage: 30,
      questions: [
        {
          formName: "alimenti_olii",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "alimenti_olii_si" },
            { label: "No", value: "alimenti_olii_no" },
            { label: "A volte", value: "alimenti_olii_a_volte" }
          ]
        }
      ],
      uid: 1
    },
    {
      description: "Assumi giornalmente frutta a guscio?",
      percentage: 30,
      questions: [
        {
          formName: "frutta_guscio",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "frutta_guscio_si" },
            { label: "No", value: "frutta_guscio_no" }
          ]
        }
      ],
      uid: 2
    },
    {
      description: "Assumi integratori ricchi di elementi antiossidanti?",
      percentage: 30,
      questions: [
        {
          formName: "elementi_antiossidanti",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "elementi_antiossidanti_si" },
            { label: "No", value: "elementi_antiossidanti_no" },
            { label: "A volte", value: "elementi_antiossidanti_a_volte" }
          ]
        }
      ],
      uid: 3
    },
    {
      description: "Assumi molti zuccheri durante la tua giornata?",
      percentage: 30,
      questions: [
        {
          formName: "molti_zuccheri",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "molti_zuccheri_si" },
            { label: "No", value: "molti_zuccheri_no" },
            { label: "A volte", value: "molti_zuccheri_a_volte" }
          ]
        }
      ],
      uid: 4
    }
  ]
};
