import React, { Component } from "react";
import { Column, Columns } from "bloomer";
import StarRating from "../../components/StarRating";

class MTrainerListItem extends Component {
  render() {
    let starvalue = 5;
    const { trainer } = this.props;
    return (
      <div
        onClick={() => this.props.onClick(trainer.uid)}
        className="lab-box has-no-bg has-padding-3"
        style={{ minHeight: "150px", maxHeight: "150px" }}
      >
        <Columns isMobile>
          <Column isSize={{ mobile: 3 }}>
            <figure className="image is-64x64">
              <img className="is-rounded" src={trainer.photoURL} alt="" />
            </figure>
          </Column>
          <Column isSize={{ mobile: 9 }} className="pl-0">
            <StarRating rating={starvalue} />
            <h2 className="subtitle">{trainer.displayName}</h2>
            <h3 className="subtitle has-super-light-gray">Personal trainer</h3>
          </Column>
        </Columns>
        <Columns>
          <Column
            isSize={{ mobile: 12 }}
            className="is-flex is-vertical-centered ptb-0"
          >
            <img
              className="is-pulled-left"
              src="https://via.placeholder.com/14x14"
              style={{ height: "14px", width: "14px" }}
              alt=""
            />
            <span className="has-text-13 has-normal-weight">18 Formule</span>
          </Column>
          <Column
            isSize={{ mobile: 12 }}
            className="is-flex is-vertical-centered ptb-0"
          >
            <img
              className="is-pulled-left"
              src="https://via.placeholder.com/14x14"
              style={{ height: "14px", width: "14px" }}
              alt=""
            />
            <span className="has-text-13 has-normal-weight">Brucia Grassi</span>
          </Column>
        </Columns>
      </div>
    );
  }
}
export default MTrainerListItem;
