import React, { Component } from "react";
import Login from "../modals/Login";
import MultiRender from "../components/MultiRender";
import ChangePassword from "../modals/ChangePassword";
import firebase from "firebase/app";
import { withRouter } from "react-router-dom";
import Utils from "../Utils";

export const UserContext = React.createContext(null);

export const UserConsumer = UserContext.Consumer;

class UserProvider extends Component {
  state = {
    user: null,
    online: true,
    usersCache: {},
    meCache: null,
    showingBusinessArea: false
  };

  constructor(props) {
    super(props);

    this.doLogout = this.doLogout.bind(this);
    this.getUserProfile = this.getUserProfile.bind(this);
    this.setUserMe = this.setUserMe.bind(this);
    this.updateUserData = this.updateUserData.bind(this);
    this.removeStripeInfo = this.removeStripeInfo.bind(this);
    this.openLoginPopup = this.openLoginPopup.bind(this);
    this.openRegistrationPopup = this.openRegistrationPopup.bind(this);
    this.changePasswordCb = this.changePasswordCb.bind(this);
    this.openChangePwPopup = this.openChangePwPopup.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.handleConnectionChange = this.handleConnectionChange.bind(this);
    this.showBusinessArea = this.showBusinessArea.bind(this);
    this.checkCompletaDati = this.checkCompletaDati.bind(this);
    this.createObservers = this.createObservers.bind(this);

    //   if (window.location.host.indexOf("localhost") === -1)
    //     window.onerror = function(msg, url, lineNo, columnNo, error) {
    //       if (msg.toLowerCase().indexOf("error: network error") !== -1)
    //         setTimeout(async () => {
    //           Utils.log("saving error to db");
    //           const payload = {
    //             error: error,
    //             url: url,
    //             line: lineNo,
    //             column: columnNo,
    //             msg: msg,
    //             isNew: true,
    //             source: "webappAndrea",
    //             errorDate: new Date(),
    //             user: firebase.auth().currentUser
    //               ? firebase.auth().currentUser.uid
    //               : null
    //           };
    //           console.debug("payload created");
    //           try {
    //             const data = await (await fetch(
    //               `https://us-central1-mylab-nutrition-1521713952210.cloudfunctions.net/userRoles`
    //             )).json();
    //             payload["ip"] = data.ip;

    //             console.debug("ip obtained");

    //             await firebase
    //               .firestore()
    //               .collection("errors")
    //               .add(payload);
    //             Utils.log("saved errors to db");
    //             localStorage.removeItem("errors");
    //           } catch (e) {
    //             Utils.handleError(e);
    //             const errors =
    //               JSON.parse(localStorage.getItem("errors") || "[]") || [];
    //             errors.push(payload);
    //             localStorage.setItem("errors", JSON.stringify(errors));
    //             console.debug(
    //               "cannot save error, saving it on local db for future saving"
    //             );
    //           }
    //         });
    //     };
  }

  showBusinessArea(show) {
    this.setState({
      ...this.state.user,
      showingBusinessArea: show && this.state.user.personalTrainer
    });
  }

  async getUserProfile(uid) {
    if (this.state.usersCache[uid]) return { ...this.state.usersCache[uid] };
    else {
      const snap = await firebase
        .firestore()
        .collection("profiles")
        .doc(uid)
        .get();
      if (snap.exists) {
        const data = snap.data();
        const usersCache = { ...this.state.usersCache };
        usersCache[uid] = data;
        return { ...data };
      }
      return null;
    }
  }

  async setUserMe(user) {
    //DEPRECATED
    /*
    if (user === null) {
      this.setState({ user: null, meCache: null });
    }

    if (this.meCache || user === null) return;
    else {
      Utils.log("AA setuserme");

      const snap = await firebase
        .firestore()
        .collection("users")
        .doc(firebase.auth().currentUser.uid)
        .get();
      if (snap.exists) {
        const data = snap.data();
        Utils.log("AA user datat", data);

        var newUser = {
          ...user,
          ...data,
          displayName: data.displayName,
          personalTrainer: data.personalTrainer
        };
        // Utils.log("personalTrainer", this.state);

        this.setState({ user: newUser, meCache: data });
      }
    }
    */
  }

  async removeStripeInfo(uid) {
    const userRef = await firebase
      .firestore()
      .collection("users")
      .doc(uid);
    await userRef.update({
      stripeInfo: firebase.firestore.FieldValue.delete()
    });
    this.setState({
      meCache: { ...this.state.meCache, stripeInfo: undefined },
      user: { ...this.state.user, stripeInfo: undefined }
    });
  }

  async updateUserData(uid, newUser) {
    Utils.log("AA User", newUser);
    const userRef = await firebase
      .firestore()
      .collection("users")
      .doc(uid);
    await userRef.update(newUser);
    this.setState({
      meCache: { ...this.state.meCache, ...newUser },
      user: { ...this.state.user, ...newUser }
    });
  }

  handleConnectionChange(event) {
    if (event.type === "offline") {
      // Utils.log("You lost connection.");
      this.setState({ online: false });
    }
    if (event.type === "online") {
      // Utils.log("You are now back online.");
      this.setState({ online: true });
    }

    // Utils.log(new Date(event.timeStamp));
  }

  async doLogout() {
    localStorage.removeItem("cart");
    Utils.log("AA logout");
    try {
      await firebase
        .auth()
        .signOut()
        .then(() => this.props.history.push("/"));
    } catch (error) {
      Utils.handleError(error);
      //console.error(`Error while doing log-out: `, error);
    }
  }

  loggedWitFB(user) {
    var isFbLogin = false;
    if (user != null) {
      user.providerData.forEach(function(profile) {
        if (profile.providerId === "facebook.com") isFbLogin = true;
        // Utils.log("Sign-in provider: " + profile.providerId);
        // Utils.log("  Provider-specific UID: " + profile.uid);
        // Utils.log("  Name: " + profile.displayName);
        // Utils.log("  Email: " + profile.email);
        // Utils.log("  Photo URL: " + profile.photoURL);
      });
    }
    // Utils.log("Facebook Login", isFbLogin);
    return isFbLogin;
  }

  createObservers() {
    console.log("AA userContext createObservers ");
    if (this.state.userObserver) this.state.userObserver();

    if (!firebase.auth().currentUser) {
      this.setState({ user: null, meCache: null });
      return;
    }

    const userObserver = firebase
      .firestore()
      .collection("users")
      .doc(firebase.auth().currentUser.uid)
      .onSnapshot(userDoc => {
        let user = userDoc.data();
        Utils.log("AA user observer", user);
        user.fulldata = true;
        user.providerData = firebase.auth().currentUser.providerData;
        this.setState({ user }, () => this.checkCompletaDati());
      });

    this.setState({ userObserver });
  }

  async checkCompletaDati() {
    const userRef = await firebase
      .firestore()
      .collection("users")
      .doc(firebase.auth().currentUser.uid)
      .get();

    Utils.log("AA user completa", userRef.data().terms);

    if (!this.state.user || (this.loggedWitFB(this.state.user) && userRef.data() && !userRef.data().terms)) this.props.history.push("/completa-dati");
  }

  async componentDidMount() {
    // needed, idk why
    this.setState({
      doLogout: this.doLogout,
      doLogin: this.openLoginPopup,
      doRegistration: this.openRegistrationPopup,
      openChangePwPopup: this.openChangePwPopup,
      getUserProfile: this.getUserProfile,
      isPersonalTrainer: this.isPersonalTrainer,
      updateUserData: this.updateUserData,
      removeStripeInfo: this.removeStripeInfo,
      showBusinessArea: this.showBusinessArea
    });

    this.onlineListener = window.addEventListener("online", this.handleConnectionChange);
    this.offlineListener = window.addEventListener("offline", this.handleConnectionChange);

    // Watching with a pub/sub system the status of the user
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(async user => {
      user ? localStorage.setItem("authUser", JSON.stringify(user)) : localStorage.removeItem("authUser");

      Utils.log("AA firebase.auth().currentUser", firebase.auth().currentUser);
      this.createObservers();

      this.setState({ user });

      // this.setState({ user });
      //this.setUserMe(firebase.auth().currentUser);
    });

    // const errors = JSON.parse(localStorage.getItem("errors") || "[]") || [];
    // try {
    //   for (let i = 0; i < errors.length; i++) {
    //     if (errors[i] instanceof Array)
    //       await firebase
    //         .firestore()
    //         .collection("errors")
    //         .add({ error: errors[i] });
    //     else
    //       await firebase
    //         .firestore()
    //         .collection("errors")
    //         .add(errors[i]);
    //   }
    // } catch (e) {
    //   Utils.handleError(e);
    //   console.error("Error saving errors to firebase", e);
    // }
  }

  componentWillUnmount() {
    if (this.unregisterAuthObserver) {
      this.unregisterAuthObserver();
      delete this.unregisterAuthObserver;
    }
    if (this.onlineListener) {
      this.onlineListener();
      delete this.onlineListener;
    }
    if (this.offlineListener) {
      this.offlineListener();
      delete this.offlineListener;
    }
  }

  openLoginPopup() {
    this.setState({ openLogin: true, openRegistration: false });
  }

  openRegistrationPopup() {
    this.setState({ openLogin: true, openRegistration: true });
  }

  openChangePwPopup() {
    this.setState({ openChangePw: true });
  }

  changePasswordCb(data) {
    this.setState({ openChangePw: false });
  }

  render() {
    return (
      <UserContext.Provider value={this.state}>
        <MultiRender>
          {this.props.children}
          <Login isActive={this.state.openLogin} registration={this.state.openRegistration} onEnd={() => this.setState({ openLogin: false })} updateUser={user => this.setState({ user: user })} />
          <ChangePassword isActive={!!this.state.user && this.state.openChangePw} userUuid={!!this.state.user ? this.state.user.uid : null} callback={this.changePasswordCb} />
        </MultiRender>
      </UserContext.Provider>
    );
  }
}

export default withRouter(UserProvider);

export function withUserCtx(Component) {
  return props => <UserConsumer>{ctx => <Component user={ctx.user} userCtx={ctx} {...props} />}</UserConsumer>;
}
