import React from 'react';
import {Control, Field, Help} from "bloomer";
import {withLabCtx} from "../../contexts/LabContext";

class RadioRenderer extends React.Component {

    constructor(props) {
        super(props);

        this.renderCheck = this.renderCheck.bind(this);
    }

    render() {
        return <Field className={this.props.className || ''}>
            <Control>
                {this.props.question.title ? <h6 className='title'>{this.props.question.title}</h6> : null}
                {this.props.question.values.map(v =>
                    this.renderCheck(this.props.question.formName,
                        this.props.lab.survey[this.props.question.formName],
                        v.value, v.label))}
                {this.props.question.subtitle ? <Help>{this.props.question.subtitle}</Help> : null}
            </Control>
        </Field>
    }


    renderCheck(formName, formValue, value, label) {
        return <div key={value} className='has-checkradio'>
            <input className="is-checkradio" id={value} type="radio"
                   name={formName} defaultChecked={formValue === value}
                   onClick={() => this.props.updateValue(formName, value)} value={value}/>
            <label htmlFor={value}>{label}</label>
        </div>
    }

}

export default withLabCtx(RadioRenderer)
