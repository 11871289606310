import React from "react";
import { Control, Field, Help } from "bloomer";
import { withLabCtx } from "../../contexts/LabContext";

class PrivacyRenderer extends React.Component {
  constructor(props) {
    super(props);

    this.renderPrivacy = this.renderPrivacy.bind(this);
    this.addRemoveValue = this.addRemoveValue.bind(this);
  }

  addRemoveValue(value) {
    this.props.addRemoveValue(this.props.question, value);
  }

  render() {
    return (
      <Field className={this.props.className || ""}>
        <Control>
          {this.props.question.title ? <h6 className="title">{this.props.question.title}</h6> : null}
          {this.props.question.values.map((v) => this.renderPrivacy(this.props.question.formName, this.props.lab.survey[this.props.question.formName], v.value))}
          {this.props.question.subtitle ? <Help>{this.props.question.subtitle}</Help> : null}
        </Control>
      </Field>
    );
  }

  renderPrivacy(formName, formValue, value) {
    return (
      <div key={value} className="has-checkradio">
        <input className="is-checkradio" id={value} type="checkbox" name={formName} checked={formValue && formValue.indexOf(value) !== -1} onClick={() => this.addRemoveValue(value)} value={value} />
        <label htmlFor={value}>
          Ho preso visione dell’informativa della{" "}
          <a href="https://mylabnutrition.net/privacy-policy/" target="_blank">
            Privacy
          </a>{" "}
          e acconsento al trattamento dei miei dati personali ai sensi dell’art. 13 D.Lgs. 30/6/2003 n.196 e dell’art. 13 regolamento UE 2016/679
        </label>
      </div>
    );
  }
}

export default withLabCtx(PrivacyRenderer);
