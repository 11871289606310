import React, { Component } from "react";
import firebase from "firebase/app";
import { withRouter } from "react-router-dom";
import Decimal from "decimal.js";
import Utils from "../Utils";

export const BusinessContext = React.createContext(null);

export const BusinessConsumer = BusinessContext.Consumer;

export class BusinessProvider extends Component {
  state = {
    revenuesTotal: 0,
    revenuesPaid: 0,
    revenues: null,
    contract: null,
    sales: null,
    activeAffiliation: false,
    completaProfilo: 0,
    completaProfiloString: "attiva il tuo contratto",
    first: true,
    loading: true
  };

  constructor(props) {
    super(props);

    this.componentDidMount = this.componentDidMount.bind(this);
    this.checkContrattoAttivato = this.checkContrattoAttivato.bind(this);
    this.checkContrattoInviato = this.checkContrattoInviato.bind(this);
    this.checkDatiPersonali = this.checkDatiPersonali.bind(this);
    this.checkVendita = this.checkVendita.bind(this);
    this.calculateProfileCompletion = this.calculateProfileCompletion.bind(this);
    this.removeObservers = this.removeObservers.bind(this);
  }

  async componentDidMount() {
    this.setState({
      revenuesTotal: 0,
      revenuesPaid: 0,
      revenues: null,
      contract: null,
      sales: null,
      activeAffiliation: false,
      completaProfilo: 0,
      completaProfiloString: "",
      loading: true,
      checkVendita: this.checkVendita,
      checkDatiPersonali: this.checkDatiPersonali,
      checkContrattoAttivato: this.checkContrattoAttivato,
      checkContrattoInviato: this.checkContrattoInviato
    });

    Utils.log("AA business context didmount");

    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(async user => {
      Utils.log("AA onAuthStateChanged", user);

      this.createObservers(user);
    });

    this.setState({ unregisterAuthObserver });
  }

  async createObservers(user) {
    Utils.log("AA create business observer");
    if (user === null || firebase.auth().currentUser === null) {
      this.removeObservers();
      return;
    }

    const uid = firebase.auth().currentUser.uid;

    if (this.state.userObserver) this.state.userObserver();

    const userObserver = firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .onSnapshot(userDoc => {
        // Utils.log("obtained user");
        Utils.log("AA obtained user");
        const user = userDoc.data();
        if (this.state.contractObserver) this.state.contractObserver();
        if (this.state.revenuesObserver) this.state.revenuesObserver();

        this.setState({ user: user });
        let contractObserver;
        //Utils.log("user", user ? user.affiliation : user);
        if (user && user.affiliation)
          contractObserver = user.affiliation.onSnapshot(contractDoc => {
            //Utils.log("obtained contract");
            this.setState({ contract: contractDoc.data() });
          });
        const revenuesObserver = firebase
          .firestore()
          .collection("revenues")
          .where("owner", "==", uid)
          .orderBy("creation", "desc")
          .onSnapshot(doc => {
            Utils.log("obtained revenues", doc);
            var revenuesPaid = 0;
            var revenuesTotal = 0;
            const revs = [];
            const sales = [];
            doc.forEach(async c => {
              // Utils.log("obtained revenues c", c.data());
              // Utils.log("obtained revenues uid", c.data().uid);
              if (c.data().status === "CANCELED") return;
              revs.push(c.data());
              revenuesTotal += c.data().amount;
              if (c.data().status === "PAID") revenuesPaid += c.data().amount;

              const orderId = c.data().order || c.data().uid;
              let recipesSold = [];

              const itemsRef = firebase
                .firestore()
                .collection("orders")
                .doc(orderId)
                .collection("items");

              if (!!itemsRef) recipesSold = await itemsRef.get();

              recipesSold.forEach(r => {
                if (r.exists && (r.data().item.owner === uid || (!!r.data().coupon && r.data().coupon.owner === uid))) {
                  var sale = {
                    ...r.data(),
                    uid: c.data().uid,
                    orderUid: c.data().order,
                    date: c.data().creation,
                    revenue: c.data().amount,
                    status: c.data().status
                  };

                  sales.push(sale);
                }
              });
            });
            this.setState(
              {
                revenues: revs,
                revenuesTotal,
                revenuesPaid,
                sales,
                loading: false
              },
              this.calculateProfileCompletion()
            );
          });
        // this.setState({ loading: false });
        this.setState({
          revenuesObserver: revenuesObserver,
          contractObserver: contractObserver
        });
      });

    this.setState({
      userObserver: userObserver
    });
  }

  removeObservers() {
    if (this.state.revenuesObserver) this.state.revenuesObserver();
    if (this.state.contractObserver) this.state.contractObserver();
    if (this.state.userObserver) this.state.userObserver();
  }

  componentWillUnmount() {
    this.removeObservers();
    if (this.state.unregisterAuthObserver) this.state.unregisterAuthObserver();
  }

  calculateProfileCompletion() {
    let completaProfilo = 25;
    let completaProfiloString = "attiva il tuo contratto";

    if (this.checkDatiPagamento()) completaProfilo += 25;
    else completaProfiloString = "inserisci i tuoi dati pagamento";

    // if (this.checkContrattoInviato()) completaProfilo += 25;
    // else completaProfiloString = "invia il tuo contratto";

    if (this.checkContrattoAttivato()) completaProfilo += 25;
    else completaProfiloString = "attiva il tuo contratto";

    if (this.checkVendita()) completaProfilo += 25;
    else completaProfiloString = "crea una formula e fai la tua prima vendita";

    this.setState({ completaProfilo, completaProfiloString });
  }

  checkDatiPagamento() {
    return !!this.state.user.paymeTo;
  }

  checkDatiPersonali() {
    return !!((this.state.user.name && this.state.user.surname) || this.state.user.companyName) && this.state.user.birthDate && (this.state.user.vatCode || this.state.user.fiscalCode);
  }

  checkContrattoInviato() {
    return !!(this.state.contract && this.state.contract.validation !== "ERROR");
  }

  checkContrattoAttivato() {
    return !!(this.state.contract && this.state.contract.validationState === "VALID");
  }

  checkVendita() {
    return !!(this.state.revenues && this.state.revenues.length > 0);
  }

  render() {
    return (
      <BusinessContext.Provider value={this.state}>
        <div>{this.props.children}</div>
      </BusinessContext.Provider>
    );
  }
}

export default withRouter(BusinessProvider);

export function withBusinessCtx(Component) {
  return props => <BusinessConsumer>{ctx => <Component business={ctx} {...props} />}</BusinessConsumer>;
}
