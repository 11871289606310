import React from 'react';
import Loader from "./Loader";
import {HorizontalBar} from 'react-chartjs-2';
import LegendBullet from "./LegendBullet";

export default class Composition extends React.Component {
    state = {
        data: {
            labels: ['Elementi'],
            datasets: [],
        },
        options: {
            maintainAspectRatio: false,
            title: {
                display: false,
            },
            legend: {
                display: false
            },
            tooltips: {
                mode: 'index',
                intersect: false,
                enabled: false
            },
            scales: {
                xAxes: [{
                    stacked: true,
                    display: false,
                    gridLines: {
                        drawBorder: false
                    }
                }],
                yAxes: [{
                    stacked: true,
                    display: false,
                    gridLines: {
                        drawBorder: false
                    }
                }]
            }
        }
    };

    colors = [
        '#d72638',
        '#981b3b',
        '#dd1155',
        '#e63946',
        '#ed6a5a'
    ];

    componentDidMount() {
        const state = {...this.state};
        let i = 0;
        let growing = false;
        state.total = 0;
        this.props.elements.forEach(e => {
            state.data.datasets.push({
                label: e.name,
                data: [e.quantity],
                backgroundColor: this.colors[i]
            });

            state.total += e.quantity;

            if (i > 0 && !growing) {
                i--;
            } else {
                if (i < 5)
                    growing = true;
                i++;
            }
        });
        this.setState(state);
    }

    render() {
        if (!this.props.elements) return <Loader/>;
        return (
            <div>
                <div style={{height: this.props.height || '28px', position: 'relative'}}>
                    <HorizontalBar options={this.state.options} data={this.state.data}/>
                </div>
                <ul style={{listStyle: 'none'}} className='ptb-12'>
                    {this.state.data.datasets.map((d, idx) => <li key={idx}><LegendBullet
                        color={d.backgroundColor}/> {d.label} <span
                        className='is-pulled-right'>{Math.floor((d.data[0] * 100) / this.state.total)}%</span></li>)}
                </ul>
            </div>
        )
    }
}