import React from "react";
import { Column, Columns, Field } from "bloomer";
import { withLabCtx } from "../../contexts/LabContext";
import Box from "./Box";

class RadioBoxRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.updateValue = this.updateValue.bind(this);
  }

  updateValue(value) {
    this.props.updateValue(this.props.question.formName, value);
  }

  render() {
    const style = { justifyContent: "center" };
    return (
      <Field className={this.props.className || ""}>
        {this.props.question.title ? <h6 className="title">{this.props.question.title}</h6> : null}
        {this.props.question.subtitle ? (
          <h3 className="subtitle" style={{ textAlign: "center", textTransform: "uppercase", fontSize: "12px" }}>
            {this.props.question.subtitle}
          </h3>
        ) : null}
        <Columns className="radioboxRenderer" isMobile isMultiline style={{ justifyContent: "center", display: this.props.displayBlock ? "block" : "flex" }}>
          {this.props.question.values.map((value) => {
            const v = !!value.id ? value.id : value.value;

            return (
              <Column key={v} isSize={this.props.question.fullwidth ? "12" : this.props.question.size || "4"}>
                <Box generali={this.props.generali} onClick={() => this.updateValue(v)} checked={this.props.lab.survey[this.props.question.formName] === v} value={value} />
              </Column>
            );
          })}
        </Columns>
      </Field>
    );
  }
}

export default withLabCtx(RadioBoxRenderer);
