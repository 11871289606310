import React, { Component } from "react";
import { Button, Column, Columns, Field, Help, Input, Modal, ModalBackground, ModalCard, ModalCardBody, ModalCardHeader } from "bloomer";
import { withUserCtx } from "../contexts/UserContext";
import firebase from "firebase/app";
import Utils from "../Utils";
import times from "../svgs/ic-24-close-red.svg";

class ChangePassword extends Component {
  state = {
    oldPassword: null,
    newPassword: null,
    newPassword2: null,
    error: null,
    invalidPassword: false
  };

  constructor(props) {
    super(props);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.isFormInvalid = this.isFormInvalid.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  onChangeInput(e) {
    const s = {};
    s[e.target.name] = e.target.value;
    s.touched = true;

    this.setState(s, () => {
      this.setState({
        invalidPassword: this.state.newPassword !== this.state.newPassword2
      });
    });
  }

  isFormInvalid() {
    return !this.state.oldPassword || !this.state.newPassword || !this.state.newPassword2 || this.state.invalidPassword;
  }

  async changePassword() {
    if (!this.isFormInvalid())
      try {
        this.setState({ isLoading: true });

        await firebase.auth().signInWithEmailAndPassword(this.props.user.email, this.state.oldPassword);
        await firebase.auth().currentUser.updatePassword(this.state.newPassword);

        this.setState({ isLoading: false });
        this.props.callback();
      } catch (error) {
        Utils.handleError(error);
        console.error("error changing password", error);
        var errorMessage = error.message;
        if (error.code === "auth/wrong-password") {
          errorMessage = "La password inserita non è valida";
        }
        this.setState({ error: errorMessage, isLoading: false });
      }
  }

  render() {
    return (
      <Modal isActive={this.props.isActive} className="user-page">
        <ModalCard className={window.innerWidth <= 768 ? "is-fullscreen" : ""}>
          <ModalCardHeader>
            <h3 className="title">
              <img src={times} className="left-header-button is-pulled-left" onClick={this.props.callback} alt="" />
              &nbsp;Cambio password
            </h3>
            <div className="is-clear-fix" />
          </ModalCardHeader>

          <ModalCardBody>
            <div>
              <form className="form" onSubmit={this.changePassword}>
                <Columns className="rows">
                  {this.state.error ? (
                    <Column className="row">
                      <div className="notification is-danger">
                        {this.state.error}
                        <span onClick={() => this.setState({ error: null })} className="pull-right">
                          <i className="fas fa-times-circle" />
                        </span>
                      </div>
                    </Column>
                  ) : null}
                  <Column>
                    <Field className="input-field mb-20">
                      <Input onChange={this.onChangeInput} name="oldPassword" defaultValue={this.state.oldPassword} type="password" autoComplete="false" placeholder={"Vecchia password"} />
                    </Field>
                    <Field className="input-field mb-20">
                      <Input onChange={this.onChangeInput} name="newPassword" defaultValue={this.state.newPassword} type="password" autoComplete="false" placeholder={"Nuova password"} />
                    </Field>
                    <Field className="input-field mb-40">
                      <Input onChange={this.onChangeInput} name="newPassword2" defaultValue={this.state.newPassword2} type="password" autoComplete="false" placeholder={"Conferma nuova password"} />
                      {this.state.invalidPassword && this.state.touched ? <Help isColor="danger">La password non corrisponde alla precedente</Help> : null}
                    </Field>
                    <Field isGrouped>
                      <Button isColor="primary" className=" is-fullwidth" onClick={this.changePassword} isLoading={this.state.isLoading} disabled={this.isFormInvalid() || this.state.isLoading}>
                        <i className="fas fa-save" />
                        &nbsp;Salva nuova password
                      </Button>
                    </Field>
                  </Column>
                </Columns>
              </form>
            </div>
          </ModalCardBody>
        </ModalCard>
        {/* <ModalBackground />
        
        <ModalCard className={window.innerWidth <= 768 ? "is-fullscreen" : ""}>
          <ModalCardHeader className="has-text-centerd">
            <ModalCardTitle>Cambio Password</ModalCardTitle>
          </ModalCardHeader>
          <ModalCardBody>
            <p style={{ marginBottom: "25px" }}>
              <img
                style={{ maxHeight: "64px" }}
                src="https://assets.mylabnutrition.net/logo/mylab-pos-orizzontale.png"
              />
            </p>
            <div>
              <form className="form" onSubmit={this.changePassword}>
                <Columns className="rows">
                  {this.state.error ? (
                    <Column className="row">
                      <div className="notification is-danger">
                        {this.state.error}
                        <span
                          onClick={() => this.setState({ error: null })}
                          className="pull-right"
                        >
                          <i className="fas fa-times-circle" />
                        </span>
                      </div>
                    </Column>
                  ) : null}
                  <Column>
                    <Field>
                      <Control hasIcons>
                        <Input
                          onChange={this.onChangeInput}
                          name="oldPassword"
                          defaultValue={this.state.oldPassword}
                          type="password"
                          placeholder={"Vecchia password"}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-lock" />
                        </span>
                      </Control>
                    </Field>
                    <Field>
                      <Control hasIcons>
                        <Input
                          onChange={this.onChangeInput}
                          name="newPassword"
                          defaultValue={this.state.newPassword}
                          type="password"
                          placeholder={"Nuova password"}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-lock" />
                        </span>
                      </Control>
                    </Field>
                    <Field>
                      <Control hasIcons>
                        <Input
                          onChange={this.onChangeInput}
                          name="newPassword2"
                          defaultValue={this.state.newPassword2}
                          type="password"
                          placeholder={"Ripeti nuova password"}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-lock" />
                        </span>
                        {this.state.invalidPassword && this.state.touched ? (
                          <span
                            id="email-err"
                            className="icon is-small is-right"
                          >
                            <i className="fas fa-exclamation-triangle" />
                          </span>
                        ) : null}
                      </Control>
                      {this.state.invalidPassword && this.state.touched ? (
                        <Help isColor="danger">
                          La password non corrisponde alla precedente
                        </Help>
                      ) : null}
                    </Field>
                    <Field isGrouped className="has-text-right">
                      <Control
                        className="has-text-right"
                        style={{ width: "100%" }}
                      >
                        <Button
                          isColor="primary"
                          onClick={this.changePassword}
                          isLoading={this.state.isLoading}
                          disabled={
                            this.isFormInvalid() || this.state.isLoading
                          }
                        >
                          <i className="fas fa-save" />
                          &nbsp;Salva
                        </Button>
                        &nbsp;
                        <Button
                          onClick={this.props.callback}
                          disabled={this.state.isLoading}
                        >
                          <i className="fas fa-times-circle" />
                          &nbsp;Annulla
                        </Button>
                      </Control>
                    </Field>
                  </Column>
                </Columns>
              </form>
            </div>
          </ModalCardBody>
        </ModalCard> */}
      </Modal>
    );
  }
}

export default withUserCtx(ChangePassword);
