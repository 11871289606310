export default class DateUtils {
  static timestampToDate(ts) {
    if (ts) {
      if (ts.toDate) {
        return ts.toDate();
      } else {
        return new Date(ts._seconds * 1000);
      }
    }
    return null;
  }

  static timestampToISOString(ts) {
    const date = DateUtils.timestampToDate(ts);
    if (date) return date.toISOString();
    else return "";
  }
}
