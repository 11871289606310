import React, { Component } from "react";
import firebase from "firebase/app";
import { Columns } from "bloomer/lib/grid/Columns";
import { Column } from "bloomer";
import * as qs from "query-string";
import Loader from "../../components/Loader";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight, faChevronRight } from "@fortawesome/free-solid-svg-icons";

export default class MCombinazioni extends Component {
  state = {
    combinations: [],
    currentSlide: 0,
    loading: true
  };

  async loadCombinations() {
    const fomrulaId = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    }).formula;

    const formulaSnap = await firebase
      .firestore()
      .collection("recipes")
      .doc(fomrulaId)
      .get();

    const formula = formulaSnap.data();

    console.log("AA formula", formula);
    const elements = [];
    formula.elements.forEach(e => {
      elements.push(e.uid);
    });

    elements.length = 3;
    console.log("AA elements", elements);

    const combinations = [];
    const comboSnap = await firebase
      .firestore()
      .collection("combinations")
      .where("category.uid", "==", formula.categories[0].uid)
      .get();

    comboSnap.forEach(c => {
      const combo = c.data();

      let verified = true;
      combo.elements.forEach(e => {
        if (verified && !elements.includes(e.uid)) verified = false;
      });

      if (verified) combinations.push(combo);
    });

    console.log("AA combinations", combinations);

    this.setState({ combinations, formula, loading: false });
  }

  async componentDidMount() {
    this.loadCombinations();
  }

  buttonPressed() {
    var slide = this.state.currentSlide + 1;
    slide = slide % this.state.combinations.length;
    console.log("AA button pressed", slide);

    this.slider.slickGoTo(slide);
    this.setState({ currentSlide: slide, rotate: true });
  }

  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    if (this.state.loading)
      return (
        <div style={{ background: "#4D6A76", height: "100vh", justifyContent: "center", paddingTop: "10%" }}>
          <Loader white />
        </div>
      );
    return (
      <div>
        <div className="vw-tablet">
          <div className="combo-riepilogo-container">
            {this.state.combinations.map(c => {
              return (
                <div className="combo-riepilogo">
                  <h1 className="mb-20" style={{ fontWeight: "bold", textTransform: "uppercase", fontSize: "22px" }}>
                    {c.elements[0].name}
                  </h1>
                  <h3 style={{ fontSize: "20px", lineHeight: "1.2" }}>{c.description}</h3>
                </div>
              );
            })}
          </div>
        </div>
        <div className="vw-mobile-only combo-riepilogo-container">
          <div className="div-freccia" onClick={() => this.buttonPressed()}></div>
          <FontAwesomeIcon icon={faChevronCircleRight} className="freccia-sinistra" />
          <Slider ref={slider => (this.slider = slider)} {...settings} style={{ height: "500px" }}>
            {this.state.combinations.map(c => {
              return (
                <div className="combo-riepilogo">
                  <h1 className="mb-20" style={{ fontWeight: "bold", textTransform: "uppercase", fontSize: "22px" }}>
                    {c.elements[0].name}
                  </h1>
                  <h3 style={{ fontSize: "20px", lineHeight: "1.2" }}>{c.description}</h3>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    );
  }
}
