import React from "react";
import { Button, Column, Columns, Field, Label, Select } from "bloomer";
import { Decimal } from "decimal.js";
import { withCartCtx } from "../../contexts/CartContext";
import OrderItemKind from "../../enums/OrderItemKind";
import { withModalsCtx } from "../../contexts/ModalsContext";
import { withRouter } from "react-router";
import firebase from "firebase/app";

class MFormulaOrder extends React.Component {
  state = {
    options: [],
    selected: 14
  };

  constructor(props) {
    super(props);
    this.addToCart = this.addToCart.bind(this);
    this.updateDaysToOrder = this.updateDaysToOrder.bind(this);
  }

  componentDidMount() {
    const options = [];
    for (let i = 14; i <= 60; i++) {
      const months = Math.trunc(i / 14);
      const operator = i % 14 === 0 ? "~" : ">";
      const text = months === 1 ? "mese" : "mesi";
      options.push({
        label: `${i} allenamenti - ${new Decimal(this.props.formula.dailyPrice)
          .times(i)
          .toDecimalPlaces(2)
          .toString()}€ (${operator} ${months} ${text})`,
        value: i
      });
    }
    this.setState({ options: options });
  }

  updateDaysToOrder(event) {
    this.setState({ selected: event.target.value });
    this.props.updateDaysToOrder(event.target.value);
  }

  addToCart() {
    this.setState({ loadingCart: true });
    this.props.cart.addElementToCart(this.props.formula.uid, OrderItemKind.FORMULA, this.state.selected, err => {
      this.setState({ loadingCart: false });
      if (err) this.props.modals.addError(`C'è stato un errore aggiungendo la formula nel carrello`);
      else this.props.history.push(`/formula/${this.props.formula.uid}/flavours`);
    });
  }

  renderPriceLabel() {
    return (
      <h6 className="title">
        Costo formula
        <br className="vw-tablet" /> giornaliera
        <span style={{ lineHeight: "15px" }} className="has-text-18 is-pulled-right">
          {this.props.formula.dailyPrice}€
        </span>
      </h6>
    );
  }

  renderRangeSelect() {
    return (
      <Field className="input-field is-primary no-m">
        <Label>Ordina per</Label>
        <Select name="daysToOrder" onChange={this.updateDaysToOrder}>
          {this.state.options.map(o => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </Select>
      </Field>
    );
  }

  renderImage() {
    return (
      <div className="packaging-formula-order">
        <img src="https://assets.mylabnutrition.net/images/pack_bustine_d.png" alt="" />
      </div>
    );
  }

  renderActions() {
    const { hideAllButtons } = this.props;
    return (
      <div>
        <Button isLoading={this.state.loadingCart} onClick={this.addToCart} className="is-fullwidth">
          Aggiungi al&nbsp;
          <br className="vw-mobile-only" />
          carrello
        </Button>
        {!!hideAllButtons && <img className="secure-payment" src="/images/payment-icons.png" />}
        {!hideAllButtons && !this.props.shared ? (
          <Button className="is-fullwidth is-transparent is-outlined mt-15" onClick={this.props.openSaveFormulaModal}>
            {this.props.formula.name && this.props.formula.name.length && this.props.formula.status !== "temp" ? "Modifica nome pack" : "Salva formula"}
          </Button>
        ) : null}
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="is-primary-to-light ptb-12 unwrap m-formula-order-box vw-mobile-only">
          <Columns>
            <Column>{this.renderPriceLabel()}</Column>
            <Column>{this.renderRangeSelect()}</Column>
            <Column>
              <Columns isMobile>
                <Column>{this.renderImage()}</Column>
                <Column isSize="narrow" className="no-p-tb">
                  {this.renderActions()}
                </Column>
              </Columns>
            </Column>
          </Columns>
        </div>
        <div className="is-primary-to-light ptb-12 unwrap m-formula-order-box vw-tablet">
          <Columns isMultiline>
            <Column isSize="full" className="img-flip-h">
              {this.renderImage()}
            </Column>
            <Column isSize="full">{this.renderPriceLabel()}</Column>
            <Column isSize="full">{this.renderRangeSelect()}</Column>
            <Column isSize="full">{this.renderActions()}</Column>
          </Columns>
        </div>
      </div>
    );
  }
}

export default withRouter(withModalsCtx(withCartCtx(MFormulaOrder)));
