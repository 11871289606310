import React, { Component } from "react";
import { Container, Modal, ModalBackground, ModalCard, ModalCardBody, ModalCardFooter, ModalCardHeader, ModalCardTitle } from "bloomer";
import { withRouter } from "react-router-dom";

class BaseModal extends Component {
  state = {
    isActive: true
  };

  constructor(props) {
    super(props);
    this.onEnd = this.onEnd.bind(this);
  }

  onEnd(result) {
    this.props.callback(this.props.id, result);
  }

  render() {
    return (
      <Modal className="has-text-centered is-small" isActive={this.state.isActive}>
        <ModalBackground onClick={this.onEnd("background-click")} />
        <ModalCard>
          <ModalCardHeader>
            <Container>
              <ModalCardTitle>{this.props.title}</ModalCardTitle>
            </Container>
          </ModalCardHeader>
          <ModalCardBody>
            <Container>{this.props.children}</Container>
          </ModalCardBody>
          <ModalCardFooter>
            {this.props.buttons.map((btn, idx) => (
              <button key={idx} className={btn.className} onClick={() => this.onEnd(btn.result)}>
                {btn.label}
              </button>
            ))}
          </ModalCardFooter>
        </ModalCard>
      </Modal>
    );
  }
}

export default withRouter(BaseModal);
