export default {
  data: [
    {
      description: "In quali tra queste problematiche riferite alla tua pelle o i tuoi capelli ti ritrovi?",
      percentage: 30,
      questions: [
        {
          formName: "target",
          fullwidth: true,
          kind: "radiobox2",
          order: 1,
          values: [
            { label: "Perdita di capelli o Alopecia", value: "perdita_capelli" },
            { label: "Invecchiamento cellulare", value: "invecchiamento_cellulare" },
            { label: "Acne", value: "acne" },
            { label: "Afte", value: "afte" },
            { label: "Psoriasi", value: "psoriasi" },
            { label: "Scarsa crescita ungueale", value: "ungueale" },
            { label: "Infezioni della cute", value: "infezione_cute" },
            { label: "Dermatite seborroica", value: "dermatite_seborroica" },
            { label: "Dermatite atopica", value: "dermatite_atopica" }
          ]
        }
      ],
      uid: 0
    },
    ////////////////// PERDITA CAPELLI //////////////////////
    {
      condition: [{ formName: "target", value: ["perdita_capelli"] }],

      description: "Hai famigliari che hanno sofferto di questo problema?",
      percentage: 40,
      questions: [
        {
          formName: "famigliari",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "famigliari_si" },
            { label: "No", value: "famigliari_no" }
          ]
        }
      ],
      uid: 1
    },
    {
      condition: [{ formName: "target", value: ["perdita_capelli"] }],

      description: "Perdi capelli?",
      percentage: 50,
      questions: [
        {
          formName: "perdi_capelli",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Solo in alcuni periodi", value: "perdi_capelli_no" },
            { label: "Durante tutto l'anno", value: "perdi_capelli_si" }
          ]
        }
      ],
      uid: 2
    },
    {
      condition: [{ formName: "target", value: ["perdita_capelli"] }],

      description: "Integri con costanza vitamine e minerali?",
      percentage: 60,
      questions: [
        {
          formName: "integri_vitamine",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "integri_vitamine_si" },
            { label: "No", value: "integri_vitamine_no" }
          ]
        }
      ],
      uid: 3
    },
    {
      condition: [{ formName: "target", value: ["perdita_capelli"] }],

      description: "Ti senti particolarmente stressato/a?",
      percentage: 70,
      questions: [
        {
          formName: "capelli_stress",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "capelli_stress_si" },
            { label: "No", value: "capelli_stress_no" }
          ]
        }
      ],
      uid: 4
    },
    {
      condition: [{ formName: "target", value: ["perdita_capelli"] }],

      description: "Fai uso di farmaci?",
      percentage: 80,
      questions: [
        {
          formName: "capelli_uso_farmaci",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "capelli_uso_farmaci_si" },
            { label: "No", value: "capelli_uso_farmaci_no" },
            { label: "A volte", value: "capelli_uso_farmaci_a_volte" }
          ]
        }
      ],
      uid: 5
    },
    ////////////////// INVECCHIAMENTO CELLULARE //////////////////////
    {
      condition: [{ formName: "target", value: ["invecchiamento_cellulare"] }],

      description: "Ti senti di avere più anni di quelli che hai?",
      percentage: 40,
      questions: [
        {
          formName: "piu_anni",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "piu_anni_si" },
            { label: "No", value: "piu_anni_no" }
          ]
        }
      ],
      uid: 6
    },
    {
      condition: [{ formName: "target", value: ["invecchiamento_cellulare"] }],

      description: "Fai uso di integratori che contengono antiossidanti?",
      percentage: 60,
      questions: [
        {
          formName: "antiossidanti",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "antiossidanti_si" },
            { label: "No", value: "antiossidanti_no" }
          ]
        }
      ],
      uid: 7
    },
    {
      condition: [{ formName: "target", value: ["invecchiamento_cellulare"] }],

      description: "Ti nutri con alimenti ricchi di sostanze antiossidanti?",
      percentage: 80,
      questions: [
        {
          formName: "alimenti_antiossidanti",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "alimenti_antiossidanti_si" },
            { label: "No", value: "alimenti_antiossidanti_no" },
            { label: "A volte", value: "alimenti_antiossidanti_a_volte" }
          ]
        }
      ],
      uid: 8
    },
    ////////////////// ACNE //////////////////////
    {
      condition: [{ formName: "target", value: ["acne"] }],

      description: "Qualche tuo familiare ha sofferto di acne da giovane?",
      percentage: 40,
      questions: [
        {
          formName: "acne_giovane",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "acne_giovane_si" },
            { label: "No", value: "acne_giovane_no" }
          ]
        }
      ],
      uid: 9
    },
    {
      condition: [{ formName: "target", value: ["acne"] }],

      description: "Soffri di squilibri ormonali?",
      percentage: 50,
      questions: [
        {
          formName: "squilibri_ormonali",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "squilibri_ormonali_si" },
            { label: "No", value: "squilibri_ormonali_no" },
            { label: "Non lo so", value: "squilibri_ormonali_a_volte" }
          ]
        }
      ],
      uid: 10
    },
    {
      condition: [{ formName: "target", value: ["acne"] }],

      description: "Segui una dieta appropriata?",
      percentage: 70,
      questions: [
        {
          formName: "dieta_appropriata",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "dieta_appropriata_si" },
            { label: "No", value: "dieta_appropriata_no" }
          ]
        }
      ],
      uid: 11
    },
    {
      condition: [{ formName: "target", value: ["acne"] }],

      description: "Le pustole sono puriginose?",
      percentage: 80,
      questions: [
        {
          formName: "pustole",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "pustole_si" },
            { label: "No", value: "pustole_no" },
            { label: "A volte", value: "pustole_a_volte" }
          ]
        }
      ],
      uid: 12
    },
    ////////////////// AFTE //////////////////////
    {
      condition: [{ formName: "target", value: ["afte"] }],

      description: "Il problema si manifesta più volte durante l'anno?",
      percentage: 40,
      questions: [
        {
          formName: "afte_anno",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "afte_anno_si" },
            { label: "No", value: "afte_anno_no" }
          ]
        }
      ],
      uid: 13
    },
    {
      condition: [{ formName: "target", value: ["afte"] }],

      description: "Fai abuso di farmaci?",
      percentage: 50,
      questions: [
        {
          formName: "afte_abuso_farmaci",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "afte_abuso_farmaci_si" },
            { label: "No", value: "afte_abuso_farmaci_no" }
          ]
        }
      ],
      uid: 14
    },
    {
      condition: [{ formName: "target", value: ["afte"] }],

      description: "Soffri di squilibri ormonali?",
      percentage: 60,
      questions: [
        {
          formName: "afte_squilibri_ormonali",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "afte_squilibri_ormonali_si" },
            { label: "No", value: "afte_squilibri_ormonali_no" }
          ]
        }
      ],
      uid: 15
    },
    {
      condition: [{ formName: "target", value: ["afte"] }],

      description: "Assumi periodicamente vitamine e minerali?",
      percentage: 80,
      questions: [
        {
          formName: "afte_vitamine",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "afte_vitamine_si" },
            { label: "No", value: "afte_vitamine_no" }
          ]
        }
      ],
      uid: 16
    },
    ////////////////// PSORIASI //////////////////////
    {
      condition: [{ formName: "target", value: ["psoriasi"] }],

      description: "Hai qualche familiare che soffre di questo disturbo?",
      percentage: 40,
      questions: [
        {
          formName: "psoriasi_familiare",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "psoriasi_familiare_si" },
            { label: "No", value: "psoriasi_familiare_no" }
          ]
        }
      ],
      uid: 17
    },
    {
      condition: [{ formName: "target", value: ["psoriasi"] }],

      description: "Fai abuso di fumo e alcool?",
      percentage: 50,
      questions: [
        {
          formName: "abuso_alcool",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "abuso_alcool_si" },
            { label: "No", value: "abuso_alcool_no" }
          ]
        }
      ],
      uid: 18
    },
    {
      condition: [{ formName: "target", value: ["psoriasi"] }],

      description: "Soffri di colesterolo alto?",
      percentage: 60,
      questions: [
        {
          formName: "colesterolo",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "colesterolo_si" },
            { label: "No", value: "colesterolo_no" }
          ]
        }
      ],
      uid: 19
    },
    {
      condition: [{ formName: "target", value: ["psoriasi"] }],

      description: "Soffri di malattie infiammatorie intestinali?",
      percentage: 80,
      questions: [
        {
          formName: "malattie_infiammatorie",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "malattie_infiammatorie_si" },
            { label: "No", value: "malattie_infiammatorie_no" }
          ]
        }
      ],
      uid: 20
    },
    ////////////////// UNGHIE  //////////////////////
    {
      condition: [{ formName: "target", value: ["ungueale"] }],

      description: "Noti delle macchie sulle unghie?",
      percentage: 40,
      questions: [
        {
          formName: "macchie_unghie",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "macchie_unghie_si" },
            { label: "No", value: "macchie_unghie_no" }
          ]
        }
      ],
      uid: 21
    },
    {
      condition: [{ formName: "target", value: ["ungueale"] }],

      description: "Le tue unghie si sfaldano facilmente?",
      percentage: 50,
      questions: [
        {
          formName: "unghie_sfaldano",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "unghie_sfaldano_si" },
            { label: "No", value: "unghie_sfaldano_no" }
          ]
        }
      ],
      uid: 22
    },
    {
      condition: [{ formName: "target", value: ["ungueale"] }],

      description: "Hai difficoltà nella crescita?",
      percentage: 60,
      questions: [
        {
          formName: "unghie_diff_crescita",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "unghie_diff_crescita_si" },
            { label: "No", value: "unghie_diff_crescita_no" }
          ]
        }
      ],
      uid: 23
    },
    {
      condition: [{ formName: "target", value: ["ungueale"] }],

      description: "Soffri spesso di infezioni in generale?",
      percentage: 80,
      questions: [
        {
          formName: "infezioni_generale",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "infezioni_generale_si" },
            { label: "No", value: "infezioni_generale_no" }
          ]
        }
      ],
      uid: 24
    },
    ////////////////// INFEZIONE CUTE  //////////////////////
    {
      condition: [{ formName: "target", value: ["infezione_cute"] }],

      description: "Riscontri con frequenza macchie cutanee a cui non sai dare una risposta?",
      percentage: 40,
      questions: [
        {
          formName: "macchie_cutanee",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "macchie_cutanee_si" },
            { label: "No", value: "macchie_cutanee_no" }
          ]
        }
      ],
      uid: 25
    },
    {
      condition: [{ formName: "target", value: ["infezione_cute"] }],

      description: "Senti spesso bruciori cutanei senza un'apparente causa?",
      percentage: 70,
      questions: [
        {
          formName: "bruciori_cutanei",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "bruciori_cutanei_si" },
            { label: "No", value: "bruciori_cutanei_no" }
          ]
        }
      ],
      uid: 26
    },
    ////////////////// DERMATITE SEBORROICA  //////////////////////
    {
      condition: [{ formName: "target", value: ["dermatite_seborroica"] }],

      description: "Hai famigliari che hanno sofferto di questo problema?",
      percentage: 40,
      questions: [
        {
          formName: "famigliari_dermatite_seborroica",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "famigliari_dermatite_seborroica_si" },
            { label: "No", value: "famigliari_dermatite_seborroica_no" }
          ]
        }
      ],
      uid: 27
    },
    {
      condition: [{ formName: "target", value: ["dermatite_seborroica"] }],

      description: "Perdi capelli?",
      percentage: 50,
      questions: [
        {
          formName: "dermatite_seborroica_perdita_capelli",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Solo in alcuni periodi", value: "dermatite_seborroica_perdita_capelli_no" },
            { label: "Durante tutto l'anno", value: "dermatite_seborroica_perdita_capelli_si" }
          ]
        }
      ],
      uid: 28
    },
    {
      condition: [{ formName: "target", value: ["dermatite_seborroica"] }],

      description: "Integri con costanza vitamine e minerali?",
      percentage: 60,
      questions: [
        {
          formName: "dermatite_seborroica_vitamine",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "dermatite_seborroica_vitamine_si" },
            { label: "No", value: "dermatite_seborroica_vitamine_no" }
          ]
        }
      ],
      uid: 29
    },
    {
      condition: [{ formName: "target", value: ["dermatite_seborroica"] }],

      description: "Ti senti particolarmente stressato/a?",
      percentage: 70,
      questions: [
        {
          formName: "dermatite_seborroica_stress",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "dermatite_seborroica_stress_si" },
            { label: "No", value: "dermatite_seborroica_stress_no" }
          ]
        }
      ],
      uid: 30
    },
    {
      condition: [{ formName: "target", value: ["dermatite_seborroica"] }],

      description: "Fai uso di farmaci?",
      percentage: 80,
      questions: [
        {
          formName: "dermatite_seborroica_farmaci",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "dermatite_seborroica_farmaci_si" },
            { label: "No", value: "dermatite_seborroica_farmaci_no" },
            { label: "A volte", value: "dermatite_seborroica_farmaci_a_volte" }
          ]
        }
      ],
      uid: 31
    },
    ////////////////// DERMATITE ATOPICA  //////////////////////
    {
      condition: [{ formName: "target", value: ["dermatite_atopica"] }],

      description: "Hai famigliari che hanno sofferto di questo problema?",
      percentage: 40,
      questions: [
        {
          formName: "famigliari_dermatite_atopica",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "famigliari_dermatite_atopica_si" },
            { label: "No", value: "famigliari_dermatite_atopica_no" }
          ]
        }
      ],
      uid: 32
    },
    {
      condition: [{ formName: "target", value: ["dermatite_atopica"] }],

      description: "Perdi capelli?",
      percentage: 50,
      questions: [
        {
          formName: "dermatite_atopica_perdita_capelli",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Solo in alcuni periodi", value: "dermatite_atopica_perdita_capelli_no" },
            { label: "Durante tutto l'anno", value: "dermatite_atopica_perdita_capelli_si" }
          ]
        }
      ],
      uid: 33
    },
    {
      condition: [{ formName: "target", value: ["dermatite_atopica"] }],

      description: "Integri con costanza vitamine e minerali?",
      percentage: 60,
      questions: [
        {
          formName: "dermatite_atopica_vitamine",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "dermatite_atopica_vitamine_si" },
            { label: "No", value: "dermatite_atopica_vitamine_no" }
          ]
        }
      ],
      uid: 34
    },
    {
      condition: [{ formName: "target", value: ["dermatite_atopica"] }],

      description: "Ti senti particolarmente stressato/a?",
      percentage: 70,
      questions: [
        {
          formName: "dermatite_atopica_stress",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "dermatite_atopica_stress_si" },
            { label: "No", value: "dermatite_atopica_stress_no" }
          ]
        }
      ],
      uid: 35
    },
    {
      condition: [{ formName: "target", value: ["dermatite_atopica"] }],

      description: "Fai uso di farmaci?",
      percentage: 80,
      questions: [
        {
          formName: "dermatite_atopica_farmaci",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "dermatite_atopica_farmaci_si" },
            { label: "No", value: "dermatite_atopica_farmaci_no" },
            { label: "A volte", value: "dermatite_atopica_farmaci_a_volte" }
          ]
        }
      ],
      uid: 36
    }
  ]
};
