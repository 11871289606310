import firebase from "firebase/app";
import "./scss/custom.css";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/messaging";
import "firebase/functions";
import Utils from "./Utils";

let config = {
  apiKey: "AIzaSyBf6RO9Eb01_5YpBdchhHIel78q3kRyFs0",
  authDomain: "mylab-nutrition-1521713952210.firebaseapp.com",
  databaseURL: "https://mylab-nutrition-1521713952210.firebaseio.com",
  projectId: "mylab-nutrition-1521713952210",
  storageBucket: "mylab-nutrition-1521713952210.appspot.com",
  messagingSenderId: "731518269030"
};
const firebaseApp = firebase.initializeApp(config);
// firebaseApp.firebase_.firestore().settings({ /* your settings... */ timestampsInSnapshots: true });

firebase
  .firestore()
  .enablePersistence({ experimentalTabSynchronization: true })
  .then(() => {
    Utils.log("Enabled local persistance");
  })
  .catch(function(err) {
    Utils.handleError(err);
    console.error("cannot do local persistance", err);
    if (err.code === "failed-precondition") {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code === "unimplemented") {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });

window.firebase = firebase;

try {
  let app = window.firebase.app();
  let features = ["auth", "database", "messaging", "storage"].filter(feature => typeof app[feature] === "function");
  Utils.log(`Firebase SDK loaded with ${features.join(", ")}`);
} catch (e) {
  console.error("Error loading the Firebase SDK, check the console.", e);
}
