export default {
  data: [
    {
      description: "In quali tra queste problematiche legate al controllo del peso corporeo ti rispecchi di più?",
      percentage: 30,
      questions: [
        {
          formName: "target",
          fullwidth: true,
          kind: "radiobox2",
          order: 1,
          values: [
            { label: "Sovrappeso", value: "sovrappeso" },
            { label: "Ritenzione idrica", value: "ritenzione" },
            { label: "Ipotiroidismo", value: "ipotiroidismo" },
            { label: "Metabolismo lento", value: "metabolismo_lento" }
          ]
        }
      ],
      uid: 0
    },
    //////////////////  SOVRAPPESO //////////////////////
    {
      condition: [{ formName: "target", value: ["sovrappeso"] }],

      description: "Hai dei chili in eccesso rispetto al tuo peso forma?",
      percentage: 40,
      questions: [
        {
          formName: "chili_eccesso",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Più di 3", value: "chili_eccesso_3" },
            { label: "Meno di 10", value: "chili_eccesso_10" },
            { label: "Oltre i 10", value: "chili_eccesso_piu_10" }
          ]
        }
      ],
      uid: 1
    },
    {
      condition: [{ formName: "target", value: ["sovrappeso"] }],

      description: "Soffri di malattie metaboliche?",
      percentage: 50,
      questions: [
        {
          formName: "malattie_metaboliche",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "malattie_metaboliche_si" },
            { label: "No", value: "malattie_metaboliche_no" },
            { label: "Non saprei", value: "malattie_metaboliche_non_so" }
          ]
        }
      ],
      uid: 2
    },
    {
      condition: [{ formName: "target", value: ["sovrappeso"] }],

      description: "Ti alimenti in maniera scorretta?",
      percentage: 60,
      questions: [
        {
          formName: "alimentazione_scorretta",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "alimentazione_scorretta_si" },
            { label: "No", value: "alimentazione_scorretta_no" },
            { label: "Ogni tanto", value: "alimentazione_scorretta_ogni_tanto" }
          ]
        }
      ],
      uid: 3
    },
    {
      condition: [{ formName: "target", value: ["sovrappeso"] }],

      description: "Fai una buona colazione?",
      percentage: 70,
      questions: [
        {
          formName: "colazione",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "colazione_si" },
            { label: "Solo caffè", value: "colazione_caffe" },
            { label: "No", value: "colazione_no" }
          ]
        }
      ],
      uid: 4
    },
    {
      condition: [{ formName: "target", value: ["sovrappeso"] }],

      description: "Svolgi attività sportiva regolarmente?",
      percentage: 75,
      questions: [
        {
          formName: "peso_corporeo_attivita_sportiva",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "peso_corporeo_attivita_sportiva_si" },
            { label: "No", value: "peso_corporeo_attivita_sportiva_no" },
            { label: "Di rado", value: "peso_corporeo_attivita_sportiva_di_rado" }
          ]
        }
      ],
      uid: 5
    },
    {
      condition: [{ formName: "target", value: ["sovrappeso"] }],

      description: "Usi prodotti per dimagrire l'estate?",
      percentage: 80,
      questions: [
        {
          formName: "prodotti_dimagranti",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "prodotti_dimagranti_si" },
            { label: "No", value: "prodotti_dimagranti_no" },
            { label: "Solo prima che inizi", value: "prodotti_dimagranti_prima_estate" }
          ]
        }
      ],
      uid: 6
    },
    //////////////////  RITENZIONE IDRICA //////////////////////
    {
      condition: [{ formName: "target", value: ["ritenzione"] }],

      description: "In che zona del corpo hai più ristagni di liquidi?",
      percentage: 40,
      questions: [
        {
          formName: "ristagni",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Addome", value: "ristagni_addome" },
            { label: "Cosce", value: "ristagni_cosce" },
            { label: "Glutei", value: "ristagni_glutei" },
            { label: "Braccia", value: "ristagni_braccia" }
          ]
        }
      ],
      uid: 7
    },
    {
      condition: [{ formName: "target", value: ["ritenzione"] }],

      description: "Svolgi attività fisica regolarmente?",
      percentage: 50,
      questions: [
        {
          formName: "ritenzione_attivita_fisica",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "ritenzione_attivita_fisica_si" },
            { label: "No", value: "ritenzione_attivita_fisica_no" },
            { label: "Saltuariamente", value: "ritenzione_attivita_fisica_saltuariamente" }
          ]
        }
      ],
      uid: 8
    },
    {
      condition: [{ formName: "target", value: ["ritenzione"] }],

      description: "Soffri di problemi circolatori?",
      percentage: 60,
      questions: [
        {
          formName: "problemi_circolatori",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "problemi_circolatori_si" },
            { label: "No", value: "problemi_circolatori_no" }
          ]
        }
      ],
      uid: 9
    },
    {
      condition: [{ formName: "target", value: ["ritenzione"] }],

      description: "Bevi acqua durante il giorno in base al tuo fabbisogno?",
      percentage: 80,
      questions: [
        {
          formName: "ritenzione_bevi_acqua",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Sempre", value: "ritenzione_bevi_acqua_si" },
            { label: "Poco", value: "ritenzione_bevi_acqua_no" },
            { label: "Solo d'estate", value: "ritenzione_bevi_acqua_estate" }
          ]
        }
      ],
      uid: 10
    },
    //////////////////  IPOTIROIDISMO //////////////////////
    {
      condition: [{ formName: "target", value: ["ipotiroidismo"] }],

      description: "Hai familiari che soffrono di questo problema?",
      percentage: 40,
      questions: [
        {
          formName: "ipotiroidismo_familiari",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "ipotiroidismo_familiari_si" },
            { label: "No", value: "ipotiroidismo_familiari_no" },
            { label: "Non so", value: "ipotiroidismo_familiari_non_so" }
          ]
        }
      ],
      uid: 11
    },
    {
      condition: [{ formName: "target", value: ["ipotiroidismo"] }],

      description: "Ti senti sempre stanca/o?",
      percentage: 50,
      questions: [
        {
          formName: "ipotiroidismo_stanco",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "ipotiroidismo_stanco_si" },
            { label: "No", value: "ipotiroidismo_stanco_no" },
            { label: "Ogni tanto", value: "ipotiroidismo_stanco_ogni_tanto" }
          ]
        }
      ],
      uid: 12
    },
    {
      condition: [{ formName: "target", value: ["ipotiroidismo"] }],

      description: "Sei sensibile al freddo?",
      percentage: 60,
      questions: [
        {
          formName: "sensibile_freddo",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "sensibile_freddo_si" },
            { label: "No", value: "sensibile_freddo_no" }
          ]
        }
      ],
      uid: 13
    },
    {
      condition: [{ formName: "target", value: ["ipotiroidismo"] }],

      description: "Il ciclo mestruale è regolare?",
      percentage: 70,
      questions: [
        {
          formName: "ciclo_mestruale",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "ciclo_mestruale_si" },
            { label: "No", value: "ciclo_mestruale_no" },
            { label: "Dipende", value: "ciclo_mestruale_dipende" }
          ]
        }
      ],
      uid: 14
    },
    {
      condition: [{ formName: "target", value: ["ipotiroidismo"] }],

      description: "Sei depressa/o?",
      percentage: 75,
      questions: [
        {
          formName: "ipotiroidismo_depresso",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "ipotiroidismo_si" },
            { label: "No", value: "ipotiroidismo_no" },
            { label: "Mi capita spesso", value: "ipotiroidismo_spesso" }
          ]
        }
      ],
      uid: 15
    },
    {
      condition: [{ formName: "target", value: ["ipotiroidismo"] }],

      description: "Hai livelli elevati di colesterolo nel sangue?",
      percentage: 80,
      questions: [
        {
          formName: "colesterolo",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "colesterolo_si" },
            { label: "No", value: "colesterolo_no" }
          ]
        }
      ],
      uid: 16
    },
    //////////////////  METABOLISMO_LENTO //////////////////////
    {
      condition: [{ formName: "target", value: ["metabolismo_lento"] }],

      description: "Pensi di avere il metabolismo lento?",
      percentage: 40,
      questions: [
        {
          formName: "metabolismo_lento_pensi",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "metabolismo_lento_pensi_si" },
            { label: "No", value: "metabolismo_lento_pensi_no" },
            { label: "Non saprei", value: "metabolismo_lento_pensi_non_so" }
          ]
        }
      ],
      uid: 17
    },
    {
      condition: [{ formName: "target", value: ["metabolismo_lento"] }],

      description: "Al risveglio ti senti stanca/o?",
      percentage: 50,
      questions: [
        {
          formName: "metabolismo_lento_risveglio",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "metabolismo_lento_risveglio_si" },
            { label: "No", value: "metabolismo_lento_risveglio_no" },
            { label: "Ogni tanto", value: "metabolismo_lento_risveglio_ogni_tanto" }
          ]
        }
      ],
      uid: 18
    },
    {
      condition: [{ formName: "target", value: ["metabolismo_lento"] }],

      description: "Negli ultimi anni hai preso qualche chilo di troppo nonostante nessun abuso alimentare?",
      percentage: 60,
      questions: [
        {
          formName: "metabolismo_lento_chili",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "metabolismo_lento_chili_si" },
            { label: "No", value: "metabolismo_lento_chili_no" }
          ]
        }
      ],
      uid: 19
    },
    {
      condition: [{ formName: "target", value: ["metabolismo_lento"] }],

      description: "Hai difficoltà nel perdere peso nonostante la dieta e l'esercizio fisico?",
      percentage: 70,
      questions: [
        {
          formName: "metabolismo_lento_dieta",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "metabolismo_lento_dieta_si" },
            { label: "No", value: "metabolismo_lento_dieta_no" },
            { label: "Non riesco a seguire una dieta", value: "metabolismo_lento_dieta_non_riesco" }
          ]
        }
      ],
      uid: 20
    },
    {
      condition: [{ formName: "target", value: ["metabolismo_lento"] }],

      description: "Hai usato in passato preparati per dimagrire?",
      percentage: 75,
      questions: [
        {
          formName: "metabolismo_lento_dieta",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si ma con scarsi risultati", value: "metabolismo_lento_preparati_si" },
            { label: "No", value: "metabolismo_lento_preparati_no" },
            { label: "All'inizio sembrava, poi sono tornata/o come prima", value: "metabolismo_lento_preparati_come_prima" }
          ]
        }
      ],
      uid: 21
    },
    {
      condition: [{ formName: "target", value: ["metabolismo_lento"] }],

      description: "Svolgi attività fisica regolarmente?",
      percentage: 80,
      questions: [
        {
          formName: "metabolismo_lento_attivita_fisica",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "metabolismo_lento_attivita_fisica_si" },
            { label: "No", value: "metabolismo_lento_attivita_fisica_no" },
            { label: "Ogni tanto qualche passeggiata", value: "metabolismo_lento_attivita_fisica_ogni_tanto" }
          ]
        }
      ],
      uid: 22
    }
  ]
};
