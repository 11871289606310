import React from 'react';
import {Control, Field, Help} from "bloomer";
import {withLabCtx} from "../../contexts/LabContext";

class CheckRenderer extends React.Component {

    constructor(props) {
        super(props);

        this.renderCheck = this.renderCheck.bind(this);
        this.addRemoveValue = this.addRemoveValue.bind(this);
    }

    addRemoveValue(value) {
        this.props.addRemoveValue(this.props.question, value);
    }

    render() {
        return <Field className={this.props.className || ''}>
            <Control>
                {this.props.question.title ? <h6 className='title'>{this.props.question.title}</h6> : null}
                {this.props.question.values.map(v =>
                    this.renderCheck(this.props.question.formName,
                        this.props.lab.survey[this.props.question.formName],
                        v.value, v.label, !!v.excludeAll))}
                {this.props.question.subtitle ? <Help>{this.props.question.subtitle}</Help> : null}
            </Control>
        </Field>
    }


    renderCheck(formName, formValue, value, label, excludeAll) {
        return <div key={value} className='has-checkradio'>
            <input className="is-checkradio" id={value} type="checkbox"
                   name={formName} checked={formValue && formValue.indexOf(value) !== -1}
                   onClick={() => this.addRemoveValue(value)} value={value}/>
            <label htmlFor={value}>{label}</label>
        </div>
    }

}

export default withLabCtx(CheckRenderer)
