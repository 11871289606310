import React, { Component } from "react";
import firebase from "firebase/app";
import Utils from "../Utils";
import { Decimal } from "decimal.js";
import OrderItemKind from "../enums/OrderItemKind";
import PaymentMethod from "../enums/PaymentMethod";
import CouponType from "../enums/CouponType";

export const ConfigContext = React.createContext(null);

export const ConfigConsumer = ConfigContext.Consumer;

export class ConfigProvider extends Component {
  state = {};

  observers = {};

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const configObserver = await firebase
      .firestore()
      .collection("config")
      .where("uid", "==", "production")
      .onSnapshot(doc => {
        doc.forEach(c => {
          Utils.log("config", c.data());
          this.setState({
            kitBasic: c.data().kitBasic,
            kitSmart: c.data().kitSmart,
            kitGold: c.data().kitGold,
            proteinCost: c.data().proteinCost,
            commissioni: c.data().commissioni
          });
        });
      });

    this.setState({
      configObserver: configObserver
    });
  }

  componentWillUnmount() {
    if (this.state.configObserver) {
      this.state.configObserver();
      delete this.state.configObserver;
    }
  }

  render() {
    return (
      <ConfigContext.Provider value={this.state}>
        <div>{this.props.children}</div>
      </ConfigContext.Provider>
    );
  }
}

export function withConfigCtx(Component) {
  return props => <ConfigConsumer>{ctx => <Component Config={ctx} {...props} />}</ConfigConsumer>;
}
