import { Decimal } from "decimal.js";
import firebase from "firebase/app";
import DateUtils from "./services/DateUtils";
export default class Utils {
  static getCategoryImage(uid, red) {
    const color = !!red ? "_rosso" : "_green";
    switch (uid) {
      case "e4174496-8e98-417e-a29d-74035c71f61f":
        return "/images/categories/brucia_grassi" + color + ".png";
      case "44e71db1-99cf-4fa6-af09-655692514d0d":
        return "/images/categories/aumento_massa_muscolare" + color + ".png";
      case "b10f6bc3-6bc0-47d6-a07a-eeda8f998eb9":
        return "/images/categories/aumento_resistenza" + color + ".png";
      case "6162173b-42be-4bfb-9618-ce812863d619":
        return "/images/categories/difese_immunitarie" + color + ".png";
      case "52ffc6fa-7ee9-4b30-8016-3caa0652d4ee":
        return "/images/categories/aumento_energia" + color + ".png";
      case "7b551bd3-334c-4f30-954d-0fa545b53f70":
        return "/images/categories/aumento_energia" + color + ".png";
      case "312845cd-c118-4543-b672-129e6d1f4dc1":
        return "/images/categories/articolazioni_ossa" + color + ".png";
      case "a168694d-d20f-446d-89df-30724b48aff1":
        return "/images/categories/recupero_muscolare" + color + ".png";
    }
  }

  static hasResto(a, b) {
    return Math.floor(a / b) !== Utils.mlround(a / b);
  }

  static orderIsValid(o) {
    return o.status != "CART" && o.status !== "PAYMENT_REFUSED" && o.status !== "CANCELED";
  }

  static mlround(i) {
    return Math.round(i * 100) / 100;
  }

  static handleError(e) {
    // return setTimeout(() => {
    console.log("handling error");
    console.error(e);
    if (!e.url || e.url.indexOf("localhost") !== -1)
      if (e instanceof Event) window.dispatchEvent(e);
      else if (e instanceof Error)
        window.dispatchEvent(
          new ErrorEvent("webapp-error", {
            colno: 12,
            error: e,
            filename: "Utils.js",
            lineno: 4,
            message: e.message
          })
        );
      else console.error("handleError: e is not an event", e);
    else console.warn("Running on localhost, error not saved", e);
    // });
  }

  static getWhenToTakeString(w) {
    switch (w) {
      case "MORNING":
        return "Mattina";
      case "BEFORE":
        return "Pre-Workout";
      case "AFTER":
        return "Post-Workout";
      case "EVENING":
        return "Sera";

      default:
        return "MORNING";
    }
  }

  static getWhenToTakeDescription(w) {
    switch (w) {
      case "MORNING":
        return "Assumere 1 bustina di prodotto la mattina dopo aver consumato il primo pasto";
      case "BEFORE":
        return "Assumere 1 bustina di prodotto 15 minuti prima di iniziare il tuo allenamento";
      case "AFTER":
        return "Assumere 1 bustina di prodotto entro 2 ore dopo aver terminato il tuo allenamento";
      case "EVENING":
        return "Assumere 1 bustina di prodotto la sera dopo aver consumato l'ultimo pasto";

      default:
        return "Assumere 1 bustina di prodotto 15 minuti prima di iniziare il tuo allenamento";
    }
  }

  static getElementWhenTotake(e, f) {
    // console.log("AA getElementWhenTotake", f, e.whenToTake);
    if (!e.whenToTake) {
      let category = "";
      if (!!f.categories) category = f.categories[0].uid;

      if (!e.categories) {
        e.whenToTake = "BEFORE";
      } else {
        e.whenToTake = "BEFORE";

        e.categories.forEach(c => {
          if (category === c.uid) {
            console.log("AA getElementWhenTotake whenToTake apply", c.whenToTake);
            e.whenToTake = c.whenToTake || "MORNING";
          }
        });
      }
    }
  }

  static getParameterByName(name, url) {
    if (!url) url = window.location.href;
    // eslint-disable-next-line
    name = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  static getRecipeDailyGrams(recipe) {
    return recipe.elements
      .map(e => new Decimal(e.quantity))
      .reduce((add, curr) => add.add(curr), new Decimal(0))
      .toDecimalPlaces(2)
      .toString();
  }

  static getRecipeTotalSachets(recipe) {
    var totalSachets = new Decimal(0);
    recipe.elements.forEach(e => {
      if (e.sachetGrams) {
        const sachets = new Decimal(e.quantity).dividedBy(e.sachetGrams);
        totalSachets = totalSachets.add(sachets);
      }
    });

    return totalSachets.toDecimalPlaces(2).toString();
  }

  static sortWhenToTake(elements) {
    //Sort elements (MORNING, BEFORE, AFTER, EVENING)
    elements.sort((a, b) => {
      if (a.whenToTake === "MORNING") return -1;
      else if (a.whenToTake === "BEFORE" && b.whenToTake != "MORNING") return -1;
      else if (a.whenToTake === "AFTER" && b.whenToTake != "MORNING" && b.whenToTake != "BEFORE") return -1;
      else return 1;
    });

    console.log("AA elementi", elements);
    return elements;
  }

  static sortWeightOnCategory(elements, category) {
    elements.forEach(e => {
      e.categories.forEach(c => {
        if (c.uid === category) {
          console.log("AA element", e.name, c.weight);

          e.weight = c.weight || 0;
        }
      });
    });

    elements.sort((a, b) => {
      if (a.weight < b.weight) return 1;
      else return -1;
    });

    console.log("AA elementi", elements);
    return elements;
  }

  async getCategories(recipe) {
    const promises = [];

    recipe.elements.forEach(e => {
      promises.push(
        new Promise(async (r, rj) => {
          try {
            const snap = await firebase
              .firestore()
              .collection("elements")
              .doc(e.uid)
              .collection("categories")
              .get();

            const categories = [];
            snap.forEach(c => {
              console.log("AA categori", c.data());
              categories.push(c.data());
            });
            e.categories = categories;

            r(snap);
          } catch (e) {
            rj(e);
          }
        })
      );
    });
    await Promise.all(promises);
  }

  async getRecipeDailyPrice(recipe) {
    const elements = [];
    const promises = [];
    recipe.elements.forEach(e => {
      promises.push(
        new Promise(async (r, rj) => {
          try {
            const snap = await firebase
              .firestore()
              .collection("elements")
              .doc(e.uid)
              .get();
            const data = snap.data();
            const pps = new Decimal(data.sachetPrice);
            const gps = new Decimal(data.sachetGrams);
            const qt = new Decimal(e.quantity);

            const price = qt
              .dividedBy(gps)
              .floor()
              .times(pps)
              .add(qt.modulo(gps).isZero() ? 0 : 1);

            elements.push(price);

            r(price);
          } catch (e) {
            rj(e);
          }
        })
      );
    });
    await Promise.all(promises);

    return elements
      .reduce((acc, curr) => acc.add(curr), new Decimal(0))
      .toDecimalPlaces(2)
      .toFixed(2);
  }

  static toEpoch(date) {
    if (!date) return null;
    const time = date.getTime();
    return new Date(time - (time % 86400000));
  }

  static getCurrentEpoch() {
    return Utils.toEpoch(new Date());
  }

  static dateFilter(data, initDate, currDate) {
    let rData = [];
    // per ogni data, tra la data inizio e la data fine
    for (let d = new Date(initDate.getTime()); d < currDate; d.setDate(d.getDate() + 1)) {
      // inserisci un elemento che abbia come (x) la data
      // e come (y) la somma delle revenue in quella data
      rData.push({
        x: new Date(d.getTime()),
        y: data.filter(e => e.x.getTime() === d.getTime()).reduce((a, b) => Number(a.y || 0) + Number(b.y || 0), 0)
      });
    }
    return rData;
  }

  static getLastMonthEpoch() {
    return Utils.toEpoch(new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000));
  }

  static getLastWeekEpoch() {
    return Utils.toEpoch(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000));
  }

  static calcPerc(value, max) {
    return Math.floor((value / max) * 100 * 100) / 100;
  }

  static decodeRevenueStatus(status) {
    switch (status) {
      case "PAID":
        return "Commissione affiliazione pagata";
      case "CANCELED":
        return "Ordine cliente annullato";
      case "CONFIRMED":
        return "Ordine cliente pagato";
      case "TO_BE_CONFIRMED":
        return "Ordine in processamento";
      default:
        return status;
    }
  }

  static toYYYMMDD(date) {
    if (!date) return null;
    let d = Utils.parseDate(date);
    if (d) return d.toISOString().substr(0, 10);
    else return date;
  }

  static dateToYYYMMDD(date) {
    if (!date) return null;
    try {
      return date.toISOString().substr(0, 10);
    } catch (e) {
      console.log("Wrong date");
    }
  }

  static parseDate(date) {
    DateUtils.timestampToDate(date);
    return null;
  }

  static formatDate(ts) {
    const date = DateUtils.timestampToDate(ts);
    if (date) {
      date.toLocaleDateString();
      return new Intl.DateTimeFormat("it-IT", {
        year: "numeric",
        month: "long",
        day: "2-digit"
      }).format(date);
    } else return "";
  }

  static formatDateTime(ms) {
    let d = new Date(ms);
    if (d) {
      return d.toLocaleString("it-IT", {
        // navigator.languages[0] || navigator.language || "it-IT"
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit"
      });
    }
    return null;
  }

  static msToDate(ms) {
    if (ms && ms > 0) return new Date(ms);
    return null;
  }

  static mapRevenueToXY(e) {
    return {
      x: new Date(e.creation),
      y: e.amount
    };
  }

  static stringToCurrency(s) {
    return (
      s.toLocaleString(undefined, {
        maximumFractionDigits: 2
      }) + " €"
    );
  }

  static fixObj(obj) {
    let newObj = {};
    Object.keys(obj).forEach(prop => {
      if (obj[prop] !== "" || obj[prop] !== "") {
        newObj[prop] = obj[prop];
      }
    });
    return newObj;
  }
  static emptyObj(obj) {
    let newObj = {};
    Object.keys(obj).forEach(prop => {
      newObj[prop] = "";
    });
    return newObj;
  }

  static emailIsVaild(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!!email) {
      const isValid = re.test(String(email).toLowerCase());
      return isValid;
    }
    return false;
  }

  static log(text, ...optionalParams) {
    console.log(text, ...optionalParams);
  }

  static getPaymentType(method) {
    if (method === "STRIPE") {
      return "Carta di credito";
    } else if (method === "PAY_BY_CASH") {
      return "Contrassegno";
    } else if (method === "PAYPAL") {
      return "Paypal";
    }
  }

  static elementIsProtein(e) {
    return e.type.id === "e36bb8cd-06f7-4de4-bd7f-4d02a0fb24f8" || e.type === "e36bb8cd-06f7-4de4-bd7f-4d02a0fb24f8";
  }

  static checkFormulaContainsProteins(f) {
    let contains = false;
    f.elements.slice(0, 5).forEach(e => {
      if (Utils.elementIsProtein(e)) {
        contains = true;
      }
    });
    return contains;
  }

  static getCategory(type) {
    switch (type) {
      case "anti_age":
        return "Anti-Age";
        break;
      case "concentrazione":
        return "Concentrazione per gli Obiettivi";
        break;
      case "depurazione":
        return "Depurazione o Disintossicazione del corpo";
        break;
      case "derma_capelli":
        return "Derma e Capelli";
        break;
      case "difese_immunitarie":
        return "Difese immunitarie";
        break;
      case "difficolta_circolatoria":
        return "Difficoltà Circolatoria";
        break;
      case "diminuzione_grasso":
        return "Diminuzione Massa grassa/Definizione";
        break;
      case "dolori_ossa":
        return "Dolori Ossa, Tendini, Muscoli";
        break;
      case "massa_muscolare":
        return "Massa muscolare";
        break;
      case "peso_corporeo":
        return "Peso Corporeo";
        break;
      case "recupero_energia":
        return "Recupero Veloce";
        break;
      case "resistenza_energia":
        return "Resistenza ed Energia";
        break;
      case "sistema_digestivo":
        return "Sistema Digestivo";
        break;
      case "sistema_nervoso":
        return "Sistema Nervoso";
        break;
      case "urogenitale_femminile":
        return "Apparato urogenitale Femminile";
        break;
      case "urogenitale_maschile":
        return "Apparato urogenitale Maschile";
        break;

      default:
        return "Peso Corporeo";
        break;
    }
  }
}
