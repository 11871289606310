import React from "react";
import { withModalsCtx } from "../../../contexts/ModalsContext";
import { withRouter } from "react-router-dom";
import { Button, Container } from "bloomer";
import { withLabCtx } from "../../../contexts/LabContext";
import MNavbar from "../../../components/mobile/MNavbar";
import MFooter from "../../../components/mobile/footer/MFooter";
import * as qs from "query-string";
import { withUserCtx } from "../../../contexts/UserContext";

import WooCommerceAPI from "react-native-woocommerce-api";
import { Columns } from "bloomer/lib/grid/Columns";
import { Column } from "bloomer/lib/grid/Column";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import RiprendiModal from "../../../modals/RiprendiModal";
import Loader from "../../../components/Loader";

class MLanding extends React.Component {
  state = {
    loaded: false,
    riprendi: false,
    microCategorie: [],
  };

  constructor(props) {
    super(props);
    this.waitQuestionsLoaded = this.waitQuestionsLoaded.bind(this);
  }

  checkAnswerAlreadyDone() {
    const questions = this.props.lab.sorted;
    console.log("AA questions", questions);
    let page = 0;
    let notFound = false;
    questions.forEach((q) => {
      q.questions.forEach((qq) => {
        if (!!this.props.lab.survey[qq.formName] && !notFound) page = q.uid;
        else if (qq.formName != "newsletter") {
          console.log("AA formName", qq.formName, this.props.lab.survey[qq.formName]);

          notFound = true;
        }
      });
    });
    console.log("AA page before", page);

    page = Math.min(page, 16);
    console.log("AA page", page);

    const categoria = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).cat;

    //elimino il vecchio obiettivo
    let surveyData = localStorage.getItem("survey");
    console.log("AA cat survey", surveyData);

    if (!!surveyData) {
      surveyData = JSON.parse(surveyData);
      const cachedCategoria = surveyData.target;
      console.log("AA cat survey exists", surveyData);

      if (!!surveyData.target) {
        console.log("AA cat target exists", surveyData.target);

        delete surveyData.target;
        localStorage.setItem("survey", JSON.stringify(surveyData));
        this.props.lab.removeTarget();
      }
    }

    console.log("AA surve", surveyData);
    const cachedCategoria = localStorage.getItem("consulenzaCategoria");

    if (cachedCategoria == categoria && !!page) {
      this.setState({ riprendi: true, lastPage: page });
    } else if (!!page) {
      this.setState({ riprendi: true, lastPage: page });
    } else {
      this.startConsulenza();
    }

    localStorage.setItem("consulenzaCategoria", categoria);
    localStorage.removeItem("consulenzaFinita");
  }

  componentDidMount() {
    console.log("zz mount");
    this.props.lab.removeQuestions();
    this.setState({
      timer: setTimeout(this.waitQuestionsLoaded, 1000),
    });
  }

  riprendiConsulenza() {
    this.props.history.push("/consulenza/" + this.state.lastPage);
  }

  startConsulenza() {
    this.props.history.push("/consulenza/transizione");
  }

  componentWillUnmount() {
    if (this.state.timer) {
      clearTimeout(this.state.timer);
    }
    if (this.state.timerTwo) {
      clearTimeout(this.state.timerTwo);
    }
  }

  waitQuestionsLoaded() {
    this.setState({
      timerTwo: setTimeout(() => {
        if (!!this.props.lab.sorted) {
          this.setState({ loaded: true });
          this.checkAnswerAlreadyDone();
        } else this.waitQuestionsLoaded();
      }, 1000),
    });
  }

  componentWillReceiveProps(p) {
    const userid = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).userid;

    if (userid) localStorage.setItem("user_cookie", userid);

    const categoria = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).cat;

    console.log("ZZ gcategoria", categoria);
    if (!!p.lab.loadConsulenzaQuestions && (!p.lab.sorted || p.lab.sorted == undefined)) {
      console.log("ZZ vaiiii");
      p.lab.loadConsulenzaQuestions(categoria);
    }

    const microCategorie = this.getMicroCategorie();
    if (!!microCategorie && microCategorie.length > 0) this.setState({ microCategorie });
  }

  getCategoryImage() {
    const categoria = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).cat;

    switch (categoria) {
      case "anti_age":
        return "https://mylabnutrition.net/wp-content/uploads/2021/01/consulenza_anti-age-300x300.png";
        break;
      case "concentrazione":
        return "https://mylabnutrition.net/wp-content/uploads/2021/01/consulenza_concentrazione-per-gli-obiettivi-300x300.png";

        break;
      case "depurazione":
        return "https://mylabnutrition.net/wp-content/uploads/2021/01/consulenza_depurazione-del-corpo-300x300.png";

        break;
      case "derma_capelli":
        return "https://mylabnutrition.net/wp-content/uploads/2021/01/consulenza_derma-e-capelli-300x300.png";

        break;
      case "difese_immunitarie":
        return "https://mylabnutrition.net/wp-content/uploads/2021/01/consulenza_difese-immunitarie-300x300.png";

        break;
      case "difficolta_circolatoria":
        return "https://mylabnutrition.net/wp-content/uploads/2021/01/consulenza_circolazione-300x300.png";

        break;
      case "diminuzione_grasso":
        return "https://mylabnutrition.net/wp-content/uploads/2021/01/consulenza_definizione-300x300.png";

        break;
      case "dolori_ossa":
        return "https://mylabnutrition.net/wp-content/uploads/2021/01/consulenza_ossa-tendini-e-muscoli-300x300.png";
        break;
      case "massa_muscolare":
        return "https://mylabnutrition.net/wp-content/uploads/2021/01/consulenza_massa-muscolare-300x300.png";

        break;
      case "peso_corporeo":
        return "https://mylabnutrition.net/wp-content/uploads/2021/01/consulenza_peso-corporeo-300x300.png";

        break;
      case "recupero_energia":
        return "https://mylabnutrition.net/wp-content/uploads/2021/01/consulenza_recupero-veloce-300x300.png";
        break;
      case "resistenza_energia":
        return "https://mylabnutrition.net/wp-content/uploads/2021/01/consulenza_resistenza-ed-energia-300x300.png";
        break;
      case "sistema_digestivo":
        return "https://mylabnutrition.net/wp-content/uploads/2021/01/consulenza_sistema-digestivo-300x300.png";

        break;
      case "sistema_nervoso":
        return "https://mylabnutrition.net/wp-content/uploads/2021/01/consulenza_sistema-nervoso-300x300.png";
        break;
      case "urogenitale_femminile":
        return "https://mylabnutrition.net/wp-content/uploads/2021/01/consulenza_apparato-urogenitale-femminile-300x300.png";

        break;
      case "urogenitale_maschile":
        return "https://mylabnutrition.net/wp-content/uploads/2021/01/consulenza_apparato-urogenitale-maschile-300x300.png";

        break;

      default:
        return "https://mylabnutrition.net/wp-content/uploads/2021/01/consulenza_sistema-nervoso-300x300.png";
        break;
    }
  }

  getMicroCategorie() {
    const categories = [];
    if (!this.props.lab.sorted) return categories;
    this.props.lab.sorted.forEach((d) => {
      const { questions } = d;
      questions.forEach((q) => {
        if (q.formName == "target") {
          const values = q.values;

          values.forEach((v) => {
            // console.log("AA v", v);

            categories.push(v.label);
          });
        }
      });
    });
    console.log("AA micro", categories);

    return categories;
  }

  goBack() {
    window.top.location.href = "https://mylabnutrition.net/consulenza-categorie";
  }

  render() {
    const c = document.getElementById("container");
    if (!!c) {
      var h = c.offsetHeight;

      var parent_origin = "https://mylabnutrition.net";
      console.log("AA h", h);
      window.parent.postMessage(
        JSON.stringify({
          task: "resize",
          height: h,
        }),
        parent_origin
      );
    }

    const userName = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).username;

    const userId = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).userid;

    const categoria = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).cat;

    const title =
      "Rispondi alle domande della nostra Consulenza Interattiva e al termine, la nostra Intelligenza Artificiale ti suggerirà i prodotti d'integrazione adeguati per aiutarti a raggiungere il tuo obiettivo";

    const claim =
      "Tutti gli integratori sono regolarmente notificati presso il Ministero della Salute.<br/>I risultati di questa consulenza interattiva non costituiscono un parere medico. Questo prodotto non intende curare, trattare o prevenire qualsiasi patologia.<br/>Se si è in gravidanza, in allattamento o si stanno assumendo farmaci in terapia cronica, consultare il proprio medico curante prima di assumere qualsiasi integratore.<br/>Evitare l’assunzione dei prodotti suggeriti in caso di ipersensibilità presunta o accertata verso uno o più dei loro componenti.<br/>Tutti i Claim associati ai nostri prodotti sono ricavati da:<br/>- DM 10 Agosto 2018 Preparati Vegetali<br/>- Regolamento UE n. 432_2012 della Commissione<br/>- Regolamento CE n. 1924_2006 del Parlamento Europeo e del Consiglio";
    return (
      <div id="container">
        {/* <MNavbar /> */}
        {/* {!!this.coupon && <CouponAppliedNotification coupon={this.coupon} />} */}

        <section className="m-consulenza-container green-gradient" style={{ paddingTop: "80px" }}>
          {!this.state.loaded && <Loader white />}

          <Container hasTextAlign="centered" className="landing-consulenza">
            {/* <Button onClick={() => this.goBack()} className="title user-area-title vw-mobile-only arrow-left">
              <img src="/images/consulenza/tasto-indietro-mobile.png" />
            </Button>
            <h1 className="category landing-title" style={{ fontSize: "38px", fontWeight: "bold" }}>
              BENVENUTO
            </h1>
            <h1 className="category" style={{ fontSize: "24px", fontWeight: "bold", marginTop: "-10px" }}>
              nella consulenza di Mylab Nutrition
            </h1>

            <h3 className="mt-40 mb-10 subtitle text-70-perc consulenza-title" style={{ fontSize: "22px", lineHeight: "1.1", fontWeight: "bold" }} dangerouslySetInnerHTML={{ __html: title }} />
            <Columns style={{ width: "fit-content", margin: "auto" }}>
              <Column>
                <div className="landing-lab mtb-20">
                  <img style={{ borderRadius: "45px", maxHeight: "218px" }} src={this.getCategoryImage()} alt="" />
                </div>
              </Column>
            </Columns>

            <div className="mt-20 has-text-mylab has-text-centered">
              {this.state.loaded ? (
                <div>
                  <div>
                    <Button onClick={() => this.props.history.push("/consulenza/transizione")} isColor="primary is-half-width mt-40 mb-20 is-blue" style={{ width: "202px" }}>
                      inizia il test
                    </Button>
                  </div>

                  <div className="ta-l vw-tablet">
                    <Button onClick={() => this.goBack()}>Indietro</Button>
                  </div>
                </div>
              ) : (
                <p className="pulse-animation">Sto caricando le domande...</p>
              )}
            </div>
            <p className="mb-10 subtitle  consulenza-claim" style={{ fontSize: "10px", lineHeight: "1.1" }} dangerouslySetInnerHTML={{ __html: claim }} /> */}

            <RiprendiModal isActive={this.state.riprendi} riprendiConsulenza={() => this.riprendiConsulenza()} closeModal={() => this.startConsulenza()}></RiprendiModal>
          </Container>
        </section>
        {/* <MFooter /> */}
      </div>
    );
  }
}

export default withUserCtx(withLabCtx(withModalsCtx(withRouter(MLanding))));
