import React from "react";
import ReactDOM from "react-dom";
import AppRouter from "./AppRouter";
import "./firebase";
import { default as UserProvider } from "./contexts/UserContext";
import { ModalsProvider } from "./contexts/ModalsContext";
import { BrowserRouter } from "react-router-dom";
import { LabProvider } from "./contexts/LabContext";
import { CartProvider } from "./contexts/CartContext";
import { BusinessProvider } from "./contexts/BusinessContext";
import { StripeProvider } from "react-stripe-elements";
import { OrderProvider } from "./contexts/OrderContext";
import { ConfigProvider } from "./contexts/ConfigContext";
import { ConsulenzaProvider } from "./contexts/ConsulenzaContext";
// import registerServiceWorker, { unregister } from "./registerServiceWorker";
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { fab } from "@fortawesome/free-brands-svg-icons";
// import { far } from "@fortawesome/free-regular-svg-icons";
// import { fas } from "@fortawesome/free-solid-svg-icons";
// import i18n from "./i18n";
// import { I18nextProvider } from "react-i18next";
// import "bulma-extensions/dist/js/bulma-extensions.min";
// import Utils from "./Utils";

// library.add(fab, fas, far);

// fixme on resize check screen and show desktop, mobile or tablet version
// i18n.then(int => {
ReactDOM.render(
  // <I18nextProvider i18n={int}>
  // <StripeProvider apiKey="pk_live_tIwEXEaRh049GXwg27f57STk">
  //   {/* <StripeProvider apiKey="pk_test_f4yNjcETqncVzhMTbcjVDLU4"> */}
  <BrowserRouter>
    <UserProvider>
      {/* <OrderProvider>
        <CartProvider> */}
      <ModalsProvider>
        <LabProvider>
          <ConsulenzaProvider>
            {/* <BusinessProvider>
                <ConfigProvider> */}
            <AppRouter />
            {/* </ConfigProvider>
              </BusinessProvider> */}
          </ConsulenzaProvider>
        </LabProvider>
      </ModalsProvider>
      {/* </CartProvider>
      </OrderProvider> */}
    </UserProvider>
  </BrowserRouter>,
  // </StripeProvider>,
  // </I18nextProvider>,
  document.getElementById("root")
);
// });

// // const unreg = Utils.getParameterByName("clearcache");

// // if (!!unreg && unreg !== "refresh") {
// //   unregister();
// //   window.location.href = getPathFromUrl(window.location.href) + "/?clearcache=refresh";
// // } else if (!!unreg && unreg === "refresh") {
// //   window.location.href = getPathFromUrl(window.location.href);
// // } else {
// //   loadSw();
// // }

// async function loadSw() {
//   try {
//     const snap = await window.firebase
//       .firestore()
//       .collection("updates")
//       .doc("webapp")
//       .get();
//     const data = snap.data();

//     const date = parseInt(localStorage.getItem("serviceWorkerDate") || new Date().getTime(), 10);

//     if (parseInt(data.date, 10) > date) {
//       localStorage.setItem("serviceWorkerDate", "" + (parseInt(data.date, 10) + 5));
//       window.location.href = getPathFromUrl(window.location.href) + "/?clearcache=true";
//     } else {
//       localStorage.setItem("serviceWorkerDate", "" + date);
//       registerServiceWorker();
//     }
//   } catch (e) {
//     registerServiceWorker();
//   }
// }

// function getPathFromUrl(url) {
//   let nUrl = url.split("?")[0];
//   if (nUrl) {
//     while (nUrl.endsWith("/")) {
//       nUrl = nUrl.substring(0, nUrl.length - 1);
//     }
//   }

//   return nUrl;
// }

console.log(
  `
🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥
      
                    ███╗   ███╗██╗   ██╗██╗      █████╗ ██████╗ 
                    ████╗ ████║╚██╗ ██╔╝██║     ██╔══██╗██╔══██╗
                    ██╔████╔██║ ╚████╔╝ ██║     ███████║██████╔╝
                    ██║╚██╔╝██║  ╚██╔╝  ██║     ██╔══██║██╔══██╗
                    ██║ ╚═╝ ██║   ██║   ███████╗██║  ██║██████╔╝
                    ╚═╝     ╚═╝   ╚═╝   ╚══════╝╚═╝  ╚═╝╚═════╝ 
      
        ███╗   ██╗██╗   ██╗████████╗██████╗ ██╗████████╗██╗ ██████╗ ███╗   ██╗
        ████╗  ██║██║   ██║╚══██╔══╝██╔══██╗██║╚══██╔══╝██║██╔═══██╗████╗  ██║
        ██╔██╗ ██║██║   ██║   ██║   ██████╔╝██║   ██║   ██║██║   ██║██╔██╗ ██║
        ██║╚██╗██║██║   ██║   ██║   ██╔══██╗██║   ██║   ██║██║   ██║██║╚██╗██║
        ██║ ╚████║╚██████╔╝   ██║   ██║  ██║██║   ██║   ██║╚██████╔╝██║ ╚████║
        ╚═╝  ╚═══╝ ╚═════╝    ╚═╝   ╚═╝  ╚═╝╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
                                                                            
               -- Powered by Google Firebase and Facebook React --                         
                
🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥`
);
