import React from "react";

export default class Progressbar extends React.Component {
  render() {
    const width = this.props.value + 100;
    const style = {
      "--width": "calc(80px + " + width + "%)"
    };

    return (
      <div className="progressbar is-consulenza">
        {/* <div className="progress-line" /> */}
        <div
          className="percentage-line"
          style={{
            minWidth: this.props.value + 18 + "%",
            maxWidth: this.props.value + 18 + "%"
          }}
        />

        {/* <div
          className="value "
          data-value={this.props.value}
          style={{
            minWidth: this.props.value + "%",
            maxWidth: this.props.value + "%"
          }}
        /> */}
        <div className="is-thumb" style={{ left: this.props.value - 3 + "%" }} />
        <div className="bar" />
        <div className="block-name">{this.props.text}</div>
      </div>
    );
  }
}
