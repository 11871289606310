import React from "react";
import Utils from "../Utils";
import { Button, Column, Columns, Container, Field, Help, Input, Title } from "bloomer";
import Loader from "../components/Loader";
import firebase from "firebase/app";
import { withModalsCtx } from "../contexts/ModalsContext";
import { withRouter } from "react-router-dom";

class Actions extends React.Component {
  state = {
    mode: null,
    oobCode: null,
    continueUrl: null,
    lang: "IT",
    loading: true,
    saving: false,
  };

  constructor(props) {
    super(props);
    this.init = this.init.bind(this);
    this.getBody = this.getBody.bind(this);
    this.getTitle = this.getTitle.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.doResetPassword = this.doResetPassword.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.askResetPassword = this.askResetPassword.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.verifyEmailRender = this.verifyEmailRender.bind(this);
    this.resetPasswordRender = this.resetPasswordRender.bind(this);
    this.recoveryEmailRender = this.recoveryEmailRender.bind(this);
    this.continueAction = this.continueAction.bind(this);
  }

  componentDidMount() {
    this.setState(
      {
        mode: Utils.getParameterByName("mode"),
        oobCode: Utils.getParameterByName("oobCode"),
        apiKey: Utils.getParameterByName("apiKey"),
        continueUrl: Utils.getParameterByName("continueUrl"),
        lang: Utils.getParameterByName("lang") || "IT",
        loading: false,
      },
      this.init
    );
  }

  async init() {
    if (!this.state.mode || (this.state.mode !== "resetPassword" && this.state.mode !== "recoverEmail" && this.state.mode !== "verifyEmail")) window.location.href = "/";

    switch (this.state.mode) {
      case "resetPassword":
        try {
          const mail = await firebase.auth().verifyPasswordResetCode(this.state.oobCode);
          this.setState({ mail: mail });
        } catch (e) {
          console.error("error initializing reset password", e);
          this.props.modals.addError(
            `Il codice per il recupero password non è valido, è scaduto oppure è già stato usato. Ti preghiamo di riprovare ad eseguire una richiesta di recupero password.`,
            null,
            this.continueAction
          );
          Utils.handleError(e);
        }
        break;
      case "recoverEmail": // FIXME
        try {
          const info = await firebase.auth().checkActionCode(this.state.oobCode);
          this.setState({ mail: info["data"]["email"] });
          await firebase.auth().applyActionCode(this.state.oobCode);
          this.setState({ completed: true });
        } catch (e) {
          console.error("error initializing recover mail", e);
          this.props.modals.addError(
            `Il codice per il recupero dell'account non è valido, è scaduto oppure è già stato usato. Ti preghiamo di riprovare ad eseguire la richiesta o a contattare il supporto tecnico.`,
            null,
            this.continueAction
          );
          Utils.handleError(e);
        }

        break;
      case "verifyEmail": // FIXME
        try {
          await firebase.auth().applyActionCode(this.state.oobCode);
          this.props.modals.addMessage(`La tua email è stata confermata! Grazie mille! Puoi ora eseguire il login tranquillamente sul tuo account.`, null, this.continueAction);
        } catch (e) {
          console.error("error initializing reset password", e);
          this.props.modals.addError(
            `Il codice per la verifica email non è valido, è scaduto oppure è già stato usato. Ti preghiamo di riprovare ad eseguire una richiesta di verifica dalla tua pagina account sul sito.`,
            null,
            this.continueAction
          );
          Utils.handleError(e);
        }
        break;
    }
  }

  getTitle() {
    switch (this.state.mode) {
      case "resetPassword":
        return "Reimposta la password";
      case "recoverEmail":
        return "Recupera account";
      case "verifyEmail":
        return "Verifica Email";
      default:
        return this.state.mode;
    }
  }

  onChangeInput(e) {
    const s = {};
    s[e.target.name] = e.target.value;
    s[e.target.name + "Dirty"] = true;
    this.setState(s, this.validatePassword);
  }

  validatePassword() {
    const passwordValid = !!this.state.password && this.state.password.length >= 8;
    const passwordRepeatValid = !!this.state.password && this.state.password === this.state.passwordRepeat;

    this.setState({
      passwordRepeatValid: passwordRepeatValid,
      passwordValid: passwordValid,
    });
  }

  resetPasswordRender() {
    return (
      <div>
        <form name="passwordReset">
          <Field className="input-field">
            <Input
              onChange={this.onChangeInput}
              name="password"
              className={!this.state.passwordValid && this.state.passwordDirty ? "has-error" : ""}
              type={this.state.showPassword ? "text" : "password"}
              placeholder="Nuova password"
            />
            {!this.state.passwordRepeatValid && this.state.passwordRepeatDirty ? (
              <Help isColor="danger">La password non rispetta i criteri</Help>
            ) : (
              <Help isColor="mylab-light-gray">Minimo 8 caratteri</Help>
            )}
          </Field>

          <Field className="input-field">
            <Input
              onChange={this.onChangeInput}
              name="passwordRepeat"
              className={!this.state.passwordRepeatValid && this.state.passwordRepeatDirty ? "has-error" : ""}
              type={this.state.showPassword ? "text" : "password"}
              placeholder="Ripeti nuova password"
            />
            {!this.state.passwordRepeatValid && this.state.passwordRepeatDirty ? <Help isColor="danger">Le password non coincidono</Help> : <Help isColor="mylab-light-gray">Minimo 8 caratteri</Help>}
          </Field>
          <Field>
            <Button onClick={this.doResetPassword} isLoading={this.state.saving} disabled={!this.state.passwordRepeatValid || !this.state.passwordValid} isColor="primary" className="is-fullwidth">
              Cambia Password
            </Button>
          </Field>
        </form>
      </div>
    );
  }

  continueAction() {
    if (this.state.continueUrl) {
      window.location.href = this.state.continueUrl;
    } else this.props.history.push("/");
  }

  async doResetPassword() {
    try {
      this.setState({ saving: true });
      await firebase.auth().confirmPasswordReset(this.state.oobCode, this.state.password);
      this.props.modals.addMessage("La password è stata cambiata con successo");
      await firebase.auth().signInWithEmailAndPassword(this.state.mail, this.state.password);
      this.continueAction();
    } catch (e) {
      console.error("Error changing password: ", e);
      this.props.modals.addError(
        `Il codice per il recupero password è scaduto oppure la password inserita è troppo debole. Ti chiediamo di riavviare la procedura di recupero password.`,
        null,
        this.continueAction
      );
      Utils.handleError(e);
    }
  }

  async askResetPassword() {
    try {
      await firebase.auth().sendPasswordResetEmail(this.state.mail);
      this.props.modals.addMessage(`Ti è stata inviata una mail con le istruzioni per il cambio password, controlla la tua email.`, null, this.continueAction);
    } catch (e) {
      this.props.modals.addError(`C'è stato un problema durante la richiesta, ti preghiamo di riprovare.`);
    }
  }

  recoveryEmailRender() {
    return (
      <div>
        <p>Il tuo account è stato ripristinato per l'utilizzo dell'indirizzo email {this.state.mail}.</p>
        <p>
          Se non sei stato tu a cambiarlo, è possibile che qualcuno sta provando ad accedere al tuo account e abbia scoperto la tua password, ti consigliamo di cambiarla cliccando sul bottone qui
          sotto.
        </p>

        <Button onClick={this.askResetPassword} isColor="primary" className="is-fullwidth">
          Cambia Password
        </Button>
        <p>oppure</p>
        <Button onClick={this.continueAction} className="is-transparent is-fullwidth">
          Continua
        </Button>
      </div>
    );
  }

  verifyEmailRender() {
    return (
      <div>
        <Title>L'email è stata verificata</Title>
      </div>
    );
  }

  getBody() {
    switch (this.state.mode) {
      case "resetPassword":
        return this.resetPasswordRender();
      case "recoverEmail":
        return this.recoveryEmailRender();
      case "verifyEmail":
        return this.verifyEmailRender();
      default:
        return this.state.mode;
    }
  }

  render() {
    if (this.state.loading) return <Loader />;
    return (
      <div className="gray-gradient" style={{ height: "100vh" }}>
        <Container>
          <Columns isMobile>
            <Column>
              <h3 className="title">
                &nbsp;
                {this.getTitle()}
              </h3>
              {this.getBody()}
            </Column>
          </Columns>
        </Container>
      </div>
    );
  }
}

export default withRouter(withModalsCtx(Actions));
