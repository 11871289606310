export default {
  data: [
    {
      description: "Assumi zuccheri e grassi?",
      percentage: 35,
      questions: [
        {
          formName: "assumi_zuccheri",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "assumi_zuccheri_si" },
            { label: "No", value: "assumi_zuccheri_no" },
            { label: "A volte", value: "assumi_zuccheri_a_volte" }
          ]
        }
      ],
      uid: 0
    },
    {
      description: "Mangi pane, pasta e pizza?",
      percentage: 40,
      questions: [
        {
          formName: "pane_pasta_pizza",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "pane_pasta_pizza_si" },
            { label: "No", value: "pane_pasta_pizza_no" },
            { label: "A volte", value: "pane_pasta_pizza_a_volte" }
          ]
        }
      ],
      uid: 1
    },
    {
      description: "Bevi bevande alcoliche?",
      percentage: 43,
      questions: [
        {
          formName: "bevande_alcoliche",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "bevande_alcoliche_si" },
            { label: "No", value: "bevande_alcoliche_no" },
            { label: "A volte", value: "bevande_alcoliche_a_volte" }
          ]
        }
      ],
      uid: 2
    },
    {
      description: "Assumi proteine quotidianamente?",
      percentage: 45,
      questions: [
        {
          formName: "proteine_quotidianamente",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "proteine_quotidianamente_si" },
            { label: "No", value: "proteine_quotidianamente_no" },
            { label: "A volte", value: "proteine_quotidianamente_a_volte" }
          ]
        }
      ],
      uid: 3
    },
    {
      description: "Assumi fibre tutti i giorni?",
      percentage: 48,
      questions: [
        {
          formName: "fibre_tutti_i_giorni",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "fibre_tutti_i_giorni_si" },
            { label: "No", value: "fibre_tutti_i_giorni_no" },
            { label: "A volte", value: "fibre_tutti_i_giorni_a_volte" }
          ]
        }
      ],
      uid: 4
    },
    {
      description: "Bevi acqua con regolarità?",
      percentage: 50,
      questions: [
        {
          formName: "acqua_con_regolarita",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "acqua_con_regolarita_si" },
            { label: "No", value: "acqua_con_regolarita_no" },
            { label: "Non sempre", value: "acqua_con_regolarita_non_sempre" }
          ]
        }
      ],
      uid: 5
    },
    {
      description: "Ti capita di saltare i pasti?",
      percentage: 53,
      questions: [
        {
          formName: "salti_pasti",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "salti_pasti_si" },
            { label: "No", value: "salti_pasti_no" },
            { label: "Non sempre", value: "salti_pasti_non_sempre" }
          ]
        }
      ],
      uid: 6
    },
    {
      description: "Soffri di irregolarità intestinale?",
      percentage: 55,
      questions: [
        {
          formName: "irregolarita_intestinale",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "irregolarita_intestinale_si" },
            { label: "No", value: "irregolarita_intestinale_no" },
            { label: "A volte", value: "irregolarita_intestinale_a_volte" }
          ]
        }
      ],
      uid: 7
    },
    {
      description: "Ti senti spesso gonfio/a?",
      percentage: 58,
      questions: [
        {
          formName: "spesso_gonfio",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "spesso_gonfio_si" },
            { label: "No", value: "spesso_gonfio_no" },
            { label: "A volte", value: "spesso_gonfio_a_volte" }
          ]
        }
      ],
      uid: 8
    },
    {
      description: "Hai molto grasso addominale?",
      percentage: 60,
      questions: [
        {
          formName: "grasso_addominale",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "grasso_addominale_si" },
            { label: "No ma vorrei migliorare", value: "grasso_addominale_no" }
          ]
        }
      ],
      uid: 9
    },
    {
      description: "Hai difficoltà nel perdere grasso addominale?",
      percentage: 63,
      questions: [
        {
          formName: "difficolta_pedere_grasso",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "difficolta_pedere_grasso_si" },
            { label: "No", value: "difficolta_pedere_grasso_no" }
          ]
        }
      ],
      uid: 10
    },
    {
      description: "Segui una dieta specifica?",
      percentage: 65,
      questions: [
        {
          formName: "dieta_specifica",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "dieta_specifica_si" },
            { label: "No", value: "dieta_specifica_no" },
            { label: "A volte", value: "dieta_specifica_a_volte" }
          ]
        }
      ],
      uid: 11
    },
    {
      description: "Fai attività aerobica con continuità?",
      percentage: 68,
      questions: [
        {
          formName: "attivita_aerobica",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "attivita_aerobica_si" },
            { label: "No", value: "attivita_aerobica_no" },
            { label: "A volte", value: "attivita_aerobica_a_volte" }
          ]
        }
      ],
      uid: 12
    },
    {
      description: "Ti alleni con i pesi con una certa frequenza?",
      percentage: 70,
      questions: [
        {
          formName: "allemento_pesi",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "allemento_pesi_si" },
            { label: "No", value: "allemento_pesi_no" }
          ]
        }
      ],
      uid: 13
    },
    {
      description: "Esegui esercizi addominali con una certa frequenza?",
      percentage: 73,
      questions: [
        {
          formName: "esercizi_addominali",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "esercizi_addominali_si" },
            { label: "No", value: "esercizi_addominali_no" },
            { label: "Ogni tanto", value: "esercizi_addominali_ogni_tanto" }
          ]
        }
      ],
      uid: 14
    },
    {
      description: "Fai esercizi di respirazione?",
      percentage: 75,
      questions: [
        {
          formName: "esercizi_respirazione",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "esercizi_respirazione_si" },
            { label: "No", value: "esercizi_respirazione_no" }
          ]
        }
      ],
      uid: 15
    },
    {
      description: "Hai pazienza e costanza per il raggiungimento dei tuoi obiettivi?",
      percentage: 80,
      questions: [
        {
          formName: "pazienza",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "pazienza_si" },
            { label: "No", value: "pazienza_no" },
            { label: "Mi scoraggio in fretta", value: "pazienza_scoraggio" }
          ]
        }
      ],
      uid: 16
    },
    {
      description: "Usi integratori specifici per l'eliminazione del grasso addominale?",
      percentage: 85,
      questions: [
        {
          formName: "integratori_specifici",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "integratori_specifici_si" },
            { label: "No", value: "integratori_specifici_no" },
            { label: "Si ma con pochi risultati", value: "integratori_specifici_pochi_risultati" }
          ]
        }
      ],
      uid: 17
    }
  ]
};
