export default {
  data: [
    {
      description: "Per quali tra queste problematiche vorresti dare un sostegno continuo alle tue difese immunitarie?",
      percentage: 30,
      questions: [
        {
          formName: "target",
          fullwidth: true,
          kind: "radiobox2",
          order: 1,
          values: [
            { label: "Anemia", value: "anemia" },
            { label: "Raffreddori continui", value: "raffreddori" },
            { label: "Condizioni di stress prolungato", value: "stress_prolungato" },
            { label: "Attività sportiva intensa", value: "sport_intenso" },
            // { label: "Prevenzione - Patologie Degenerative", value: "prevenzione" },
            { label: "Stanchezza Cronica", value: "stanchezza_cronica" },
            { label: "Invecchiamento", value: "invecchiamento" }
          ]
        }
      ],
      uid: 0
    },
    //////////////////  ANEMIA //////////////////////
    {
      condition: [{ formName: "target", value: ["anemia"] }],

      description: "Quante volte la settimana assumi alimenti ricchi di ferro?",
      percentage: 40,
      questions: [
        {
          formName: "alimenti_ferro",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "1", value: "alimenti_ferro_1" },
            { label: "Più di 2-3 volte", value: "alimenti_ferro_2" },
            { label: "Quasi mai", value: "alimenti_ferro_mai" }
          ]
        }
      ],
      uid: 1
    },
    {
      condition: [{ formName: "target", value: ["anemia"] }],

      description: "Mangi proteine animali?",
      percentage: 50,
      questions: [
        {
          formName: "proteine_animail",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "proteine_animail_si" },
            { label: "No", value: "proteine_animail_no" },
            { label: "Ogni tanto", value: "proteine_animail_ogni_tanto" }
          ]
        }
      ],
      uid: 2
    },
    {
      condition: [{ formName: "target", value: ["anemia"] }],

      description: "Soffri di tachicardia?",
      percentage: 60,
      questions: [
        {
          formName: "tachicardia",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Spesso", value: "tachicardia_si" },
            { label: "No", value: "tachicardia_no" }
          ]
        }
      ],
      uid: 3
    },
    {
      condition: [{ formName: "target", value: ["anemia"] }],

      description: "Assumi integratori alimentari per sopperire alla tua carenza?",
      percentage: 80,
      questions: [
        {
          formName: "integratori_alimentari",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Spesso", value: "integratori_alimentari_si" },
            { label: "No", value: "integratori_alimentari_no" },
            { label: "Ogni tanto", value: "integratori_alimentari_ogni_tanto" }
          ]
        }
      ],
      uid: 4
    },
    //////////////////  RAFFREDDPRO //////////////////////
    {
      condition: [{ formName: "target", value: ["raffreddori"] }],

      description: "Sei raffreddato/a solo d'inverno?",
      percentage: 40,
      questions: [
        {
          formName: "raffreddato",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "raffreddato_si" },
            { label: "No", value: "raffreddato_no" },
            { label: "Capita anche nelle altre stagioni", value: "raffreddato_altre_stagioni" }
          ]
        }
      ],
      uid: 5
    },
    {
      condition: [{ formName: "target", value: ["raffreddori"] }],

      description: "Starnutisci frequentemente?",
      percentage: 60,
      questions: [
        {
          formName: "starnutisci",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "starnutisci_si" },
            { label: "No", value: "starnutisci_no" }
          ]
        }
      ],
      uid: 6
    },
    {
      condition: [{ formName: "target", value: ["raffreddori"] }],

      description: "Avverti una sensazione di malessere generale?",
      percentage: 80,
      questions: [
        {
          formName: "malessere_generale",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "malessere_generale_si" },
            { label: "No", value: "malessere_generale_no" },
            { label: "Capita", value: "malessere_generale_capita" }
          ]
        }
      ],
      uid: 7
    },
    //////////////////  STRESS PROLUNGATO //////////////////////
    {
      condition: [{ formName: "target", value: ["stress_prolungato"] }],

      description: "Avverti tensioni e dolori muscolari senza apparenti cause?",
      percentage: 40,
      questions: [
        {
          formName: "tensioni_dolori",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "tensioni_dolori_si" },
            { label: "No", value: "tensioni_dolori_no" },
            { label: "Ogni tanto", value: "tensioni_dolori_ogni_tanto" }
          ]
        }
      ],
      uid: 8
    },
    {
      condition: [{ formName: "target", value: ["stress_prolungato"] }],

      description: "Hai difficoltà nel prendere sonno?",
      percentage: 50,
      questions: [
        {
          formName: "difficolta_prendere_sonno",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "difficolta_prendere_sonno_si" },
            { label: "No", value: "difficolta_prendere_sonno_no" },
            { label: "Capita", value: "difficolta_prendere_sonno_capita" }
          ]
        }
      ],
      uid: 9
    },
    {
      condition: [{ formName: "target", value: ["stress_prolungato"] }],

      description: "Avverti spesso la sensazione di avere lo stomaco chiuso?",
      percentage: 60,
      questions: [
        {
          formName: "stomaco_chiuso",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "stomaco_chiuso_si" },
            { label: "No", value: "stomaco_chiuso_no" }
          ]
        }
      ],
      uid: 10
    },
    {
      condition: [{ formName: "target", value: ["stress_prolungato"] }],

      description: "Soffri di ipertensione?",
      percentage: 70,
      questions: [
        {
          formName: "ipertensione",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "ipertensione_si" },
            { label: "No", value: "ipertensione_no" },
            { label: "Non lo so", value: "ipertensione_non_so" }
          ]
        }
      ],
      uid: 11
    },
    {
      condition: [{ formName: "target", value: ["stress_prolungato"] }],

      description: "Fai uso di psicofarmaci?",
      percentage: 80,
      questions: [
        {
          formName: "stress_psicofarmaci",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "stress_psicofarmaci_si" },
            { label: "No", value: "stress_psicofarmaci_no" },
            { label: "Ogni tanto", value: "stress_psicofarmaci_ogni_tanto" }
          ]
        }
      ],
      uid: 12
    },

    //////////////////  SPORT INTENSO //////////////////////
    {
      condition: [{ formName: "target", value: ["sport_intenso"] }],

      description: "Pensi di dare il massimo durante il tuo allenamento?",
      percentage: 40,
      questions: [
        {
          formName: "massimo_allenamento",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "massimo_allenamento_si" },
            { label: "Potrei fare di più", value: "massimo_allenamento_no" }
          ]
        }
      ],
      uid: 13
    },
    {
      condition: [{ formName: "target", value: ["sport_intenso"] }],

      description: "Ti senti stanco/a dopo l'allenamento?",
      percentage: 50,
      questions: [
        {
          formName: "stanco_dopo_allenamento",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "stanco_dopo_allenamento_si" },
            { label: "No", value: "stanco_dopo_allenamento_no" },
            { label: "Capita", value: "stanco_dopo_allenamento_capita" }
          ]
        }
      ],
      uid: 14
    },
    {
      condition: [{ formName: "target", value: ["sport_intenso"] }],

      description: "Ti alimenti in maniera corretta?",
      percentage: 60,
      questions: [
        {
          formName: "alimentazione_corretta",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "alimentazione_corretta_si" },
            { label: "Non so", value: "alimentazione_corretta_non_so" }
          ]
        }
      ],
      uid: 15
    },
    {
      condition: [{ formName: "target", value: ["sport_intenso"] }],

      description: "Assumi integratori prima, durante o dopo l'attività agonistica che svolgi?",
      percentage: 80,
      questions: [
        {
          formName: "integratori_prima_attivita",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Sempre", value: "integratori_prima_attivita_si" },
            { label: "Mai", value: "integratori_prima_attivita_no" }
          ]
        }
      ],
      uid: 16
    },
    //////////////////  PREVENZIONE //////////////////////
    {
      condition: [{ formName: "target", value: ["prevenzione"] }],

      description: "Hai casi in famiglia di persone che soffrono di malattie degenerative?",
      percentage: 40,
      questions: [
        {
          formName: "malattie_degenerative",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "malattie_degenerative_si" },
            { label: "No", value: "malattie_degenerative_no" }
          ]
        }
      ],
      uid: 17
    },
    {
      condition: [{ formName: "target", value: ["prevenzione"] }],

      description: "Fai un eccessivo consumo di grassi saturi?",
      percentage: 50,
      questions: [
        {
          formName: "consumo_grassi_saturi",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "consumo_grassi_saturi_si" },
            { label: "No", value: "consumo_grassi_saturi_no" },
            { label: "Ogni tanto", value: "consumo_grassi_saturi_ogni_tanto" }
          ]
        }
      ],
      uid: 18
    },
    {
      condition: [{ formName: "target", value: ["prevenzione"] }],

      description: "Mantieni il fisico e la mente allenati?",
      percentage: 60,
      questions: [
        {
          formName: "mente_allenata",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "mente_allenata_si" },
            { label: "No", value: "mente_allenata_no" },
            { label: "Ogni tanto", value: "mente_allenata_ogni_tanto" }
          ]
        }
      ],
      uid: 19
    },
    {
      condition: [{ formName: "target", value: ["prevenzione"] }],

      description: "Ti senti più vecchio della tua età a livello psicofisico?",
      percentage: 80,
      questions: [
        {
          formName: "piu_vecchio",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "piu_vecchio_si" },
            { label: "No", value: "piu_vecchio_no" },
            { label: "Ogni tanto", value: "piu_vecchio_ogni_tanto" }
          ]
        }
      ],
      uid: 20
    },
    //////////////////  STANCHEZZA CRONICA //////////////////////
    {
      condition: [{ formName: "target", value: ["stanchezza_cronica"] }],

      description: "Soffri di disturbi ormonali?",
      percentage: 40,
      questions: [
        {
          formName: "disturbi_ormonali",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "disturbi_ormonali_si" },
            { label: "No", value: "disturbi_ormonali_no" }
          ]
        }
      ],
      uid: 21
    },
    {
      condition: [{ formName: "target", value: ["stanchezza_cronica"] }],

      description: "Quante ore dormi in media la notte?",
      percentage: 50,
      questions: [
        {
          formName: "ore_sonno",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Fino a 3", value: "ore_sonno_3" },
            { label: "Da 4 a 6", value: "ore_sonno_6" },
            { label: "Più di 7", value: "ore_sonno_7" }
          ]
        }
      ],
      uid: 22
    },
    {
      condition: [{ formName: "target", value: ["stanchezza_cronica"] }],

      description: "La stanchezza ti accompagna tutto il giorno?",
      percentage: 60,
      questions: [
        {
          formName: "stanchezza_giorno",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "stanchezza_giorno_si" },
            { label: "Solo al mattino", value: "stanchezza_giorno_mattino" },
            { label: "Alterno mattino e pomeriggio", value: "stanchezza_giorno_alterno" }
          ]
        }
      ],
      uid: 23
    },
    {
      condition: [{ formName: "target", value: ["stanchezza_cronica"] }],

      description: "Sei depresso/a?",
      percentage: 70,
      questions: [
        {
          formName: "sei_depresso",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "sei_depresso_si" },
            { label: "No", value: "sei_depresso_no" }
          ]
        }
      ],
      uid: 24
    },
    {
      condition: [{ formName: "target", value: ["stanchezza_cronica"] }],

      description: "Assumi farmaci?",
      percentage: 80,
      questions: [
        {
          formName: "stanchezza_farmaci",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "stanchezza_farmaci_si" },
            { label: "No", value: "stanchezza_farmaci_no" },
            { label: "Ogni tanto", value: "stanchezza_farmaci_ogni_tanto" }
          ]
        }
      ],
      uid: 25
    },
    //////////////////  INVECCHIAMENTO //////////////////////
    {
      condition: [{ formName: "target", value: ["invecchiamento"] }],

      description:
        "Negli ultimi anni hai notato un rallentamento di alcuni funzioni: calo della vista, diminuzione dell’udito, perdita di memoria, ossa più fragili, digestione più lenta, macchie sulla pelle, perdita di energia complessiva?",
      percentage: 40,
      questions: [
        {
          formName: "rallentamento",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "rallentamento_si" },
            { label: "No", value: "rallentamento_no" },
            { label: "Solo alcune di queste", value: "rallentamento_alcune" }
          ]
        }
      ],
      uid: 26
    },
    {
      condition: [{ formName: "target", value: ["invecchiamento"] }],

      description: "Fai check up annuali diagnostici preventivi",
      percentage: 50,
      questions: [
        {
          formName: "check_up",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "check_up_si" },
            { label: "No", value: "check_up_no" },
            { label: "Ogni tanto", value: "check_up_ogni_tanto" }
          ]
        }
      ],
      uid: 27
    },
    {
      condition: [{ formName: "target", value: ["invecchiamento"] }],

      description: "Consumi alimenti dolci in maniera eccessiva",
      percentage: 60,
      questions: [
        {
          formName: "alimenti_dolci",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "alimenti_dolci_si" },
            { label: "No", value: "alimenti_dolci_no" },
            { label: "Ogni tanto", value: "alimenti_dolci_ogni_tanto" }
          ]
        }
      ],
      uid: 28
    },
    {
      condition: [{ formName: "target", value: ["invecchiamento"] }],

      description: "Ti senti più vecchio della tua età",
      percentage: 70,
      questions: [
        {
          formName: "senti_vecchio",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "senti_vecchio_si" },
            { label: "No", value: "senti_vecchio_no" },
            { label: "Ogni tanto", value: "senti_vecchio_ogni_tanto" }
          ]
        }
      ],
      uid: 29
    },
    {
      condition: [{ formName: "target", value: ["invecchiamento"] }],

      description: "Integri in aggiunta alla tua alimentazione vitamine e minerali antinvecchiamento",
      percentage: 80,
      questions: [
        {
          formName: "integratori_antinvecchiamento",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "integratori_antinvecchiamento_si" },
            { label: "No", value: "integratori_antinvecchiamento_no" },
            { label: "Ogni tanto", value: "integratori_antinvecchiamento_ogni_tanto" }
          ]
        }
      ],
      uid: 30
    }
  ]
};
