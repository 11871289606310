import React, { Component } from "react";
import firebase from "firebase/app";
import { Columns } from "bloomer/lib/grid/Columns";
import { Column } from "bloomer";
import * as qs from "query-string";
import Loader from "../../components/Loader";
import Slider from "react-slick";
import { Container } from "bloomer/lib/layout/Container";
import { Button } from "bloomer/lib/elements/Button";
import Utils from "../../Utils";

export default class MSapeviChe extends Component {
  state = {
    sapeviche: [],
    currentSlide: 0,
    loading: true
  };

  async loadData() {
    const fomrulaId = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    }).formula;

    const formulaSnap = await firebase
      .firestore()
      .collection("recipes")
      .doc(fomrulaId)
      .get();

    const formula = formulaSnap.data();

    console.log("AA formula", formula);
    const elements = [];
    formula.elements.forEach(e => {
      elements.push(e.uid);
    });

    const sapeviche = [];
    const sapeviSnap = await firebase
      .firestore()
      .collection("sapevateche")
      .where("category.uid", "==", formula.categories[0].uid)
      .get();

    sapeviSnap.forEach(c => {
      const sapevi = c.data();
      sapeviche.push(sapevi);
    });

    console.log("AA sapeviche", sapeviche);

    this.setState({ sapeviche, formula, loading: false });
    const height = !!this.divElement ? this.divElement.clientHeight : 0;
    Utils.log("AA widonw", height, this.divElement);

    if (!!height) {
      Utils.log("AA widonw", height, this.divElement);
      var parent_origin = "https://mylabnutrition.net";

      window.parent.postMessage(
        JSON.stringify({
          task: "resize",
          height: height + 300
        }),
        parent_origin
      );
    }
  }

  async componentDidMount() {
    this.loadData();
  }

  buttonPressed() {
    console.log("AA button pressed");

    var slide = this.state.currentSlide + 1;
    slide = slide % this.state.sapeviche.length;

    this.slider.slickGoTo(slide);
    this.setState({ currentSlide: slide, rotate: true });
  }

  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    console.log("AA render");
    if (this.state.loading)
      return (
        <div style={{ background: "#394e56", height: "100vh", justifyContent: "center", paddingTop: "10%" }}>
          <Loader white />
        </div>
      );
    return (
      <div>
        <div className="green-background vw-tablet" style={{ height: "100%" }}>
          <div className="background-radius-sapeviche" />
          <Container>
            <Columns className="sapeviche-container">
              <Column className="sapeviche-container-column">
                <div className="testa-container">
                  <img className="testa" src="/images/consulenza/testa.png" />
                  <img className={this.state.rotate ? "labirinto rotate" : "labirinto"} src="/images/consulenza/labirinto.png" onAnimationEnd={() => this.setState({ rotate: false })} />

                  <img className="punto-interrogativo" src="/images/consulenza/resoconto-10.png" onClick={() => this.buttonPressed()} />
                  <img className="freccia-sapeviche" src="/images/consulenza/freccia.png" />
                </div>
              </Column>
              <Column className="sapeviche-scroll">
                <Slider ref={slider => (this.slider = slider)} {...settings}>
                  {this.state.sapeviche.map(c => {
                    return (
                      <div className="sapeviche">
                        <h1>{c.description}</h1>
                      </div>
                    );
                  })}
                </Slider>
                <img className="logo" src="/images/consulenza/logo-punti.png" />
              </Column>
            </Columns>
          </Container>
        </div>

        <div className="green-background vw-mobile-only" style={{ height: "100%" }} ref={divElement => (this.divElement = divElement)}>
          <Container className="ta-c">
            <Slider className="sapeviche-scroll" {...settings}>
              {this.state.sapeviche.map(c => {
                return (
                  <div className="sapeviche">
                    <h1>{c.description}</h1>
                  </div>
                );
              })}
            </Slider>
            <div className="testa-container">
              <img className="testa" src="/images/consulenza/testa.png" />
              <img className={this.state.rotate ? "labirinto rotate" : "labirinto"} src="/images/consulenza/labirinto.png" onAnimationEnd={() => this.setState({ rotate: false })} />

              <img className="punto-interrogativo" src="/images/consulenza/resoconto-10.png" onClick={() => this.buttonPressed()} />
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
