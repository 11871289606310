// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from "react";
import firebase from "firebase/app";
import { Redirect, Route } from "react-router-dom";

const PrivateRoute = ({ render: Render, ...rest }) => {
  // Add your own authentication on the below line.
  const isLoggedIn = JSON.parse(localStorage.getItem("authUser"));

  return <Route {...rest} render={props => (isLoggedIn ? <Render {...props} /> : <Redirect to={{ pathname: "/", state: { from: props.location } }} />)} />;
};

export default PrivateRoute;
