import React from "react";
import { withModalsCtx } from "../../../contexts/ModalsContext";
import times from "../../../svgs/ic-24-close.svg";
import { withUserCtx } from "../../../contexts/UserContext";
import { withLabCtx } from "../../../contexts/LabContext";
import MFormulaOrder from "../../../components/mobile/MFormulaOrder";
import firebase from "firebase/app";
import Utils from "../../../Utils";
import Loader from "../../../components/Loader";
import ElementBox from "../../../components/ElementBox";
import StarRating from "../../../components/StarRating";
import Composition from "../../../components/Composition";
import FunctionalCategories from "../../../components/FunctionalCategories";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Column, Columns, Tabs, TabList, Tab, TabLink, Button } from "bloomer";
import { faCommentAlt } from "@fortawesome/free-solid-svg-icons";

class MFormula extends React.Component {
  state = {
    tab: "info",
    formula: {
      name: "",
      description: ""
    },
    formulaUid: null
  };

  changeTab(tab) {
    this.setState({ tab: tab });
  }

  async componentDidMount() {
    const formulaUid = this.props.match.params.formulaUid;

    try {
      const recipeObserver = await firebase
        .firestore()
        .collection("recipes")
        .doc(formulaUid)
        .onSnapshot(async snap => {
          if (snap.exists) {
            const data = { ...snap.data() };
            data.dailyPrice = await new Utils().getRecipeDailyPrice(data);
            data.dailyGrams = Utils.getRecipeDailyGrams(data);
            Utils.log(data);
            this.setState({
              formula: data,
              loaded: true
            });
          } else {
            console.error("formula not found", formulaUid);
            this.props.modals.addError(`Formula ${formulaUid} non trovata`, null, () => {
              if (this.state.observer) this.state.observer();
              this.props.history.goBack()();
            });
          }
        });
      this.setState({
        observer: recipeObserver
      });
    } catch (e) {
      console.error("error fetching formula", e);
      Utils.handleError(e);
    }
  }

  componentWillUnmount() {
    if (this.state.observer) this.state.observer();
  }

  render() {
    const { formula } = this.state;
    return (
      <div style={{ backgroundColor: "#161617", height: "100vh" }}>
        <Container>
          <Columns isMobile>
            <Column>
              <img
                src={times}
                className="is-pulled-left"
                onClick={this.props.history.goBack()}
                style={{
                  minWidth: "24px",
                  minHeight: "24px",
                  maxWidth: "24px",
                  maxHeight: "24px",
                  marginTop: "1.5px"
                }}
                alt="indietro"
              />
              <h3 className="title">&nbsp;Hall Of Fame</h3>
            </Column>
          </Columns>
          <Columns isMobile>
            <Column isSize={{ mobile: 12 }}>
              <h3 className="title">{formula.name}</h3>
            </Column>
          </Columns>
          {formula.owner ? <FormulaUser userUid={formula.owner} /> : null}
        </Container>
        <Tabs>
          <TabList>
            <Tab className={this.state.tab === "recipe" ? "is-active" : ""}>
              <TabLink onClick={() => this.changeTab("recipe")}>
                <span>La Formula</span>
              </TabLink>
            </Tab>
            <Tab className={this.state.tab === "info" ? "is-active" : ""}>
              <TabLink onClick={() => this.changeTab("info")}>
                <span>Info e Recensioni </span>
              </TabLink>
            </Tab>
          </TabList>
        </Tabs>
        {this.state.loaded ? this.renderRecipe() : <Loader />}
      </div>
    );
  }

  renderRecipe() {
    return <div>{this.state.tab === "info" ? <InfoTab formula={this.state.formula} /> : <RecipeTab formula={this.state.formula} />}</div>;
  }
}

class FormulaUser extends React.Component {
  state = {
    user: {
      displayName: ""
    }
  };

  async componentDidMount() {
    const userUid = this.props.userUid;

    try {
      const userObserver = await firebase
        .firestore()
        .collection("profiles")
        .doc(userUid)
        .onSnapshot(async snap => {
          if (snap.exists) {
            const data = { ...snap.data() };

            Utils.log(data);
            this.setState({
              user: data,
              loaded: true
            });
          } else {
            console.error("user not found", userUid);
            this.props.modals.addError(`User ${userUid} non trovata`, null, () => {
              if (this.state.observer) this.state.observer();
              this.props.history.goBack()();
            });
          }
        });
      this.setState({
        observer: userObserver
      });
    } catch (e) {
      console.error("error fetching user", e);
      Utils.handleError(e);
    }
  }

  componentWillUnmount() {
    if (this.state.observer) this.state.observer();
  }

  render() {
    return (
      <Columns isMobile>
        <Column isSize={{ mobile: 2 }}>
          <figure className="image is-32x32">
            <img className="is-rounded" src={this.state.user.photoURL} alt="MyLab avatar" />
          </figure>
        </Column>
        <Column isSize={{ mobile: 10 }} className="pl-0">
          <h4 className="subtitle has-super-light-gray mb-0">&nbsp;di {this.state.user.displayName}</h4>
          <StarRating rating="1" size="small" stars="1" label="4,5" />
        </Column>
      </Columns>
    );
  }
}

class InfoTab extends React.Component {
  state = {
    trainers: []
  };

  render() {
    let starvalue = 5;
    const { formula } = this.props;
    return (
      <div className="mb-50">
        <Container>
          <div className="pb-12">
            <div className="ptb-12">
              <h4 className="title">Descrizione formula</h4>
              <h3 className="subtitle is-truncated">{formula.description}</h3>
              <span className="has-text-mylab-color-light">+ LEGGI DI PI&Ugrave;</span>
            </div>
            <h4 className="title mb-0">Recensioni formula</h4>
            <StarRating rating={starvalue} label="14" />
          </div>
        </Container>
        <div onClick={() => this.goTo("#")} className="formula-list-element has-no-bg has-padding-3">
          <Container>
            <Columns isMobile>
              <Column isSize={{ mobile: 11 }} className="pl-0">
                <h2 className="subtitle mb-0">&nbsp;Marco Pastorelli</h2>
                <h4 className="subtitle has-super-light-gray mb-0">&nbsp;Ordinata nel mese di Aprile 2018</h4>
                <h4 className="subtitle is-pulled-left has-super-light-gray has-text-14 mb-0">&nbsp;Voto:</h4>{" "}
                <div className="is-pulled-left">
                  <StarRating rating={starvalue} />
                </div>
                <h4 className="subtitle is-pulled-left has-super-light-gray has-text-14 mb-0 ml-6p">Data: 14 Maggio</h4>
                <div className="is-clear-fix" />
              </Column>
              <Column isSize={{ mobile: 1 }} className="pl-0">
                <FontAwesomeIcon icon={faCommentAlt} />
              </Column>
            </Columns>
          </Container>
          <p>La L-Carnitina è un derivato aminoacidico sintetizzato nell'organismo umano, prevalentemente a livello epatico e renale. Detta più semplicemente Carnitina.</p>
        </div>
        <div onClick={() => this.goTo("#")} className="formula-list-element has-no-bg has-padding-3">
          <Container>
            <Columns isMobile>
              <Column isSize={{ mobile: 11 }} className="pl-0">
                <h2 className="subtitle mb-0">&nbsp;Giuliano</h2>
                <h4 className="subtitle has-super-light-gray mb-0">&nbsp;Ordinata nel mese di Aprile 2018</h4>
                <h4 className="subtitle is-pulled-left has-super-light-gray has-text-14 mb-0">&nbsp;Voto:</h4>{" "}
                <div className="is-pulled-left">
                  <StarRating rating={starvalue} />
                </div>
                <h4 className="subtitle is-pulled-left has-super-light-gray has-text-14 mb-0 ml-6p">Data: 14 Maggio</h4>
                <div className="is-clear-fix" />
              </Column>
              <Column isSize={{ mobile: 1 }} className="pl-0">
                <FontAwesomeIcon icon={faCommentAlt} />
              </Column>
            </Columns>
          </Container>
          <p>La L-Carnitina è un derivato aminoacidico sintetizzato nell'organismo umano, prevalentemente a livello epatico e renale. Detta più semplicemente Carnitina.</p>
        </div>
        <Container>
          <MFormulaOrder formula={this.props.formula} />
          <h1 className="ptb-12">
            <Button className="is-transparent is-outlined is-fullwidth">Apri nel laboratorio</Button>
          </h1>
        </Container>
      </div>
    );
  }
}

class RecipeTab extends React.Component {
  render() {
    return (
      <div className="pb-12 mb-50">
        <Container>
          <Columns isMobile>
            <Column isSize={{ mobile: 4 }} className="has-text-centered has-relative-position has-right-border">
              {this.props.formula.elements.length} ingredienti <br />
              <span className="has-super-light-gray">al giorno</span>
            </Column>
            <Column isSize={{ mobile: 4 }} className="has-text-centered has-relative-position has-right-border">
              {this.props.formula.dailyGrams}
              gr <br />
              <span className="has-super-light-gray">al giorno</span>
            </Column>
            <Column isSize={{ mobile: 4 }} className="has-text-centered">
              {this.props.formula.dailyPrice}€ <br />
              <span className="has-super-light-gray">al giorno</span>
            </Column>
          </Columns>
        </Container>
        <Container>
          <Columns isMobile>
            <Column isSize={{ mobile: 12 }} className="has-text-centered has-super-light-gray">
              Tocca gli ingredienti per i dettagli
            </Column>
          </Columns>
          <Container>
            <Columns className="ptb-12 has-text-centered is-multiline" isMobile>
              {this.props.formula.elements.map(e => (
                <Column key={e.uid} isSize="1/2">
                  <ElementBox element={e} />
                </Column>
              ))}
            </Columns>
          </Container>
          <MFormulaOrder formula={this.props.formula} />
          <h1 className="ptb-12">
            <Link to={"/crea/" + this.props.formula.uid} className="button is-transparent is-outlined is-fullwidth">
              Apri nel laboratorio
            </Link>
          </h1>
          <div className="ptb-12">
            <h4 className="title">Come assumerla</h4>
            <h3 className="subtitle">Miscela gli ingredienti con un liquido (acqua o latte), shakera e assumi l'integrazione seguendo i consigli descritti in ogni singolo ingrediente.</h3>
          </div>
          <div className="ptb-12">
            <h4 className="title">Composizione giornaliera</h4>
            <Composition elements={this.props.formula.elements} />
          </div>
          <div className="ptb-12">
            <h4 className="title">Categorie funzionali</h4>
            <FunctionalCategories elements={this.props.formula.elements} />
          </div>
          <div>
            <h1 className="ptb-12 is-pulled-left">
              <Button className="is-transparent is-outlined is-fullwidth">Salva</Button>
            </h1>
            <h1 className="ptb-12 is-pulled-right">
              <Link to={"/crea/" + this.props.formula.uid} className="button is-transparent is-outlined is-fullwidth">
                Apri nel laboratorio
              </Link>
            </h1>
            <div className="is-clear-fix" />
          </div>
        </Container>
      </div>
    );
  }
}

export default withLabCtx(withModalsCtx(withUserCtx(withRouter(MFormula))));
