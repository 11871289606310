import React from "react";
import Loader from "./Loader";
import { Radar } from "react-chartjs-2";
import firebase from "firebase/app";
import Utils from "../Utils";

export default class FunctionalCategories extends React.Component {
  state = {
    loading: true,
    data: {
      labels: ["Categorie"],
      datasets: []
    },
    options: {
      maintainAspectRatio: false,
      title: {
        display: false
      },
      legend: {
        display: false
      },

      scale: {
        angleLines: { color: "rgba(255, 255, 255, 0.7)" },
        gridLines: { color: "rgba(255, 255, 255, 0.4)" },
        pointLabels: {
          fontColor: "white" // labels around the edge like 'Running'
        },
        ticks: {
          display: false
        }
      },
      tooltips: {
        mode: "index",
        intersect: false,
        enabled: false
      }
    }
  };

  colors = ["#981b3b", "#d72638", "#dd1155", "#e63946", "#ed6a5a", "#ffc2c2", "#ff6d92", "#db2754"];

  async componentDidMount() {
    const state = { ...this.state };
    let i = 0;
    state.total = 0;
    state.loading = false;

    let categories = {};
    let promises = [];

    // 1. calculate the sum of every category in grams by the percentage of appartenence

    this.props.elements.forEach(e => {
      promises.push(
        new Promise(async (r, rj) => {
          try {
            const elSnap = await firebase
              .firestore()
              .collection("elements")
              .doc(e.uid)
              .collection("categories")
              .get();

            elSnap.forEach(el => {
              const c = el.data();
              const grams = (e.quantity * c.weight) / 100;

              if (!categories[c.uid]) categories[c.uid] = 0;

              categories[c.uid] += grams;
            });

            r(elSnap);
          } catch (er) {
            console.error("Error calculating radar for recipe", er);
            Utils.handleError(er);
            rj(er);
          }
        })
      );
    });

    await Promise.all(promises);

    let labels = [];
    let values = [];

    state.categories = [];

    for (let key in categories) {
      if (categories.hasOwnProperty(key)) {
        const cSnap = await firebase
          .firestore()
          .collection("categories")
          .doc(key)
          .get();
        const c = cSnap.data();
        labels.push(c.name);
        values.push(categories[key]);
        state.total += categories[key];
        state.categories.push({
          label: c.name,
          uid: key,
          value: categories[key]
        });
      }
    }

    state.data.labels = labels;

    state.data.datasets.push({
      label: "Categorie per Formula",
      data: values,
      backgroundColor: this.colors[i++]
    });
    Utils.log(state);

    this.setState(state);
  }

  render() {
    if (this.state.loading) return <Loader />;
    return (
      <div>
        <ol className="ptb-12 ml-15 mt-20">
          {this.state.categories.map((d, idx) => (
            <li className="has-text-white" key={d.uid}>
              {d.label} <span className="is-pulled-right has-text-white">{Math.round((d.value * 100) / this.state.total)}%</span>
            </li>
          ))}
        </ol>
        <div style={{ height: this.props.height || "230px", position: "relative" }}>
          <Radar options={this.state.options} data={this.state.data} />
        </div>
      </div>
    );
  }
}
