export default {
  data: [
    {
      description: "In quali tra queste problematiche riferite ai dolori di tendini, muscoli e ossa ti ritrovi?",
      percentage: 30,
      questions: [
        {
          formName: "target",
          fullwidth: true,
          kind: "radiobox2",
          order: 1,
          values: [
            { label: "Tendiniti", value: "tendiniti" },
            { label: "Artrite o Artrosi", value: "artrite" },
            { label: "Cervicalgia", value: "cervicalgia" },
            { label: "Sciatica", value: "sciatica" },
            { label: "Traumi muscolari", value: "traumi_muscolari" },
            { label: "Rigidità Articolare", value: "rigidita_articolare" }
          ]
        }
      ],
      uid: 0
    },
    //////////////////  TENDINITI //////////////////////
    {
      condition: [{ formName: "target", value: ["tendiniti"] }],

      description: "I tendini ti fanno male quando li sovraccarichi?",
      percentage: 40,
      questions: [
        {
          formName: "dolore_tendini",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "dolore_tendini_si" },
            { label: "No", value: "dolore_tendini_no" }
          ]
        }
      ],
      uid: 1
    },
    {
      condition: [{ formName: "target", value: ["tendiniti"] }],

      description: "Soffri di problemi posturali?",
      percentage: 50,
      questions: [
        {
          formName: "problemi_posturali",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "problemi_posturali_si" },
            { label: "No", value: "problemi_posturali_no" }
          ]
        }
      ],
      uid: 2
    },
    {
      condition: [{ formName: "target", value: ["tendiniti"] }],

      description: "Soffri di problemi circolatori?",
      percentage: 60,
      questions: [
        {
          formName: "problemi_circolatori",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "problemi_circolatori_si" },
            { label: "No", value: "problemi_circolatori_no" }
          ]
        }
      ],
      uid: 3
    },
    {
      condition: [{ formName: "target", value: ["tendiniti"] }],

      description: "Il dolore è costante?",
      percentage: 80,
      questions: [
        {
          formName: "dolore_costante",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "dolore_costante_si" },
            { label: "Aterno", value: "dolore_costante_no" }
          ]
        }
      ],
      uid: 4
    },
    //////////////////  ARTRITE //////////////////////
    {
      condition: [{ formName: "target", value: ["artrite"] }],

      description: "Hai le articolazioni gonfie?",
      percentage: 40,
      questions: [
        {
          formName: "articolazioni_gonfie",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "articolazioni_gonfie_si" },
            { label: "No", value: "articolazioni_gonfie_no" }
          ]
        }
      ],
      uid: 5
    },
    {
      condition: [{ formName: "target", value: ["artrite"] }],

      description: "Il dolore nella zona dolente è persistente?",
      percentage: 60,
      questions: [
        {
          formName: "dolore_persistente",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "dolore_persistente_si" },
            { label: "No", value: "dolore_persistente_no" },
            { label: "A volte", value: "dolore_persistente_a_volte" }
          ]
        }
      ],
      uid: 6
    },
    {
      condition: [{ formName: "target", value: ["artrite"] }],

      description: "Ti senti rigido/a?",
      percentage: 80,
      questions: [
        {
          formName: "dolori_ossa_rigido",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "dolori_ossa_rigido_si" },
            { label: "No", value: "dolori_ossa_rigido_no" },
            { label: "A volte", value: "dolori_ossa_rigido_a_volte" }
          ]
        }
      ],
      uid: 7
    },
    //////////////////  CERVICALGIA //////////////////////
    {
      condition: [{ formName: "target", value: ["cervicalgia"] }],

      description: "Il dolore si irradia fino alle spalle?",
      percentage: 40,
      questions: [
        {
          formName: "dolore_spalle",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "dolore_spalle_si" },
            { label: "No", value: "dolore_spalle_no" }
          ]
        }
      ],
      uid: 8
    },
    {
      condition: [{ formName: "target", value: ["cervicalgia"] }],

      description: "Hai mal di testa, nausea, ronzii alle orecchie?",
      percentage: 60,
      questions: [
        {
          formName: "ronzii",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "ronzii_si" },
            { label: "No", value: "ronzii_no" }
          ]
        }
      ],
      uid: 9
    },
    {
      condition: [{ formName: "target", value: ["cervicalgia"] }],

      description: "Il problema si manifesta quando sei sotto stress?",
      percentage: 80,
      questions: [
        {
          formName: "cervicalgia_sotto_stress",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "cervicalgia_sotto_stress_si" },
            { label: "No", value: "cervicalgia_sotto_stress_no" }
          ]
        }
      ],
      uid: 10
    },
    //////////////////  SCIATICA //////////////////////
    {
      condition: [{ formName: "target", value: ["sciatica"] }],

      description: "Ogni quanto si manifesta un attacco di sciatica durante l'anno?",
      percentage: 40,
      questions: [
        {
          formName: "sciatica_manifesta",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "1 volta", value: "sciatica_manifesta_1" },
            { label: "2 o più volte", value: "sciatica_manifesta_2" }
          ]
        }
      ],
      uid: 11
    },
    {
      condition: [{ formName: "target", value: ["sciatica"] }],

      description: "Fai una vita sedentaria?",
      percentage: 60,
      questions: [
        {
          formName: "sciatica_vita_sedentaria",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "sciatica_vita_sedentaria_si" },
            { label: "No", value: "sciatica_vita_sedentaria_no" }
          ]
        }
      ],
      uid: 12
    },
    {
      condition: [{ formName: "target", value: ["sciatica"] }],

      description: "Hai subito traumi o fratture agli arti inferiori?",
      percentage: 80,
      questions: [
        {
          formName: "sciatica_traumi",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "sciatica_traumi_si" },
            { label: "No", value: "sciatica_traumi_no" }
          ]
        }
      ],
      uid: 13
    },
    //////////////////  TRAUMI MUSCOLARI  //////////////////////
    {
      condition: [{ formName: "target", value: ["traumi_muscolari"] }],

      description: "Fai molta attività sportiva?",
      percentage: 40,
      questions: [
        {
          formName: "traumi_muscolari_sport",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "traumi_muscolari_sport_si" },
            { label: "No", value: "traumi_muscolari_sport_no" }
          ]
        }
      ],
      uid: 14
    },
    {
      condition: [{ formName: "target", value: ["traumi_muscolari"] }],

      description: "Hai dolori muscolari frequenti?",
      percentage: 60,
      questions: [
        {
          formName: "traumi_muscolari_dolori",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "traumi_muscolari_dolori_si" },
            { label: "No", value: "traumi_muscolari_dolori_no" }
          ]
        }
      ],
      uid: 15
    },
    {
      condition: [{ formName: "target", value: ["traumi_muscolari"] }],

      description: "Ti senti i muscoli rigidi e contratti?",
      percentage: 80,
      questions: [
        {
          formName: "traumi_muscolari_muscoli_contratti",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "traumi_muscolari_muscoli_contratti_si" },
            { label: "No", value: "traumi_muscolari_muscoli_contratti_no" }
          ]
        }
      ],
      uid: 16
    },
    //////////////////  RIGIDITA ARTICOLARE  //////////////////////
    {
      condition: [{ formName: "target", value: ["rigidita_articolare"] }],

      description: "Hai difficoltà a compiere determinati movimenti?",
      percentage: 40,
      questions: [
        {
          formName: "rigidita_articolare_difficolta_movimenti",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "rigidita_articolare_difficolta_movimenti_si" },
            { label: "No", value: "rigidita_articolare_difficolta_movimenti_no" }
          ]
        }
      ],
      uid: 17
    },
    {
      condition: [{ formName: "target", value: ["rigidita_articolare"] }],

      description: "Ti senti più rigido al risveglio?",
      percentage: 60,
      questions: [
        {
          formName: "rigidita_articolare_rigido_risveglio",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "rigidita_articolare_rigido_risveglio_si" },
            { label: "No", value: "rigidita_articolare_rigido_risveglio_no" }
          ]
        }
      ],
      uid: 18
    },
    {
      condition: [{ formName: "target", value: ["rigidita_articolare"] }],

      description: "La rigidità si attenua col movimento?",
      percentage: 80,
      questions: [
        {
          formName: "rigidita_articolare_attenua_movimento",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "rigidita_articolare_attenua_movimento_si" },
            { label: "No", value: "rigidita_articolare_attenua_movimento_no" }
          ]
        }
      ],
      uid: 19
    }
  ]
};
