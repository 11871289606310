export default {
  data: [
    {
      description: "Sei vegano o vegetariano?",
      percentage: 90,
      questions: [
        {
          formName: "veganform",
          fullwidth: true,
          kind: "radiobox2",
          order: 1,
          values: [
            { label: "Si Vegano", value: "vegano" },
            { label: "Si Vegetariano", value: "vegetariano" },
            { label: "Nessuno dei due", value: "no_vegano_vegetariano" },
          ],
        },
      ],
      uid: 0,
    },
    {
      description: "Hai problemi di salute o sei una donna in gravidanza? Se più di una seleziona multiplo",
      percentage: 90,
      questions: [
        {
          formName: "diseases",
          fullwidth: true,
          kind: "checkbox2",
          order: 1,
          values: [
            { excludeAll: true, label: "Nessuno", value: "none" },
            { label: "Problemi Cardiaci", value: "cardio" },
            { label: "Problemi Renali", value: "kidney" },
            { label: "Ipertensione  (Pressione alta)", value: "hypertension" },
            { label: "Pressione bassa", value: "low_blood_pressure" },
            { label: "Diabete", value: "diabet" },
            { label: "Insulino resistenza", value: "insulin" },
            { label: "Sono in gravidanza", value: "gravidanza" },
            { label: "Sto allattando", value: "allattamento" },
            { label: "Prendi anticoagulanti o hai problemi di coagulazione del sangue", value: "anticoagulanti" },
          ],
        },
      ],
      uid: 1,
    },
    {
      description: "Hai una di queste allergie o intolleranze? Se più di una seleziona multiplo",
      percentage: 95,
      questions: [
        {
          formName: "intolerances",
          kind: "checkbox2",
          order: 1,
          values: [
            { excludeAll: true, label: "Nessuna", value: "none" },
            { label: "Lattosio", value: "lf" },
            { label: "Uova", value: "uf" },
            { label: "Pesce", value: "pf" },
            { label: "Molluschi", value: "mf" },
            { label: "Noci", value: "nf" },
            { label: "Arachidi", value: "af" },
            { label: "Soia", value: "sf" },
            { label: "Glutine", value: "gf" },
            { label: "Nichel", value: "ni" },
          ],
        },
      ],
      uid: 2,
    },
    {
      description: "Piccola domanda. Possiamo usare i tuoi dati a fini statistici? Verranno, ovviamente, resi anonimi",
      percentage: 99,
      questions: [
        {
          formName: "statisticsUse",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Sì", value: "yes" },
            { label: "No", value: "no" },
          ],
        },
      ],
      uid: 3,
    },
  ],
};
