import jsonp from "jsonp";
import toQueryString from "to-querystring";
import Utils from "../Utils";
import CriptoService from "./CriptoService";
const getAjaxUrl = url => url.replace("/post?", "/post-json?");

export default class MailchimpService {
  static subscribe(data, listId) {
    const params = toQueryString(data);
    const url = getAjaxUrl("https://mylabnutrition.us17.list-manage.com/subscribe/post?u=898a8d38fe739cc4e74df0943&id=" + listId) + "&" + params;

    jsonp(
      url,
      {
        param: "c"
      },
      (err, data) => {
        if (err) {
          Utils.log("Mailchimp error", err);
        } else if (data.result !== "success") {
          Utils.log("Mailchimp error", data.msg);
        } else {
          Utils.log("Mailchimp success");
        }
      }
    );
  }
  static modify(data, listId, email) {
    const params = toQueryString(data);
    const id = CriptoService.md5(email);
    const url = getAjaxUrl("https://mylabnutrition.us17.list-manage.com/subscribe/patch?u=898a8d38fe739cc4e74df0943&id=" + listId) + "&" + params;

    jsonp(
      url,
      {
        param: "c"
      },
      (err, data) => {
        if (err) {
          Utils.log("Mailchimp error", err);
        } else if (data.result !== "success") {
          Utils.log("Mailchimp error", data.msg);
        } else {
          Utils.log("Mailchimp success");
        }
      }
    );
  }
}
