import React from "react";
import firebase from "firebase/app";
import Loader from "./Loader";
import Utils from "../Utils";
import { Link } from "react-router-dom";

export default class ElementBox extends React.Component {
  state = {};

  async componentDidMount() {
    const photoSnap = await firebase.firestore().collection("elements").doc(this.props.element.uid).collection("photos").doc("main").get();
    if (photoSnap.exists) {
      this.setState({ imageUrl: photoSnap.data().url });
    } else {
      this.setState({
        imageUrl: `https://assets.mylabnutrition.net/product/${this.props.element.uid}.png`,
      });
    }
    const elSnap = await firebase.firestore().collection("elements").doc(this.props.element.uid).get();
    if (elSnap.exists) {
      const catSnap = await firebase.firestore().collection("elements").doc(this.props.element.uid).collection("categories").get();

      const categories = [];

      catSnap.forEach((c) => categories.push(c.data()));

      this.setState({ element: elSnap.data(), categories: categories });
    } else {
      this.setState({ element: { ...this.props.element } });
    }
  }

  getObiettivo(e) {
    const { categories } = this.props;

    Utils.log("AA Cat ", e, e.categories, categories);
    let name = "";
    let uid = "";
    this.state.categories.forEach((c) => {
      categories.forEach((cat) => {
        if (c.uid === cat.uid) {
          name = cat.name;
          uid = c.uid;
        }
      });
    });
    return [name, uid];
  }

  render() {
    if (!this.state.element) return <Loader />;
    const { showDetails, showDescription, showShortDetails } = this.props;
    const bustine = (this.props.element.quantity / this.props.element.sachetGrams) * this.props.quantity;
    let regex = /style="([^"]*)"/g;
    const description = this.props.element.description ? this.props.element.description.replace(regex, "") : "";

    const bustineText = bustine === 1 ? "bustina" : "bustine";

    return (
      <div className="element-box gray-gradient">
        <h1>
          <img src={this.state.imageUrl} alt="" />
        </h1>
        {showDescription ? <h3 className="title">{this.props.element.name}</h3> : <h3 className="subtitle no-m bolder">{this.props.element.name}</h3>}{" "}
        {showDetails && (
          <div>
            <p className="has-text-grey-light">
              <small>
                {bustine} {bustineText}
              </small>
            </p>
            <p className={showShortDetails ? "bottom-border has-text-grey-light" : "has-text-grey-light"}>
              <small>da {this.props.element.sachetGrams}g</small>
            </p>
          </div>
        )}
        {showDescription && (
          <div className="ptb-20">
            {this.props.categories && (
              <h3 className="subtitle mb-40 has-text-green" style={{ display: "inline", fontSize: "13.5px", fontWeight: "bold", textTransform: "uppercase" }}>
                {this.getObiettivo(this.props.element)[0]}
                <img
                  style={{ height: "24px", width: "auto", marginLeft: "10px", verticalAlign: "middle", marginBottom: "5px" }}
                  src={Utils.getCategoryImage(this.getObiettivo(this.props.element)[1], false)}
                />
              </h3>
            )}
            <small>
              <div className="mt-20 has-text-green text-70-perc is-bolder element-description" dangerouslySetInnerHTML={{ __html: description }} />
            </small>
            <Link to={`/ingrediente/${this.state.element.uid}`}>
              <h4 className="subtitle has-text-green">
                <u>...LEGGI DI PIU'</u>
              </h4>
            </Link>
            }
          </div>
        )}
        {showShortDetails && this.props.categories && (
          <span className="has-text-grey-light">
            <small style={{ fontSize: "14px" }}>{this.getObiettivo(this.props.element)[0]}</small>
            <img
              style={{ height: "24px", width: "auto", marginLeft: "10px", verticalAlign: "middle", marginBottom: "5px" }}
              src={Utils.getCategoryImage(this.getObiettivo(this.props.element)[1], false)}
            />
          </span>
        )}
      </div>
    );
  }
}
