import React from "react";
import TextQuestion from "./TextQuestion";
import { withLabCtx } from "../../contexts/LabContext";
import SelectQuestion from "./SelectQuestion";
import NumberRenderer from "./NumberRenderer";
import RadioBoxRenderer from "./RadioBoxRenderer";
import CheckRenderer from "./CheckRenderer";
import RadioRenderer from "./RadioRenderer";
import CheckBoxRenderer from "./CheckBoxRenderer";
import SliderRenderer from "./SliderRenderer";
import { chiudiButton, withModalsCtx } from "../../contexts/ModalsContext";
import PrivacyRenderer from "./PrivacyRenderer";
import Utils from "../../Utils";
import NewsletterRenderer from "./NewsletterRenderer";

class QuestionRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.excludeAll = this.excludeAll.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.addRemoveValue = this.addRemoveValue.bind(this);
    this.openErrorModal = this.openErrorModal.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  onBlur() {
    if (!this.props.lab.isValidQuestion(this.props.question)) {
      this.openErrorModal();
    }
  }

  excludeAll(formName, value) {
    this.props.lab.excludeAllAnswer(formName, value, this.props.callback);
  }

  openErrorModal() {
    const text = (
      <div>
        <p>{this.props.question.errorMessage}</p>
      </div>
    );
    this.props.modals.addModal("Dati inseriti non validi", text, [chiudiButton], false);
  }

  updateValue(formName, value) {
    Utils.log("saving value", formName, value);
    this.props.lab.answerQuestion(formName, value, this.props.callback);
  }

  addRemoveValue(question, value) {
    this.props.lab.addRemoveAnswer(question, value, this.props.callback);
  }

  render() {
    switch (this.props.question.kind) {
      case "text":
        return <TextQuestion className={this.props.className || ""} onBlur={this.onBlur} updateValue={this.updateValue} question={this.props.question} />;
      case "select":
        return <SelectQuestion className={this.props.className || ""} updateValue={this.updateValue} question={this.props.question} />;
      case "number":
        return <NumberRenderer className={this.props.className || ""} onBlur={this.onBlur} updateValue={this.updateValue} question={this.props.question} />;
      case "radiobox":
        return <RadioBoxRenderer generali={this.props.generali} className={this.props.className || ""} updateValue={this.updateValue} question={this.props.question} />;
      case "radiobox2":
        return <RadioBoxRenderer generali={this.props.generali} className={this.props.className || ""} updateValue={this.updateValue} question={this.props.question} displayBlock />;
      case "radio":
        return <RadioRenderer className={this.props.className || ""} updateValue={this.updateValue} question={this.props.question} />;
      case "privacy":
        return <PrivacyRenderer className={this.props.className || ""} addRemoveValue={this.addRemoveValue} excludeAll={this.excludeAll} question={this.props.question} />;
      case "newsletter":
        return <NewsletterRenderer className={this.props.className || ""} addRemoveValue={this.addRemoveValue} excludeAll={this.excludeAll} question={this.props.question} />;
      case "check":
        return <CheckRenderer className={this.props.className || ""} addRemoveValue={this.addRemoveValue} excludeAll={this.excludeAll} question={this.props.question} />;
      case "checkbox":
        return (
          <CheckBoxRenderer generali={this.props.generali} className={this.props.className || ""} addRemoveValue={this.addRemoveValue} excludeAll={this.excludeAll} question={this.props.question} />
        );
      case "checkbox2":
        return (
          <CheckBoxRenderer
            generali={this.props.generali}
            className={this.props.className || ""}
            addRemoveValue={this.addRemoveValue}
            excludeAll={this.excludeAll}
            question={this.props.question}
            displayBlock
          />
        );
      case "slider":
        return <SliderRenderer className={this.props.className || ""} updateValue={this.updateValue} question={this.props.question} />;
      default:
        return <p>No question renderer for type {this.props.question.kind}</p>;
    }
  }
}

export default withModalsCtx(withLabCtx(QuestionRenderer));
