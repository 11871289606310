import React from "react";
import { Field, Help, Input, Label } from "bloomer";
import { withLabCtx } from "../../contexts/LabContext";

class NumberRenderer extends React.Component {
  render() {
    return (
      <Field className={"input-field " + (this.props.className || "")}>
        {this.props.question.title ? <h6 className="title">{this.props.question.title}</h6> : null}

        <Input
          type="number"
          onChange={e => this.props.updateValue(e.target.name, e.target.value)}
          placeholder={this.props.question.placeholder}
          onBlur={this.props.onBlur}
          defaultValue={this.props.lab.survey[this.props.question.formName]}
          max={this.props.question.max || 1000}
          min={this.props.question.min || 0}
          name={this.props.question.formName}
        />
        {this.props.question.subtitle ? <Help>{this.props.question.subtitle}</Help> : null}
      </Field>
    );
  }
}

export default withLabCtx(NumberRenderer);
