export default {
  data: [
    {
      description: "Ti alleni con costanza?",
      percentage: 35,
      questions: [
        {
          formName: "alleni_costanza",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "alleni_costanza_si" },
            { label: "No", value: "alleni_costanza_no" },
            { label: "A periodi", value: "alleni_costanza_a_periodi" }
          ]
        }
      ],
      uid: 0
    },
    {
      description: "Senti i muscoli indolenziti a fine allenamento?",
      percentage: 40,
      questions: [
        {
          formName: "muscoli_indolenziti",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "muscoli_indolenziti_si" },
            { label: "No", value: "muscoli_indolenziti_no" },
            { label: "A volte", value: "muscoli_indolenziti_a_volte" }
          ]
        }
      ],
      uid: 1
    },
    {
      description: "Fai un aumento progressivo dei carichi di lavoro?",
      percentage: 45,
      questions: [
        {
          formName: "aumento_progressivo",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "aumento_progressivo_si" },
            { label: "No", value: "aumento_progressivo_no" }
          ]
        }
      ],
      uid: 2
    },
    {
      description: "Nonostante i tuoi sforzi non vedi cambiamenti?",
      percentage: 50,
      questions: [
        {
          formName: "non_vedi_cambiamenti",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "non_vedi_cambiamenti_si" },
            { label: "No", value: "non_vedi_cambiamenti_no" }
          ]
        }
      ],
      uid: 3
    },
    {
      description: "Ti nutri in maniera corretta con programmi specifici?",
      percentage: 52,
      questions: [
        {
          formName: "nutrizione_corretta",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "nutrizione_corretta_si" },
            { label: "No", value: "nutrizione_corretta_no" },
            { label: "Non sono costante", value: "nutrizione_corretta_no_costante" }
          ]
        }
      ],
      uid: 4
    },
    {
      description: "Assumi abbastanza proteine?",
      percentage: 55,
      questions: [
        {
          formName: "assumi_proteine",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "assumi_proteine_si" },
            { label: "No", value: "assumi_proteine_no" },
            { label: "A volte", value: "assumi_proteine_a_volte" }
          ]
        }
      ],
      uid: 5
    },
    {
      description: "Ti idrati con costanza e regolarità?",
      percentage: 58,
      questions: [
        {
          formName: "idrati",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "idrati_si" },
            { label: "No", value: "idrati_no" },
            { label: "A volte", value: "idrati_a_volte" }
          ]
        }
      ],
      uid: 6
    },
    {
      description: "Dormi a sufficienza?",
      percentage: 60,
      questions: [
        {
          formName: "dormi_a_sufficienza",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "dormi_a_sufficienza_si" },
            { label: "No", value: "dormi_a_sufficienza_no" },
            { label: "A volte", value: "dormi_a_sufficienza_a_volte" }
          ]
        }
      ],
      uid: 7
    },
    {
      description: "Trovi difficoltà nel prendere massa muscolare?",
      percentage: 62,
      questions: [
        {
          formName: "difficolta_massa",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "difficolta_massa_si" },
            { label: "No", value: "difficolta_massa_no" }
          ]
        }
      ],
      uid: 8
    },
    {
      description: "Hai cambiato spesso metodi di allenamento?",
      percentage: 65,
      questions: [
        {
          formName: "cambi_metodo",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "cambi_metodo_si" },
            { label: "No", value: "cambi_metodo_no" }
          ]
        }
      ],
      uid: 9
    },
    {
      description: "Pensi di sbagliare in qualcosa?",
      percentage: 68,
      questions: [
        {
          formName: "sbali_qualcosa",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "sbali_qualcosa_si" },
            { label: "No", value: "sbali_qualcosa_no" },
            { label: "Non saprei", value: "sbali_qualcosa_non_so" }
          ]
        }
      ],
      uid: 10
    },
    {
      description: "Ti sei posto degli obiettivi?",
      percentage: 70,
      questions: [
        {
          formName: "obiettivi",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "obiettivi_si" },
            { label: "No", value: "obiettivi_no" }
          ]
        }
      ],
      uid: 11
    },
    {
      description: "Assumi integratori con continuità?",
      percentage: 75,
      questions: [
        {
          formName: "integratori_con_continuita",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "integratori_con_continuita_si" },
            { label: "No", value: "integratori_con_continuita_no" },
            { label: "A volte", value: "integratori_con_continuita_a_volte" }
          ]
        }
      ],
      uid: 12
    },
    {
      description: "Quando assumi integratori, te li consiglia un esperto?",
      percentage: 80,
      questions: [
        {
          formName: "consigli_esperto",
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "consigli_esperto_si" },
            { label: "No", value: "consigli_esperto_no" },
            { label: "Faccio da solo", value: "consigli_esperto_da_solo" }
          ]
        }
      ],
      uid: 13
    }
  ]
};
