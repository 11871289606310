import React, { Component } from "react";
import firebase from "firebase/app";
import Utils from "../Utils";
import Consulenza from "../Consulenza";
import iniziali from "../consulenze/iniziali";
import finali from "../consulenze/finali";
import antiAge from "../consulenze/antiAge";
import concentrazione from "../consulenze/concentrazione";
import depurazione from "../consulenze/depurazione";
import dermaCapelli from "../consulenze/dermaCapelli";
import difeseImmunitarie from "../consulenze/difeseImmunitarie";
import difficoltaCircolatoria from "../consulenze/difficoltaCircolatoria";
import diminuzioneGrasso from "../consulenze/diminuzioneGrasso";
import doloriOssa from "../consulenze/doloriOssa";
import massaMuscolare from "../consulenze/massaMuscolare";
import pesoCorporeo from "../consulenze/pesoCorporeo";
import recuperoEnergia from "../consulenze/recuperoEnergia";
import resistenzaEnergia from "../consulenze/resistenzaEnergia";
import sistemaDigestivo from "../consulenze/sistemaDigestivo";
import sistemaNervoso from "../consulenze/sistemaNervoso";
import urogenitaleFemminile from "../consulenze/urogenitaleFemminile";
import urogenitaleMaschile from "../consulenze/urogenitaleMaschile";
import categorie from "../consulenze/categorie";

export const ConsulenzaContext = React.createContext(null);

export const ConsulenzaConsumer = ConsulenzaContext.Consumer;

export class ConsulenzaProvider extends Component {
  state = {
    survey: {},
    recipeElements: {},
    surveyResult: null,
    elementsNoFilter: [],
  };

  surveyPromise = null;

  constructor(props) {
    super(props);
    this.getNextPage = this.getNextPage.bind(this);
    this.resolveValue = this.resolveValue.bind(this);
    this.answerQuestion = this.answerQuestion.bind(this);
    this.addRemoveAnswer = this.addRemoveAnswer.bind(this);
    this.isValidQuestion = this.isValidQuestion.bind(this);
    this.excludeAllAnswer = this.excludeAllAnswer.bind(this);
    this.calcolaConsulenza = this.calcolaConsulenza.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.saveAnswersOnLocal = this.saveAnswersOnLocal.bind(this);
    this.isValidTextQuestion = this.isValidTextQuestion.bind(this);
    this.isValidCheckQuestion = this.isValidCheckQuestion.bind(this);
    this.isValidSelectQuestion = this.isValidSelectQuestion.bind(this);
    this.isValidSliderQuestion = this.isValidSliderQuestion.bind(this);
    this.isValidNumberQuestion = this.isValidNumberQuestion.bind(this);
    this.isAnswerValidForAllForm = this.isAnswerValidForAllForm.bind(this);
    this.saveFormula = this.saveFormula.bind(this);
    this.updateFormula = this.updateFormula.bind(this);
    this.deleteFormula = this.deleteFormula.bind(this);
    this.getTargetName = this.getTargetName.bind(this);
    this.loadConsulenzaQuestions = this.loadConsulenzaQuestions.bind(this);
    this.loadConsulenzaCategorieQuestions = this.loadConsulenzaCategorieQuestions.bind(this);
    this.removeTarget = this.removeTarget.bind(this);
    this.removeQuestions = this.removeQuestions.bind(this);
    this.saveElementsNoFilter = this.saveElementsNoFilter.bind(this);
  }

  async calcolaConsulenza() {
    // If the page who calls is unmounted, obtain the result anyway
    this.surveyPromise = new Promise(async (resolve, reject) => {
      // If it has already been downloaded...
      if (!!this.state.surveyResult && this.state.surveyResult === this.state.survey.result) {
        resolve(this.state.surveyResult);
      } else {
        try {
          if (!!this.state.survey["statisticsUse"]) {
            const consulenza = { ...this.state.survey };
            consulenza.date = new Date();
            // delete consulenza.result;
            try {
              await firebase
                .firestore()
                .collection("consulenze")
                .add(consulenza);
            } catch (saveError) {
              console.warn("Cannot save consulting for statistics use: ", saveError);
              Utils.handleError(saveError);
            }
          }

          const answers = { ...this.state.survey };
          answers.owner = firebase.auth().currentUser ? firebase.auth().currentUser.uid : "GUEST";
          answers.alternativeTarget = localStorage.getItem("consulenzaCategoria");
          answers.user_cookie = localStorage.getItem("user_cookie");

          const doConsulenza = firebase.functions().httpsCallable("consulenza_v2");

          // const recipeUid = await new Consulenza().executeConsulenza(answers, this); //firebase.functions().httpsCallable("consulenza_v2");

          const result = await doConsulenza(answers);
          const recipeUid = result.data.uid;
          const elementsNoFilter = result.data.elementsNoFilter;
          const tagsNoFilter = result.data.tagsNoFilter;
          console.log("CC elementsNoFilter", elementsNoFilter);
          if (elementsNoFilter.length > 0) this.saveElementsNoFilter(elementsNoFilter, tagsNoFilter);

          this.setState({ surveyResult: recipeUid }, () => this.answerQuestion("result", recipeUid));
          resolve(recipeUid);
        } catch (e) {
          console.error("Cannot calculate recipe: ", e);
          Utils.handleError(e);
          reject(e);
        }
      }
    });

    return this.surveyPromise;
  }

  saveElementsNoFilter(elements, tags) {
    let { elementsNoFilter } = this.state;
    elementsNoFilter = elements;
    console.log("CC elementsNoFilter", elementsNoFilter, elements, tags);
    this.setState({ elementsNoFilter, tagsNoFilter: tags });
  }

  writeConsultationNew() {
    return;
    const { consultation } = this.state;
    Object.keys(consultation).forEach(function(key, index) {
      console.log("AA consultation", consultation[key]);
      firebase
        .firestore()
        .collection("consultation_new")
        .doc(key)
        .set(consultation[key]);

      // key: the name of the object key
      // index: the ordinal position of the key within the object
    });
  }

  sortArray(arr) {
    return arr.sort((a, b) => (a.uid > b.uid ? 1 : b.uid > a.uid ? -1 : 0));
  }

  loadConsulenzaQuestions(type) {
    localStorage.setItem("categoria", type);

    console.log("AA categoria", type);
    let sorted = [];
    let Diniziali = [];
    let Dgoals = [];
    let Dfinali = [];

    Diniziali = iniziali.data;
    Diniziali.forEach((s) => {
      s.blockName = "lifestyle";
    });
    sorted = sorted.concat(this.sortArray(Diniziali));
    sorted[0].pixelSend = "Consulenza_Inizio_Lifestyle";

    switch (type) {
      case "anti_age":
        Dgoals = antiAge.data;
        break;
      case "concentrazione":
        Dgoals = concentrazione.data;
        break;
      case "depurazione":
        Dgoals = depurazione.data;
        break;
      case "derma_capelli":
        Dgoals = dermaCapelli.data;
        break;
      case "difese_immunitarie":
        Dgoals = difeseImmunitarie.data;
        break;
      case "difficolta_circolatoria":
        Dgoals = difficoltaCircolatoria.data;
        break;
      case "diminuzione_grasso":
        Dgoals = diminuzioneGrasso.data;
        break;
      case "dolori_ossa":
        Dgoals = doloriOssa.data;
        break;
      case "massa_muscolare":
        Dgoals = massaMuscolare.data;
        break;
      case "peso_corporeo":
        Dgoals = pesoCorporeo.data;
        break;
      case "recupero_energia":
        Dgoals = recuperoEnergia.data;
        break;
      case "resistenza_energia":
        Dgoals = resistenzaEnergia.data;
        break;
      case "sistema_digestivo":
        Dgoals = sistemaDigestivo.data;
        break;
      case "sistema_nervoso":
        Dgoals = sistemaNervoso.data;
        break;
      case "urogenitale_femminile":
        Dgoals = urogenitaleFemminile.data;
        break;
      case "urogenitale_maschile":
        Dgoals = urogenitaleMaschile.data;
        break;

      default:
        Dgoals = pesoCorporeo.data;
        break;
    }

    const goalsIndex = sorted.length;
    Dgoals.forEach((s) => {
      s.blockName = "goals";
    });
    sorted = sorted.concat(this.sortArray(Dgoals));
    sorted[goalsIndex].pixelSend = "Consulenza_Inizio_Goals";

    const finaliIndex = sorted.length;
    Dfinali = finali.data;

    Dfinali.forEach((s) => {
      s.blockName = "salute";
    });
    sorted = sorted.concat(this.sortArray(Dfinali));
    sorted[finaliIndex].pixelSend = "Consulenza_Inizio_Salute";

    let index = 0;
    sorted.forEach((a) => {
      a.uid = index;
      index++;
    });
    console.log("AA sorted", sorted);

    this.setState({ sorted });
  }

  loadConsulenzaCategorieQuestions() {
    let categorieQuestions = categorie.data;

    console.log("AA categorieQuestions", categorieQuestions);

    this.setState({ categorieQuestions });
  }

  componentDidMount() {
    const elementsObserver = firebase
      .firestore()
      .collection("elements")
      .where("visible", "==", true)
      .onSnapshot((snap) => {
        const elements = [];
        snap.forEach((s) => {
          elements.push(s.data());
        });
        this.setState({ elements: elements });
      });
    const categoriesObserver = firebase
      .firestore()
      .collection("categories")
      .onSnapshot((snap) => {
        const categories = [];
        snap.forEach((s) => {
          categories.push(s.data());
        });
        this.setState({ categories: categories });
      });
    const tagsObserver = firebase
      .firestore()
      .collection("tags")
      .onSnapshot((snap) => {
        const tags = [];
        snap.forEach((s) => {
          tags.push(s.data());
        });
        this.setState({ tags: tags });
      });
    const typesObserver = firebase
      .firestore()
      .collection("eltypes")
      .orderBy("order", "asc")
      .where("enabled", "==", true)
      .onSnapshot((snap) => {
        const types = [];
        snap.forEach((s) => {
          types.push(s.data());
        });
        this.setState({ types: types });
      });
    const consultationObserver = firebase
      .firestore()
      .collection("consultation")
      .orderBy("uid", "asc")
      .onSnapshot((snap) => {
        const pages = {};
        const questions = {};
        snap.forEach((s) => {
          const data = s.data();
          pages[data.uid] = data;
          data.questions
            .map((q) => {
              const nQ = { ...q };
              const answers = {};
              if (q.values) {
                q.values.forEach((v) => {
                  answers[v.value] = v.label;
                });
                nQ.answers = answers;
              }
              return nQ;
            })
            .forEach((q) => {
              questions[q.formName] = q;
            });
        });
        // this.setState({ consultation: pages, questions: questions }, () => console.log("AA c", JSON.stringify(this.state.consultation)));
      });

    let surveyData = localStorage.getItem("survey");
    if (!!surveyData) surveyData = JSON.parse(surveyData);

    let recipeElements = localStorage.getItem("recipeElements");
    if (!!recipeElements) recipeElements = JSON.parse(recipeElements);

    this.setState({
      elementsObserver: elementsObserver,
      categoriesObserver: categoriesObserver,
      tagsObserver: tagsObserver,
      typesObserver: typesObserver,
      consultationObserver: consultationObserver,
      getNextPage: this.getNextPage,
      answerQuestion: this.answerQuestion,
      resolveValue: this.resolveValue,
      isValidQuestion: this.isValidQuestion,
      addRemoveAnswer: this.addRemoveAnswer,
      excludeAllAnswer: this.excludeAllAnswer,
      calcolaConsulenza: this.calcolaConsulenza,
      saveFormula: this.saveFormula,
      updateFormula: this.updateFormula,
      deleteFormula: this.deleteFormula,
      getTargetName: this.getTargetName,
      isAnswerValidForAllForm: this.isAnswerValidForAllForm,
      loadConsulenzaQuestions: this.loadConsulenzaQuestions,
      loadConsulenzaCategorieQuestions: this.loadConsulenzaCategorieQuestions,
      removeTarget: this.removeTarget,
      removeQuestions: this.removeQuestions,
      saveElementsNoFilter: this.saveElementsNoFilter,
      consultationGenerali: this.consultationGenerali,
      survey: surveyData || {},
      recipeElements: recipeElements || [],
    });
  }

  resolveValue(formName, value, resolveEntireValue) {
    const answer = value || this.state.survey[formName];
    const question = this.state.questions[formName];

    if (resolveEntireValue && !!question) {
      let resolved = null;
      question.values.forEach((v) => {
        if (v.value === value) resolved = { ...v };
      });
      return resolved;
    } else if (!!question && !!question.answers) {
      return question.answers[answer] || "Altro";
    } else return answer;
  }

  answerQuestion(questionId, answer, callback) {
    const s = { survey: { ...this.state.survey } };
    s.survey[questionId] = answer;
    this.setState(s, () => {
      this.saveAnswersOnLocal();
      if (callback) callback();
    });
  }

  excludeAllAnswer(question, value, callback) {
    const s = { survey: { ...this.state.survey } };

    s.survey[question.uid] = [value];

    this.setState(s, () => {
      this.saveAnswersOnLocal();
      if (callback) callback();
    });
  }

  removeQuestions() {
    console.log("zz remove");
    this.setState({ sorted: undefined });
  }

  removeTarget() {
    const { survey } = this.state;
    if (!!survey.target) {
      delete survey.target;
      this.setState({ survey });
    }
  }

  addRemoveAnswer(question, value, callback) {
    const s = { survey: { ...this.state.survey } };
    const answer = [...(s.survey[question.formName] || [])];

    const addingRemoveAllValue = this.hasExcludeAll(question, [value]) && !this.hasExcludeAll(question, answer);

    const addingOtherValue = !this.hasExcludeAll(question, [value]) && this.hasExcludeAll(question, answer);

    // Utils.log(
    //   "addingRemoveAllValue",
    //   addingRemoveAllValue,
    //   "addingOtherValue",
    //   addingOtherValue
    // );

    if (addingRemoveAllValue || addingOtherValue) {
      answer.splice(0, answer.length);
    }

    if (answer.indexOf(value) === -1) {
      answer.push(value);
    } else {
      answer.splice(answer.indexOf(value), 1);
    }

    //Utils.log(answer);

    s.survey[question.formName] = answer;

    this.setState(s, () => {
      this.saveAnswersOnLocal();
      if (callback) callback();
    });
  }

  hasExcludeAll(question, values) {
    let hasOne = false;
    values.forEach((v) => {
      question.values.forEach((qv) => {
        if (qv.value === v && !!qv.excludeAll) hasOne = true;
      });
    });

    return hasOne;
  }

  getNextPage(pageId) {
    const newPage = this.state.sorted[pageId + 1];
    // console.log("AA pageid", pageId);
    if (!!newPage) {
      let nPageId = newPage.uid;
      if (!!newPage.condition) {
        // console.log("AA page condition", pageId);

        let conditionSatisfied = true;

        newPage.condition.forEach((c) => {
          // const comparePage = this.state.sorted[c.page];
          const formName = c.formName;
          const answer = this.state.survey[formName];
          console.log("AA page formName", formName);

          if (Array.isArray(answer)) {
            let found = false;
            answer.forEach((a) => {
              c.value.forEach((v) => {
                if (v === a) found = true;
              });
            });

            conditionSatisfied = conditionSatisfied && found;
          } else {
            let found = false;
            c.value.forEach((v) => {
              if (v === answer) found = true;
            });

            conditionSatisfied = conditionSatisfied && found;
          }
        });

        if (!conditionSatisfied) {
          return this.getNextPage(newPage.uid);
        }
      }

      return `/consulenza/${nPageId}`;
    } else {
      this.setState({ surveyResult: null });
      localStorage.setItem("consulenzaFinita", true);
      return `/consulenza/finish`;
    }
  }

  isValidQuestion(question) {
    switch (question.kind) {
      case "text":
        return this.isValidTextQuestion(question);
      case "select":
      case "radio":
      case "radiobox":
      case "radiobox2":
        return this.isValidSelectQuestion(question);
      case "check":
      case "checkbox":
      case "checkbox2":
      case "privacy":
        return this.isValidCheckQuestion(question);
      case "newsletter":
        return true;
      case "number":
        return this.isValidNumberQuestion(question);
      case "slider":
        return this.isValidSliderQuestion(question);
      default:
        return false;
    }
  }

  isValidSliderQuestion(question) {
    const id = question.formName;
    const answer = this.state.survey[id] || [];

    const min = question.min || 0;
    const max = question.max || 5;
    const step = question.step || 1;

    const cMarks = {};
    //Utils.log("calculating marks");
    for (let i = min; i <= max; i = i + step) {
      cMarks[i] = "" + i;
    }
    const marks = question.marks || cMarks;

    return !!marks[answer];
  }

  isValidCheckQuestion(question) {
    const id = question.formName;
    const answer = this.state.survey[id] || [];
    Utils.log("AA answser", answer);
    //Utils.log(question.formName, answer);
    if (answer.length <= (question.min || 0) || answer.length > (question.max || 1000)) return false;
    else {
      let isValid = true;
      answer.forEach((a) => {
        if (!question.values.map((v) => v.value).includes(a)) isValid = false;
      });
      return isValid;
    }
  }

  isValidTextQuestion(question) {
    const id = question.formName;
    const answer = this.state.survey[id];
    return !!answer && answer.length > 0;
  }

  isValidNumberQuestion(question) {
    const id = question.formName;
    const answer = this.state.survey[id];
    const min = question.min || 0;
    const max = question.max || 1000;

    return !!answer && answer >= min && answer <= max;
  }

  isValidSelectQuestion(question) {
    const id = question.formName;
    const answer = this.state.survey[id];

    for (let v of question.values) {
      if (v.value === answer) return true;
    }

    return false;
  }

  isAnswerValidForAllForm(question) {
    const id = question.formName;
    const answer = this.state.survey[id];
    if (!question.values) return false;
    //Utils.log("Questsion", question);
    for (let v of question.values) {
      if (v.value === answer && v.answerTrueIfValid) return true;
    }

    return false;
  }

  async saveFormula(f) {
    f.owner = firebase.auth().currentUser ? firebase.auth().currentUser.uid : "GUEST";

    f.kind = "SACHET";
    f.roles = {};
    f.roles[f.owner] = "owner";
    await firebase
      .firestore()
      .collection("recipes")
      .add(f)
      .then((newRef) => {
        this.setState({
          tempFormulaId: newRef.id,
        });
      });
  }

  async updateFormula(f, deleteTempField) {
    // Utils.log("Formula update", f);
    if (deleteTempField) {
      f.owner = firebase.auth().currentUser.uid;
      f.roles = {};
      f.roles[f.owner] = "owner";
      f.status = firebase.firestore.FieldValue.delete();
    }
    await firebase
      .firestore()
      .collection("recipes")
      .doc(f.uid)
      .update(f);
  }

  async deleteFormula(uid) {
    //Utils.log("Delete formula", uid);
    await firebase
      .firestore()
      .collection("recipes")
      .doc(uid)
      .delete()
      .then(() => {
        // Utils.log("deleted formula with id: ", uid);
      });
  }

  saveAnswersOnLocal() {
    localStorage.setItem("survey", JSON.stringify(this.state.survey));
    localStorage.setItem("surveyResult", JSON.stringify(this.state.survey));
    localStorage.setItem("recipeElements", JSON.stringify(this.state.recipeElements));
  }

  getTargetName(text) {
    switch (text) {
      case "fat":
        return "Bruciare grassi";
      case "growMuscle":
        return "Aumento massa";
      case "growEndurance":
        return "Aumento resistenza";
      case "immuneSystem":
        return "Difese immunitarie";
      case "growStrength":
        return "Aumento forza";
      case "growEnergy":
        return "Aumento energia";
      case "bones":
        return "Articolazione e ossa";
      case "muscleRepair":
        return "Recupero muscolare";
    }
  }

  componentWillUnmount() {
    this.saveAnswersOnLocal();
    if (this.state.tagsObserver) {
      this.state.tagsObserver();
      delete this.state.tagsObserver;
    }
    if (this.state.elementsObserver) {
      this.state.elementsObserver();
      delete this.state.elementsObserver;
    }
    if (this.state.categoriesObserver) {
      this.state.categoriesObserver();
      delete this.state.categoriesObserver;
    }
    if (this.state.typesObserver) {
      this.state.typesObserver();
      delete this.state.typesObserver;
    }
    if (this.state.consultationObserver) {
      this.state.consultationObserver();
      delete this.state.consultationObserver;
    }
  }

  render() {
    return (
      <ConsulenzaContext.Provider value={this.state}>
        <div>{this.props.children}</div>
      </ConsulenzaContext.Provider>
    );
  }
}

export function withConsultCtx(Component) {
  return (props) => <ConsulenzaConsumer>{(ctx) => <Component consult={ctx} {...props} />}</ConsulenzaConsumer>;
}
