export default {
  data: [
    {
      description: "In quali tra queste problematiche riferite a problemi all'apparato urogenitale?",
      percentage: 30,
      questions: [
        {
          formName: "target",
          fullwidth: true,
          kind: "radiobox2",
          order: 1,
          values: [
            { label: "Cistiti", value: "cistiti" },
            { label: "Ipermenorrea", value: "ipermenorrea" },
            { label: "Ipomenorrea", value: "ipomenorrea" },
            { label: "Dismenorrea", value: "dismenorrea" }
          ]
        }
      ],
      uid: 0
    },
    //////////////////  CISTITI //////////////////////
    {
      condition: [{ formName: "target", value: ["cistiti"] }],

      description: "Avverti un senso di peso?",
      percentage: 40,
      questions: [
        {
          formName: "cistiti_senso_peso",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "cistiti_senso_peso_si" },
            { label: "No", value: "cistiti_senso_peso_no" },
            { label: "A volte", value: "cistiti_senso_peso_a_volte" }
          ]
        }
      ],
      uid: 1
    },
    {
      condition: [{ formName: "target", value: ["cistiti"] }],

      description: "Hai bruciore durante la minzione?",
      percentage: 50,
      questions: [
        {
          formName: "cistiti_bruciore",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "cistiti_bruciore_si" },
            { label: "No", value: "cistiti_bruciore_no" },
            { label: "A volte", value: "cistiti_bruciore_a_volte" }
          ]
        }
      ],
      uid: 2
    },
    {
      condition: [{ formName: "target", value: ["cistiti"] }],

      description: "Soffri di problemi ormonali?",
      percentage: 60,
      questions: [
        {
          formName: "cistiti_problemi_ormonali",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "cistiti_problemi_ormonali_si" },
            { label: "No", value: "cistiti_problemi_ormonali_no" },
            { label: "A volte", value: "cistiti_problemi_ormonali_a_volte" }
          ]
        }
      ],
      uid: 3
    },
    {
      condition: [{ formName: "target", value: ["cistiti"] }],

      description: "Senti il bisogno di urinare spesso?",
      percentage: 80,
      questions: [
        {
          formName: "cistiti_bisogno_urinare",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "cistiti_bisogno_urinare_si" },
            { label: "No", value: "cistiti_bisogno_urinare_no" },
            { label: "A volte", value: "cistiti_bisogno_urinare_a_volte" }
          ]
        }
      ],
      uid: 4
    },
    //////////////////  ipermenorrea //////////////////////
    {
      condition: [{ formName: "target", value: ["ipermenorrea"] }],

      description: "Soffri di carenza di ferro?",
      percentage: 40,
      questions: [
        {
          formName: "carenza_ferro",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "carenza_ferro_si" },
            { label: "No", value: "carenza_ferro_no" }
          ]
        }
      ],
      uid: 5
    },
    {
      condition: [{ formName: "target", value: ["ipermenorrea"] }],

      description: "Fai uso di farmaci e anticoncezionali?",
      percentage: 60,
      questions: [
        {
          formName: "anticoncezionali",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "anticoncezionali_si" },
            { label: "No", value: "anticoncezionali_no" }
          ]
        }
      ],
      uid: 6
    },
    {
      condition: [{ formName: "target", value: ["ipermenorrea"] }],

      description: "Ti senti sempre stanca?",
      percentage: 80,
      questions: [
        {
          formName: "ipermenorrea_stanca",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "ipermenorrea_stanca_si" },
            { label: "No", value: "ipermenorrea_stanca_no" },
            { label: "A volte", value: "ipermenorrea_stanca_a_volte" }
          ]
        }
      ],
      uid: 7
    },
    //////////////////  ipomenorrea //////////////////////
    {
      condition: [{ formName: "target", value: ["ipomenorrea"] }],

      description: "Ti nutri in maniera corretta?",
      percentage: 40,
      questions: [
        {
          formName: "ipomenorrea_nutrizione_corretta",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "ipomenorrea_nutrizione_corretta_si" },
            { label: "No", value: "ipomenorrea_nutrizione_corretta_no" },
            { label: "A volte", value: "ipomenorrea_nutrizione_corretta_a_volte" }
          ]
        }
      ],
      uid: 8
    },
    {
      condition: [{ formName: "target", value: ["ipomenorrea"] }],

      description: "Ti senti ansiosa?",
      percentage: 50,
      questions: [
        {
          formName: "ipomenorrea_ansia",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "ipomenorrea_ansia_si" },
            { label: "No", value: "ipomenorrea_ansia_no" },
            { label: "Spesso", value: "ipomenorrea_ansia_spesso" }
          ]
        }
      ],
      uid: 9
    },
    {
      condition: [{ formName: "target", value: ["ipomenorrea"] }],

      description: "Sei vicino alla menopausa?",
      percentage: 60,
      questions: [
        {
          formName: "menopausa",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "menopausa_si" },
            { label: "No", value: "menopausa_no" }
          ]
        }
      ],
      uid: 10
    },
    {
      condition: [{ formName: "target", value: ["ipomenorrea"] }],

      description: "Soffri di stress psicofisico?",
      percentage: 80,
      questions: [
        {
          formName: "ipomenorrea_stress",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "ipomenorrea_stress_si" },
            { label: "No", value: "ipomenorrea_stress_no" }
          ]
        }
      ],
      uid: 11
    },
    //////////////////  DISMENORREA //////////////////////
    {
      condition: [{ formName: "target", value: ["dismenorrea"] }],

      description: "Ti sono stati riscontrati fibromi uterini?",
      percentage: 40,
      questions: [
        {
          formName: "fibromi",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "fibromi_si" },
            { label: "No", value: "fibromi_no" }
          ]
        }
      ],
      uid: 12
    },
    {
      condition: [{ formName: "target", value: ["dismenorrea"] }],

      description: "I dolori sono frequenti ad ogni ciclo con fitte dolorose?",
      percentage: 60,
      questions: [
        {
          formName: "fitte_dolorose",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "fitte_dolorose_si" },
            { label: "No", value: "fitte_dolorose_no" },
            { label: "A volte", value: "fitte_dolorose_a_volte" }
          ]
        }
      ],
      uid: 13
    },
    {
      condition: [{ formName: "target", value: ["dismenorrea"] }],

      description: "Si accompagnano anche lombalgia, mal di testa, mal di schiena?",
      percentage: 80,
      questions: [
        {
          formName: "lombalgia",
          fullwidth: true,
          kind: "radiobox",
          order: 1,
          values: [
            { label: "Si", value: "lombalgia_si" },
            { label: "No", value: "lombalgia_no" },
            { label: "A volte", value: "lombalgia_a_volte" }
          ]
        }
      ],
      uid: 14
    }
  ]
};
